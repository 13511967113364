import styled from "styled-components";
import { Breadcrumbs } from '@material-ui/core';

export const Container = styled.div`
    width: 100%;
    height: 30px;
`

export const BreadcrumbsContainer = styled(Breadcrumbs)`
  a, p {
    text-decoration: none;
  }
`;

interface Props {
  isLast: boolean;
}

export const Span = styled.div<Props>`
  font-weight: ${(props: Props) => props.isLast ? 'bold' : 'normal'};
  cursor: ${(props: Props) => props.isLast ? 'auto' : 'pointer'};
  font-size: 12px;
  color: #222222;
`;