import styled from 'styled-components';
import Background from '../../../assets/png/bg-replacement-return.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderHero = styled.div`
  background: url(${Background});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  height: 400px;

  @media screen and (max-width: 1279px) {
    height: 214px;
  }

  @media screen and (max-width: 607px) {
    height: 160px;
  }
`;

export const HeaderHeroInfo = styled.div`
  margin-left: 57%;
  width: 460px;

  @media screen and (max-width: 1279px) {
    width: 438px;
  }

  @media screen and (max-width: 607px) {
  }
`;

export const HeaderHeroTitle = styled.h1`
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  font-family: var(--poppins);
  color: var(--white);
  margin-bottom: 16px;

  @media screen and (max-width: 1279px) {
    font-size: 24px;
    max-width: 220px;
    line-height: 35px;
  }

  @media screen and (max-width: 607px) {
    font-size: 16px;
    max-width: 152px;
    line-height: 16px;
    margin-bottom: 18px;
  }
`;

export const HeaderHeroDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: var(--roboto);
  color: var(--white);
  line-height: 18px;

  @media screen and (max-width: 1279px) {
    font-size: 14px;
    line-height: 16px;
    max-width: 220px;
  }

  @media screen and (max-width: 607px) {
    font-size: 10px;
    line-height: 11px;
    max-width: 125px;
    margin-left: 28px;
  }
`;

export const Content = styled.div`
  width: var(--container);
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1279px) {
    padding: 26px 0 100px 0;
  }

  @media screen and (max-width: 607px) {
    padding: 16px 0 100px 0;
  }
`;

export const Title = styled.h3`
  font-size: 36px;
  font-weight: 700;
  font-family: var(--roboto);

  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }

  @media screen and (max-width: 607px) {
    font-size: 16px;
  }
`;

export const CardContainer = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 190px);
    grid-gap: 100px;
  }

  @media screen and (max-width: 1279px) {
    margin-top: 50px;

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 190px);
      grid-gap: 50px 100px;
    }
  }

  @media screen and (max-width: 607px) {
    margin-top: 40px;

    .grid {
      display: grid;
      grid-template-columns: repeat(1, 190px);
      grid-gap: 24px 100px;
    }
  }
`;

export const AccordionContainer = styled.div`
  max-width: 848px;
  width: 100%;
  margin: 100px 0 56px;

  @media screen and (max-width: 1279px) {
    margin: 100px 0 88px;
  }

  @media screen and (max-width: 607px) {
    margin: 100px 0 288px;
    padding: 0 8px;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey-light-1);
  margin: 16px 0;
`;

export const LinkFAQ = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: var(--roboto);
  cursor: pointer;
  color: var(--grey-medium-1);

  &:hover {
    text-decoration: underline;
  }
`;

