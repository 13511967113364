import React from 'react';
import { ArrowButton } from '../styles';

import { ReactComponent as ArrowBack } from '../../../assets/svg/icon-arrow-back.svg';
import { ReactComponent as ArrowNext } from '../../../assets/svg/icon-arrow-next.svg';

interface NextArrow {
  currentSlide?: number;
  className?: string;
  'data-role'?: string;
  onClick?: () => void;
  slideCount?: number;
  style?: {
    display?: string;
  };
}

interface PreviousArrow {
  currentSlide?: number;
  slideCount?: number;
  className?: string;
  'data-role'?: string;
  onClick?: () => void;
  style?: {
    display?: string;
  };
}

export function SampleNextArrow({
  currentSlide,
  ...props
}: NextArrow): JSX.Element {
  return (
    <ArrowButton
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <ArrowBack />
    </ArrowButton>
  );
}

export function SamplePrevArrow({
  currentSlide,
  slideCount,
  ...props
}: PreviousArrow): JSX.Element {
  return (
    <ArrowButton
      {...props}
      className={
        'slick-next slick-arrow' +
        (slideCount && currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={!!(slideCount && currentSlide === slideCount - 1)}
      type="button"
    >
      <ArrowNext />
    </ArrowButton>
  );
}
