import React, { FormEvent, useCallback } from 'react';
import {
  Container,
  Title,
  ForgotPassword,
  ForgotEmail,
  ButtonWrapper,
  InputWrapper
} from './styles';
import useForm from '../../../../hooks/useForm';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { handleErrors } from '../../../../helpers/utils';
import { useAuth } from '../../../../hooks/useAuth';
import { useToast } from '../../../../hooks/useToast';
import { Link } from 'react-router-dom';
import { useLoading } from '../../../../hooks/useLoading';
import {IValidateUser} from '../index';

interface Props {
  navigateTo: string;
  setValidateUser:  React.Dispatch<React.SetStateAction<IValidateUser>>
  setRegisterStep: (step: 'initial' | 'createAccount') => void;
}

const Login: React.FC<Props> = ({ navigateTo, setValidateUser, setRegisterStep }) => {
  const {
    form, error, onChange, validateForm, onBlur, setError
  } = useForm({
    email: {
      type: 'email',
      required: true,
      limit: 100
    },
    password: {
      type: 'password',
      required: true,
      limit: 100
    }
  });
  const { signIn } = useAuth();
  const { toast } = useToast();
  const { showLoading, closeLoading } = useLoading();

  const queryValidation = navigateTo !== '/' ? `?ReturnUrl=${navigateTo}` : '';

  const handleUserLogin =  useCallback(async () => {
    showLoading();
    const e = await signIn(form, navigateTo);

    if(e.type === 'internal') {
      toast({
        description: e.message,
        type: 'error'
      });
    }
    if (e.type === 'invalidCode') {
      setValidateUser({
        userId: e.data.id,
        email: form.email,
        password: form.password
      });
      setRegisterStep('createAccount')
    }
    if (e.errors) {
      setError(handleErrors(e, error));
      closeLoading();
      return;
    }
    closeLoading();
  }, [showLoading, signIn, form, navigateTo, closeLoading, toast, setValidateUser, setRegisterStep, setError, error]);


  const handleSumit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    const isValid = validateForm();
    if(!isValid) {
      return;
    }
    await handleUserLogin();
  }, [validateForm, handleUserLogin]);


  return (
    <Container onSubmit={handleSumit}>
      <Title>Já sou cliente</Title>
      <InputWrapper>
        <Input
          title='Login'
          type="email"
          value={form.email}
          onBlur={() => onBlur('email')}
          onChange={(e) => onChange('email', e)}
          error={error.email}
          required
          placeholder='Insira o e-mail'
        />
        <div>
          <Input
            title={'Senha'}
            type="password"
            value={form.password}
            onBlur={() => onBlur('password')}
            onChange={(e) => onChange('password', e)}
            error={error.password}
            required
            placeholder='********'
          />
          <ForgotPassword>
            <Link to={`/login/recuperar-senha${queryValidation}`}>Esqueci minha senha</Link>
          </ForgotPassword>
        </div>
      </InputWrapper>
      <ButtonWrapper>
        <Button color='var(--purple)' scale='scale-4' expand>Entrar</Button>
      </ButtonWrapper>
      <ForgotEmail>Não sabe seu e-mail? <Link to={`/login/recuperar-email${queryValidation}`}>Clique aqui</Link></ForgotEmail>
    </Container>
  );
};

export default Login;
