import React from 'react';
import {
  ButtonEdit,
  Container,
  AddressContainer,
  Content,
  AddressContent,
  Row,
  EmptyContainer,
  ErrorText,
} from './styles';
import { AddressInterface } from '../../../../interfaces/address';

interface Props {
  data?: AddressInterface | null;
  updateAddress: (item: AddressInterface) => void;
  error?: boolean;
}

const AddressCard: React.FC<Props> = ({ data, updateAddress, error }) => {
  return (
    <Container>
      <Content className={error ? 'error' : ''}>
        {data && (
          <AddressContainer>
            <Row>
              <AddressContent>
                <h3>{data.title}</h3>
                <p>
                  {data.street}, {data.number},{' '}
                  {data.adjunct ? data.adjunct + ' - ' : ''} {data.neighborhood}{' '}
                  - {data.city} -{data.uf} CEP {data.zipCode}
                </p>
              </AddressContent>
            </Row>
            <ButtonEdit onClick={() => updateAddress(data)}>
              <i className="icon icon-edit" />
            </ButtonEdit>
          </AddressContainer>
        )}
        {!data && (
          <EmptyContainer>
            <p>Você ainda não possui um endereço cadastrado</p>
          </EmptyContainer>
        )}
      </Content>

      {error && (
        <ErrorText>
          É necessário cadastrar e selecionar um endereço para entrega do
          pedido.
        </ErrorText>
      )}
    </Container>
  );
};

export default AddressCard;
