import { Container, BreadcrumbsContainer, Span } from "./styles"
import React from "react"
import { Link } from 'react-router-dom';

interface Props {
  values: { title: string, link: string, param?: string, }[];
}

const Breadcrumb: React.FC<Props> = ({ values }) => {
  const lastPosition = values.length - 1;

  function isLast(index: number) {
    return index == lastPosition
  }

  return (
    <Container>
      <BreadcrumbsContainer separator="›" aria-label="breadcrumb">
        {values.map((val, index) => {
          if (isLast(index)) {
            return <Span isLast={true}>{val.title}</Span>
          } else {
            return <Link to={val.link + (val.param ?? '')} key={index}>
              <Span isLast={false} >{val.title}</Span>
            </Link>
          }
        })}
      </BreadcrumbsContainer>
    </Container>
  );
}

export default Breadcrumb;