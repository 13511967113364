import styled from 'styled-components';


import {ReactComponent as Sales} from '../../../assets/svg/increased-sales.svg';
import {ReactComponent as Dollar} from '../../../assets/svg/circle-dolar.svg';
import {ReactComponent as Phone} from '../../../assets/svg/phone-chart.svg';
import {ReactComponent as Security} from '../../../assets/svg/security.svg';
import {ReactComponent as Reports} from '../../../assets/svg/reports.svg';
import {ReactComponent as ImHere} from '../../../assets/svg/to-aqui.svg';
import {ReactComponent as Stores} from '../../../assets/svg/stores.svg';
import {ReactComponent as PhoneCloset} from '../../../assets/svg/phone-closet.svg';


export const Banner = styled.div`
  background-color: var(--grey-light-1);

  padding: 0 calc((100% - var(--container)) / 2);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 160px;

  column-gap: 33px;

  @media (min-width: 607px) {
    height: 214px;
    column-gap: 106px;
  }

  @media (min-width: 1279px) {
    height: 450px;
    column-gap: 155px;
  }
`;

export const BannerPhoto = styled.div`
  img {
    height: 160px;
  }
  @media (min-width: 607px) {
    img {
      height: 214px;
    }
  }
  @media (min-width: 1279px) {
    img {
      height: 450px;
    }
  }
`;

export const BannerInfo = styled.div`
  max-width: 152px;
  color: var(--grey-dark);

  .subtitle {
    font-size: 10px;
  }

  .title {
    color: var(--purple);
    font-size: 12px;
    margin: 5px 0;
    font-weight: 400;
    line-height: 15px;
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
  }

  .text {
    font-size: 8px;
    line-height: 12px;
    margin-bottom: 7px;
  }

  > button {
    padding: 0;
    height: 24px;
    max-width: 86px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  @media (min-width: 607px) {
    max-width: 252px;

    .subtitle {
      font-size: 12px;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0 12px;
    }

    .text {
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 11px;
    }

    > button {
      max-width: 179px;
      height: 32px;
      font-size: 12px;
    }
  }

  @media (min-width: 1279px) {
    max-width: 509px;

    .subtitle {
      font-size: 20px;
    }

    .title {
      font-size: 32px;
      line-height: 40px;
    }

    .text {
      max-width: 415px;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 43.5px;
  background-color: var(--grey-light-3);

  > h1 {
    font-size: 1rem;
    text-align: center;
    color: var(--purple);
    font-weight: 400;
    line-height: 24px;
    max-width: 283px;
    margin-bottom: 24px;
    font-family: var(--poppins);
    strong {
      font-family: var(--poppins);
    }
  }

  @media (min-width: 607px) {
    padding: 33px 0;

    > h1 {
      font-size: 20px;
      max-width: 100%;
      margin-bottom: 33px;
    }
  }

  @media (min-width: 1279px) {
    padding: 58px 0 49px;

    > h1 {
      line-height: 48px;
      font-size: 32px;
      margin-bottom: 57px;
    }
  }
`;

export const BenefitsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    max-width: 179px;
    border-radius: 2px;
    font-size: 12px;
    margin-top: 24px;
  }

  @media (min-width: 607px) {
    > div {
      max-width: 563px;
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }
    > button {
      max-width: 179px;
      border-radius: 2px;
      font-size: 12px;
      margin-top: 32px;
    }
  }

  @media (min-width: 1279px) {
    > div {
      max-width: 979px;
    }

    > button {
      max-width: 240px;
      height: 60px;
      font-size: 18px;
      border-radius: 2px;
      margin-top: 56px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: flex-start;

  width: 280px;

  padding: 16px;
  margin-bottom: 24px;

  background: var(--white);
  box-shadow: 0 6.40076px 12.8015px #E5E5E5;
  border-radius: 4.26717px;


  @media (min-width: 607px) {
    width: 270px;
    height: 130px;
    margin-bottom: 30px;
  }


  @media (min-width: 1279px) {
    width: 460px;
    height: 214px;
    margin-bottom: 70px;
    padding: 32px;
    border-radius: 8px;
  }

  &:last-child, &:nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 67px;
  height: 70px;

  border: 1px solid var(--grey-light-2);
  border-radius: 8px;
  margin-right: 14px;

  @media (min-width: 607px) {
    width: 73px;
    height: 76px;
  }

  @media (min-width: 1279px) {
    width: 127px;
    height: 133px;

    margin-right: 23.7px;
  }
`;

export const CardInfo = styled.div`
  max-width: 168px;

  h3 {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 12.8px;
    color: var(--grey-dark);
  }

  p {
    font-size: 10px;
    line-height: 12px;
    color: #686868;
  }

  @media (min-width: 607px) {
    max-width: 128px;
  }

  @media (min-width: 1279px) {
    max-width: 242px;
    > h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 14px;
    }

    > p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &.security {
    max-width: 168px;
    h3 {
      margin-bottom: 4.27px;
    }
    @media (min-width: 607px) {
      max-width: 157px;
      h3 {
        margin-bottom: 5px;
      }
    }
    @media (min-width: 1279px) {
      max-width: 242px;
      > h3 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 14px;
      }
    }
  }
`;


export const SalesIcon = styled(Sales)`
  width: 33.24px;
  height: 42.22px;

  @media (min-width: 607px) {
    width: 36.21px;
    height: 45.84px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const DollarIcon = styled(Dollar)`
  width: 33.76px;
  height: 35.37px;

  @media (min-width: 607px) {
    width: 36.79px;
    height: 38.4px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const PhoneIcon = styled(Phone)`
  width: 22.71px;
  height: 37.89px;

  @media (min-width: 607px) {
    width: 24.75px;
    height: 41.14px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const SecurityIcon = styled(Security)`
  width: 31.59px;
  height: 37.52px;

  @media (min-width: 607px) {
    width: 34.42px;
    height: 40.73px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const ReportsIcon = styled(Reports)`
  width: 31.59px;
  height: 38.27px;

  @media (min-width: 607px) {
    width: 34.42px;
    height: 41.55px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const ImHereIcon = styled(ImHere)`
  width: 33.24px;
  height: 36.05px;

  @media (min-width: 607px) {
    width: 36.21px;
    height: 39.14px;
  }

  @media (min-width: 1279px) {
    width: auto;
    height: auto;
  }
`;

export const Slogan = styled.h1`
  max-width: 300px;
  margin: 24px auto;

  text-align: center;
  line-height: 24px;

  color: var(--purple);
  font-weight: 400;
  font-size: 16px;

  &, strong {
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
  }

  @media (min-width: 607px) {
    font-size: 20px;
    line-height: 30px;
    max-width: 100%;
    margin: 32px auto;
  }

  @media (min-width: 1279px) {
    font-size: 32px;
    line-height: 48px;
    margin: 32px auto 56px;
  }
`;


export const BenefitsCardsContainer = styled.div`
  max-width: 309px;
  width: 100%;
  margin: 24px auto 32.5px;

  @media (min-width: 607px) {
    max-width: 598px;
    margin: 24px auto;
  }

  @media (min-width: 1279px) {
    max-width: 1094px;
    margin: 60px auto;
  }
`;

export const BenefitsCard = styled.div`
  width: 100%;
  padding: 11.45px 16px 25px;
  background: var(--white);
  box-shadow: 0 6.54279px 13.0856px #E5E5E5;

  margin-bottom: 24px;

  @media (min-width: 607px) {
    padding: 11.45px 33.68px 43px 42px;
  }

  @media (min-width: 1279px) {
    padding: 21px 61px 39px 78px;
    margin-bottom: 60px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 67px;

  padding-bottom: 18px;
  border-bottom: 0.545232px solid var(--grey-light-1);
  margin-bottom: 16px;

  > h2 {
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--grey-dark);
    font-weight: 400;
    max-width: 187px;

    > span {
      color: var(--purple);
    }
  }

  @media (min-width: 607px) {
    > h2 {
      font-size: 20px;
      line-height: 30px;
      max-width: 100%;
    }
  }

  @media (min-width: 1279px) {
    height: 124px;
    margin-bottom: 36px;

    > h2 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const StoresIcon = styled(Stores)`
  width: 30px;
  height: 31.48px;

  @media (min-width: 607px) {
    width: 43.93px;
    height: 46.09px;
  }

  @media (min-width: 1279px) {
    width: 80.56px;
    height: 84.54px;
  }
`;

export const PhoneClosetIcon = styled(PhoneCloset)`
  width: 25px;
  height: 41.81px;

  @media (min-width: 607px) {
    width: 32.28px;
    height: 53.98px;
  }

  @media (min-width: 1279px) {
    width: 59.2px;
    height: 99px;
  }
`;

export const DividerHeader = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--grey-light-1);
  margin: 0 20px 0 28px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  > .main {
    padding: 0 11px;
  }

  @media (min-width: 607px) {
    flex-direction: row;
    align-items: center;

    > .main {
      flex: 1;
      padding: 0;

      &:first-child {
        max-width: 221px;
      }
    }
  }

  @media (min-width: 1279px) {
    > .main {
      &:first-child {
        max-width: 406px;
      }
    }
  }
`;

export const BenefitsText = styled.p`
  font-size: 10px;
  color: var(--grey-medium-1);

  > a {
    color: var(--purple);
    cursor: pointer;
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (min-width: 607px) {
    font-size: 12px;
    line-height: 14.06px;
  }

  @media (min-width: 1279px) {
    font-size: 16px;
    line-height: 18.75px;


    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Divider = styled.div`
  flex: initial;
  width: 100%;
  height: 1px;
  background-color: var(--grey-light-1);
  margin: 19px 0;

  @media (min-width: 607px) {
    height: 241px;
    max-width: 1px;
    margin: 0 35px;
  }

  @media (min-width: 1279px) {
    height: 329px;
    max-width: 1px;
    margin: 0 64px;
  }
`;


export const Title = styled.h2`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #313131;
  margin-bottom: 18px;

  font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;

  b {
    font-family: var(--poppins);
  }

  @media (min-width: 607px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (min-width: 1279px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 28px;
  }
`;

export const BenefitsRows = styled.div`
  display: flex;
  align-items: center;

  > i {
    margin-right: 18px;
    font-size: 4.7px;
    color: var(--purple);
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (min-width: 607px) {
    > i {
      margin-right: 8.32px;
    }
  }

  @media (min-width: 1279px) {
    margin-bottom: 24px;

    > i {
      font-size: 8.62px;
      margin-right: 15px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0 80px;

  > button {
    width: 179px;
    height: 32px;
  }

  @media (min-width: 1279px) {
    margin: 56px 0 120px;

    > button {
      width: 240px;
      height: 60px;
      font-size: 18px;
    }
  }
`;
