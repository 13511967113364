import styled, { createGlobalStyle } from 'styled-components';

import BackgroundImage from '../../assets/png/back-b2c.png';

export const CSS = createGlobalStyle`
  .icon-houpa-logo {
    font-size: 40px;
    color: var(--white);
  }

  .icon-go-back {
    font-size: 18px;
    color: var(--purple);
    margin-right: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-image: url(${BackgroundImage});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: 1279px) {
    background: var(--white);
    justify-content: center;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  max-height: 38px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  background-color: var(--white);
  max-width: 524px;
  width: 524px;
  border-radius: 10px;
  padding: 55px 42px 20px 42px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1279px) {
    margin: 0 auto;
    padding: 0;
    max-width: 452px;
    width: 452px;
  }

  @media (max-width: 607px) {
    margin: 0 auto;
    max-width: 328px;
    width: 328px;
    padding: 20px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  margin-top: 15px;
`;

export const GoBackField = styled.div`
  display: none;

  @media (max-width: 607px) {
    display: flex;
    margin-bottom: 65px;
  }
`;

export const GoBackLink = styled.span`
  font-size: 16px;
  color: var(--black);
  display: flex;
  align-items: center;
`;

export const TitleField = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1279px) {
    margin-bottom: 30px;
  }

  @media (max-width: 607px) {
    text-align: left;
    margin-bottom: 20px;
  }
`;

export const Title = styled.strong`
  font-weight: 700;
  font-size: 24px;
  font-family: var(--poppins);
`;

export const ButtonBack = styled.button`
  display: flex;
  outline: none;
  background: transparent;
  align-items: center;
  font-size: 1rem;
  outline: 0;
  margin-bottom: 40px;
  cursor: pointer;

  > i {
    margin-right: 5px;
    color: ${({theme}) => theme.colors.purple};
  }
`;

export const InputField = styled.div`
  margin-top: 17px;
`;

export const ForgotPasswordField = styled.div`
  margin-top: 11px;
  text-align: right;

  @media (max-width: 1279px) {
    margin-top: 5px;
  }

  @media (max-width: 607px) {
    margin-top: 24px;
    text-align: center;
  }
`;

export const ForgotPasswordLink = styled.span`
  font-family: var(--roboto);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonField = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 56px 0;

  @media (max-width: 1279px) {
    margin: 84px 77px 0;
  }

  @media (max-width: 607px) {
    margin: 38px 0 0;
    flex: 1;
    flex-direction: column;
  }
`;

export const ForgotEmailField = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 1279px) {
    margin-top: 66px;
  }

  @media (max-width: 607px) {
    margin-top: 40px;
  }
`;

export const ForgotEmailText = styled.span`
  font-family: var(--roboto);
  font-size: 16px;
  cursor: pointer;
`;

export const ForgotEmailLink = styled.span`
  font-family: var(--roboto);
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;

export const MakeRegisterField = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: 1279px) {
    margin-top: 10px;
  }
`;

export const MakeRegisterText = styled.span`
  font-family: var(--roboto);
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 608px) {
    font-size: 14px;
  }
`;

export const MakeRegisterLink = styled.span`
  font-family: var(--roboto);
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;

  @media (max-width: 608px) {
    font-size: 14px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px 0px;
  min-height: 600px;
  width: 100%;

  @media(min-width: 37.9375em){
    width: var(--container);
  }
`;
