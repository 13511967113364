import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--grey-light-1);
  margin-top: 40px;
  border-radius: 8px;
  background-color: var(--grey-light-3)
`;

export const PreFormater = styled.pre` 
  color: var(--grey-medium-4);
  line-height: 1.4;
  font-size: 14px;
`;