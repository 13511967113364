import styled from 'styled-components';

export const Container = styled.div`
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Validating = styled.p`
  text-align: center;
  color: var(--purple);
  font-family: var(--poppins);
  font-size: 16px;
  line-height: 20px;
  margin-top: 22px;
`;
