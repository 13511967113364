import styled from 'styled-components';

export const Blip = styled.div`
  position: fixed;
  opacity: 1;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;

  @media(min-width: 37.9375em){
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 70%;
    right: 10px;
    max-width: 400px;
    box-shadow: 0 -1px 12px 0 rgb(0 0 0 / 20%);
    bottom: 90px;
    border-radius: 10px;
    overflow: hidden;
  }

  @keyframes out{
    0%{
      opacity: initial;
    }

    to{
      opacity: 0;
      transform: translate(0, 100%);
    }
  }

  &.visible-false{
    animation: out .5s normal forwards;
    height: 0;
  }

  @keyframes show{
    from{
      opacity: initial;
    }

    to{
      opacity: 1;
      transform: translate(0, -3%);
    }
  }

  &.visible-true{
    @media(min-width: 37.9375em){
      animation: show .5s normal forwards;
    }
  }
`;

export const ButtonBlip = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  background-color: var(--purple);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0,0,0,1);
  box-shadow: 0px 0px 18px -5px rgba(0,0,0,1);
  z-index: 9;

  svg{
    fill: #fff;
    width: 20px;
    height: 20px;

    @media(min-width: 37.9375em){
      width: 30px;
      height: 30px;
    }
  }

  @media(min-width: 37.9375em){
    z-index: 999;
  }

  @media(min-width: 79.9375em){
    height: 60px;
    width: 60px;
  }
`;

export const CloseIcon = styled.div`
  position: fixed;
  top: 15px;
  z-index: 9999;
  right: 10px;

  @media(min-width: 37.9375em){
    display: none;
  }

  svg{
    fill: #fff;
  }
`;
