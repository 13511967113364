import React from 'react';
import {
  Banner,
  BannerPhoto,
  BannerInfo,
  BenefitsCard,
  BenefitsCardsContainer,
  BenefitsContainer,
  BenefitsContent,
  Card,
  CardIcon,
  CardInfo,
  DividerHeader,
  DollarIcon,
  PhoneIcon,
  ReportsIcon,
  SalesIcon,
  SecurityIcon,
  Slogan,
  StoresIcon,
  CardHeader,
  CardContent,
  BenefitsText,
  Divider,
  Title,
  BenefitsRows,
  PhoneClosetIcon,
  ButtonContainer,
  ImHereIcon
} from './styles';
import Button from '../../../components/Button';
import {NewsletterContainer} from '../../Checkout/stages/Confirm/styles';
import Newsletter from '../../../components/Newsletter';
import BannerImg from '../../../assets/png/image-help-2.png';
import {getIOS} from '../../../helpers/getPlatform';
import {Tooltip} from '@material-ui/core';

const ForBusiness: React.FC = () => {
  const iosPlatform = getIOS();

  const goToApp = () => {
    window.open(
      iosPlatform ?
        'https://apps.apple.com/br/app/houpa-neg%C3%B3cios/id1489513685'
        :
        'https://play.google.com/store/apps/details?id=com.houpabusiness.org&hl=pt_BR&gl=US'
    );
  };


  return (
    <section>
      <Banner>
        <BannerPhoto>
          <img src={BannerImg} alt="Duas mulheres posando para a câmera."/>
        </BannerPhoto>
        <BannerInfo>
          <p className="subtitle">Atacado e varejo na mesma rede.</p>

          <h1 className="title">Compre e venda roupas online no app houpa! negócios</h1>

          <p className="text">O houpa! é o lugar perfeito para marcas atacadistas e lojas varejistas que desejam
            expandir os negócios vendendo online.</p>

          <Button onClick={goToApp} color={'var(--purple)'} expand scale={'scale-0'}>Comece agora</Button>
        </BannerInfo>
      </Banner>

      <BenefitsContainer>
        <h1>Conheça as vantagens do <strong>houpa!</strong></h1>

        <BenefitsContent>
          <div>
            <Card>
              <CardIcon>
                <SalesIcon/>
              </CardIcon>

              <CardInfo>
                <h3>Aumento de vendas!</h3>

                <p>Milhares de multimarcas varejistas e marcas atacadistas comprando e vendendo pela plataforma do
                  houpa!</p>
              </CardInfo>
            </Card>

            <Card>
              <CardIcon>
                <SecurityIcon/>
              </CardIcon>

              <CardInfo className="security">
                <h3>Segurança nas transações. Compra e venda segura!</h3>

                <p>Navegue com segurança em toda a plataforma e realize compra e vendas de produtos, 100% seguras com
                  nosso meio de pagamento houpa!pago.</p>
              </CardInfo>
            </Card>

            <Card>
              <CardIcon>
                <DollarIcon/>
              </CardIcon>

              <CardInfo>
                <h3>Baixo investimento e diminuição de custos</h3>

                <p>O houpa! é gratuito e comprando online, você reduz custos de transporte e logística.</p>
              </CardInfo>

            </Card>

            <Card>
              <CardIcon>
                <ReportsIcon/>
              </CardIcon>

              <CardInfo>
                <h3>Relatórios </h3>

                <p>Obtenha dados e insights de marketing e negócio pela ferramenta e aplique em suas estratégias de
                  vendas.</p>
              </CardInfo>
            </Card>

            <Card>
              <CardIcon>
                <PhoneIcon/>
              </CardIcon>

              <CardInfo>
                <h3>Ferramentas exclusivas </h3>

                <p>Gerencie seu negócio via houpa! app Negócios.</p>
              </CardInfo>
            </Card>

            <Card>
              <CardIcon>
                <ImHereIcon/>
              </CardIcon>

              <CardInfo>
                <h3>Tô aqui!</h3>

                <p>Equipe de atendimento exclusiva para dar suporte ao uso da ferramenta e funcionalidades.</p>
              </CardInfo>
            </Card>
          </div>

          <Button onClick={goToApp} color={'var(--purple)'} expand scale={'scale-2'}>Cadastre-se grátis</Button>
        </BenefitsContent>
      </BenefitsContainer>

      <Slogan>Faça parte da rede que conecta &nbsp;
        <strong>todos os estilos.</strong></Slogan>

      <BenefitsCardsContainer>

        <BenefitsCard>
          <CardHeader>
            <PhoneClosetIcon/>

            <DividerHeader/>

            <h2>Vantagens para marcas <span>Varejistas</span></h2>
          </CardHeader>

          <CardContent>
            <div className={'main'}>
              <BenefitsText>
                Compre dos maiores polos de moda do Brasil e receba em casa! Faça o seu cadastro gratuito e tenha acesso
                aos lançamentos, coleções e promoções de milhares de marcas do mercado atacadista do Brasil.
              </BenefitsText>
              <BenefitsText>
                Compre online produtos do mercado atacado pelo app ou plataforma web do houpa!Negócios e economize tempo
                e dinheiro.
              </BenefitsText>
            </div>

            <Divider/>

            <div className={'main'}>
              <Title>
                Aproveite as facilidades do houpa! para você e para a <b>sua empresa.</b>
              </Title>

              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Compre de vários atacadistas em um único lugar.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Monte sua própria rede de negócios ao fazer conexão com atacadistas.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Consulte estoque em tempo real, 24h por dia.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Compre peças de diversas marcas em um mesmo pedido e faça apenas um pagamento
                  único.</BenefitsText>
              </BenefitsRows>

              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>E muito mais!</BenefitsText>
              </BenefitsRows>
            </div>
          </CardContent>
        </BenefitsCard>

        <BenefitsCard>
          <CardHeader>
            <StoresIcon/>

            <DividerHeader/>

            <h2>Vantagens para marcas <span>Atacadistas</span></h2>
          </CardHeader>

          <CardContent>
            <div className={'main'}>
              <BenefitsText>
                Conecte-se com diversas lojas varejistas cadastradas na plataforma, crie a sua rede e venda online para
                milhares de multimarcas
              </BenefitsText>
              <BenefitsText>
                Gerencie seu negócio com as diversas ferramentas do houpa! e alavanque suas estratégias de vendas.
              </BenefitsText>
            </div>

            <Divider>

            </Divider>

            <div className={'main'}>
              <Title>
                Liberdade para o seu <b>negócio.</b>
              </Title>

              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Configure suas próprias regras de venda e administre sua lista de clientes.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Venda mesmo com a sua loja física fechada, 24h por dia.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>Cadastro de produtos ilimitado.</BenefitsText>
              </BenefitsRows>


              <BenefitsRows>
                <i className="icon icon-success"/>
                <BenefitsText>
                  Planos houpa! exclusivos para o seu negócio. 
                  <Tooltip
                    placement='bottom'
                    enterTouchDelay={0}
                    leaveTouchDelay={3500}
                    title={
                      <p style={{textAlign: 'center'}}>Estamos trabalhando nisso, liberaremos em breve</p>
                    }
                  >
                    <a>Conheça!</a>
                  </Tooltip>
                </BenefitsText>
              </BenefitsRows>
            </div>
          </CardContent>

        </BenefitsCard>
      </BenefitsCardsContainer>

      <ButtonContainer>
        <Button onClick={goToApp} scale={'scale-0'} expand color={'var(--purple)'}>Cadastre-se grátis</Button>
      </ButtonContainer>

      <NewsletterContainer>
        <Newsletter/>
      </NewsletterContainer>
    </section>
  );
};

export default ForBusiness;
