import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  
  @media (min-width:1281px) {
    padding-left: 80px;
  }

  margin-bottom: 10px;
`

export const ShowMobile = styled.div`
  padding-bottom: 17px;

  @media (min-width:481px)  {  
    display: none;
  }
`

export const ShowDesktop = styled.div`
  display: none;

  @media (min-width:481px)  {
    display: block;
  }
`

export const Container = styled.div`
  max-width: var(--container);
  padding: 10px 0;
  margin: 20px auto;

  display: flex;
  flex-direction: column;

  @media (max-width: 1279px) {
    margin: 20px auto;
  }

  @media (max-width: 607px) {
    width: 100%;
  }

  .main-content {
    display: flex;
    margin: 0 auto;

    .container-images {
      margin-right: 69px;
      max-width: 631px;

      .description-container {
        width: 100%;
        margin-top: 49px;

        padding-left: 107px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        color: ${(props) => props.theme.colors.greyDark};

        .title {
          font-size: 20px;
          font-family: Roboto-Medium;

          margin-bottom: 17px;
        }

        .text {
          font-size: 14px;
        }
      }

      @media (max-width: 1279px) and (min-width: 608px) {
        margin-right: 18px;
        max-width: 373px;

        .description-container {
          margin-top: 23px;

          padding-left: 0;

          .title {
            font-size: 16px;
          }

          .text {
            font-size: 12px;
          }
        }
      }
    }

    .hide-desktop {
      display: none;
    }

    .info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 8px;

      > h2 {
        font-size: 14px;
        font-family: Roboto-Light;

        text-transform: uppercase;

        color: ${(props) => props.theme.colors.greyDark};
      }

      .right-header {
        display: flex;

        .tag {
          width: max-content;
          padding: 3px 8px;

          background: ${(props) => props.theme.colors.greyLight2};

          font-size: 10px;

          color: ${(props) => props.theme.colors.greyMedium1};

          text-transform: uppercase;

          display: flex;
          align-items: center;
        }
      }
    }

    .product-name {
      font-size: 24px;
      font-family: Roboto-Light;

      text-transform: uppercase;

      color: ${(props) => props.theme.colors.greyDark};

      width: 100%;

      flex-wrap: wrap;

      @media screen and (min-width: 607px) and (max-width: 1279px) {
        font-size: 14px;
      }
      @media screen and (max-width: 607px) {
        font-size: 16px;
      }
    }

    .subtitle-container {
      display: flex;
      margin-top: 11px;
      margin-bottom: 39px;

      .seller {
        font-size: 12px;
        color: ${(props) => props.theme.colors.greyDark};
        margin-right: 16px;

        .seller-name {
          font-family: Roboto-Medium;
          text-decoration-line: underline;
          color: ${(props) => props.theme.colors.greyDark};
        }
      }

      .sku {
        font-size: 12px;
        color: ${(props) => props.theme.colors.greyLight1};
      }
    }

    .price-container {
      display: flex;
      flex-direction: column;
      color: ${(props) => props.theme.colors.greyDark};

      margin-bottom: 28px;

      .original-price {
        font-size: 14px;
        color: ${(props) => props.theme.colors.greyMedium4};
        text-decoration-line: line-through;
      }

      .price-box {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .price {
          font-size: 30px;
          font-family: Roboto-Medium;
          margin-right: 5px;
        }

        .percentage {
          font-size: 14px;
          color: ${(props) => props.theme.colors.redDark};
        }
      }

      .price-description {
        font-size: 10px;
      }
    }

    .container-info {
      width: 404px;

      display: flex;
      flex-direction: column;

      .hide-mobile {
        display: flex;
        flex-direction: column;
      }

      .product-description {
        font-size: 12px;
        color: ${(props) => props.theme.colors.greyDark};

        margin-bottom: 24px;

        .see-more-button {
          font-size: 12px;
          font-family: Roboto-Medium;
          text-decoration-line: underline;

          color: ${(props) => props.theme.colors.greyMedium4};

          background: ${(props) => props.theme.colors.white};

          cursor: pointer;
        }
      }

      .list-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        > span {
          font-size: 12px;
          color: ${(props) => props.theme.colors.greyDark};
          margin-bottom: 8px;

          .selected-name {
            font-size: 13px;
            font-family: Roboto-Medium;

            text-transform: capitalize;
          }

          .selected-color{
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: ${(props) => props.theme.colors.greyMedium4};
            text-transform: uppercase;
          }

          .sold-out{
            text-transform: uppercase;
            border-radius: 2px;
            margin-left: 5px;
            box-shadow: 0px 1px 1px 0px #00000026;
            color: ${(props) => props.theme.colors.redDark};
            font-weight: bold;
            padding: 2px 8px;
          }
        }

        .list {
          display: flex;
          flex-wrap: wrap;
        }

        .list-error {
          font-size: 12px;
          color: ${(props) => props.theme.colors.redDark};
          margin-top: 8px;
        }
      }

      .freight-container {
        width: 100%;

        margin-top: 45px;
        margin-bottom: 30px;

        padding: 24px;

        display: flex;
        flex-direction: column;

        border: 1px solid ${(props) => props.theme.colors.greyLight2};
        border-radius: 8px;

        .freight-title {
          font-size: 14px;
          font-family: Roboto-Bold;
          margin-bottom: 16px;
          color: ${(props) => props.theme.colors.greyDark};
        }

        .freight-subtitle {
          font-size: 12px;
          margin-bottom: 16px;
          color: ${(props) => props.theme.colors.greyDark2};
        }

        .cep {
          display: flex;
          align-items: center;

          .input-cep {
            width: 190px;
            height: 36px;
            margin-right: 12px;
          }

          .button-cep {
            width: 41px;
            margin-right: 16px;
          }

          > a {
            font-size: 10px;
            font-family: Roboto-Medium;
            text-decoration-line: underline;
            color: ${(props) => props.theme.colors.greyDark};
          }
        }

        .freight-list {
          display: flex;
          flex-direction: column;
          margin-top: 23px;

          .freight-item {
            display: grid;
            grid-template-columns: 10% 50% 30%;
            column-gap: 5px;

            align-items: center;

            :not(:last-child) {
              margin-bottom: 24px;
            }

            color: ${(props) => props.theme.colors.black};

            > i {
              font-size: 16px;
              margin-right: 21px;
            }

            .freight-info {
              display: flex;
              flex-direction: column;

              .freight-name {
                font-size: 14px;
                font-family: Roboto-Bold;
                margin-bottom: 8px;
                color: ${(props) => props.theme.colors.greyDark};
              }

              .delivery-time {
                font-size: 12px;
                color: ${(props) => props.theme.colors.greyDark2};
              }
            }

            > p {
              font-size: 12px;
              text-transform: uppercase;
              font-family: Roboto-Light;
              text-align: center;
            }
          }
        }
      }

      .accordion-body {
        margin-top: 16px;

        > p {
          font-size: 14px;
        }
      }

      @media (max-width: 1279px) and (min-width: 608px) {
        width: 220px;

        .info-header {
          margin-bottom: 8px;

          > h2 {
            font-size: 10px;
          }

          .right-header {
            .tag {
              font-size: 8px;
            }

            .share-button {
              > i {
                font-size: 12px;
              }
            }
          }
        }

        .product-name {
          font-size: 14px;
        }

        .subtitle-container {
          .seller {
            font-size: 10px;
          }

          .sku {
            font-size: 10px;
          }
        }

        .price-container {
          .original-price {
            font-size: 10px;
          }

          .price-box {
            .price {
              font-size: 20px;
            }

            .percentage {
              font-size: 10px;
            }
          }

          .price-description {
            font-size: 8px;
          }

          .product-description {
            font-size: 10px;

            .see-more-button {
              font-size: 10px;
            }
          }
        }

        .list-container {
          margin-bottom: 16px;
        }

        .freight-container {
          margin-top: 24px;
          margin-bottom: 18px;

          padding: 10px;

          .freight-title {
            font-size: 12px;
            margin-bottom: 4px;
          }

          .freight-subtitle {
            font-size: 10px;
            margin-bottom: 12px;
          }

          .cep {
            flex-wrap: wrap;

            .input-cep {
              width: 133px;
              height: 32px;
            }

            .button-cep {
              width: 41px;
              margin-right: 0;
            }

            > a {
              font-size: 10px;
              margin-top: 8px;
            }
          }

          .freight-list {
            .freight-item {
              > i {
                font-size: 12px;
              }

              .freight-info {
                .freight-name {
                  font-size: 10px;
                  margin-bottom: 4px;
                }

                .delivery-time {
                  font-size: 8px;
                }
              }

              > p {
                font-size: 8px;
              }
            }
          }
        }

        .accordion-body {
          margin-top: 8px;

          > p {
            font-size: 10px;
          }
        }
      }
    }

    @media (max-width: 607px) {
      flex-direction: column;

      .container-images {
        margin-right: 0;
        max-width: 100%;

        .description-container {
          margin-top: 24px;

          padding-left: 0;

          .title {
            font-size: 14px;

            margin-bottom: 10px;
          }

          .text {
            font-size: 10px;
          }
        }
      }

      .hide-desktop {
        display: flex;
        flex-direction: column;
      }

      .info-header {
        margin-bottom: 8px;

        > h2 {
          font-size: 10px;
        }

        .right-header {
          .tag {
            font-size: 8px;
          }

          .share-button {
            margin-left: 16px;

            > i {
              font-size: 12px;
            }
          }
        }
      }

      .product-name {
        font-size: 16px;
      }

      .subtitle-container {
        margin-bottom: 30px;
        justify-content: space-between;

        .seller {
          font-size: 10px;
          margin-right: 0;
        }

        .sku {
          font-size: 10px;
        }
      }

      .price-container {
        .original-price {
          font-size: 10px;
        }

        .price-box {
          .price {
            font-size: 20px;
          }

          .percentage {
            font-size: 10px;
          }
        }

        .price-description {
          font-size: 8px;
        }
      }

      .container-info {
        width: 100%;

        .hide-mobile {
          display: none;
        }

        .freight-container {
          width: 100%;
          margin-top: 24px;
          padding: 10px;

          .freight-title {
            font-size: 12px;
            margin-bottom: 5px;
          }

          .freight-subtitle {
            font-size: 10px;
            margin-bottom: 12px;
          }

          .cep {
            flex-wrap: wrap;

            .input-cep {
              width: 227px;
              height: 32px;
            }

            .button-cep {
              margin-right: 0;
            }

            > a {
              font-size: 10px;
              margin-top: 8px;
            }
          }

          .freight-list {
            .freight-item {
              > i {
                font-size: 12px;
              }

              .freight-info {
                .freight-name {
                  font-size: 10px;
                  margin-bottom: 4px;
                }

                .delivery-time {
                  font-size: 8px;
                }
              }

              > p {
                font-size: 10px;
              }
            }
          }
        }

        .accordion-body {
          > p {
            font-size: 10px;
          }
        }
      }
    }
  }

  .suggestions {
    display: none;

    @media (min-width: 37.9375em) {
      display: flex;
    }

    flex-direction: column;

    margin: 100px auto auto;

    padding-left: 160px;

    .list-products-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 85px;

      .list-products-title {
        font-size: 18px;
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 24px;
      }

      .list-products {
        display: grid;
        grid-template-columns: repeat(3, 308px);
        grid-column-gap: 65px;

        margin-bottom: 50px;
      }

      .see-more-products {
        font-size: 16px;
        font-family: Roboto-Medium;
        color: ${(props) => props.theme.colors.greyDark};
        text-decoration-line: underline;
      }
    }

    @media (max-width: 1279px) and (min-width: 608px) {
      margin-top: 40px;

      padding-left: 0;

      .list-products-container {
        margin-bottom: 60px;

        .list-products-title {
          font-size: 14px;
          margin-bottom: 24px;
        }

        .list-products {
          grid-template-columns: repeat(3, 190px);
          grid-column-gap: 18px;

          margin-bottom: 22px;
        }

        .see-more-products {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 607px) {
      margin-top: 36px;
      padding-left: 0;

      .list-products-container {
        margin-bottom: 51px;

        display: flex;
        justify-content: center;
        align-items: center;

        .list-products-title {
          font-size: 14px;
          margin-bottom: 12px;
        }

        .list-products {
          display: flex;
          flex-direction: column;

          margin-bottom: 50px;
        }

        .see-more-products {
          font-size: 16px;
          font-family: Roboto-Medium;
          color: ${(props) => props.theme.colors.greyDark};
          text-decoration-line: underline;
        }
      }
    }
  }
`;

interface ColorInterface {
  hex?: string;
  active?: boolean;
}

export const Color = styled.button<ColorInterface>`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  border-radius: 50%;

  cursor: pointer;

  border: 1.5px solid
    ${(props) =>
    props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  .color-content {
    width: 24px;
    height: 24px;

    border: 1px solid ${(props) => props.theme.colors.greyLight1};
    border-radius: 50%;

    background: ${(props) =>
    props.hex !== undefined ? props.hex : props.theme.colors.white};

    > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
`;

interface SizeInterface {
  available: boolean;
  active?: boolean;
}

export const Size = styled.div<SizeInterface>`
  width: 40px;
  height: 40px;

  margin-right: 11px;

  border-radius: 50%;
  border: 1.5px solid
    ${(props) =>
    props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors.white};

  cursor: ${(props) => (props.available ? 'pointer' : 'not-allowed')};

  .size-content {
    width: 32px;
    height: 32px;

    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.greyLight1};

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.colors.greyLight3};

    position: relative;
    z-index: 1;

    > span {
      font-size: 12px;
      color: ${props => props.theme.colors.greyDark};
      text-transform: uppercase;
      font-weight: bold;
    }

    .disabled {
      display: flex;
      width: 100%;
      height: 100%;

      border-radius: 50%;

      background: #DCDCDC;

      z-index: 2;

      position: absolute;

      align-items: center;
      justify-content: center;
      text-align: center;

      >span {
        font-weight: bold;
        width: 100%;
        font-size: 12px;
        color: ${(props) => props.theme.colors.greyMedium4};
      }

      .line {
        position: absolute;
        width: 100%;
        height: 2px;
        left: 50%;
        top: 50%;
        background: ${(props) => props.theme.colors.greyLight1};
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

export const Break = styled.div`
  width: 100%;
  margin-bottom: 13px;
`;

export const SlickStyled = styled.div`
  max-width: 217px;
  margin: 0 auto 40px auto;

  @media (min-width: 37.9375em) {
    display: none;
  }
`;

export const ArrowButton = styled.button`
  width: auto;
  height: auto;

  &:before {
    content: '';
  }
`;

export const SlickTitle = styled.h1`
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
`;
