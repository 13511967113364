import React, { useState } from 'react';
import { CardInfo, CardHeader, CardBody, Content, Container } from './styles';

import SideCart from '../../../components/SideCart';
import Button from '../../../components/Button';

const SideCartExample: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Container>
      <Content>
        <h2>Tooltips</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <h1>Utilização:</h1>
          </CardHeader>
          <CardBody>
            <p>import SideCart from 'components/SideCart';</p>
          </CardBody>
          <CardHeader>
            <h1>Menu de carrinho lateral</h1>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>
                Abrir o menu de carrinho lateral
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <pre>
                {`
                  const [isOpen, setIsOpen] = useState<boolean>(false);

                  <SideCart setIsOpen={setIsOpen} isOpen={isOpen}/>
                `}
              </pre>
            </div>
          </CardBody>
        </CardInfo>
      </Content>
      <SideCart setIsOpen={setIsOpen} isOpen={isOpen} />
    </Container>
  );
};

export default SideCartExample;
