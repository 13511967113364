import styled from 'styled-components';

export const Container = styled.form`
  width: 301px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
    width: 100%; 
  }
`;

export const Title = styled.h2`
  font-family: var(--poppins);
  font-size: 24px;
  line-height: 28px;
  color: var(--grey-dark);
  font-weight: 700;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 32px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    row-gap: 48px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 50px;
    row-gap: 36px;
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  > a {
    font-size: 12px;
    text-decoration: underline;
    color: var(--black);
    line-height: 14px;
    cursor: pointer;
  }
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 607px) {
    justify-content: center;
  }
`;

export const ForgotEmail = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--grey-dark);
  > a {
    color: var(--grey-dark);
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 607px) {
    font-size: 14px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 45px auto 0;
    width: 296px;
  }
  @media screen and (max-width: 607px) {
    margin: 35px auto 0;
    width: 152px;
  }
`;