import React from 'react';

interface Props {
  icon: React.ReactNode;
  text: string;
  textWidth?: string;
}

import {
  Container, IconContainer, Text
} from './styles';

const InstitutionalCard: React.FC<Props> = ({ icon, text, textWidth }) => {
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <Text textWidth={textWidth}>{text}</Text>
    </Container>
  );
};

export default InstitutionalCard;
