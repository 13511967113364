import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Input, Button } from './styles';

interface Props {
  quantity: number;
  onValueChange: (newValue: number) => Promise<unknown>;
}

const InputQuantity: React.FC<Props> = ({ quantity, onValueChange }) => {
  const [value, setValue] = useState(quantity);

  const previousValue = useRef(0);

  const handleValueChange = useCallback(
    (newValue: number) => {
      onValueChange(newValue)
        .then(() => {
          previousValue.current = newValue;
        })
        .catch(() => {
          setValue(previousValue.current);
        });
    },
    [onValueChange],
  );

  const Increment = useCallback(() => {
    const newValue = value + 1;

    handleValueChange(newValue);
    setValue(newValue);
  }, [handleValueChange, value]);

  const Decrement = useCallback(() => {
    if (value === 0) {
      return;
    }

    const newValue = value - 1;
    handleValueChange(newValue);
    setValue(newValue);
  }, [handleValueChange, value]);

  useEffect(() => {
    previousValue.current = quantity;

    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Button onClick={Decrement}>-</Button>
      <Input disabled value={value} />
      <Button onClick={Increment}>+</Button>
    </Container>
  );
};

export default InputQuantity;
