import React, { useCallback, useContext, useRef } from 'react';
import OrderSummary from '../../components/OrderSummary';
import {
  Container,
  Content,
  GridProducts,
  Items,
  SuggestionsContainer,
  TipsContainer,
} from './styles';
import { SummaryContainer } from '../../styles';
import CartItem from '../../components/CartItem';
import { ReactComponent as Arrows } from '../../../../assets/svg/arrows.svg';
import { ReactComponent as HoupaHeart } from '../../../../assets/svg/houpa-heart.svg';
import { ReactComponent as Safety } from '../../../../assets/svg/safety.svg';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../../../components/ProductCard';
import Slider from 'react-slick';
import { SlickStyled } from '../../../Search/styled';
import { CartContext } from '../..';
import { SampleNextArrow, SamplePrevArrow } from './components';
import { useRecommendedProducts } from '../../../../hooks/useRecommendedProducts';

const Cart: React.FC = () => {
  const navigate = useNavigate();

  const {
    cartProducts: { products, setProducts },
    brandsWithProducts: { getBrands },
  } = useContext(CartContext);

  const [recommendedProducts] = useRecommendedProducts();

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }).current;

  const nextStage = () => {
    navigate('/carrinho/enderecos');
  };

  const onRemove = useCallback(
    (itemId: number, status: boolean) => {
      if (!status) {
        return;
      }

      setProducts((prevState) =>
        prevState.filter((product) => product.cartProductId !== itemId),
      );
    },
    [setProducts],
  );

  const handleUpdateItem = useCallback(
    (productId: number, quantity: number) => {
      setProducts((prevState) =>
        prevState.map((product) => {
          if (product.cartProductId === productId) {
            product.quantity = quantity;
          }

          return product;
        }),
      );

      getBrands();
    },
    [getBrands, setProducts],
  );

  return (
    <Container>
      <Content>
        <div>
          {products.map((item) => (
            <Items key={item.cartProductId}>
              <CartItem
                onRemove={onRemove}
                onUpdate={handleUpdateItem}
                data={item}
              />
            </Items>
          ))}
        </div>
        <SummaryContainer>
          <OrderSummary onClick={nextStage} cart />
        </SummaryContainer>
      </Content>

      <TipsContainer>
        <div>
          <Arrows />
          <p>Política de Trocas</p>
        </div>

        <div>
          <HoupaHeart />
          <p>houpa! aproximando pessoas e gerando negócios</p>
        </div>

        <div>
          <Safety />
          <p>O houpa é 100% seguro</p>
        </div>
      </TipsContainer>

      <SuggestionsContainer>
        <h4>Veja mais peças da Miranda Flor</h4>
        <GridProducts>
          {recommendedProducts.map((item) => (
            <ProductCard
              key={item.id}
              image={item.mainImage}
              hoverImage={item.mainImage}
              title={item.name}
              price={item.price}
              pricePromotion={item.originalPrice}
              parcel={item.paymentCondition}
              isFavorite={item.isFavorite}
              productId={item.id}
              productSlug={item.productSlug}
              profileUrl={item.sellerProfileUrl}
              selloffPercentage={item.selloffPercentage}
            />
          ))}
        </GridProducts>
      </SuggestionsContainer>

      <SlickStyled>
        <h4>Veja mais peças da Miranda Flor</h4>
        <Slider {...settings}>
          {recommendedProducts.map((item, key) => (
            <div key={key}>
              <ProductCard
                key={item.id}
                image={item.mainImage}
                hoverImage={item.mainImage}
                title={item.name}
                price={item.price}
                pricePromotion={item.originalPrice}
                parcel={item.paymentCondition}
                isFavorite={item.isFavorite}
                productId={item.id}
                productSlug={item.productSlug}
                profileUrl={item.sellerProfileUrl}
                selloffPercentage={item.selloffPercentage}
              />
            </div>
          ))}
        </Slider>
      </SlickStyled>
    </Container>
  );
};
export default Cart;
