import React, {Dispatch, SetStateAction} from 'react';
import {Container, Content, DrawerStyled } from './styled';
import Button from '../Button';
import FilterProduct from '../../components/FilterProduct';
import {ObjectFilter} from '../FilterProduct/interface';
import {ChangeFilterString, EditFilterString, SetFilterString} from '../../hooks/useFilterString';

interface Filter {
  id: string;
  name: string;
  value?: string;
  type: string;
  checked?: boolean;
}

interface Props {
  filter: Filter[];
  setFilter: Dispatch<SetStateAction<Filter[]>>
  data: ObjectFilter;
  filterString?: string|null;
  setFilterString?: SetFilterString;
  changeFilterString?: ChangeFilterString;
  editFilterString?: EditFilterString;
}

const Drawer: React.FC<Props> = ({filter, filterString, setFilterString, editFilterString, changeFilterString, setFilter, data }) => {
  const [drawer, setDrawer] = React.useState(false);


  function toggleDrawer(){
    setDrawer(!drawer);
  }

  function onBlurDrawer(e: React.MouseEvent<HTMLElement>){
    if (e.currentTarget === e.target) setDrawer(false);
  }

  function showDrawer(){
    return(
      <Container onClick={onBlurDrawer}>
        <Content>
          <FilterProduct
            data={data}
            filter={filter}
            setFilter={setFilter}
            setFilterString={setFilterString}
            filterString={filterString}
            changeFilterString={changeFilterString}
            editFilterString={editFilterString}
            setDrawer={setDrawer}
          />
        </Content>
      </Container>
    );
  }

  return(
    <DrawerStyled>
      <Button onClick={toggleDrawer} scale='scale-1' color='var(--purple)'>Filtrar <i className="icon icon-filter" /></Button>
      {drawer && showDrawer()}
    </DrawerStyled>
  );
};

export default Drawer;
