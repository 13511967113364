import styled from 'styled-components';


export const Container = styled.div` 
  width: 100%;
  position: relative;
`;

export const Line = styled.div` 
  position: absolute;
  top: 50%;
  background-color: var(--purple);
  height: 2px;
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

export const Circle = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--grey-light-2);
  box-shadow: 0px 0px 1px rgb(40 41 61 / 4%), 0px 2px 4px rgb(96 97 112 / 16%);
  &.active {
    background-color: var(--purple);
  }
  &.lastStep.active {
    background-color: var(--white);
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 36px;
    height: 36px;
  }
  @media screen and (max-width: 607px) {
    width: 32px;
    height: 32px;
  }
`;

export const Icon = styled.i` 
  color: var(--grey-light-3);
  font-size: 22px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 16px;
  }
  @media screen and (max-width: 607px) {
    font-size: 14px;
  }
  &.lastStep.active {
    color: var(--green-dark);
  }
`;

export const StepText = styled.p`  
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  color: var(--grey-dark);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 10px;
    bottom: -20px;
  }
  @media screen and (max-width: 607px) {
    font-size: 8px;
    bottom: -15px;
  }
`;