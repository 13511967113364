import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label` 
  font-size: 12px;
  color: var(--grey-dark);

  > span {
    color: var(--red-dark);
  }
`;

export const TextareaStyled = styled.textarea`
  resize: none;
  border-bottom: 1px solid var(--grey-dark);
  outline: none;
  margin-top: 18px;
  height: 172px;
`;

export const TextWrapper = styled.div` 
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  gap: 5px;
`;

export const Authorization = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: var(--grey-medium-1);
  max-width: 308px;
  @media screen and (max-width: 1279px) {
    font-size: 10px;
    line-height: 12px;
    max-width: 231px;
  }
  @media screen and (max-width: 607px) {
    max-width: 160px;
  }
`;

export const CharactersLimitText = styled(Authorization)`
  @media screen and (max-width: 607px) {
    max-width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  font-size: 12px;
  color: var(--red-dark);
  bottom: -20px;
  @media screen and (max-width: 607px) {
    bottom: -30px;
  }
`;