import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  padding: 40px 0;
`;

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;

  @media(min-width: 79.9375em){
    max-width: var(--container);
    display: flex;

    & > div{
      flex: 1;
    }
  }
`;

export const Clear = styled.div`
  clear: both;
`;

export const ToolsAndResults = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey);

  .tab-0, .tab-1{
    display: none;
  }
  &.control-tab-0 .tab-0{
    display: block;
  }

  &.control-tab-1 .tab-1{
    display: block;
  }

  p{
    font-size: 10px;

    @media(min-width: 37.9375em){
      font-size: 12px;
    }

    @media(min-width: 79.9375em){
      font-size: 16px;
    }
  }
`;

export const Body = styled.div`
  padding: 20px 0;
`;

export const DrawerStyled = styled.div`
  display: block;

  @media(min-width: 79.9375em){
    display: none;
  }
`;

export const AppBarStyled = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TextSearch = styled.div`
  min-width: 130px;
  color: var(--grey);
  size: 12px;
  font-weight: 400;
`;

export const NotFound = styled.div`
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;

  h1{
    font-family: Poppins-Light, Poppins-Regular, Roboto-Regular, sans-serif, Arial;
    font-size: 30px;
    font-weight: 300;
    color: var(--purple);
    margin: 10px 0;
  }

  h2{
    font-size: 24px;
    margin: 30px 0;
    color: var(--black);
    font-weight: 400;

    @media(min-width: 37.9375em){
      font-size: 24px;
    }
  }

  p{
    color: var(--grey-medium-1);
    font-size: 10px;
    line-height: 11.72px;
    max-width: 267px;
    margin: 0 auto;

    @media(min-width: 37.9375em){
      font-size: 14px;
      line-height: 22px;
      max-width: 450px;
    }

    @media(min-width: 79.9375em){
      font-size: 16px;
      line-height: 18.75px;
      max-width: 524px;
    }
  }
`;

export const SelectStyled = styled.div`
  width: 120px;
`;

export const TabsStyled = styled.div`
  flex: 1;

  button {
    border-bottom: 2px solid var(--grey-light-2);
  }

  .MuiTab-textColorInherit {
    opacity: 1;

    &.Mui-selected {
      color: var(--purple);

      .MuiTab-wrapper {
        font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
      }
    }

    @media(max-width: 607px){
      font-size: 9px;

      padding: 5px;
    }
  }
`;

export const ContainerProducts = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 157.475px);

  @media(min-width: 37.9375em){
    grid-gap: 20px;
    grid-template-columns: repeat(3, 201.06px);
  }

  @media(min-width: 79.9375em){
    grid-gap: 30px;
    grid-template-columns: repeat(4, 217px);
  }

  &[data-grid="two-columns"]{grid-template-columns: repeat(2, 435px)};
  &[data-grid="three-columns"]{grid-template-columns: repeat(3, 280px)};
  &[data-grid="four-columns"]{grid-template-columns: repeat(4, 217px)};
`;

export const Results = styled.p`
  display: none;

  span, strong {
    font-size: 14px;
  }

  @media(max-width: 79.9375em){
    display: none !important;
  }

  @media(min-width: 37.9375em){
    display: block;
  }

  @media(min-width: 79.9375em){
    font-size: 14px;
  }
`;

export const GridBlocksStyled = styled.div`
  width: 120px;
  display: none;

  @media(max-width: 79.9375em){
    display: none !important;
  }

  @media(min-width: 79.9375em){
    display: block;
  }
`;

export const FilterStyledDesktop = styled.div`
  display: none;

  @media(min-width: 79.9375em){
    display: block;
    max-width: 292px;
    margin-right: 40px;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h1{
    font-size: 20px;
  }
`;

export const ClearFilter = styled.span`
  color: #858C94;
  font-size: 10px;
  text-decoration: underline;
`;

export const ContainerBrands = styled.div`
  background-color: var(--grey-light-3);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 312px;
  margin: 0 auto 20px auto;

  @media(min-width: 37.9375em){
    max-width: 608px;
  }

  @media(min-width: 79.9375em){
    display: flex;
    max-width: 956px;
  }
`;

export const SlickStyled = styled.div`
  max-width: 217px;
  margin: 0 auto;

  > h4 {
    font-size: 14px;
    margin-bottom: 12px;
  }

  @media(min-width: 37.9375em){
    display: none;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  button{
    margin-right: 10px;
    margin-bottom: 20px;
  }
`;


export const ArrowButton = styled.button`
  width: auto;
  height: auto;

  &:before{
    content: '';
  }

`;

export const BoxBrands = styled.div`
  max-width: 217px;
  margin: 0 auto;
  text-align: center;

  @media(min-width: 37.9375em){
    max-width: 437px;
  }

  @media(min-width: 79.9375em){
    display: flex;
    max-width: 191px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
  }
`;

export const ImageBrand = styled.div`
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 68px;
  display: inline-block;
`;

export const NameBrand = styled.h1`
  font-size: 10px;

  @media(min-width: 37.9375em){
    font-size: 18px;
  }
`;

export const DescriptionBrand = styled.p`
  font-size: 12px;
  margin: 20px 0;
  color: var(--grey-medium-4);

  @media(min-width: 37.9375em){
    font-size: 14px;
  }

  @media(min-width: 79.9375em){
    font-size: 10px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media(min-width: 79.9375em){
    margin-bottom: 40px;
  }
`;

export const Tag = styled.div`
  border: 1px solid var(--grey-medium-4);
  color: var(--grey-medium-4);
  border-radius: 2px;
  padding: 2px 10px;
  margin-right: 10px;
  font-size: 12px;
  display: none;

  &:nth-of-type(1){display: block;}
  &:nth-of-type(2){display: block;}
  &:nth-of-type(3){display: block;}

  &:last-of-type{
    margin-right: 0;
  }
`;

export const BrandButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  button{
    width: 152px;
    font-size: 12px;
    border-radius: 2px;
  }
`;

export const ProductsTabletAndDesktop = styled.div`
  display: none;
  width: 100%;

  > div{
    display: none;
  }

  > div:nth-of-type(1){display: block}
  > div:nth-of-type(2){display: block}

  @media(min-width: 37.9375em){
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 279px);
  }

  @media(min-width: 79.9375em){
    > div:nth-of-type(3){display: block}
    grid-template-columns: repeat(3, 189.66px);
  }
`;
