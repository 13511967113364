import React, { useRef } from 'react';
import { 
  Container,
  Title,
  TextWrapper,
  Text,
  ButtonWrapper,
} from './styles';
import Button from '../../../Button';
import Lottie from 'react-lottie';
import SuccessAnimation from '../../../../assets/animations/check_verde.json'

interface Props {
  onClose: () => void;
}

const PaymentSuccess: React.FC<Props> = ({ onClose }) => {

  const defaultOptions = useRef({
    loop: false,
    autoplay: true,
    animationData: SuccessAnimation,
  }).current;

  return(
    <Container>
      <Lottie width={52} height={52} options={defaultOptions} />
      <Title>Você alterou a forma de pagamento deste pedido. </Title>
      <TextWrapper>        
        <Text>
          Em caso de boleto, não esqueça de pagar até a data do vencimento, 
          caso contrário, o pedido será cancelado automaticamente em 5 dias. 
        </Text>
        <Text>
          Se pagou com cartão, fique atento ao seu e-mail para 
          receber confirmação de aprovação do pagamento
        </Text>
      </TextWrapper>
      <ButtonWrapper>
        <Button 
          onClick={onClose}
          color='var(--purple)' 
          scale='scale-3'
          expand
        >
          Ok
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default PaymentSuccess;