import styled from 'styled-components';

export const PreFormat = styled.pre`
  color: var(--grey-medium-3);
`;

export const HR = styled.hr`
  margin-top: 30px; 
  width: 500px;
  height: 2px;
  color: grey;
  background: linear-gradient(to right, transparent, var(--purple), transparent);
`;