import React from 'react';
import {AreaImg, Container, Content, Image, Subtitle, Text, TextContainer, Title} from './styles';
import {Link} from 'react-router-dom';


const NotFound: React.FC = () => {

  return (
    <Container>
      <Link to={'/'}>{'< voltar à página inicial'}</Link>

      <Content>
        <TextContainer>
          <Title>Oops!</Title>

          <Image/>

          <Subtitle>Desculpe, a página que você buscou não <strong>existe ou está indisponível.</strong></Subtitle>
          <Text>Aconteceu algo inesperado e sentimos por isso. Atualize a página do seu navegador, volte à página
            inicial
            ou verifique sua conexão com a internet.</Text>
        </TextContainer>

        <AreaImg>
          <Image/>
        </AreaImg>
      </Content>
    </Container>
  );
};

export default NotFound;
