import styled from 'styled-components';

export const Container = styled.div`
`;

export const Content = styled.div`
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  padding: 16px 32px;
  height: 74px;

  &.error {
    border-color: var(--red-dark);
  }

  @media(max-width: 1279px){
    height: 93px;
  }

  @media(max-width: 607px){
    height: 75px;
    padding: 14px 24px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const AddressContent = styled.div`
  color: var(--grey-dark);

  > h3 {
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: 1279px) {
    > h3 {
      font-size: 16px;
    }

    > p {
      font-size: 14px;
    }
  }

  @media (max-width: 607px) {
    > h3 {
      font-size: 12px;
      margin-bottom: 3px;
    }

    > p {
      font-size: 10px;
      line-height: 12px;
      max-width: 227px;
    }
  }

`;

export const ButtonEdit = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--purple);
  font-size: 19px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  > p {
    color: #8E8E8E;
  }

  @media(max-width: 607px){
    font-size: 12px;
    text-align: center;
  }
`;

export const ErrorText = styled.p`
  color: var(--red-dark);
  margin-top: 12px;

  @media(max-width: 1279px){
    font-size: 12px;
    margin-top: 16px;
  }

  @media(max-width: 607px){
    margin-top: 8px;
  }
`;
