import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  padding: 40px 0;
  background-color: var(--purple);
`;

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--container);
`;

export const Clear = styled.div`
  clear: both;
`;

export const NewsletterContainer = styled.form`
  display: flex;
  align-items: flex-end;

  @media (min-width: 1279px) {
    flex: 1;
    margin-left: 20px;
    align-items: center;
  }

  & > div:first-of-type {
    margin-right: 20px;
    flex: 1;

    input {
      padding: 10px;
      height: 40px;
    }

    div:first-of-type {
      border: 1px solid var(--grey-light-2) !important;
      height: 40px;
      background-color: var(--white);
      border-radius: 4px;

      > div:first-of-type {
        margin-top: 0 !important;
      }
    }

    div:last-of-type {
      border: 0 !important;
    }
  }

  button {
    height: 40px;
    background: transparent;
    color: var(--white);
    border: 1px solid var(--grey-light-2);
  }
`;


export const Flex = styled.div`
  @media(min-width: 1279px){
    display: flex;
  }
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  color: var(--white);
  font-size: 16px;

  @media (min-width: 1279px) {
    font-size: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 607px) {
    font-size: 12px;

    &:last-of-type {
      margin-bottom: 8px;
    }
  }
`;
