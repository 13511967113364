import React, { useCallback, useContext, useState } from 'react';
import { Backdrop, Fade } from '@material-ui/core';
import { Container, Body, ButtonClose, Title, Wrapper } from './styles';
import useForm from '../../../../../../hooks/useForm';
import { useEffect } from 'react';
import { api } from '../../../../../../services/api';
import Input, { RequiredTip } from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import { CartContext } from '../../../..';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const ModalMoipAccount: React.FC<Props> = ({ open, onClose, onSave }) => {
  const {
    order: { setOrderConfig },
  } = useContext(CartContext);

  const [name, setName] = useState('');
  const { form, error, setValueForm, onChange, onBlur, validateForm } = useForm(
    {
      fullName: {
        type: 'fullname',
        required: true,
      },
      cpf: {
        type: 'cpf',
        required: true,
        limit: 14,
      },
      cellphone: {
        type: 'phone',
        required: true,
        limit: 15,
      },
      birthDate: {
        type: 'date',
        required: true,
        limit: 10,
      },
    },
  );

  const closeModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const save = useCallback(() => {
    const isFormValidated = validateForm();

    if (!isFormValidated) {
      return false;
    }

    setOrderConfig({
      clientData: {
        fullname: form.fullName,
        birthDate: form.birthDate,
        cpf: form.cpf,
        phoneNumber: form.cellphone,
      },
    });

    closeModal();
    onSave();
  }, [
    closeModal,
    form.birthDate,
    form.cellphone,
    form.cpf,
    form.fullName,
    onSave,
    setOrderConfig,
    validateForm,
  ]);

  useEffect(() => {
    api
      .get('users')
      .then(({ data }) => {
        const firstName = data.customerData.fullName.split(' ')[0];

        setName(firstName);
        setValueForm('fullName', data.customerData.fullName);
        setValueForm('cpf', data.customerData.cpf);
        setValueForm('cellphone', data.customerData.cellphone);
        setValueForm('birthDate', data.customerData.birthDate || '');
      })
      .catch();

    //eslint-disable-next-line
  }, []);

  return (
    <Container
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Body>
          <ButtonClose onClick={closeModal}>
            <i className={'icon icon-exit'} />
          </ButtonClose>

          <button className={'back-button'} onClick={closeModal}>
            <i className={'icon icon-arrow-left'} />
            <span>Voltar</span>
          </button>

          <Title id="transition-modal-title">Olá, {name}</Title>

          <Wrapper>
            <p className="subtitle">
              Agora precisamos de mais alguns dados para finalizar sua primeira
              compra com segurança.
            </p>
            <p className="description">
              Não se preocupe, vai levar poucos minutos e você só preencherá uma
              única vez.
            </p>

            <div className="form">
              <Input
                className="input"
                error={error.fullName}
                title="Nome Completo"
                placeholder="Digite seu nome completo"
                value={form.fullName}
                onChange={(e) => onChange('fullName', e)}
                onBlur={() => onBlur('fullName')}
                required
              />

              <Input
                className="input"
                error={error.cpf}
                title="CPF"
                placeholder="Digite apenas números"
                value={form.cpf}
                onChange={(e) => onChange('cpf', e)}
                onBlur={() => onBlur('cpf')}
                required
              />

              <Input
                className="input"
                error={error.birthDate}
                title="Data de Nascimento"
                placeholder="DD/MM/AAAA"
                value={form.birthDate}
                onChange={(e) => onChange('birthDate', e)}
                onBlur={() => onBlur('birthDate')}
                required
              />

              <Input
                className="input"
                error={error.cellphone}
                title="Telefone"
                placeholder="Digite o número com DDD"
                value={form.cellphone}
                onChange={(e) => onChange('cellphone', e)}
                onBlur={() => onBlur('cellphone')}
                required
              />
            </div>
            <div className="requiredTip">
              <RequiredTip removePadding />
            </div>
            <div className="save-button">
              <Button
                scale="scale-1"
                color="var(--purple)"
                onClick={() => save()}
              >
                Salvar Dados
              </Button>
            </div>
          </Wrapper>
        </Body>
      </Fade>
    </Container>
  );
};

export default ModalMoipAccount;
