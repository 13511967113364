import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  max-width: 1300px;
  padding: 57px 10px 0px;
  margin: 0 auto;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    padding: 32px 10px 0px;
    max-width: 628px;
  }
  @media screen and (max-width: 607px) {
    padding: 31px 10px 0px;
    max-width: 332px;
  }
`;

export const HelpAndSupport = styled.div`
  display: flex;
  column-gap: 65px;
  @media screen and (max-width: 1279px) {
    column-gap: 0;
    row-gap: 40px;
    flex-direction: column;
  }
`;

export const Banner = styled.img`
  width: 100%;
`;
