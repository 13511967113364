import styled from 'styled-components';

export const Container = styled.form`
`;

export const InputsPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  margin-top: 42px;
`;

export const PasswordButtonWrapper = styled.div`
  max-width: 214px;
  margin: 49px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 296px;
    margin: 131px auto 0;
  }
  @media screen and (max-width: 607px) {
    max-width: 152px;
    margin: 89px auto 0;
  }
`;

export const PasswordChanged = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 18px;
  margin-top: 80px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 117px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 47px;
  }
`;

export const PasswordChangedIcon = styled.i`
  color: var(--green-dark);
  font-size: 20px;
`;

export const PasswordChangedText = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  font-family: var(--poppins);
  color: var(--green-dark);
  @media screen and (max-width: 607px) {
    line-height: 30px;
    font-size: 20px;
  }
`;