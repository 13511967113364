import React, { useEffect, useState } from 'react';
import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  CodeBlock,
} from './styles';

import Pagination from '../../../components/Pagination';

const ProductCardExample: React.FC = () => {
  const [actualPage, setActualPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const nextPage = () => {
    if(actualPage === totalPages) return false;
    setActualPage(actualPage + 1);
  };

  const previousPage = () => {
    if(actualPage === 1) return false;
    setActualPage(actualPage - 1);
  };

  useEffect(() => {
    setTotalPages(10);
  }, []);

  return (
    <Container>
      <Content>
        <h2>Componente de paginação</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <Pagination
              index={
                actualPage <= 9
                  ? `0${actualPage.toString()}`
                  : actualPage.toString()
              }
              max={totalPages.toString()}
              onClick={(direction: string) =>
                direction === 'next' ? nextPage() : previousPage()
              }
            />
          </CardHeader>
          <CardBody>
            <p>import Pagination from "components/Pagination"</p>
            <CodeBlock>
              <h1>Pagination</h1>
              <pre>
                {`
                const [actualPage, setActualPage] = useState<number>(1);
                const [totalPages, setTotalPages] = useState<number>(10);

                const nextPage = () => {
                  if (actualPage === totalPages) {
                    return false;
                  }
                  setActualPage(actualPage + 1);
                };

                const previousPage = () => {
                  if (actualPage === 1) {
                    return false;
                  }
                  setActualPage(actualPage - 1);
                };

                <Pagination
                  index={actualPage <= 9 ? ` +
                  '`0${actualPage.toString()}`' +
                  ` : actualPage.toString()}
                  max={totalPages.toString()}
                  onClick={(direction: string) =>
                    direction === "next" ? nextPage() : previousPage()
                  }
                />
                `}
              </pre>
            </CodeBlock>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default ProductCardExample;
