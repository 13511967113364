const light = {
  title: 'light',

  colors: {
    purple: '#7F1CEF',
    purpleHover: '#861EFC',
    purpleMedium: '#5E0DB3',
    purpleBusiness: '#3D0E73',
    purpleBusinessDark: '#3F0B6D',

    white: '#FFF',
    black: '#000',

    grey: '#525252',
    greyDark: '#222222',
    greyDark2: '#333333',
    greyMedium1: '#707070',
    greyMedium2: '#676D70',
    greyMedium3: '#838c91',
    greyMedium4: '#919191',
    greyLight1: '#C8C8C8',
    greyLight2: '#E9E9E9',
    greyLight3: '#FAFAFA',

    redDark: '#D50000',
    redMedium: '#E01f26',
    redLight: '#E16868',

    yellowGold: '#FFD500',
    yellowDark: '#F0EE05',
    yellowMedium: '#F7F677',
    yellowLight: '#FAF9A7',

    greenDark: '#02CB4B',
    green: '#5DCC77',
    blue: '#188CFF',
    rose: '#FFCEC1',

    /* Hovers */
    greyDarkHover: '#3B3B3B',
    houpaPurpleHover: '#7219D6',
    houpaBusinessPurpleHover: '#4A118C',
    blueHover: '#167EE6',
    greyLightHover1: '#ADADAD',
    yellowDarkHover: '#D6D404',
    greenHover: '#51B368',
    redDarkHover: '#A30000',
    whiteHover: '#E6E6E6',
  },
};

export default light;
