import {CartResume, UseCartProductsInterface} from '../interfaces';
import {useCallback, useEffect, useState} from 'react';
import {ProductCart} from '../../../interfaces/orderProduct';
import {api} from '../../../services/api';
import {moneyToNumber, numberToMoney} from '../../../helpers/formatNumber';

export const useCartProducts = (freight?: string): UseCartProductsInterface => {
  const [products, setProducts] = useState<ProductCart[]>([]);
  const [cartResume, setCartResume] = useState<CartResume>({
    discount: 0,
    total: 'R$ 0,00',
    freight: 'R$ 0,00',
    totalWithDiscount: 'R$0,00',
    totalValue: 0,
  });
  const [loading, setLoading] = useState(false);

  const calcResumeFreight = (total: string, freight: string) => {
    total = total.replace('R$', '').trim();

    const calc = moneyToNumber(freight) + moneyToNumber(total);

    total = 'R$ ' + numberToMoney(calc);

    return {total, totalNumber: calc};
  };

  const getProducts = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const {
        data: {data},
      } = await api.get('carts');
      const {products: cartProducts} = data;

      setProducts(cartProducts);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, [loading]);

  const getCartResume = async () => {
    try {
      const {data} = await api.get('cart-resume');

      if (freight) {
        data.freight = 'R$ ' + freight;

        const {total, totalNumber} = calcResumeFreight(data.totalWithDiscount, freight);

        data.totalWithDiscount = total;
        data.totalValue = totalNumber;
      }

      setCartResume(data);
    } catch (e: any) {
    }
  };

  useEffect(() => {
    getCartResume().then();

    //eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    getProducts().then();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const resume = {...cartResume};

    const {total, totalNumber} = calcResumeFreight(resume.totalWithDiscount, freight ?? '');

    resume.freight = 'R$ ' + freight;
    resume.totalWithDiscount = total;
    resume.totalValue = totalNumber;

    setCartResume(resume);
    //eslint-disable-next-line
  }, [freight]);

  return {
    products,
    cartResume,
    getProducts,
    setProducts,
    isLoadingProducts: loading,
  };
};
