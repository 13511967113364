import styled from 'styled-components';

interface Props {
  isOpen?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AccordionHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  color: var(--purple);
  cursor: pointer;

  > i {
    font-size: 9px;
    ${(props) => props.isOpen && 'transform: rotate(180deg);'}
    font-weight: 700;
  }

  > span {
    margin-left: 14px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const AccordionBody = styled.div<Props>`
  max-width: 719px;
  width: 100%;
  margin: 4px 0 0 28px;
  display: ${(props) => props.isOpen ? 'block' : 'none'};

  > span {
    font-family: var(--roboto);
    font-size: 14px;
    font-weight: 700;
    color: var(--grey-medium-1);
    line-height: 16px;
  }
`;
