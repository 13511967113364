import React, {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react';
import {
  Container,
  Return,
  ReturnIcon,
  ReturnText,
  Title,
  InsertCodeMessage,
  EmailBold,
  InpurCodeWrapper,
  CodeNotReceivesMessage,
  Login
} from './styles';
import InputCode from '../../../../../components/InputCode';
import {useAuth} from '../../../../../hooks/useAuth';
import {api} from '../../../../../services/api';
import {useToast} from '../../../../../hooks/useToast';
import {handleErrors} from '../../../../../helpers/utils';
import {IValidateUser} from '../../index';

export interface Form {
  [key: string]: string;
}

interface Props {
  registerEmail: string;
  navigateTo: string;
  form: Form;
  userId: number;
  error: Form;
  setError: Dispatch<SetStateAction<Form>>;
  setUserId: (id: number) => void;
  setValueForm: (index: string, value: string) => void;
  setRegisterStep: (step: 'initial' | 'createAccount') => void;
  state: IValidateUser;
}

const VerifyCode: React.FC<Props> = ({
  registerEmail,
  navigateTo,
  form,
  userId,
  error,
  setError,
  state,
  setUserId,
  setRegisterStep
}) => {
  const [status, setStatus] = useState<string>('');
  const {signIn} = useAuth();
  const {toast} = useToast();

  const emailValidate = state.email || registerEmail;
  const email = `${emailValidate.slice(0, 2)}******@${emailValidate.split('@')[1]}`;

  const handleSignIn = useCallback(async () => {
    const validateForm = state.userId ? {
      email: state.email,
      password: state.password
    } : form;

    await signIn(validateForm, navigateTo);
  }, [state.userId, state.email, state.password, form, signIn, navigateTo]);

  const validateCode = useCallback(async (code: string) => {
    try {
      await api.put(`/access-code/${code}`, {userId});

      setStatus('success');
      await handleSignIn();
    } catch (error: any) {
      setStatus('');
      setStatus('error');
    }
  }, [handleSignIn, userId]);

  const resendingEmail = useCallback(async () => {
    try {
      await api.post('/access-code', {userId: userId || state.userId});

      toast({
        type: 'success',
        description: 'Email reenviado com sucesso!'
      });
    } catch (e: any) {
      toast({
        type: 'error',
        description: e.message
      });

      setError(handleErrors(e, error));
    }
    // eslint-disable-next-line
  }, [error, setError, toast, userId]);

  const goToInitialPage = () => setRegisterStep('initial');

  useEffect(() => {
    if (state.userId) {
      setUserId(state.userId);
      resendingEmail().then();
    }
    // eslint-disable-next-line
  }, [state.userId]);

  return (
    <Container>
      <Return onClick={goToInitialPage}>
        <ReturnIcon className="icon icon-arrow-left"/>
        <ReturnText>Voltar</ReturnText>
      </Return>
      <Title>{!state.userId ? 'Quero me cadastrar' : 'Validação de e-mail'}</Title>
      <InsertCodeMessage>Insira o código de 4 dígitos que enviamos para o
        e-mail <EmailBold>{email}</EmailBold></InsertCodeMessage>
      <InpurCodeWrapper>
        <InputCode status={status} validateCode={validateCode}/>
      </InpurCodeWrapper>
      <CodeNotReceivesMessage>Se você não receber o código <span
        onClick={resendingEmail}>Clique aqui</span></CodeNotReceivesMessage>
      <Login>Já possui conta? <span onClick={goToInitialPage}>Faça login</span></Login>
    </Container>
  );
};

export default VerifyCode;
