import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  '@global': {
    // Você deve direcionar [class*="MuiButton-root"] em vez de aninhar temas.
    '.MuiAccordion-root': {
      borderRadius: 8,
      marginTop: 20,
      marginBottom: 20,
    },
    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      color: '#909090',
    },
    '.MuiAccordionSummary-root': {
      padding: '5px 24px',
    },
    '.MuiAccordionSummary-content .icon': {
      fontSize: 30,
      margin: '0 15px',
    },
    '.MuiAccordionSummary-content h3': {
      margin: '0 25px',
    },
  },
  root: {
    overflow: 'hidden',
    marginBottom: -1,
    minHeight: 56,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: 8,
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    '@global': {
      '.MuiAccordionSummary-content': {
        alignItems: 'center',
        color: 'var(--purple)',
      },
    },
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
