import React from 'react';
import {
  Container,
  Content,
  MainContainer,
  MainContent,
  Clear,
  Capa,
  Text,
  StartUpContent,
  HeaderValues,
  Values,
  StartUpValuesContent,
  Banner,
} from './styles';
import ImageProducts from '../../../assets/jpg/images-aboult-products.jpg';
import ImageValues from '../../../assets/jpg/image-aboult.jpg';
import Newsletter from '../../../components/Newsletter';
import MobileIcon from '../../../assets/jpg/mobile.jpeg';
import ResponsiveIcon from '../../../assets/jpg/responsive.jpeg';
import WorldIcon from '../../../assets/jpg/world.jpeg';
import BannerImg from '../../../assets/jpg/image-help-2.jpg';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';


const AboutHoupa: React.FC = () => {
  return(
    <>
      <MainContainer>
        <Banner title="Banner" alt="Banner" src={BannerImg} />
        <Capa>
          <MainContent>
            <Text>
              <h1>Conheça o houpa!</h1>
              <p>Somos o primeiro social marketplace de moda voltado para o mercado atacadista e varejista brasileiro. Trabalhamos para conectar marcas, multimarcas e seus clientes.</p>
              <Link to='/login'>
                <Button scale='scale-1' color='var(--white)'>Criar Conta</Button>
              </Link>
              <Clear />
            </Text>
          </MainContent>
        </Capa>
      </MainContainer>
      <Container>
        <Content>
          <StartUpContent>
            <div id="startup">
              <h1>A startup</h1>
              <p>O houpa! surgiu da necessidade de auxiliar a otimizar os processos de vendas do mercado atacadista de moda. Observando essa movimentação, entendemos que faz muito sentido poder integrar não só a moda atacadista, mas toda sua cadeia e com isso, proporcionar a melhor experiência de consumo de vestuário para todos seus consumidores. Foi com a vontade de fazer a diferença, levando a moda para todos, que o houpa! nasceu. Duas pessoas brilhando em um porão do Bom Retiro para levar a moda de todos para todos e hoje somos mais de milhares!</p>
            </div>
            <div><img alt="Imagem Produtos" title="Imagem Produtos" src={ImageProducts}/></div>
          </StartUpContent>
          <Clear />
        </Content>
      </Container>
      <Container>
        <Content>
          <StartUpValuesContent>
            <div>
              <div><img alt="Icone Mobile" title="Icone Mobile" src={MobileIcon}/></div>
              <div>
                <hr/>
                <p>Nossa missão é revolucionar o jeito de comprar e vender roupas, ao promover uma rede única de negócios, que aproxima fornecedores e compradores dos maiores polos de moda do Brasil com uma simples conexão entre perfis.</p>
              </div>
            </div>
            <div>
              <div><img alt="Icone" title="Icone" src={WorldIcon}/></div>
              <div>
                <hr/>
                <p>Temos uma visão muito clara do futuro com o houpa! Queremos mudar a forma de produzir, comercializar e consumir a moda no mundo. Otimizar a produção, compra e venda online e offline com tecnologia proporcionando eficiência para TODOS.</p>
              </div>
            </div>
            <div>
              <div><img alt="Icone Responsivo" title="Icone Responsivo" src={ResponsiveIcon}/></div>
              <div>
                <hr/>
                <p>Nosso sistema é multiplataforma e foi desenvolvido para que você possa acessar pelo computador ou smartphone de qualquer lugar do mundo!</p>
              </div>
            </div>
          </StartUpValuesContent>
          <Clear />
        </Content>
      </Container>
      <Container overflow='hidden' background={'var(--purple)'} padding="40px 0">
        <Content>
          <HeaderValues>
            <h1>Valores</h1>
            <p>"A partir deles guiamos todas as nossas ações, contratações e principalmente, nosso coração."</p>
          </HeaderValues>
          <Values>
            <div className="inovacao">
              <h1>Inovação</h1>
              <p>Inovar é ir além do esperado. Imaginar, criar, testar, produzir e compartilhar. A inovação é nosso mindset e nós a promovemos em tudo que estamos envolvidos.</p>
            </div>
            <div className="orientacao">
              <h1>Orientação a dados</h1>
              <p>Orientar processos com coleta e análise de dados. Buscar, aprimorar e utilizar fontes confiáveis de informações nas tomadas de decisão de todas as frentes de negócio.</p>
            </div>
            <div className="confianca">
              <h1>Confiança</h1>
              <p>Manter relação ética e transparente em qualquer situação, promovendo respeito e confiança para todas aspartes envolvidas.</p>
            </div>
            <div className="zelo">
              <h1>Zelo</h1>
              <p>Devemos encantar as pessoas. Promover experiências únicas em todas as nossas entregas. Ouvir, falar, entender, compreender e educar.</p>
            </div>
            <div className="diversidade">
              <h1>Diversidade</h1>
              <p>O houpa! é de todos para todos.</p>
            </div>
            <div className="imagem">
              <img alt="Houpa | Valores" title="Houpa | Valores" src={ImageValues}/>
            </div>
          </Values>
          <Clear />
        </Content>
      </Container>
      <Newsletter />
    </>
  );
};

export default AboutHoupa;
