import styled from 'styled-components';

export const Container = styled.div`
  max-width: 444px;
  margin: 57px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 32px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;

export const Return = styled.div`
  display: flex;
  align-items: center;
  column-gap: 9px;
  cursor: pointer;
`;

export const ReturnIcon = styled.i`
  font-size: 19px;
  color: var(--purple);
`;

export const ReturnText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
`;

export const Title = styled.h2`
  font-family: var(--poppins);
  font-size: 24px;
  font-weight: 700;
  margin-top: 37px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;

export const ContactUsText = styled.p`
  line-height: 19px;
  color: var(--grey-dark);
  font-size: 16px;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  max-width: 214px;
  margin: 80px auto 0;
  @media screen and (max-width: 607px) {
    margin-top: 62px;
    max-width: 178px;
  }
`;

export const Login = styled.p`
  margin-top: 150px;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey-dark);
  text-align: center;
  > a {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color: var(--grey-dark);
  }
`;