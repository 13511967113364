import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import { Container, Header, Main, NavLeft, NavList, Wrapper } from './styles';

import Cards from './Cards';
import Tooltips from './Tooltips';
import AlertsModal from './AlertsModal';
import Colors from './Colors';
import Buttons from './Buttons';
import Inputs from './Inputs';
import InputsCode from './InputsCode';
import Checkboxes from './Checkboxes';
import SelectCheckboxes from './SelectCheckboxes';
import ProductCardExample from './ProductCardExample';
import Snackbar from './Snackbar';
import Step from './Step';
import Accordions from './Accordions';
import ButtonsFilters from './ButtonsFilters';
import SelectExample from './SelectExample';
import GridBlocksExample from './GridBlocksExample';
import ScrollTopTopExample from './ScrollToTopExample';
import FilterContainer from './FilterContainer';
import ProductImageContainerExemple from './ProductImagesContainerExample';
import DrawerExample from './DrawerExample';
import SideCartExample from './SideCartExample';
import CookiesAcceptExample from './CookiesAcceptExample';
import Newsletter from '../../components/Newsletter';
import Pagination from './PaginationExample';
import CheckoutStepBar from './CheckoutStepBarExample';
import SelectCardExample from './SelectCardExample';
import PaymentMethodsModalExample from './PaymentMethodsModalExample'

const StyleGuide: React.FC = () => {
  return (
    <Container>
      <Header>
        <p className='header-text'>Identidade Digital Houpa!</p>
      </Header>
      <Wrapper>
        <NavLeft>
          <NavList>
            <li>
              <Link to='/style-guide/colors'>Cores</Link>
            </li>
            <li>
              <Link to='/style-guide/buttons'>Botões</Link>
            </li>
            <li>
              <Link to='/style-guide/inputs'>Inputs</Link>
            </li>
            <li>
              <Link to='/style-guide/inputscode'>InputsCode</Link>
            </li>
            <li>
              <Link to='/style-guide/checkboxes'>Checkboxes</Link>
            </li>
            <li>
              <Link to='/style-guide/alertmodal'>Modal de Alerta</Link>
            </li>
            <li>
              <Link to='/style-guide/cardendereco'>
                Cards <br />
                (Endereço / Pagamento)
              </Link>
            </li>
            <li>
              <Link to='/style-guide/tooltip'>Tooltip</Link>
            </li>
            <li>
              <Link to='/style-guide/productcard'>Product Card</Link>
            </li>
            <li>
              <Link to='/style-guide/selectCheckboxes'>SelectCheckboxes</Link>
            </li>
            <li>
              <Link to='/style-guide/stepbar'>StepBar</Link>
            </li>
            <li>
              <Link to='/style-guide/snackbar'>Snackbar</Link>
            </li>
            <li>
              <Link to="/style-guide/accordion">Accordion</Link>
            </li>
            <li>
              <Link to="/style-guide/button-filters">ButtonFilters</Link>
            </li>
            <li>
              <Link to="/style-guide/select">Select</Link>
            </li>
            <li>
              <Link to="/style-guide/grid-blocks">Grid Blocks</Link>
            </li>
            <li>
              <Link to="/style-guide/scroll-to-top">Scroll To Top</Link>
              <Link to="/style-guide/product-images-container">
                ProductImagesContainer
              </Link>
            </li>
            <li>
              <Link to="/style-guide/filter-container">FilterContainer</Link>
            </li>
            <li>
              <Link to="/style-guide/drawer">Drawer</Link>
            </li>
            <li>
              <Link to="/style-guide/side-cart">SideCart</Link>
            </li>
            <li>
              <Link to="/style-guide/cookies-accept">Cookies Accept</Link>
            </li>
            <li>
              <Link to="/style-guide/newsletter">Newsletter</Link>
            </li>
            <li>
              <Link to="/style-guide/pagination">Pagination</Link>
            </li>
            <li>
              <Link to="/style-guide/checkout-step-bar">CheckoutStepBar</Link>
            </li>
            <li>
              <Link to="/style-guide/select-card">SelectCard</Link>
            </li>
            <li>
              <Link to="/style-guide/payment-methods-modal">ChangePaymentMethodModal</Link>
            </li>
          </NavList>
        </NavLeft>
        <Main>
          <Routes>
            <Route path="/cardendereco" element={<Cards />} />
            <Route path="/tooltip" element={<Tooltips />} />
            <Route path="/alertmodal" element={<AlertsModal />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/inputs" element={<Inputs />} />
            <Route path="/checkboxes" element={<Checkboxes />} />
            <Route path="/productcard" element={<ProductCardExample />} />
            <Route path="/inputscode" element={<InputsCode />} />
            <Route path="/checkboxes" element={<Checkboxes />} />
            <Route path="/stepbar" element={<Step />} />
            <Route path="/selectCheckboxes" element={<SelectCheckboxes />} />
            <Route path="/snackbar" element={<Snackbar />} />
            <Route path="/accordion" element={<Accordions />} />
            <Route path="/button-filters" element={<ButtonsFilters />} />
            <Route path="/select" element={<SelectExample />} />
            <Route path="/grid-blocks" element={<GridBlocksExample />} />
            <Route path="/scroll-to-top" element={<ScrollTopTopExample />} />
            <Route
              path="/product-images-container"
              element={<ProductImageContainerExemple />}
            />
            <Route path="/filter-container" element={<FilterContainer />} />
            <Route path="/drawer" element={<DrawerExample />} />
            <Route path="/side-cart" element={<SideCartExample />} />
            <Route path="/cookies-accept" element={<CookiesAcceptExample />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/pagination" element={<Pagination/>} />
            <Route path="/side-cart" element={<SideCartExample/>} />
            <Route path="/cookies-accept" element={<CookiesAcceptExample/>} />
            <Route path="/newsletter" element={<Newsletter/>} />
            <Route path="/checkout-step-bar" element={<CheckoutStepBar/>} />
            <Route path="/select-card" element={<SelectCardExample/>} />
            <Route path="/payment-methods-modal" element={<PaymentMethodsModalExample/>} />
          </Routes>
        </Main>
      </Wrapper>
    </Container>
  );
};

export default StyleGuide;
