import styled from 'styled-components';

interface Props {
  textWidth?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 190px;
`;

export const IconContainer = styled.div`
  height: 143px;
  width: 143px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1.5px solid var(--grey-light-2);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.071);

  @media screen and (max-width: 1279px) {
    height: 113px;
    width: 113px;
  }

  @media screen and (max-width: 607px) {
    height: 100px;
    width: 100px;
  }
`;

export const Text = styled.div<Props>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--roboto);
  color: var(--grey-medium-1);
  margin-top: 26px;
  width: ${(props) => (props.textWidth ? `${props.textWidth}px` : '190px')};

  @media screen and (max-width: 1279px) {
    font-size: 12px;
    margin-top: 16px;
    width: ${(props) => (props.textWidth ? `${props.textWidth}px` : '158px')};
  }

  @media screen and (max-width: 607px) {
    font-size: 10px;
    margin-top: 14px;
    width: ${(props) => (props.textWidth ? `${props.textWidth}px` : '152px')};
  }
`;
