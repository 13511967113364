export const feminineFake = [
  {id: '1', name: 'Blazer', value: '', type: 'feminine'},
  {id: '2', name: 'Blusa', value: '', type: 'feminine'},
  {id: '3', name: 'Body', value: '', type: 'feminine'},
  {id: '4', name: 'Calça', value: '', type: 'feminine'},
  {id: '5', name: 'Camisa', value: '', type: 'feminine'},
  {id: '6', name: 'Camiseta', value: '', type: 'feminine'},
  {id: '7', name: 'Cardigã', value: '', type: 'feminine'},
  {id: '8', name: 'Casaco', value: '', type: 'feminine'},
  {id: '9', name: 'Colete', value: '', type: 'feminine'},
  {id: '10', name: 'Conjunto', value: '', type: 'feminine'},
  {id: '11', name: 'Jaqueta', value: '', type: 'feminine'},
  {id: '12', name: 'Kimono', value: '', type: 'feminine'},
  {id: '13', name: 'Macacão', value: '', type: 'feminine'},
  {id: '14', name: 'Polo', value: '', type: 'feminine'},
  {id: '15', name: 'Saia', value: '', type: 'feminine'},
  {id: '16', name: 'Shorts / Bermuda', value: '', type: 'feminine'},
  {id: '17', name: 'Top', value: '', type: 'feminine'},
  {id: '18', name: 'Vestidos', value: '', type: 'feminine'},
];

export const sizesFake = [
  {id: '1', name: 'PP', value: '', type: 'sizes'},
  {id: '2', name: 'P', value: '', type: 'sizes'},
  {id: '3', name: 'M', value: '', type: 'sizes'},
  {id: '4', name: 'G', value: '', type: 'sizes'},
  {id: '5', name: 'GG', value: '', type: 'sizes'},
  {id: '6', name: 'XG', value: '', type: 'sizes'},
  {id: '7', name: 'G1', value: '', type: 'sizes'},
  {id: '8', name: 'G2', value: '', type: 'sizes'},
  {id: '9', name: 'G3', value: '', type: 'sizes'},
  {id: '10', name: 'G4', value: '', type: 'sizes'},
  {id: '11', name: 'G5', value: '', type: 'sizes'},
  {id: '12', name: '36', value: '', type: 'sizes'},
  {id: '13', name: '38', value: '', type: 'sizes'},
  {id: '14', name: '40', value: '', type: 'sizes'},
  {id: '15', name: '42', value: '', type: 'sizes'},
  {id: '16', name: '44', value: '', type: 'sizes'},
  {id: '17', name: '46', value: '', type: 'sizes'},
  {id: '18', name: '48', value: '', type: 'sizes'},
  {id: '19', name: '50', value: '', type: 'sizes'},
  {id: '20', name: '52', value: '', type: 'sizes'},
  {id: '21', name: '56', value: '', type: 'sizes'},
  {id: '22', name: '58', value: '', type: 'sizes'},
  {id: '23', name: '60', value: '', type: 'sizes'},
  {id: '23', name: 'ÚNICO', value: '', type: 'sizes'},
];

export const colorsFake = [
  {id: '1', name: 'Azul', value: '', type: 'color'},
  {id: '2', name: 'Claro', value: '', type: 'color'},
  {id: '3', name: 'Azul Marinho', value: '', type: 'color'},
  {id: '4', name: 'Amarelo', value: '', type: 'color'},
  {id: '5', name: 'Bege', value: '', type: 'color'},
  {id: '6', name: 'Bordô', value: '', type: 'color'},
  {id: '7', name: 'Branco', value: '', type: 'color'},
  {id: '8', name: 'Bronze', value: '', type: 'color'},
  {id: '9', name: 'Caramelo', value: '', type: 'color'},
  {id: '10', name: 'Cáqui', value: '', type: 'color'},
  {id: '11', name: 'Cinza', value: '', type: 'color'},
  {id: '12', name: 'Cinza Escuro', value: '', type: 'color'},
  {id: '13', name: 'Cobre', value: '', type: 'color'},
  {id: '14', name: 'Dourado', value: '', type: 'color'},
  {id: '15', name: 'Laranja', value: '', type: 'color'},
  {id: '16', name: 'Lilás', value: '', type: 'color'},
  {id: '17', name: 'Marrom', value: '', type: 'color'},
  {id: '18', name: 'Multicolorido', value: '', type: 'color'},
  {id: '19', name: 'Nude', value: '', type: 'color'},
  {id: '20', name: 'Off-White', value: '', type: 'color'},
  {id: '21', name: 'Prata', value: '', type: 'color'},
  {id: '22', name: 'Preto', value: '', type: 'color'},
  {id: '23', name: 'Pink', value: '', type: 'color'},
  {id: '24', name: 'Rosa', value: '', type: 'color'},
  {id: '25', name: 'Roxo', value: '', type: 'color'},
  {id: '26', name: 'Salmão', value: '', type: 'color'},
  {id: '27', name: 'Verde', value: '', type: 'color'},
  {id: '28', name: 'Vermelho', value: '', type: 'color'},
  {id: '29', name: 'Vinho', value: '', type: 'color'},
  {id: '30', name: 'Violeta', value: '', type: 'color'}
];

export const pricesFake = [
  {id: '1', name: 'Até R$100,00', value: '', type: 'prices'},
  {id: '2', name: 'R$ 100,00  -  R$200,00', value: '', type: 'prices'},
  {id: '3', name: 'R$ 200,00  -  R$300,00', value: '', type: 'prices'},
  {id: '4', name: 'Acima de R$ 300,00', value: '', type: 'prices'},
];

export const stylesFake = [
  {id: '1', name: 'Beachwear', value: '', type: 'styles'},
  {id: '2', name: 'Casual Chic', value: '', type: 'styles'},
  {id: '3', name: 'Fashion Trend', value: '', type: 'styles'},
  {id: '4', name: 'Festa', value: '', type: 'styles'},
  {id: '5', name: 'Fitness', value: '', type: 'styles'},
  {id: '6', name: 'Sofisticado', value: '', type: 'styles'},
  {id: '7', name: 'Streetwear', value: '', type: 'styles'},
  {id: '8', name: 'Trendy', value: '', type: 'styles'},
  {id: '9', name: 'Moda Íntima', value: '', type: 'styles'},
  {id: '10', name: 'Sleepwear', value: '', type: 'styles'},
];

export const brandsFake = [
  {id: '1', name: 'Atelier Lily Daisy', value: '', type: 'brands'},
  {id: '2', name: 'Cabocla', value: '', type: 'brands'},
  {id: '3', name: 'Clavedesol', value: '', type: 'brands'},
  {id: '4', name: 'Coinage', value: '', type: 'brands'},
  {id: '5', name: 'Cony green', value: '', type: 'brands'},
  {id: '6', name: 'Dolce Vita', value: '', type: 'brands'},
  {id: '7', name: 'Emdu', value: '', type: 'brands'},
  {id: '8', name: 'Glamffashion', value: '', type: 'brands'},
  {id: '9', name: 'Hemix Confecções', value: '', type: 'brands'},
  {id: '10', name: 'JJFashion Oficial', value: '', type: 'brands'},
  {id: '11', name: 'Kessey', value: '', type: 'brands'},
  {id: '12', name: 'La Folie', value: '', type: 'brands'},
  {id: '13', name: 'Lailak', value: '', type: 'brands'},
  {id: '14', name: 'Milalai', value: '', type: 'brands'},
  {id: '15', name: 'Mix Nativa', value: '', type: 'brands'},
  {id: '16', name: 'modasblackpink', value: '', type: 'brands'},
  {id: '17', name: 'Namine Confecções', value: '', type: 'brands'},
  {id: '18', name: 'Ouz Be', value: '', type: 'brands'},
  {id: '19', name: 'Pianeta Plus Size', value: '', type: 'brands'},
  {id: '20', name: 'S. Line', value: '', type: 'brands'},
  {id: '21', name: 'Seiss Deiss', value: '', type: 'brands'},
  {id: '22', name: 'Shantê Fashion', value: '', type: 'brands'},
  {id: '23', name: 'Skai', value: '', type: 'brands'},
  {id: '24', name: 'Tifá', value: '', type: 'brands'},
  {id: '25', name: 'Unique Chic', value: '', type: 'brands'},
  {id: '26', name: 'Uva Doce', value: '', type: 'brands'},
  {id: '27', name: 'Voc', value: '', type: 'brands'},
];

export const printsFake = [
  {id: '1', name: 'Abstrato', value: '', type: 'prints'},
  {id: '2', name: 'Animal Print', value: '', type: 'prints'},
  {id: '3', name: 'Argyle', value: '', type: 'prints'},
  {id: '4', name: 'Camuflado', value: '', type: 'prints'},
  {id: '5', name: 'Cashmere', value: '', type: 'prints'},
  {id: '6', name: 'Chevron', value: '', type: 'prints'},
  {id: '7', name: 'Étnica', value: '', type: 'prints'},
  {id: '8', name: 'Floral', value: '', type: 'prints'},
  {id: '9', name: 'Geométrico', value: '', type: 'prints'},
  {id: '10', name: 'Liberty', value: '', type: 'prints'},
  {id: '11', name: 'Liso', value: '', type: 'prints'},
  {id: '12', name: 'Listrado', value: '', type: 'prints'},
  {id: '13', name: 'Listrado Navy', value: '', type: 'prints'},
  {id: '14', name: 'Oncinha', value: '', type: 'prints'},
  {id: '15', name: 'Pied-de-poule', value: '', type: 'prints'},
  {id: '16', name: 'Poá', value: '', type: 'prints'},
  {id: '17', name: 'Pucci', value: '', type: 'prints'},
  {id: '18', name: 'Risca-de-Giz', value: '', type: 'prints'},
  {id: '19', name: 'Tartan', value: '', type: 'prints'},
  {id: '20', name: 'Vichy', value: '', type: 'prints'},
  {id: '21', name: 'Xadrez', value: '', type: 'prints'},
  {id: '22', name: 'Zig Zag Missoni', value: '', type: 'prints'},
  {id: '23', name: 'Personalizado', value: '', type: 'prints'},
];

export const tissuesFake = [
  {id: '1', name: 'Algodão', value: '', type: 'tissues'},
  {id: '2', name: 'Cambraia', value: '', type: 'tissues'},
  {id: '3', name: 'Cetim', value: '', type: 'tissues'},
  {id: '4', name: 'Chiffon', value: '', type: 'tissues'},
  {id: '5', name: 'Couro', value: '', type: 'tissues'},
  {id: '6', name: 'Couro P.U.', value: '', type: 'tissues'},
  {id: '7', name: 'Crepe', value: '', type: 'tissues'},
  {id: '8', name: 'Gabardine', value: '', type: 'tissues'},
  {id: '9', name: 'Jeans', value: '', type: 'tissues'},
  {id: '10', name: 'Lã', value: '', type: 'tissues'},
  {id: '11', name: 'Laise', value: '', type: 'tissues'},
  {id: '12', name: 'Linho', value: '', type: 'tissues'},
  {id: '13', name: 'Malha', value: '', type: 'tissues'},
  {id: '14', name: 'Mousseline', value: '', type: 'tissues'},
  {id: '15', name: 'Organza', value: '', type: 'tissues'},
  {id: '16', name: 'Renda', value: '', type: 'tissues'},
  {id: '17', name: 'Paetês', value: '', type: 'tissues'},
  {id: '18', name: 'Sarja', value: '', type: 'tissues'},
  {id: '19', name: 'Seda', value: '', type: 'tissues'},
  {id: '20', name: 'Tactel', value: '', type: 'tissues'},
  {id: '21', name: 'Tencel', value: '', type: 'tissues'},
  {id: '22', name: 'Tricô', value: '', type: 'tissues'},
  {id: '23', name: 'Tricoline', value: '', type: 'tissues'},
  {id: '24', name: 'Tule', value: '', type: 'tissues'},
  {id: '25', name: 'Veludo', value: '', type: 'tissues'},
  {id: '26', name: 'Viscose', value: '', type: 'tissues'},
];
