import styled, { keyframes } from 'styled-components';
import { Modal } from '@material-ui/core';

const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;

  border-radius: 16px;

  background: ${(props) => props.theme.colors.white};
  outline: none;

  position: relative;

  overflow-x: hidden;

  .back-button {
    display: none;
  }

  @media (min-width: 608px) {
    max-width: 551px;
    height: max-content;
  }

  @media (max-width: 607px) {
    padding: 20px;

    border-radius: 0;

    transform: translateX(0);
    animation: ${animeRight} 0.2s ease-out;
    overflow-y: auto;

    .back-button {
      display: flex;
      align-items: center;

      margin-bottom: 33px;

      i {
        font-size: 18px;
        color: ${(props) => props.theme.colors.purple};
        margin-right: 15px;
      }

      span {
        font-size: 16px;
        line-height: 18.75px;
      }
    }
  }
`;

export const ButtonClose = styled.button`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;

  i {
    font-size: 20px;
    color: ${(props) => props.theme.colors.greyLight1};
  }

  @media (max-width: 607px) {
    display: none;
  }
`;

export const Title = styled.h6`
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto-Bold;
  color: ${(props) => props.theme.colors.black};

  margin-bottom: 12px;

  @media (min-width: 608px) {
    font-size: 30px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtitle {
    font-size: 14px;
    font-weight: 700;
    font-family: Roboto-Bold;
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 17px;
  }

  .description {
    font-size: 12px;
    color: ${(props) => props.theme.colors.greyDark};

    margin-bottom: 55px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input {
      margin-bottom: 16px;

      @media (min-width: 608px) {
        max-width: 212px;
      }
    }
  }

  .requiredTip {
    width: 100%;
  }

  .save-button {
    margin-top: 20px;
    width: 97px;
  }

  @media (min-width: 608px) {
    .subtitle {
      font-size: 18px;
    }

    .description {
      font-size: 14px;
    }

    .save-button {
      width: 129px;
    }
  }
`;
