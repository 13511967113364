import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  text-align: center;
  max-width: 40px;
  padding: 3px 8px;
  border: 1px solid #DFDFDF;
  min-height: 19px;
  outline: none;
  border-radius: 4px;
  margin: 0 8px;
`;


export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  font-family: Poppins-Medium, Roboto-Medium, Arial, sans-serif;

  border-radius: 50%;
  background-color: var(--purple);
  color: var(--white);
  font-size: 16px;

  cursor: pointer;
`;
