import React, { useState } from 'react';
import Button from '../../components/Button';
import {
  CSS,
  Container,
  IconContainer,
  FormContainer,
  TitleField,
  Title,
  ForgotPasswordField,
  ButtonField,
  MakeRegisterField,
  MakeRegisterText,
  MakeRegisterLink,
  Wrapper,
  Text,
  ButtonBack,
} from './styles';
import {useNavigate} from 'react-router-dom';
import TakeBlip from '../../components/TakeBlip';

const ForgotEmail: React.FC = () => {
  const navigate = useNavigate();
  const [chat, setChat] = useState(false);

  function openBlip(){
    setChat(true);
  }

  return (
    <Container>
      <Wrapper>
        <CSS/>
        <IconContainer onClick={() => navigate('/')}>
          <i className="icon icon-houpa icon-houpa-logo"/>
        </IconContainer>
        <FormContainer>
          <ButtonBack onClick={() => navigate('/login')}>
            <i className={'icon icon-arrow-left'}/>
            Voltar
          </ButtonBack>
          <TitleField>
            <Title>Esqueceu o seu e-mail?</Title>
            <Text>Entre em contato com a nossa equipe para recuperar o seu acesso.</Text>
          </TitleField>
          <ForgotPasswordField>
          </ForgotPasswordField>
          <ButtonField>
            <Button onClick={openBlip} expand color="var(--purple)" scale="scale-4">
              Entrar em Contato
            </Button>
          </ButtonField>
          <MakeRegisterField>
            <MakeRegisterText>
              Já possui conta?{' '}
              <MakeRegisterLink onClick={() => navigate('/login')}>
              Faça login
              </MakeRegisterLink>
            </MakeRegisterText>
          </MakeRegisterField>
        </FormContainer>
      </Wrapper>
      {chat && <TakeBlip needsLogin={false}/>}
    </Container>
  );
};

export default ForgotEmail;
