import {RefObject, useRef} from 'react';
import {Filter} from '../components/FilterProduct/interface';

export type FilterString = RefObject<string>;
export type SetFilterString = (value: string, executeFunction?: () => void) => void;
export type ChangeFilterString = (item: Filter) => string;
export type EditFilterString = (filterStringValue: string, newValue: string, param: string) => string;

type UseFilterString = [FilterString, {setFilterString: SetFilterString, changeFilterString: ChangeFilterString, editFilterString: EditFilterString}];

export function useFilterString(): UseFilterString {
  const filterString = useRef<string>('');
  const filterTimeout = useRef<number>(0);

  const setFilterString = (value: string, executeFunction?: () => void) => {
    filterString.current = value;

    clearTimeout(filterTimeout.current);
    filterTimeout.current = window.setTimeout(async () => {
      executeFunction && executeFunction();
    }, 500);
  };

  const changeFilterString = (item: Filter): string => {
    let stringContent = '';
    switch (item.type){
    case 'colors':
      stringContent = `&${item.type}[]=%27${item.hexadecimal?.replace('#', '%23')}%27`;
      break;

    case 'prices':
      stringContent = `&${item.value}`;
      break;

    default:
      stringContent = `&${item.type}[]=${item.id}`;
    }

    if(filterString.current.includes(stringContent)){
      return filterString.current.replace(stringContent, '');
    } else {
      return filterString.current + stringContent;
    }
  };

  function editFilterString(filterStringValue: string, newValue: string, param: string): string {
    const split = filterStringValue.split('&');
    const index = split.findIndex((i) => i.includes(param));

    console.log(param, newValue);
    if(newValue === '0,00') {
      split.splice(index, 1);
    } else if(newValue.length > 0) {
      const newValueString = `${param}=${newValue}`;

      if(index < 0) {
        split.push(`${param}=${newValue}`);
      } else {
        split[index] = newValueString;
      }
    }

    return split.join('&');
  }

  return [filterString, {setFilterString, changeFilterString, editFilterString}];
}
