import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Container,
  Content,
  ToolsAndResults,
  Body,
  AppBarStyled,
  TextSearch,
  NotFound,
  SelectStyled,
  TabsStyled,
  ContainerProducts,
  Results,
  GridBlocksStyled,
  FilterStyledDesktop,
  DrawerStyled,
  ContainerBrands,
  SlickStyled,
  BoxBrands,
  ImageBrand,
  NameBrand,
  DescriptionBrand,
  TagsContainer,
  Tag,
  BrandButton,
  ArrowButton,
  ProductsTabletAndDesktop,
  FilterContainer,
} from './styled';
import Select from '../../components/Select';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Drawer from '../../components/Drawer';
import ProductCard from '../../components/ProductCard';
import notFoundAnimation from '../../assets/animations/not_afound.json';
import Lottie from 'react-lottie';
import { ProductOrders } from '../../interfaces/orderProduct';
import GridBlocks from '../../components/GridBlocks';
import Button from '../../components/Button';
import ScrollToTop from '../../components/ScrollToTop';
import FilterProduct from '../../components/FilterProduct';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as ArrowBack } from '../../assets/svg/icon-arrow-back.svg';
import { ReactComponent as ArrowNext } from '../../assets/svg/icon-arrow-next.svg';
import ButtonFilters from '../../components/ButtonFilters';
import Pagination from '../../components/Pagination';
import { api } from '../../services/api';
import { Filter, ObjectFilter } from '../../components/FilterProduct/interface';
import { useQuery } from '../../hooks/useQuery';
import { useFilterString } from '../../hooks/useFilterString';
import { useLoading } from '../../hooks/useLoading';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface NextArrow {
  currentSlide?: number;
  className?: string;
  'data-role'?: string;
  onClick?: () => void;
  slideCount?: number;
  style?: {
    display?: string;
  };
}

interface PreviousArrow {
  currentSlide?: number;
  slideCount?: number;
  className?: string;
  'data-role'?: string;
  onClick?: () => void;
  style?: {
    display?: string;
  };
}

interface Style {
  id: string;
  name: string;
}

interface Store {
  id: string;
  name: string;
  profileImage: string;
  coverImage: string;
  description: string;
  profileUrl: string;
  products: ProductOrders[];
  styles: Style[];
}

const Search: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const searchParam = query.get('s');
  const categoryParam = query.get('category');
  const pageParam = query.get('p');
  const orderParam = query.get('order');

  const { showLoading, closeLoading } = useLoading();

  const [selectError, setSelectError] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<string>('');
  const [grid, setGrid] = React.useState('');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [products, setProducts] = useState<ProductOrders[]>([]);
  const [filters, setFilters] = useState<ObjectFilter>({} as ObjectFilter);
  const [activeTab, setActiveTab] = React.useState(1);
  const [stores, setStores] = useState<Store[]>([]);

  const [filterString, { setFilterString, changeFilterString, editFilterString }] = useFilterString();

  const order = useRef('');
  const search = useRef('');
  const page = useRef<number>(1);
  const options = useRef([
    {
      key: 'two-columns',
      columns: 2,
    },
    {
      key: 'three-columns',
      columns: 3,
    },
    {
      key: 'four-columns',
      columns: 4,
    },
  ]).current;
  const defaultOptions = useRef({
    loop: false,
    autoplay: true,
    animationData: notFoundAnimation,
  }).current;

  const executeScroll = useCallback(
    () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [],
  );

  const getProducts = useCallback(async () => {
    try {
      showLoading();
      const { data } = await api.get(`/search/products?search=${search.current}&page=${page.current}&order=${order.current}${filterString.current}`);

      setProducts(data.products);
      setTotalPages(data.pages);
      setTotalResults(data.results);
    } catch (e: any) {
      console.log(e);
    } finally {
      executeScroll();
      closeLoading();
    }
  }, [closeLoading, executeScroll, filterString, showLoading]);

  const getFilters = useCallback(async () => {
    try {
      const { data } = await api.get('filters');

      if (categoryParam !== null) {
        const item = data.styles.filter((i: Filter) => {
          if (Number(i.id) === Number(categoryParam)) {
            i.checked = true;
          }

          return Number(i.id) === Number(categoryParam);
        });

        setFilter(item);
      }

      setFilters(data);

    } catch (e: any) {
      console.log(e);
    }
  }, [categoryParam]);

  function addFilter(item: Filter) {
    setFilterString(changeFilterString(item))
  }

  function getFilterPrice(lstPrices: Filter[], currentFilter: Filter[]) {
    const minPrice = query.get('minPrice')
    const maxPrice = query.get('maxPrice')

    if (minPrice || maxPrice) {
      let valueFilter = '';

      valueFilter += minPrice ? `minPrice=${minPrice}` : ''
      valueFilter += minPrice && maxPrice ? '&' : ''
      valueFilter += maxPrice ? `maxPrice=${maxPrice}` : ''

      let newFilter = lstPrices.find(price => price.value == valueFilter)

      if (!newFilter) {
        const name = minPrice && maxPrice
          ? `R$ ${minPrice} - R$ ${maxPrice}`
          : minPrice ? `Acima de R$ ${minPrice}` : `Até R$ ${maxPrice}`

        newFilter = {
          id: '',
          name: name,
          value: valueFilter,
          type: 'prices',
        }
      }

      currentFilter.push(newFilter)
      addFilter(newFilter)
    }
  }

  async function getFilterUrl() {
    let currentFilter: Filter[] = []

    const size = query.getAll('sizes[]')
    const styles = query.getAll('styles[]')
    const brands = query.getAll('brands[]')
    const colors = query.getAll('colors[]')

    const { data } = await api.get('filters');

    size.map((val) => {
      const item = data.sizes.filter((value: any) => value.id == val)
      if (item) {
        currentFilter.push(item[0])
        addFilter(item[0])
      }
    });

    styles.map((val) => {
      const item = data.styles.filter((value: any) => value.id == val)
      if (item) {
        currentFilter.push(item[0])
        addFilter(item[0])
      }
    });

    brands.map((val) => {
      const item = data.brands.filter((value: any) => value.id == val)
      if (item) {
        currentFilter.push(item[0])
        addFilter(item[0])
      }
    });

    colors.map((val) => {
      const item = data.colors.filter((value: any) => `'${value.hexadecimal}'` == val)
      if (item) {
        currentFilter.push(item[0])
        addFilter(item[0])
      }
    });

    getFilterPrice(data.prices, currentFilter)
    setFilter(currentFilter)
  }

  const getStores = useCallback(async () => {
    try {
      const { data } = await api.get(`/search/sellers?search=${search.current}&page=${page.current}&order=${order.current}${filterString.current}`);

      setStores(data.sellers);
    } catch (e: any) {
      console.log(e);
    }
  }, [filterString]);

  function buildLocationSearch(value: string) {
    window.location.search = value;
  }

  function changeOrder(value: string) {
    let valueSearch = window.location.search;

    if (valueSearch.includes("order")) {
      valueSearch = valueSearch.replace(selectedOrder, value);
    } else {
      valueSearch += `&order=${value}`;
    }

    buildLocationSearch(valueSearch);
  }


  const callSetFilterString = useCallback((value: string) => {
    setFilterString(value, () => {
      if (activeTab === 0) {
        getStores().then();
      } else {
        let order = window.location.search.includes("order") ? `&order=${selectedOrder}` : '';
        buildLocationSearch(value + order);
      }
    });
  }, [activeTab, getProducts, getStores, setFilterString]);

  useEffect(() => {

    async function init() {
      if (searchParam !== null) {
        search.current = searchParam;
      }
  
      if (pageParam !== null) {
        page.current = Number(pageParam);
      }
  
      if (orderParam !== null) {
        order.current = orderParam;
        setSelectedOrder(orderParam);
      }
  
      if (categoryParam !== null) {
        setFilterString('&styles[]=' + categoryParam);
      }
      
      showLoading();
      await getFilters();
      await getFilterUrl();

      await getProducts();
    }

    init();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('s'), query.get('p'), query.get('o')]);

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setActiveTab(newValue);

    if (newValue === 0) {
      await getStores();
    }
  };

  const nextPage = useCallback(async () => {
    if (page.current === totalPages || totalPages === 0) {
      return false;
    }

    page.current = page.current + 1;
    navigate(`?s=${search.current}&p=${page.current}`);
  }, [navigate, totalPages]);

  const previousPage = useCallback(async () => {
    if (page.current === 1 || totalPages === 0) {
      return false;
    }

    page.current = page.current - 1;
    navigate(`?s=${search.current}&p=${page.current}`);
  }, [navigate, totalPages]);

  const removeFilter = useCallback((item: Filter) => {
    callSetFilterString(changeFilterString(item));

    const dataFilter = filter.filter((i) => i !== item);

    filters[item.type].map((i) => {
      if (i.id === item.id) {
        i.checked = false;
      }
    });

    setFilter(dataFilter);
  }, [callSetFilterString, changeFilterString, filter, filters]);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    indicator: {
      backgroundColor: '#7F1CEF',
    },
    flexContainer: {
      backgroundColor: '#fff',
      color: '#666',
      fontSize: '9px',
    },
  }));

  const classes = useStyles();

  function SampleNextArrow({ currentSlide, ...props }: NextArrow) {
    return (
      <ArrowButton
        {...props}
        className={
          'slick-prev slick-arrow' +
          (currentSlide === 0 ? ' slick-disabled' : '')
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0}
        type="button"
      >
        <ArrowBack />
      </ArrowButton>
    );
  }

  function SamplePrevArrow({
    currentSlide,
    slideCount,
    ...props
  }: PreviousArrow) {
    return (
      <ArrowButton
        {...props}
        className={
          'slick-next slick-arrow' +
          (slideCount && currentSlide === slideCount - 1
            ? ' slick-disabled'
            : '')
        }
        aria-hidden="true"
        aria-disabled={
          !!(slideCount && currentSlide === slideCount - 1)
        }
        type="button"
      >
        <ArrowNext />
      </ArrowButton>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function notFound() {
    return (
      <NotFound>
        <h1>Oops!</h1>
        <Lottie width={117} height={117} options={defaultOptions} />
        <h2>Não encontramos nada por aqui!</h2>
        <p>
          Desculpe, não foram encontrados resultados para essa pesquisa. Faça
          uma nova busca para encontrar o que está procurando ou navegue pelas
          categorias e filtros.
        </p>
      </NotFound>
    );
  }

  return (
    <Container>
      <Content>
        <FilterStyledDesktop>
          <FilterProduct
            data={filters}
            filter={filter}
            setFilter={setFilter}
            setFilterString={callSetFilterString}
            filterString={filterString.current}
            changeFilterString={changeFilterString}
            editFilterString={editFilterString}
          />
        </FilterStyledDesktop>
        <div>
          <ToolsAndResults className={`control-tab-${activeTab}`}>
            {search.current.length > 0 && (
              <>
                <p className="tab-1">
                  Resultados encontrados em <strong>“{search.current}”</strong>
                </p>
                <p className="tab-0">
                  Você buscou por <strong>“{search.current}”</strong>
                </p>
              </>
            )}
            <Results className="tab-1"><strong>{totalResults}</strong>{' '}
              <span>{totalResults !== 1 ? 'Resultados' : 'Resultado'}</span></Results>
            <GridBlocksStyled className="tab-1">
              <GridBlocks changeGrid={setGrid} value={grid} options={options} />
            </GridBlocksStyled>
            <SelectStyled className="tab-1">
              <Select
                disabledOption="Ordenar por"
                options={[
                  {
                    id: 'release',
                    name: 'Lançamento'
                  },
                  {
                    id: 'popularity',
                    name: 'Mais populares'
                  },
                  {
                    id: 'biggerDiscount',
                    name: 'Maior desconto'
                  },
                  {
                    id: 'smallerDiscount',
                    name: 'Menor desconto'
                  },
                  {
                    id: 'biggerPrice',
                    name: 'Maior preço'
                  },
                  {
                    id: 'smallerPrice',
                    name: 'Menor preço'
                  },
                  {
                    id: 'AZ',
                    name: 'A-Z'
                  },
                ]}
                selectError={selectError}
                setselectError={setSelectError}
                selectedValue={selectedOrder}
                setSelectedValue={(arg) => changeOrder(arg)}
                required={false}
              />
            </SelectStyled>
          </ToolsAndResults>
          <Body className={classes.root}>
            <AppBarStyled>
              <TextSearch>
                <p>Procurar em:</p>
              </TextSearch>
              <TabsStyled>
                <AppBar style={{ boxShadow: 'none' }} position="static">
                  <Tabs
                    classes={{
                      indicator: classes.indicator,
                      flexContainer: classes.flexContainer,
                    }}
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="LOJAS" {...a11yProps(0)} />
                    <Tab label="PRODUTOS" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
              </TabsStyled>
              <DrawerStyled>
                <Drawer
                  data={filters}
                  filter={filter}
                  setFilter={setFilter}
                  setFilterString={callSetFilterString}
                  filterString={filterString.current}
                  changeFilterString={changeFilterString}
                  editFilterString={editFilterString}
                />
              </DrawerStyled>
            </AppBarStyled>
            <TabPanel value={activeTab} index={0}>
              {
                stores.map((store) => (
                  <ContainerBrands key={store.id}>
                    <BoxBrands>
                      <ImageBrand>
                        <img src={store.profileImage} alt={''} />
                      </ImageBrand>
                      <NameBrand>{store.name}</NameBrand>
                      <DescriptionBrand>
                        {store.description}
                      </DescriptionBrand>
                      <TagsContainer>
                        {store.styles.map((style) => (
                          <Tag key={style.id}>{style.name}</Tag>
                        ))}
                      </TagsContainer>
                      <BrandButton>
                        <Button scale="scale-2" color="var(--purple)"
                          onClick={() => navigate(`/perfil/${store.profileUrl}`)}>
                          Ver Loja
                        </Button>
                      </BrandButton>
                    </BoxBrands>
                    <SlickStyled>
                      <Slider {...settings}>
                        {store.products.map((product) => (
                          <div key={product.id}>
                            <ProductCard
                              productId={Number(product.id)}
                              productSlug={product.productSlug}
                              profileUrl={product.sellerProfileUrl}
                              isFavorite={product.isFavorite}
                              image={product.mainImage}
                              hoverImage={product.mainImage}
                              title={product.name}
                              price={product.price}
                              pricePromotion={product.originalPrice}
                              parcel={product.paymentCondition}
                              inProfile={true}
                              searchParam={searchParam}
                            />
                          </div>
                        ))}
                      </Slider>
                    </SlickStyled>
                    <ProductsTabletAndDesktop>
                      {store.products.map((product) => (
                        <div key={product.id}>
                          <ProductCard
                            productId={Number(product.id)}
                            productSlug={product.productSlug}
                            profileUrl={product.sellerProfileUrl}
                            isFavorite={product.isFavorite}
                            image={product.mainImage}
                            hoverImage={product.mainImage}
                            title={product.name}
                            price={product.price}
                            pricePromotion={product.originalPrice}
                            parcel={product.paymentCondition}
                            inProfile={true}
                            searchParam={searchParam}
                          />
                        </div>
                      ))}
                    </ProductsTabletAndDesktop>
                  </ContainerBrands>
                ))
              }
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <FilterContainer>
                {filter.map((el, key) => (
                  <ButtonFilters onClick={() => removeFilter(el)} key={key}>
                    {el.name}
                  </ButtonFilters>
                ))}
              </FilterContainer>
              {products.length > 0 ? (
                <>
                  <ContainerProducts data-grid={grid}>
                    {products.map((product) => (
                      <ProductCard
                        key={product.id}
                        productId={Number(product.id)}
                        isFavorite={product.isFavorite}
                        productSlug={product.productSlug}
                        profileUrl={product.sellerProfileUrl}
                        image={product.mainImage}
                        hoverImage={product.mainImage}
                        title={product.name}
                        price={product.price}
                        pricePromotion={product.originalPrice}
                        selloffPercentage={product.selloffPercentage}
                        parcel={product.paymentCondition}
                        searchParam={searchParam}
                      />
                    ))}
                  </ContainerProducts>
                  {totalPages > 1 && <Pagination
                    index={
                      page.current <= 9
                        ? `0${page.current.toString()}`
                        : page.current.toString()
                    }
                    max={totalPages.toString()}
                    onClick={(direction: string) =>
                      direction === 'next' ? nextPage() : previousPage()
                    }
                  />}
                </>
              ) : (
                notFound()
              )}
            </TabPanel>
          </Body>
        </div>
        <ScrollToTop />
      </Content>
    </Container>
  );
};

export default Search;
