import React from 'react';
import InstitutionalCard from '../../../components/InstitutionalCard';
import {ReactComponent as IconToAqui} from '../../../assets/svg/to-aqui.svg';
import {ReactComponent as IconMobile} from '../../../assets/svg/mobile.svg';
import {ReactComponent as IconConfirmBox} from '../../../assets/svg/confirm-box.svg';
import {ReactComponent as IconMail} from '../../../assets/svg/mail.svg';
import {
  Container,
  HeaderHero,
  HeaderHeroInfo,
  HeaderHeroTitle,
  HeaderHeroDescription,
  Content,
  Title,
  CardContainer,
  AccordionContainer,
  Divisor,
  LinkFAQ,
} from './styles';
import InstitutionalAccordion from '../../../components/InstitutionalAccordion';
import Newsletter from '../../../components/Newsletter';

const ReplacementReturnPolicy: React.FC = () => {
  return (
    <>
      <HeaderHero>
        <HeaderHeroInfo>
          <HeaderHeroTitle>Política de trocas e devoluções</HeaderHeroTitle>
          <HeaderHeroDescription>
            Nós queremos que sua experiência seja a melhor possível e, para
            isso, criamos a política de trocas e devoluções do houpa.
          </HeaderHeroDescription>
        </HeaderHeroInfo>
      </HeaderHero>
      <Container>
        <Content>
          <Title>Como solicitar uma troca?</Title>
          <CardContainer>
            <div className="grid">
              <InstitutionalCard
                icon={<IconToAqui height="58" width="53" />}
                text="Envie e-mail para toaqui@houpa.app"
                textWidth="110"
              />
              <InstitutionalCard
                icon={<IconMobile height="64" width="38" />}
                text="Nós enviaremos para o seu e-mail o código de postagem."
              />
              <InstitutionalCard
                icon={<IconConfirmBox height="52" width="59" />}
                text="Embale cuidadosamente o produto, imprima o código de postagem recebido no e-mail e apresente-o na agência dos Correios desejada para enviar seu produto."
              />
              <InstitutionalCard
                icon={<IconMail height="30" width="57" />}
                text="Fique de olho no seu e-mail! Nós enviaremos os detalhes de recebimento de sua solicitação"
              />
            </div>
          </CardContainer>
          <AccordionContainer>
            <InstitutionalAccordion title="Troca Simples">
              Embale cuidadosamente o produto, imprima o código de postagem
              recebido no e-mail e apresente-o na agência dos Correios desejada
              para enviar seu produto.
            </InstitutionalAccordion>

            <Divisor />

            <InstitutionalAccordion title="Troca por defeito">
              Após a devolução, seguiremos com a troca da peça, entraremos em
              contato para informar qual a peça que o cliente deseja receber.
            </InstitutionalAccordion>

            <Divisor />

            <InstitutionalAccordion title="2° troca">
              Precisamos que você embale a peça, leve aos Correios e apresente o
              código de postagem que está em anexo, com ele você não pagará pelo
              envio.
            </InstitutionalAccordion>

            <Divisor />

            <InstitutionalAccordion title="Peças em promoção / liquidação">
              Só após a devolução você receberá o estorno do valor de acordo com
              a forma de pagamento.
            </InstitutionalAccordion>

            <Divisor />

            <InstitutionalAccordion title="Devolução de pedidos">
              Você tem até 10 dias úteis para realizar a postagem, após este
              prazo a etiqueta vence e não poderá mais realizar a
              troca/devolução da peça.
            </InstitutionalAccordion>
          </AccordionContainer>
          <LinkFAQ>Dúvidas? Acesse o FAQ do houpa.</LinkFAQ>
        </Content>
      </Container>
      <Newsletter />
    </>
  );
};

export default ReplacementReturnPolicy;
