import React from 'react';
import NoCard from '../NoCard';
import BankSlip from '../BankSlip';
import CreditCards from '../CreditCards';

interface OwnProps {
  paymentMethod: 'creditCard' | 'bankSlip';
  onClose: () => void;
}

const Main: React.FC<OwnProps> = ({ paymentMethod, onClose }) => {
  const userCards = [''];
  return (
    <>
      {paymentMethod === 'bankSlip' && <BankSlip />}
      {paymentMethod === 'creditCard' && (
        <>
          {userCards.length > 0 ? (
            <CreditCards onClose={onClose} />
          ) : (
            <NoCard onClose={onClose} />
          )}
        </>
      )}
    </>
  );
}

export default Main;