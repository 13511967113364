import React, { useState, FormEvent, useCallback } from 'react';
import { 
  Container,
  InputsPasswordWrapper,
  PasswordButtonWrapper,
  PasswordChanged,
  PasswordChangedIcon,
  PasswordChangedText,
} from './styles';
import useForm from '../../../../hooks/useForm';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { api } from '../../../../services/api';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  navigateTo: string;
  userId: number;
  email: string;
}
const NewPassword: React.FC<Props> = ({ navigateTo, userId, email }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const { signIn } = useAuth();

  const { 
    form, error, setError, onChange, validateForm, onBlur
  } = useForm({
    password: {
      type: 'password',
      required: true,
      limit: 100
    },
    confirmPassword: {
      type: 'password',
      required: true,
      limit: 100
    },
  });
  
  const validatePassword = async (e: FormEvent) => {
    e.preventDefault();

    if (form.password !== form.confirmPassword) {
      error.confirmPassword = 'As senhas precisam ser iguais';
      setError({...error});

      return false;
    }

    if (validateForm()) {
      await handleRecoverPassword();
    }
  };

  const handleRecoverPassword = useCallback(async () => {
    try {
      await api.put(`/recover-password/${userId}`, form);
      setSuccess(true);
      await signIn({email, password: form.password}, navigateTo);
    } catch (error: any) {
      setError({...error, confirmPassword: error.message});
    }
  }, [userId, form, signIn, email, setError, navigateTo]);



  return (
  <div>
    <Container onSubmit={validatePassword}>
      <InputsPasswordWrapper>
        <Input
          title='Nova senha'
          type="password"
          value={form.password}
          onBlur={() => onBlur('password')}
          onChange={(e) => onChange('password', e)}
          error={error.password}
          required
          placeholder='********'
        />
        <Input
          title='Confirme sua nova senha'
          type="password"
          value={form.confirmPassword}
          onBlur={() => onBlur('confirmPassword')}
          onChange={(e) => onChange('confirmPassword', e)}
          error={error.confirmPassword}
          required
          placeholder='********'
        />
      </InputsPasswordWrapper>
      {success ? (
        <PasswordChanged>
          <PasswordChangedIcon className="icon icon-success" />
          <PasswordChangedText>Senha alterada com sucesso</PasswordChangedText>
        </PasswordChanged>
      ) : (
        <PasswordButtonWrapper>
          <Button color="var(--purple)" scale="scale-4" expand>Enviar</Button>
        </PasswordButtonWrapper>
      )}
    </Container>
  </div>
  );
};

export default NewPassword;