import React, { useState } from 'react';
import { 
  PreFormat, 
  HR 
} from './styles';
import PaymentMethodsModal from '../../../components/ChangePaymentMethodModal';
import Button from '../../../components/Button';

const PaymentMethodsModalExample: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <Button onClick={() => setOpen(true)} color='var(--purple)'>Alterar forma de pagamento</Button>
      <PaymentMethodsModal
        setOpen={setOpen}
        open={open}
      />

      <HR/>

      <PreFormat>
        {`
          const [open, setOpen] = useState<boolean>(false);

          return (
            <PaymentMethodsModal
              open={open}
              setOpen={setOpen}
            />
          );
        `}
      </PreFormat>
    </div>
  );
}

export default PaymentMethodsModalExample;