import React, { useRef, useState } from 'react';
import {
  Content,
  IconCart,
  LinkContainer,
  Title,
  Text,
  Suggestion,
} from './styles';
import { Link } from 'react-router-dom';
import { GridProducts } from '../Cart/styles';
import ProductCard from '../../../../components/ProductCard';
import { SlickStyled } from '../../../Search/styled';
import Slider from 'react-slick';
import { SuggestionFake } from '../../../../dataFake/checkout';
import { SampleNextArrow, SamplePrevArrow } from './components';
import { useRecommendedProducts } from '../../../../hooks/useRecommendedProducts';

const EmptyCart: React.FC = () => {
  const [suggestion] = useState(SuggestionFake);

  const [recommendedProducts] = useRecommendedProducts();

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }).current;

  return (
    <section>
      <LinkContainer>
        <Link to={'/'}>
          <i className="icon icon-arrow-left" />
          Voltar para página inicial
        </Link>
        <Content>
          <IconCart />
          <Title>Você ainda não possui itens no seu carrinho.</Title>
          <Text>
            Que tal navegar e adicionar peças para sua próxima compra?
          </Text>
        </Content>

        <Suggestion>
          <h4>Sugestões para você</h4>
          <GridProducts>
            {recommendedProducts.map((item) => (
              <ProductCard
                key={item.id}
                image={item.mainImage}
                hoverImage={item.mainImage}
                title={item.name}
                price={item.price}
                pricePromotion={item.originalPrice}
                parcel={item.paymentCondition}
                isFavorite={item.isFavorite}
                productId={item.id}
                productSlug={item.productSlug}
                profileUrl={item.sellerProfileUrl}
                selloffPercentage={item.selloffPercentage}
              />
            ))}
          </GridProducts>
        </Suggestion>

        <SlickStyled style={{ marginTop: '100px' }}>
          <h4>Veja mais peças da Miranda Flor</h4>
          <Slider {...settings}>
            {suggestion.map((item, key) => (
              <div key={key}>
                <ProductCard
                  key={key}
                  image={item.img_main}
                  hoverImage={item.img_secondary}
                  title={item.title}
                  price={item.price}
                  pricePromotion={item.price_promotion}
                  parcel={item.parcel}
                />
              </div>
            ))}
          </Slider>
        </SlickStyled>
      </LinkContainer>
    </section>
  );
};

export default EmptyCart;
