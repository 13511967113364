import React from 'react';

import { ToastProvider } from './hooks/useToast';
import { AlertProvider } from './hooks/useAlert';
import { AuthProvider } from './hooks/useAuth';

const AppProvider: React.FC = ({ children }) => {
  return (
    <>
      <AlertProvider>
        <ToastProvider>
          <AuthProvider>{children}</AuthProvider>
        </ToastProvider>
      </AlertProvider>
    </>
  );
};

export default AppProvider;
