import styled from 'styled-components';
import {ReactComponent as IconEmptyCart} from '../../../../assets/svg/icon-empty-cart.svg';
import {SuggestionsContainer} from '../Cart/styles';

export const LinkContainer = styled.div`
  margin-top: -31px;

  > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--black);

    i {
      margin-right: 9px;
      font-size: 18px;
      color: var(--purple);
    }
  }

  @media (max-width: 1279px) {
    margin-top: 0;
  }

  @media (max-width: 607px) {
    margin-top: 32px;
    > a {
      font-size: 12px;

      > i {
        font-size: 14px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 129px 0 261px;

  @media (max-width: 1279px) {
    margin: 134px 0 113px;
  }

  @media (max-width: 1279px) {
    margin: 69px 0 94px;
  }
`;

export const Title = styled.h2`
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;

  @media(max-width: 607px){
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const Text = styled.p`
  color: var(--grey-medium-1);
  text-align: center;

  @media(max-width: 607px){
    font-size: 10px;
  }
`;

export const IconCart = styled(IconEmptyCart)`
  margin-bottom: 32px;

  @media(max-width: 1279px){
    margin-bottom: 16px;
    width: 55px;
    height: 45px;
  }
`;


export const Suggestion = styled(SuggestionsContainer)`
`;
