import React, {useCallback} from 'react';
import Input from '../Input';
import {Container, Content, Clear, NewsletterContainer, Flex, Title} from './styles';
import useForm from '../../hooks/useForm';
import Button from '../Button';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useAlert} from '../../hooks/useAlert';
import {api} from '../../services/api';

const Newsletter: React.FC = () => {
  const {sendEventAnalytics} = useAnalytics();
  const {form, error, onBlur, onChange, validateForm, clearForm} = useForm({
    email: {
      type: 'email',
      required: true,
    },
  });

  const {showAlert} = useAlert();

  const submitForm = useCallback(async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      validateForm();

      if (error.email) {
        return false;
      }

      await api.post('/newsletter', {
        email: form.email,
        tag: 'newsletter'
      });

      sendEventAnalytics({category: 'Newsletter', action: 'Registro para Newsletter', label: 'Newsletter Botão'});

      showAlert({
        type: 'success',
        description: 'Oba! Agora você vai estar por dentro de todas as novidades do Houpa!'
      });

      clearForm();

    }catch (e: any){
      showAlert({
        type: 'error',
        description: 'Infelizmente não conseguimos salvar seu e-mail, tente novamente!'
      });
    }
  }, [validateForm, error.email, form.email, sendEventAnalytics, showAlert, clearForm]);

  return (
    <>
      <Container>
        <Content>
          <Flex>
            <div>
              <Title>
                Assine nossa newsletter!
              </Title>
              <Title>
                Fique por dentro de ofertas e novidades exclusivas!
              </Title>
            </div>
            <NewsletterContainer onSubmit={submitForm}>
              <div>
                <Input
                  value={form.email}
                  onBlur={() => onBlur('email')}
                  onChange={(e) => onChange('email', e)}
                  error={error.email}
                  required
                />
              </div>
              <div>
                <Button type={'submit'} scale='scale-1'>Enviar</Button>
              </div>
            </NewsletterContainer>
          </Flex>
          <Clear></Clear>
        </Content>
      </Container>
    </>
  );
};

export default Newsletter;
