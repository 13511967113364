import React, {useState} from 'react';

import { AccordionBody, AccordionHeader, Container } from './styles';

interface Props {
  title: string;
}

const InstitutionalAccordion: React.FC<Props> = ({ title, children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <Container>
      <AccordionHeader isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        <i className="icon icon-arrow-down" />
        <span>{title}</span>
      </AccordionHeader>
      <AccordionBody isOpen={isOpen}>
        <span>{children}</span>
      </AccordionBody>
    </Container>
  );
};

export default InstitutionalAccordion;
