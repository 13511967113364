import styled from 'styled-components';
import { Tabs } from '@material-ui/core';

export const ShowMobile = styled.div`
  padding: 8px 0 17px 0;

  @media (min-width:481px)  {  
    display: none;
  }
`

export const ShowDesktop = styled.div`
  display: none;

  @media (min-width:481px)  {
    display: block;
  }
`

export const Container = styled.div`
  max-width: var(--container);
  margin: 0 auto;
  padding: 16px 0;
`;

export const ProfileContainer = styled.div`
  display: flex;

  @media (max-width: 1279px) {
    flex-direction: column-reverse;
  }
`;

export const ProfileContent = styled.div`
  flex: 1;
  max-width: 308px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 360px;
  padding: 38px 22px 0;
  background-color: var(--grey-light-3);
  margin-bottom: 32px;

  > h3 {
    font-size: 20px;
    margin: 19px;
  }

  > p {
    font-size: 10px;
    text-align: center;
    margin-bottom: 32px;
    line-height: 15px;
    color: var(--grey-medium-4);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
    margin: 0 0 18px;
    min-height: 307px;

    position: relative;

    > h3 {
      margin-top: 66px;
    }

    > p {
      max-width: 264px;
    }
  }

  @media (max-width: 607px) {
    min-height: 230px;

    > h3 {
      font-size: 1rem;
      margin: 30px 0 10px;
    }

    > p {
      max-width: 264px;
      margin-bottom: 21px;
    }
  }

`;

export const ProfileIMG = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--grey-light-2);

  > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  @media (max-width: 1279px) {
    position: absolute;
    width: 150px;
    height: 150px;
    top: -75px;
  }

  @media (max-width: 607px) {
    width: 100px;
    height: 100px;
    top: -50px;
  }
`;

export const Cover = styled.div`
  flex: 1;
  height: 360px;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }


  @media (max-width: 1279px) {
    height: 225px;
  }

  @media (max-width: 607px) {
    height: 116px;
  }
`;

export const CategoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CategoryCard = styled.div`
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid var(--grey-medium-4);
  margin-right: 7px;

  font-size: 10px;
  color: var(--grey-medium-4);

  &:last-child {
    margin-right: 0;
  }
`;

export const Content = styled.section`
  display: flex;

  > div {
    flex: 1;
  }
`;

export const FilterContainer = styled.div`
  max-width: 292px;
  margin-right: 32px;

  > h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const Body = styled.div`
  flex: 1
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 73px;


  @media (max-width: 1279px) {
    margin-bottom: 26px;
  }
`;

export const InputContainer = styled.div`
  max-width: 440px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const BlockContainer = styled.div`
  width: auto;

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const ResultText = styled.p`
  font-size: 14px;
  color: var(--grey-medium-1);
  margin-right: 15px;
  white-space: nowrap;

  > span {
    font-weight: 700;
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const SelectContainer = styled.div`
  width: 180px;

  margin-left: 25px;

  @media (max-width: 607px) {
    width: 102px;
    margin-left: 21px;
  }
`;

export const TagsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 47px;

  > button {
    margin-right: 12px;
    margin-bottom: 5px;
  }

  @media (max-width: 607px) {
    margin-bottom: 24px;
  }
`;

export const TabsContainer = styled.div`
  max-width: var(--container);

  > div {
    flex: 1
  }
`;

export const TabsHeader = styled(Tabs)`
  && {
    background-color: var(--white);
    box-shadow: 0 0 0;
    color: var(--grey-dark);
    text-transform: none;
    margin-bottom: 30px;

    @media (max-width: 607px) {
      margin-bottom: 24px;
    }
  }

  .MuiTabs-scroller {
    > span {
      background-color: var(--purple) !important;
    }
  }

  .MuiTab-textColorInherit {
    opacity: 1;

    &.Mui-selected {
      color: var(--purple);

      .MuiTab-wrapper {
        font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
      }
    }

    @media (max-width: 607px) {
      font-size: 9px;

      padding: 5px;
    }
  }
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 215px);
  gap: 32px;

  &.three-grid {
    grid-template-columns: repeat(3, 297.33px);
  }

  &.two-grid {
    grid-template-columns: repeat(2, 462px);
  }

  @media (max-width: 1279px) {
    grid-template-columns: repeat(3, 190.7px) !important;
    gap: 18px;
  }

  @media (max-width: 607px) {
    grid-template-columns: repeat(2, 151px) !important;
    gap: 8px;
  }
`;
