import React, { useEffect, useState } from 'react';
import {
  ContainerSlide,
  Category,
  Title,
  Container,
  Content,
  Clear,
  ContentSlideNews,
  SlideNewsItem,
  ViewMore,
  SlidePromotionsItem,
  SlidePromotions,
  ImageLink
} from './styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from '../../components/ProductCard';
import Newsletter from '../../components/Newsletter';
import { api } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import { useLoading } from '../../hooks/useLoading';

interface BannerProps {
  component?: string;
  empresaId?: string;
  id: number;
  imageUrl: string;
  imageUrlWeb: string;
  order: number;
  status: number;
  typeId?: string;
  url?: string;
}

interface StylesProps {
  name: string;
}

interface NewsProps {
  id: number;
  name: string;
  productSlug: string;
  selloffStatus: number;
  price: string;
  originalPrice?: string;
  selloffPercentage: number;
  newReleaseStatus: number;
  isFavorite: number;
  mainImage: string;
  sellerProfileUrl: string;
  paymentCondition: string;
}

interface PromotionsProps {
  id: number;
  name: string;
  productSlug: string;
  selloffStatus: number;
  price: string;
  originalPrice?: string;
  selloffPercentage: number;
  newReleaseStatus: number;
  isFavorite: number;
  mainImage: string;
  sellerProfileUrl: string;
  paymentCondition: string;
}

const Home: React.FC = () => {
  const [banners, setBanners] = useState<BannerProps[]>([]);
  const [styles, setStyles] = useState<StylesProps[] | false>();
  const [news, setNews] = useState<NewsProps[] | false>();
  const [promotions, setPromotions] = useState<PromotionsProps[] | false>();

  const { showLoading, closeLoading } = useLoading();
  const navigate = useNavigate();
  const mobile = useMedia('(max-width: 607px)');

  const getBanners = async () => {
    try {
      const { data } = await api.get('/home/banners');

      setBanners(data.data);
    } catch (err) { }
  };

  const getStyles = async () => {
    try {
      const { data } = await api.get('/home/styles');

      if (data.status) {
        setStyles(data.data);
      }
    } catch (err) { }
  };

  const getNews = async () => {
    try {
      const { data } = await api.get(
        '/home/news?tab=release&order=rand&limit=7',
      );
      if (data.status) {
        setNews(data.data.products);
      }
    } catch (err) { }
  };

  const getPromotions = async () => {
    try {
      const { data } = await api.get(
        '/home/ promotions?order=rand&tab=selloff&limit=5',
      );
      if (data.status) {
        setPromotions(data.data.products);
      }
    } catch (err) { }
  };

  const openLink = (url: string | undefined) => {
    if (url && url != '') {
      window.location.href = url;
    }
  }

  const init = async () => {
    try {
      showLoading();

      await Promise.all([
        getBanners(),
        getStyles(),
        getNews(),
        getPromotions(),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    init();

    //eslint-disable-next-line
  }, []);

  return (
    <>
      {banners.length && (
        <ContainerSlide className="testecontainer">
          <Slider
            dots={false}
            infinite
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            adaptiveHeight={true}
            autoplay
          >
            {banners.map((option, index) => (
              <ImageLink
                key={index}
                onClick={() => openLink(option.url)}
              >
                <img
                  alt={'Banners promocionais'}
                  src={`${mobile ? option.imageUrl : option.imageUrlWeb}`}
                />
              </ImageLink>
            ))}
          </Slider>
        </ContainerSlide>
      )}
      {styles && (
        <Container>
          <Content>
            <Category>
              <ul>
                {styles.map((option, index) => (
                  <li
                    onClick={() =>
                      navigate(
                        `/pesquisa${index < styles.length - 1 ? '?s=' + option.name : ''
                        }`,
                      )
                    }
                    key={index}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            </Category>
            <Clear />
          </Content>
        </Container>
      )}
      {news && (
        <Container>
          <Content>
            <Title className="center">NOVIDADES DA ESTAÇÃO</Title>
            <ContentSlideNews>
              {news.map((option) => (
                <SlideNewsItem key={option.id}>
                  <ProductCard
                    image={option.mainImage}
                    hoverImage={option.mainImage}
                    title={option.name}
                    price={option.price}
                    pricePromotion={option.originalPrice}
                    parcel={option.paymentCondition}
                    isFavorite={option.isFavorite}
                    productId={option.id}
                    productSlug={option.productSlug}
                    profileUrl={option.sellerProfileUrl}
                    selloffPercentage={option.selloffPercentage}
                  />
                </SlideNewsItem>
              ))}
            </ContentSlideNews>
            <ViewMore to={'/pesquisa?order=release'}>Ver mais</ViewMore>
            <Clear />
          </Content>
        </Container>
      )}
      {promotions && (
        <Container>
          <Content>
            <Title className="center">PROMOÇÕES</Title>
            <SlidePromotions>
              {promotions.map((el) => (
                <SlidePromotionsItem key={el.id}>
                  <ProductCard
                    image={el.mainImage}
                    hoverImage={el.mainImage}
                    title={el.name}
                    price={el.price}
                    pricePromotion={el.originalPrice}
                    parcel={el.paymentCondition}
                    isFavorite={el.isFavorite}
                    productId={el.id}
                    productSlug={el.productSlug}
                    profileUrl={el.sellerProfileUrl}
                    selloffPercentage={el.selloffPercentage}
                  />
                </SlidePromotionsItem>
              ))}
            </SlidePromotions>
            <ViewMore to={'/pesquisa?order=biggerDiscount'}>Ver mais</ViewMore>
            <Clear />
          </Content>
        </Container>
      )}
      <Newsletter />
    </>
  );
};

export default Home;
