import React, { useState, FormEvent, useCallback, useEffect } from 'react';
import {
  Container,
  Title,
  FormText,
  InputsWrapper,
  ButtonWrapper,
  CheckboxWrapper,
  CheckboxText,
  CheckboxErrorMessage
} from './styles';
import { Link } from 'react-router-dom';
import useForm from '../../../../hooks/useForm';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import VerifyCode from './VerifyCode';
import { handleErrors } from '../../../../helpers/utils';
import { useLoading } from '../../../../hooks/useLoading';
import { api } from '../../../../services/api';
import {IValidateUser} from '../index';

interface Props {
  navigateTo: string;
  registerStep: 'initial' | 'createAccount';
  setRegisterStep: (step: 'initial' | 'createAccount') => void;
  validateUser: IValidateUser;
}
const Register: React.FC<Props> = ({ navigateTo, registerStep, setRegisterStep, validateUser }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [checkedError, setCheckedError] = useState<boolean>(false);
  const [registerEmail, setRegisterEmail] = useState<string>('');

  const {
    form, error, setError, onChange, validateForm, onBlur, setValueForm
  } = useForm({
    name: {
      type: 'fullname',
      required: true,
      limit: 100
    },
    email: {
      type: 'email',
      required: true,
      limit: 100
    },
    password: {
      type: 'password',
      required: true,
      limit: 100
    },
    confirmPassword: {
      type: 'password',
      required: true,
      limit: 100
    }
  });
  const { showLoading, closeLoading } = useLoading();
  const [userId, setUserId] = useState<number>(Number);


  const handleSubmit = useCallback(async(e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if(!checked) {
        setCheckedError(true);
      }

      if (!validateForm() || !checked) {
        return false;
      }

      if(form.password !== form.confirmPassword) {
        error.confirmPassword = 'As senhas precisam ser iguais';
        setError({...error});

        return false;
      }

      showLoading();

      setRegisterEmail(form.email);
      const {data: {data}} = await api.post('/users', form);
      setUserId(data.userId);
      setRegisterStep('createAccount');

      closeLoading();
    } catch (e: any) {
      setError(handleErrors(e, error));
      closeLoading();
    }
  }, [
    checked,
    closeLoading,
    error,
    form,
    setError,
    setRegisterEmail,
    showLoading,
    validateForm,
    setRegisterStep
  ]);

  const handleChecked = () => setChecked(!checked);

  useEffect(() => {
    window.scrollTo(({top: 0}));
  }, [registerStep]);

  return (
    <>
    {registerStep === 'initial' && (
    <Container onSubmit={handleSubmit}>
      <Title>Quero me cadastrar</Title>
      <FormText>Preencha os dados abaixo para concluir seu cadastro.</FormText>
      <InputsWrapper>
        <Input
          title='Nome e sobrenome'
          value={form.name}
          onBlur={() => onBlur('name')}
          onChange={(e) => onChange('name', e)}
          error={error.name}
          required
          placeholder='Insira o seu nome completo'
        />
        <Input
          title='E-mail'
          type="email"
          value={form.email}
          onBlur={() => onBlur('email')}
          onChange={(e) => onChange('email', e)}
          error={error.email}
          required
          placeholder='Insira o seu e-mail'
        />
        <Input
          title='Senha'
          type="password"
          value={form.password}
          onBlur={() => onBlur('password')}
          onChange={(e) => onChange('password', e)}
          error={error.password}
          required
          placeholder='********'
        />
        <Input
          title='Confirme sua senha'
          type="password"
          value={form.confirmPassword}
          onBlur={() => onBlur('confirmPassword')}
          onChange={(e) => onChange('confirmPassword', e)}
          error={error.confirmPassword}
          required
          placeholder='********'
        />
      </InputsWrapper>
      <ButtonWrapper>
        <Button color="var(--purple)" scale="scale-4" expand>Criar conta</Button>
      </ButtonWrapper>
      <CheckboxWrapper>
        <Checkbox checked={checked} color='var(--purple)' onChange={handleChecked}>
          <CheckboxText>
            Aceito os <Link to='/termos'>Termos e condições</Link> e autorizo o uso de meus dados de acordo com a <Link to="/politicas-privacidade">Declaração de privacidade</Link>.
          </CheckboxText>
        </Checkbox>
        {checkedError && !checked && (
          <CheckboxErrorMessage>Necessário aceitar os termos de uso para prosseguir</CheckboxErrorMessage>
        )}
      </CheckboxWrapper>
    </Container>
    )}

    {registerStep === 'createAccount' && (
      <VerifyCode
        form={form}
        error={error}
        setError={setError}
        userId={userId}
        setUserId={setUserId}
        navigateTo={navigateTo}
        setRegisterStep={setRegisterStep}
        registerEmail={registerEmail}
        setValueForm={setValueForm}
        state={validateUser}
      />
    )}
    </>
  );
};

export default Register;
