import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import OrderSummary from '../../components/OrderSummary';
import { SummaryContainer } from '../../styles';
import {
  ButtonAlterMethods,
  Container,
  ContainerInfo,
  Content,
  FreightArea,
  FreightContent,
  FreightProducts,
  StatusContainer,
} from './styles';

import FreightProductsItem from '../../components/FreightProductsItem';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../index';
import { useToast } from '../../../../hooks/useToast';
import { useLoading } from '../../../../hooks/useLoading';

const Details: React.FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { showLoading, closeLoading } = useLoading();

  const {
    address: { selectedAddress },
    brandsWithProducts: { brands },
    order: { saveOrder, orderConfig, isLoadingSaveOrder },
    paymentMethods: { methods, availableInstallments },
  } = useContext(CartContext);

  const paymentMethod = useMemo(() => {
    const method = methods.filter((m) => m.id === orderConfig.methodId);

    return (
      method[0] || {
        id: 0,
        name: 'loading...',
      }
    );
  }, [methods, orderConfig.methodId]);

  const choseInstallment = useMemo(() => {
    if (!orderConfig.installmentId) {
      return null;
    }

    const installment = availableInstallments.filter(
      (i) => i.id === orderConfig.installmentId,
    );

    return installment[0];
  }, [availableInstallments, orderConfig.installmentId]);

  const nextStage = useCallback(async () => {
    const status = await saveOrder();

    if (status === undefined) {
      return;
    }

    if (!status) {
      toast({
        type: 'error',
        description: 'Não possível realizar o pedido',
      });

      return;
    }

    navigate('/carrinho/pedido');
    // eslint-disable-next-line
  }, [navigate, saveOrder, toast]);

  useEffect(() => {
    if (isLoadingSaveOrder) {
      showLoading();
    } else {
      closeLoading();
    }

    // eslint-disable-next-line
  }, [isLoadingSaveOrder]);

  return (
    <Container>
      <Content>
        <div className="header">
          <h2>Meu pedido</h2>
        </div>

        <StatusContainer>
          {selectedAddress && (
            <ContainerInfo>
              <h4>Endereço de Entrega:</h4>
              <p className="subtitle">{selectedAddress.title}</p>
              <p>{`${selectedAddress.street}, ${selectedAddress.number} - ${
                selectedAddress.adjunct ? `${selectedAddress.adjunct} - ` : ''
              }${selectedAddress.neighborhood}, ${selectedAddress.city} - ${
                selectedAddress.uf
              }`}</p>

              <ButtonAlterMethods
                onClick={() => navigate('/carrinho/enderecos')}
              >
                Alterar endereço de entrega
              </ButtonAlterMethods>
            </ContainerInfo>
          )}

          <ContainerInfo>
            <h4>Método de Pagamento</h4>
            <p>{paymentMethod.name}</p>
            {choseInstallment && <p>{choseInstallment.name}</p>}

            <ButtonAlterMethods
              onClick={() => navigate('/carrinho/pagamentos')}
            >
              Alterar forma de pagamento
            </ButtonAlterMethods>
          </ContainerInfo>
        </StatusContainer>

        <FreightArea>
          {brands.map((brand, key) => (
            <FreightContent key={brand.id}>
              <h2>Encomenda {key + 1}</h2>
              <p>
                Envio {`${key + 1} de ${brands.length}`} (Enviado por{' '}
                {brand.name})
              </p>
              {brand.address !== undefined && (
                <p className={'small address'}>
                  {`${brand.address.street}, ${brand.address.streetNumber} - ${brand.address.city}/${brand.address.state} - ${brand.address.zipCode}`}
                </p>
              )}

              <FreightProducts>
                {brand.products.map((product, index) => (
                  <FreightProductsItem data={product} key={index} />
                ))}
              </FreightProducts>
            </FreightContent>
          ))}
        </FreightArea>
      </Content>
      <SummaryContainer className="summary">
        <OrderSummary onClick={nextStage} />
      </SummaryContainer>
    </Container>
  );
};

export default Details;
