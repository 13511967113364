import styled from 'styled-components';

export const Container = styled.div` 
  max-width: 820px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 auto;
`;

export const Code = styled.div` 
  margin-top: 60px;
  width: 100%;
  background-color: var(--grey-light-2);
  border: 1px solid var(--grey-medium-1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CodeItem = styled.div` 
  color: var(--grey-medium-1);
`;