import styled from 'styled-components';

export const Container = styled.div` 
  max-width: 845px;
  margin: 85px auto 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 70px auto 0 auto;
    max-width: 430px;
  }
  @media screen and (max-width: 607px) {
    flex-direction: column;
    row-gap: 43px;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
  max-width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  border: 2px solid var(--grey-light-2);
  width: 143px;
  height: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  box-shadow: 0 0 8px 0 #e5e5e5;
  @media screen and (max-width: 1279px) {
    width: 72.02px;
    height: 73.66px;
    border-radius: 17px;
  }
`;

export const ImgWrapper = styled.div` 
  width: 43px;
  height: 45px;
  @media screen and (max-width: 1279px) {
    width: 24px;
    height: 22px;
  }
`;

export const IconTitle = styled.p`
  margin-top: 25px;
  font-size: 20px;
  color: var(--purple);
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
    margin-top: 16px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 12px;
  }
`;

export const IconText = styled.p`
  margin-top: 9px;
  font-size: 14px;
  color: var(--grey-medium-1);
  line-height: 17px;
  text-align: center;
  &.first {
    max-width: 136px;
  }
  &.second {
    max-width: 140px;
  }
  &.third {
    max-width: 145px;
  }
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const LinkFalse = styled.span` 
  cursor: pointer;
  text-decoration: underline;
`;