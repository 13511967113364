import React, { useCallback, useRef } from 'react';
import InputQuantity from '../../../../components/InputQuantity';
import { useAlert } from '../../../../hooks/useAlert';
import { useCart } from '../../../../hooks/useCart';
import { ProductCart } from '../../../../interfaces/orderProduct';
import {
  Container,
  ProductIMG,
  ProductInfo,
  Info,
  Col,
  Remove,
  Row,
} from './styles';
import { Tooltip } from '@material-ui/core';

interface Props {
  data: ProductCart;
  onRemove: (itemId: number, status: boolean) => void;
  onUpdate?: (itemId: number, newQuantity: number) => void;
}

const CartItem: React.FC<Props> = ({ data, onRemove, onUpdate }) => {
  const { updateItem, deleteItem } = useCart();
  const { showAlert } = useAlert();
  const updateTimeout = useRef(0);

  const handleRemoveItem = useCallback(() => {
    showAlert({
      type: 'error',
      description: 'Tem certeza que deseja excluir esse produto?',
      cancelText: 'Cancelar',
      onSubmit: () =>
        deleteItem(data.cartProductId).then((status) => {
          onRemove(data.cartProductId, status);
        }),
    });
    // eslint-disable-next-line
  }, [data.cartProductId, deleteItem, onRemove, showAlert]);

  const handleUpdateItem = useCallback(
    (value: number) =>
      new Promise((resolve, reject) => {
        if (value === 0) {
          handleRemoveItem();
          reject();
          return;
        }

        clearTimeout(updateTimeout.current);
        updateTimeout.current = window.setTimeout(() => {
          updateItem(data.cartProductId, value).then((status) => {
            if (status) {
              resolve(true);
              onUpdate && onUpdate(data.cartProductId, value);
            } else {
              reject();
            }
          });
        }, 500);
      }),
    [data.cartProductId, handleRemoveItem, onUpdate, updateItem],
  );

  return (
    <Container>
      <ProductInfo>
        <ProductIMG>
          <img src={data.image ?? ''} alt={`Produto de ${data.store}`} />
        </ProductIMG>
        <Info>
          <div>
            <Tooltip title={data.seller}>
              <p>
                <strong>
                  Loja: <span>{data.seller}</span>
                </strong>
              </p>
            </Tooltip>
            <p className={'name'}>{data.name}</p>
          </div>
          <div>
            <p>Tam: {data.sizeName}</p>
            <p>Cor: {data.colorName}</p>
            <Tooltip title={data.sku}>
              <p>SKU: {data.sku}</p>
            </Tooltip>
          </div>
        </Info>
      </ProductInfo>

      <Row>
        <Col>
          <p className={'title'}>Valor</p>
          <p>{data.price}</p>
        </Col>

        <Col>
          <p className={'title'}>Quantidade</p>
          <InputQuantity
            quantity={data.quantity}
            onValueChange={handleUpdateItem}
          />
        </Col>

        <Col className={'ab-mob'}>
          <Remove onClick={() => handleRemoveItem()}>
            <i className="icon icon-trashcan" />
            <span>Remover</span>
          </Remove>
        </Col>
      </Row>
    </Container>
  );
};

export default CartItem;
