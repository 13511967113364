import React, { useState } from 'react';
import SelectCard from '../../../components/SelectCard';
import { Container, PreFormater } from './styles';
import { CardInterface } from '../../Checkout/interfaces';

const SelectCardExample: React.FC = () => {
  const [cardSelected, setCardSelected] = useState<CardInterface>({
    id: Math.random(),
    brandName: 'Mastercard',
    brand: 'mastercard',
    holder: 'Renata M Silva',
    number: '111-1111-111-1006',
    main: 1,
  });

  return (
    <div>
      <SelectCard
        onSelectInstallment={() => true}
        cardSelected={cardSelected}
        setCardSelected={setCardSelected}
        results={3}
        loadMoreCards={() => console.log('Carregar mais cartões')}
        options={[
          {
            id: Math.random(),
            brandName: 'Mastercard',
            brand: 'mastercard',
            holder: 'Renata M Silva',
            number: '111-1111-111-1006',
            main: 1,
          },
          {
            id: Math.random(),
            brandName: 'Mastercard',
            brand: 'mastercard',
            holder: 'Renata M Silva',
            number: '111-1111-111-1006',
            main: 0,
          },
          {
            id: Math.random(),
            brandName: 'Mastercard',
            brand: 'mastercard',
            holder: 'Renata M Silva',
            number: '111-1111-111-1006',
            main: 0,
          },
        ]}
        installments={[
          {
            id: 1,
            name: '1x',
            value: 1,
          },
        ]}
      />
      <Container>
        <PreFormater>
          {`
            const [cardSelected, setCardSelected] = useState<{ banner: { img: string; alt: string }, cardName: string, cardNumbers: string} >({
              banner: {
                img:
                  'https://logodownload.org/wp-content/uploads/2014/07/mastercard-logo-1-1.png',
                  alt: 'MasterCard',
              },
              cardName: '1 MasterCard',
              cardNumbers: '111-1111-111-1006',
            });

            return (

              <SelectCard
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
                options={[
                  {
                    banner: {
                      img:
                        'https://logodownload.org/wp-content/uploads/2014/07/mastercard-logo-1-1.png',
                      alt: 'MasterCard',
                    },
                    cardName: '1 MasterCard',
                    cardNumbers: '111-1111-111-1006',
                  },
                  {
                    banner: {
                      img:
                        'https://logodownload.org/wp-content/uploads/2014/07/mastercard-logo-1-1.png',
                      alt: 'MasterCard',
                    },
                    cardName: '2 MasterCard',
                    cardNumbers: '222-2222-222-1007',
                  },
                  {
                    banner: {
                      img:
                        'https://logodownload.org/wp-content/uploads/2014/07/mastercard-logo-1-1.png',
                      alt: 'MasterCard',
                    },
                    cardName: '3 MasterCard',
                    cardNumbers: '333-3333-333-1008',
                  },
                ]}
              />
            )
          `}
        </PreFormater>
      </Container>
    </div>
  );
};

export default SelectCardExample;
