import React, { useState, FormEvent, useEffect, useCallback } from 'react';
import {
  Container,
  Return,
  ReturnIcon,
  ReturnText,
  Title,
  InputWrapper,
  ButtonWrapper,
  ForgotEmail,
  Login,
  InputCodeWrapper,
  InsertCode,
} from './styles';
import Input, { RequiredTip } from '../../../components/Input';
import InputCode from '../../../components/InputCode';
import Button from '../../../components/Button';
import useForm from '../../../hooks/useForm';
import NewPassword from './NewPassword';
import { useToast }  from '../../../hooks/useToast';
import { api } from '../../../services/api';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  navigateTo: string;
}

const RecoverPassword: React.FC<Props> = ({ navigateTo }) => {
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<'initial' | 'inputCode' | 'newPassword'>('initial');
  const [userId, setUserId] = useState<number>(Number);
  const [status, setStatus] = useState<string>('');
  const navigate = useNavigate();

  const queryValidation = navigateTo !== '/' ? `?ReturnUrl=${navigateTo}` : '';

  const {
    form, error, onChange, validateForm, onBlur, setError
  } = useForm({
    email: {
      type: 'email',
      required: true,
      limit: 100
    },
  });
  const encryptedEmail = `${email.slice(0,2)}******@${email.split('@')[1]}`;
  const { toast } = useToast();

  const validateEmail = async (e: FormEvent) => {
    e.preventDefault();

    validateForm();
    if (!error.email && form.email) {
      await handleSendEmail();
    }
  };

  const handleSendEmail = useCallback(async () => {
    try {
      const {data: {data: {userId}}} = await api.post('/recover-password', form);

      setUserId(userId);
      setEmail(form.email);
      setStep('inputCode');
    } catch (e: any) {
      if (e.type) {
        toast({
          type: 'error',
          description: e.message
        });

        return false;
      }

      setError({...error, email: e.message});
    }
  }, [toast, form, error, setEmail, setUserId, setError]);


  const handleReturn = () => {
    switch(step) {
      case 'initial':
        navigate('/login');
        break;
      case 'inputCode':
        setStep('initial');
        break;
      case 'newPassword':
        setStep('initial');
        setStatus('');
        break;
      default:
        return null;
    }
  };

  const verifyCode = useCallback(async (code) => {
    try {
      await api.put(`/access-code/${code}`, {userId});

      setStatus('success');
      setTimeout(() => {
        setStep('newPassword');
      }, 500);

    } catch (error: any) {
      setStatus('');
      setStatus('error');
    }
  }, [setStep, userId]);

  const resendingEmail = useCallback(async () => {
    try {
      await api.post('/access-code', { userId: userId });

      toast({
        type: 'success',
        description: 'Email reenviado com sucesso!'
      });
    } catch (e: any) {
      toast({
        type: 'error',
        description: e.message
      });

    }
    // eslint-disable-next-line
  }, [toast, userId]);

  useEffect(() => {
    window.scrollTo({top: 0});
  }, [step]);

  return (
    <>
      <Container>
        <Return onClick={handleReturn}>
          <ReturnIcon className="icon icon-arrow-left" />
          <ReturnText>Voltar</ReturnText>
        </Return>
        <Title>
          {step !== 'newPassword' ? 'Recupere sua senha' : 'Digite uma nova senha'}
        </Title>
        {step === 'initial' && (
          <InputWrapper onSubmit={validateEmail}>
            <Input
              title='E-mail'
              type="email"
              value={form.email}
              onBlur={() => onBlur('email')}
              onChange={(e) => onChange('email', e)}
              error={error.email}
              required
              placeholder='Insira o seu e-mail'
            />
            <ButtonWrapper>
              <Button color="var(--purple)" scale="scale-4" expand>Enviar</Button>
            </ButtonWrapper>
          </InputWrapper>
        )}

        {step === 'inputCode' && (
          <>
            <InsertCode>Insira o código de 4 dígitos que enviamos para o e-mail <span>{encryptedEmail}</span></InsertCode>
            <InputCodeWrapper>
              <InputCode status={status} validateCode={verifyCode} />
            </InputCodeWrapper>
          </>
        )}

        {step === 'newPassword' && (
          <NewPassword userId={userId} navigateTo={navigateTo} email={form.email} />
        )}

        {step !== 'newPassword' && (
          <ForgotEmail>
            {step === 'inputCode' ? (
              <>Se você não receber o código <span onClick={resendingEmail}>Clique aqui</span></>
            ) : (
              <>Não sabe seu e-mail? <Link to={`/login/recuperar-email${queryValidation}`}>Clique aqui</Link></>
            )}
          </ForgotEmail>
        )}
        <Login>Já possui conta? <Link to={`/login${queryValidation}`}>Faça login</Link></Login>
        {step !== 'inputCode' && <RequiredTip centerText/>}
      </Container>
    </>
  );
};

export default RecoverPassword;
