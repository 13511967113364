import styled from 'styled-components';

export const Container = styled.div`
  max-width: 446px;
  margin: 57px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 452px;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
  }
`;

export const Return = styled.div`
  display: flex;
  align-items: center;
  column-gap: 9px;
  cursor: pointer;
`;

export const ReturnIcon = styled.i`
  font: 18px;
  color: var(--purple);
`;

export const ReturnText = styled.span`
  font-size: 16px;
  color: var(--black);
  line-height: 19px;
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 36px;
  color: var(--grey-dark);
  font-weight: 700;
  font-family: var(--poppins);
  margin-top: 37px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;

export const InputWrapper = styled.form`
  margin-top: 16px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 150px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 46px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 214px;
  margin: 49px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 296px;
    margin: 131px auto 0;
  }
  @media screen and (max-width: 607px) {
    max-width: 152px;
    margin: 89px auto 0;
  }
`;

export const ForgotEmail = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey-dark);
  margin-top: 40px;
  > span, a {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color: var(--grey-dark);
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 67px;
;
  }
  @media screen and (max-width: 607px) {
    margin-top: 42px;
  }
`;

export const Login = styled(ForgotEmail)`
  margin-top: 150px;
`;

export const InputCodeWrapper = styled.div`
  margin-top: 108px;
`;

export const InsertCode = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: var(--grey);
  margin-top: 25px;
  span {
    font-weight: 700;
  }
`;
