import React, { useContext, useMemo, useRef, useState } from 'react';
import {
  BankSlipContainer,
  Banner,
  ButtonFinishOrder,
  Container,
  Content,
  CreditContainer,
  OrderContainer,
  Panel,
  PaymentsContainer,
  Suggestion,
  Title,
  StatusContainer,
  ContainerInfo,
  Wrapper,
  FreightArea,
  FreightProducts,
} from './styles';

import Button from '../../../../components/Button';
import { GridProducts } from '../Cart/styles';
import ProductCard from '../../../../components/ProductCard';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import Snack from '../../../../components/Snackbar';
import { CartContext } from '../../index';
import FreightProductsItem from '../../components/FreightProductsItem';
import { useRecommendedProducts } from '../../../../hooks/useRecommendedProducts';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../../assets/animations/loading.json';

const Confirm: React.FC = () => {
  const navigate = useNavigate();

  const { purchaseProduct } = useAnalytics();

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
  }).current;

  const {
    order: { orderResponse, orderConfig, newCard },
    paymentMethods: { methods, availableInstallments },
    cartProducts: { cartResume, products },
    cards: { cardSelected },
    address: { selectedAddress },
  } = useContext(CartContext);

  purchaseProduct(cartResume, products);

  const [recommendedProducts] = useRecommendedProducts();

  const paymentMethod = useMemo(() => {
    const method = methods.filter((m) => m.id === orderConfig.methodId);

    return method[0];
  }, [methods, orderConfig.methodId]);

  const choseInstallment = useMemo(() => {
    if (!orderConfig.installmentId) {
      return null;
    }

    const installment = availableInstallments.filter(
      (i) => i.id === orderConfig.installmentId,
    );

    return installment[0];
  }, [availableInstallments, orderConfig.installmentId]);

  const card = useMemo(() => {
    if (orderConfig.methodId === 1) {
      return null;
    }

    if (orderConfig.cardId) {
      return cardSelected;
    } else if (newCard && orderConfig.card) {
      const number = '************' + newCard.cardNumber.substr(-4, 4);

      return {
        number,
        holder: newCard.holder,
        brandName: orderConfig.card.brandName,
      };
    }
  }, [
    cardSelected,
    newCard,
    orderConfig.card,
    orderConfig.cardId,
    orderConfig.methodId,
  ]);

  const [snack, setSnack] = useState(false);

  const handleClose = () => {
    setSnack(false);
  };

  if (!orderResponse) {
    navigate('/pesquisa');
    return null;
  }

  return (
    <Container>
      <p className="container-title">Resumo do pedido</p>
      <Content>
        <div>
          <Panel>
            <Title>
              <div className="icon-container">
                <i className="icon icon-success" />
              </div>
              <p>Parabéns, seu pedido foi realizado com sucesso!</p>
            </Title>

            <Wrapper>
              <OrderContainer>
                <p>n° do pedido</p>
                <h2>{orderResponse.orderId}</h2>
                <p>
                  Todas as informações com o status da sua compra serão enviadas
                  para o e-mail <strong>{orderResponse.email}</strong>
                </p>
              </OrderContainer>

              <StatusContainer>
                {selectedAddress && (
                  <ContainerInfo>
                    <h4>Endereço de Entrega:</h4>
                    <p className="subtitle">{selectedAddress.title}</p>
                    <p>{`${selectedAddress.street}, ${selectedAddress.number}${
                      selectedAddress.adjunct && ` - ${selectedAddress.adjunct}`
                    } - ${selectedAddress.neighborhood}, ${
                      selectedAddress.city
                    } - ${selectedAddress.uf}`}</p>
                  </ContainerInfo>
                )}

                <ContainerInfo>
                  <h4>Método de Pagamento:</h4>
                  <p>{paymentMethod.name}</p>
                  {choseInstallment && <p>{choseInstallment.name}</p>}
                  {paymentMethod.id === 1 && (
                    <p>{cartResume.totalWithDiscount}</p>
                  )}
                </ContainerInfo>
              </StatusContainer>
            </Wrapper>

            <Wrapper>
              <PaymentsContainer>
                {paymentMethod.id === 1 && (
                  <BankSlipContainer>
                    <p>
                      Agora, basta realizar o pagamento do seu boleto para dar
                      continuidade ao procedimento e receber seus produtos. Após
                      o pagamento do boleto e sua confirmação, a estimativa de
                      entrega começará a partir da aprovação.
                    </p>

                    <h2>Valor total: {cartResume.totalWithDiscount}</h2>
                  </BankSlipContainer>
                )}
                {paymentMethod.id === 2 && card && (
                  <CreditContainer>
                    <p>
                      <strong>Nome titular do cartão:</strong> {card.holder}
                    </p>
                    <p>
                      <strong>Número do cartão:</strong> {card.number} (
                      {card.brandName})
                    </p>
                    {choseInstallment && (
                      <p>
                        <strong>Parcelas:</strong> {choseInstallment.name}
                      </p>
                    )}

                    <div className="alert">
                      <p className="alert-title">Atenção!</p>
                      <p className="alert-description">
                        Em casos de uso de cartões de terceiros, é feita uma
                        verificação dos dados com o titular do cartão. Isso pode
                        levar a um tempo de espera maior para aprovação do
                        pagamento, mas não se preocupe, isso acontece para
                        garantir segurança a todos.
                      </p>
                    </div>
                  </CreditContainer>
                )}
              </PaymentsContainer>
            </Wrapper>

            <FreightArea>
              <p className="freight-title">Itens do Pedido</p>
              <FreightProducts
                style={{ backgroundColor: 'var(--grey-light-3' }}
              >
                {products.map((product) => (
                  <>
                    <FreightProductsItem
                      key={product.cartProductId}
                      data={product}
                    />
                  </>
                ))}
              </FreightProducts>
            </FreightArea>

            <ButtonFinishOrder>
              <Button
                color="var(--purple)"
                onClick={() =>
                  navigate(
                    `/minha-conta/pedidos/detalhes/${orderResponse.orderId}`,
                  )
                }
                scale={'scale-3'}
                expand
              >
                Acompanhe seu pedido
              </Button>
              <Button
                color="var(--purple)"
                onClick={() => navigate('/minha-conta')}
                scale={'scale-3'}
                expand
              >
                Ir para minha conta
              </Button>
            </ButtonFinishOrder>
          </Panel>
        </div>
        <div>
          <Banner />
        </div>
      </Content>

      <Suggestion>
        <h4>Sugestões para você</h4>
        <GridProducts>
          {recommendedProducts.map((item) => (
            <ProductCard
              key={item.id}
              image={item.mainImage}
              hoverImage={item.mainImage}
              title={item.name}
              price={item.price}
              pricePromotion={item.originalPrice}
              parcel={item.paymentCondition}
              productId={item.id}
              productSlug={item.productSlug}
              isFavorite={item.isFavorite}
              profileUrl={item.sellerProfileUrl}
              selloffPercentage={item.selloffPercentage}
            />
          ))}
        </GridProducts>
      </Suggestion>

      <Snackbar open={snack} autoHideDuration={1500} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="info"
        >
          Código copiado!
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default Confirm;
