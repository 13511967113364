import React  from 'react';
import CookiesAccept from '../../../components/CookiesAccept';

const CookiesAcceptExample: React.FC  = () => {
  return (
    <CookiesAccept/>
  );
};

export default CookiesAcceptExample;
