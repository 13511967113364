import {
  NewCardType,
  OrderConfigInterface,
  OrderResponseType,
  UseOrderConfigInterface,
} from '../interfaces';
import { useCallback, useRef, useState } from 'react';
import { api } from '../../../services/api';
import {IFreights} from '../../../hooks/useShipping';

export const useOrderConfig = (): UseOrderConfigInterface => {
  const initOrderConfig = useRef({
    methodId: 2,
    addressId: 0,
    installmentId: 2,
    freights: [] as IFreights[]
  }).current;

  const [orderResponse, setOrderResponse] = useState<OrderResponseType>(null);
  const [orderConfig, setOrderConfig] = useState<OrderConfigInterface>(
    initOrderConfig,
  );
  const [loading, setLoading] = useState(false);
  const [newCard, setNewCard] = useState<NewCardType>(null);

  const previousConfig = useRef<OrderConfigInterface>(initOrderConfig);

  const handleSetOrderConfig = (newValues: Partial<OrderConfigInterface>) => {
    setOrderConfig((prevState) => {
      let config = { ...prevState, ...newValues };

      if (newValues.methodId === undefined) {
        previousConfig.current = config;
      } else {
        if (newValues.methodId === 1) {
          delete config.cardId;
          delete config.installmentId;
          delete config.card;
        } else {
          config = previousConfig.current;
          config.installmentId = 2;
        }
      }

      return config;
    });
  };

  const saveOrder = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await api.post('orders', orderConfig);

      setOrderResponse(data);
      return true;
    } catch (e: any) {
      return false;
    } finally {
      setLoading(false);
    }
  }, [loading, orderConfig]);

  return {
    orderConfig,
    setOrderConfig: handleSetOrderConfig,
    saveOrder,
    orderResponse,
    isLoadingSaveOrder: loading,
    newCard,
    setNewCard,
  };
};
