import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1300px;
  padding: 78px 10px 40px;
  margin: 0 auto;

  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 630px;
    margin: 0 auto;
    padding: 25px 10px;
  }
  @media screen and (max-width: 607px) {
    max-width: 332px;
    margin: 0 auto;
    padding: 36px 10px;
  }
`;

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 232px);
  gap: 45px;
  max-width: 1063px;
  margin: 0 auto;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, 191.3px);
    column-gap: 18px;
    row-gap: 44px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    grid-template-columns: repeat(2, minmax(140px, 151px));
    column-gap: 8px;
    row-gap: 36px;
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-family: var(--poppins);
  font-size: 18px;
  margin-top: 20px;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
  }
  @media screen and (max-width: 607px) {
    font-size: 16px;
  }
`;

export const SeparateItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1063px;
  margin: 30px auto ;
`;

export const ProductsQuantity = styled.p`
  font-size: 14px;
  color: var(--grey-medium-1);
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
`;

export const ProductsQuantityNumber = styled.span`
  display: inline-block;
  margin-right: 5px;
  font-weight: 700;
`;

export const SelectWrapper = styled.div`
  width: 180px;
  @media screen and (max-width: 607px) {
    width: 105px
  }
`;

export const ProductCardWrapper= styled.div`
  position: relative;
  .wrapper {
    display: none
  }
  .chip {
    position: absolute;
    top: 10px;
    left: 6px;
  }
`;

export const IconCircle = styled.div`
  position: absolute;
  right: 8px;
  top: 10px;
  background-color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: 1279px) {
    top: 12px;
    width: 22px;
    height: 22px;
  }
`;

export const CloseIcon = styled.i`
  font-size: 12px;
  color: var(--red-dark);
  @media screen and (max-width: 1279px) {
    font-size: 8px;
  }
`;

export const Unavailable = styled.div`
  &.unavailable {
    display: block;
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const UnavailableMessageWrapper = styled.div`
  display: none;
  &.unavailable {
    display: block;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    @media screen and (min-width: 607px) and (max-width: 1279px) {
      top: 105px;
    }
    @media screen and (max-width: 607px) {
      top: 85px;
    }
  }
`;

export const UnavailableMessage = styled.p`
    background-color: var(--grey-light-2);
    font-size: 12px;
    padding: 5px 16px;
    margin: 0 auto;
    max-width: 122px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
`;

export const UnavailableRedMessage = styled.p`
  display: none;
  &.unavailable {
    display: block;
    position: absolute;
    bottom: -20px;
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(213, 0, 0, 1);
  }
`;

export const NoProducts = styled.div`
  margin-top: 130px;
  @media screen and (max-width: 1279px) {
    margin-top: 75px;
  }
`;

export const Hello = styled.h2`
  color: var(--purple);
  text-align: center;
  font-size: 36px;
  @media screen and (max-width: 1279px) {
    font-size: 24px;
  }
`;

export const Message = styled.p`
  text-align: center;
  font-size: 24px;
  margin-top: 24px;
  line-height: 16.41px;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
    margin-top: 16px;
  }
  @media screen and (max-width: 607px) {
    max-width: 214px;
    margin: 16px auto 0 auto;
  }
`;

export const CallToAction = styled.p`
  color: var(--grey-medium-1);
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
  @media screen and (max-width: 1279px) {
    font-size: 10px;
  }
`;

export const Location = styled.span`
  font-size: 12px;
  color: var(--grey-dark);
  a {
    text-decoration: none;
    color: var(--grey-dark);
    margin-right: 4px;
  }

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
