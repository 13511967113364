import React from 'react';
import {
  Container,
  Wrapper,
  HelpAndSupport,
  Banner
} from './styles';
import HelpImg from '../../../assets/jpg/image-help.jpg';
import Newsletter from '../../../components/Newsletter';

import NeedHelp from './NeedHelp';
import Form from './Form';
import IconsSection from './IconsSection';
import MoreAbout from './MoreAbout';

const Help: React.FC = () => {

  return (
    <Container>
      <div>
        <Banner src={HelpImg} alt='Uma mulher com blusa roxa segurando um celular com o houpa!app e sorrindo.'/>
      </div>

      <Wrapper>

        <HelpAndSupport>
          <NeedHelp />
          <Form />
        </HelpAndSupport>

        <IconsSection />

        <MoreAbout />

      </Wrapper>
      <Newsletter />
    </Container>
  );
};

export default Help;
