import styled from 'styled-components';


interface ContainerProps{
  background?: string,
  padding?: string,
  overflow?: string,
}

export const Container = styled.div<ContainerProps>`
  float: left;
  width: 100%;
  padding: ${props => props.padding ? props.padding : '40px 0'};
  background-color: ${props => props.background ? props.background : 'var(--white)'};
  overflow: ${props => props.overflow ? props.overflow : 'visible'};
  margin-bottom: 50px;

`;

export const Content = styled.div`
  width: 98%;
  max-width: var(--container);
  margin: 0 auto;
`;

export const MainContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
`;

export const MainContent = styled.div`
  width: 98%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-width: var(--container);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(min-width: 79.9375em){
    width: 1280px;
    column-gap: 160px;
    justify-content: center;

  }
  @media(min-width: 37.9375em){
    column-gap: 140px;
    justify-content: center;
  }
`;

export const Banner = styled.img`
  width: 100%;
`;

export const Capa = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  z-index: 99;
`;

export const Text = styled.div`
  width: 50%;
  position: absolute;
  right: 0;
  color: var(--white);

  a{
    color: #fff;
    text-decoration: none;
  }

  h1{
    font-size: 12px;
    color: var(--white);
    font-family: var(--poppins);

    @media(min-width: 37.9375em){
      font-size: 20px;
    }

    @media(min-width: 79.9375em){
      font-size: 32px;
    }
  }

  p{
    font-size: 8px;
    margin: 10px 0;
    line-height: 12px;

    @media(min-width: 37.9375em){
      font-size: 10px;
      margin: 15px 0;
      line-height: 16px;
    }

    @media(min-width: 79.9375em){
      font-size: 16px;
      line-height: 22px;
    }
  }

  button{
    font-size: 10px;
    width: 86px;
    background-color: transparent;
    color: #fff;

    &:hover{
      background-color: transparent;
    }

    @media(min-width: 37.9375em){
      margin: 0;
      width: 179px;
      font-size: 12px;
    }

    @media(min-width: 79.9375em){
      font-size: 14px;
    }
  }
  @media(min-width: 37.9375em){
    width: 217px;
  }
  @media(min-width: 79.9375em){
    width: 415px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Clear = styled.div`
  clear: both;
`;

export const StartUpContent = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 37.9375em){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div:first-of-type{
      flex: 1;
      min-width: 60%;
    }
  }

  @media(min-width: 79.9375em){
    max-width: 1100px;
    margin: 0 auto;
  }

  h1{
    font-size: 16px;
    color: var(--purple);
    font-family: var(--poppins);
    margin-bottom: 20px;

    @media(min-width: 37.9375em){
      font-size: 20px;
    }

    @media(min-width: 79.9375em){
      font-size: 32px;
    }
  }

  p{
    font-size: 12px;
    line-height: 20px;

    @media(min-width: 37.9375em){
      font-size: 12px;
    }

    @media(min-width: 79.9375em){
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export const StartUpValuesContent = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 37.9375em){
    flex-direction: row;
    justify-content: space-between;
  }

  @media(min-width: 79.9375em){
    max-width: 1100px;
    margin: 0 auto;
  }

  & > div{
    display: flex;
    margin-bottom: 50px;
    flex: 1;

    @media(min-width: 37.9375em){
      display: block;
      margin-bottom: 0;
      margin-right: 80px;
      &:last-of-type{
        margin-right: 0;
      }
    }

    & > div:first-of-type{
      margin: 0 30px 30px 30px;
    }

    img{
      min-width: 50px;

      @media(min-width: 37.9375em){
        margin: 0 auto;
        height: 80px;
      }

      @media(min-width: 79.9375em){
        height: 120px;
      }
    }

    hr{
      height: 3px;
      background-color: var(--purple);
      border: 0;
      margin-bottom: 20px;
      max-width: 30px;
    }

    p{
      font-size: 12px;
      line-height: 16px;

      @media(min-width: 79.9375em){
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

export const HeaderValues = styled.div`
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 900px;

  h1{
    margin: 10px 0;
    font-size: 24px;
    font-family: var(--poppins);

    @media(min-width: 79.9375em){
      font-size: 32px;
    }
  }

  p{
    font-size: 12px;
  }
`;

export const Values = styled.div`
  color: #fff;
  display: grid;
  margin: 0 auto;
  max-width: 900px;

  .inovacao{
    grid-area: inovacao;
  }

  .orientacao{
    grid-area: orientacao;
  }

  .confianca{
    grid-area: confianca;
  }

  .zelo{
    grid-area: zelo;
  }

  .diversidade{
    grid-area: diversidade;
  }

  .imagem{
    grid-area: imagem;
  }

  grid-template-areas:
  "inovacao"
  "orientacao"
  "confianca"
  "zelo"
  "diversidade"
  "imagem"
  ;

  @media(min-width: 37.9375em){
    grid-template-areas:
    "inovacao confianca diversidade"
    "orientacao zelo imagem"
    ;

    grid-gap: 30px;
  }

  & > div{
    position: relative;
    margin-bottom: 20px;

    @media(min-width: 37.9375em){
      min-width: 167.7px;
    }

    @media(min-width: 79.9375em){
      margin-bottom: 40px;
      min-width: 201px;
    }

    h1{
      margin: 10px 0;
      font-size: 18px;

      @media(min-width: 37.9375em){
        font-size: 14px;
      }

      @media(min-width: 79.9375em){
        font-size: 18px;
      }
    }

    p{
      font-size: 16px;
      line-height: 20px;

      @media(min-width: 37.9375em){
        font-size: 12px;
      }

      @media(min-width: 79.9375em){
        font-size: 16px;
      }
    }
  }

  & > div:last-of-type img{
    position: relative;
    bottom: -60px;

    @media(min-width: 37.9375em){
      position: absolute;
      bottom: -60px;
      right: -80px;
      width: 260px;
      max-width: none !important;

      @media(min-width: 79.9375em){
        bottom: -80px;
        right: -40px;
        width: 270px;
      }
    }
  }
`;

export const ContainerDownloadApp = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 64px;

  img{
    width: 100%;
  }

  @media(min-width: 37.9375em){
    margin-bottom: 120px;
  }
  @media(min-width: 79.9375em){
    margin-bottom: 140px;
  }
`;

export const TitleDownloadApp = styled.h1`
  font-family: var(--poppins);
  font-size: 16px;
  text-align: center;
  margin: 32px 0;

  @media(min-width: 37.9375em){
    margin: 20px 0;
  }

  @media(min-width: 79.9375em){
    margin: 64px 0;
    font-size: 30px;
  }
`;
