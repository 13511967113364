import { useCallback, useEffect, useRef, useState } from 'react';
import {
  AddressInterface,
  SelectedAddressType,
  UseAddressInterface,
} from '../interfaces/address';
import { api } from '../services/api';
import { SetOrderConfigType } from '../pages/Checkout/interfaces';

export const useAddresses = (
  setOrderConfig?: SetOrderConfigType,
): UseAddressInterface => {
  const [addresses, setAddresses] = useState<AddressInterface[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddressType>(
    null,
  );
  const [results, setResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const page = useRef<number>(1);

  const getAddresses = useCallback(
    async (shouldRefresh = false) => {
      if (loading) {
        return;
      }

      try {
        if (shouldRefresh) {
          page.current = 1;
        }

        setLoading(true);
        const { data } = await api.get(
          `/addresses?limit=6&page=${page.current}`,
        );
        setAddresses((prevState) =>
          shouldRefresh ? [...data.adresses] : [...prevState, ...data.adresses],
        );
        setResults(data.results);
        setTotalPages(data.totalPages);
        page.current = page.current + 1;
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    },
    [loading],
  );

  const onSelectAddress = useCallback(
    (address: AddressInterface) => {
      setSelectedAddress(address);
      setOrderConfig && setOrderConfig({ addressId: address.id });
    },
    [setOrderConfig],
  );

  const getMainAddress = useCallback(async () => {
    try {
      const { data } = await api.get('/main-address');

      if (data) {
        onSelectAddress(data);
      }
    } catch (error: any) {}
  }, [onSelectAddress]);

  useEffect(() => {
    getAddresses().then();
    getMainAddress().then();

    // eslint-disable-next-line
  }, []);

  return {
    addresses,
    getAddresses,
    results,
    totalPages,
    selectedAddress,
    setSelectedAddress: onSelectAddress,
  };
};
