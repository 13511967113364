import styled from 'styled-components';

export const SupportTitle = styled.h3`
  font-family: var(--poppins);
  margin-top: 25px;
  color: var(--purple);
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  max-width: 524px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 20px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    margin-top: 0px;
    font-size: 14px;
    line-height: 22px;
    max-width: 100%;
  }
`;

export const SupportTitleBold = styled.span` 
  font-family: var(--poppins);
  font-weight: 700;
`;

export const SupportText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 30px;
  color: var(--grey-medium-1);
  max-width: 292px;
  @media screen and (max-width: 1279px) {
    line-height: 20px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
  }
`;

export const SelectStyled = styled.div`
  select {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid var(--grey-dark);
    padding: 10px 0 5px;
    color: var(--grey-dark);
  }
  select.error {
    border-bottom: 1px solid var(--red-dark);
  }
`;

export const SelectBottomGap = styled.span` 
  height: 14px;
  margin-top: 3px;
  display: block;
`;

export const FormWrapper = styled.form` 
  margin-top: 95px;
  display: grid;
  grid-template-columns: repeat(2, 251px);
  align-items: flex-end;
  column-gap: 22px;
  row-gap: 16px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 44px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 51px;
    grid-template-columns: 1fr;
    column-gap: 0px;
  }
`;

export const TextareaWrapper = styled.div`
  grid-column: 1/-1;
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div` 
  grid-column: 2/-1;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  > button {
    max-width: 118px;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 0;
    > button {
      max-width: 100px;
    }
  }
  @media screen and (max-width: 607px) {
    > button {
      max-width: 152px;
    }
    margin-top: 18px;
    grid-column: 1;
    justify-content: center;
  }
`;

export const RequiredTipContainer = styled.div`
  grid-column: 1/-1;
  > p {
    font-size: 12px;
    margin: 18px 0;
  }
`;