import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 20px;
  > h2 {
    color: var(--purple);
  }`;

export const Content = styled.section`
  display: flex;
`;

export const CardInfo = styled.div`
  width: 100%;
  border: 2px solid var(--grey-light-2);
  border-radius: 9px;
  margin-left: 20px;
`;

export const CardBody = styled.div`
  width: 100%;
  background-color: var(--grey-light-3);
  padding: 30px 20px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  > div {
    margin-top: 20px;

    > p {
      color: var(--grey-medium-3);
    }
  }
`;
