import React from 'react';
import { Container, ContentContainer, RowLogo, Content, Title, Subtitle, Text } from './styles';

const Terms: React.FC = () => {
  return (
    <Container>
      <ContentContainer>
        <RowLogo>
          <i className="icon icon-houpa"></i>
        </RowLogo>
        <Content>
          <Title>Termos e Condições de Uso</Title>
          <Text>
            Caro usuário, seja bem-vindo! <br />
            Recomendamos que leia com atenção os presentes Termos e Condições de
            Uso (“Termos e Condições”). A leitura garantirá o melhor uso e
            conhecimento de nossas funcionalidades.
          </Text>
          <Subtitle>Sumário:</Subtitle>
          <Text>
            1. Os Termos e Condições representam um contrato aceito por você
            usuário ou representante legal devidamente constituído do usuário
            (“Usuário”) no momento que inicia e durante o uso da plataforma do
            Houpa! (“Plataforma” ou simplesmente “Houpa!”), independentemente de
            qualquer outra forma de aceitação.
          </Text>
          <Text>
            2. O Houpa é uma plataforma acessível via web e/ou aplicativo que
            consiste na disposição de um ambiente virtual de fomento de negócios
            entre anunciantes/potenciais vendedores (“Usuários Anunciantes”) e
            compradores/potenciais compradores (“Usuários Compradores”). Os
            Usuários Anunciantes poderão oferecer à venda os seus próprios
            produtos para que eventuais Usuários Compradores possam contatá-los
            para eventuais aquisições diretas e entre si.
          </Text>
          <Text>
            3. O Houpa! não é fornecedor, tampouco responsável por quaisquer
            produtos anunciados em sua plataforma, prestando-se exclusivamente à
            disponibilização de espaço virtual que permite a interação entre
            seus Usuários. Ademais, o Houpa! não se responsabiliza por quaisquer
            compromissos ou pagamentos assumidos pelos Usuários Compradores
            perante os Usuários Anunciantes.
          </Text>
          <Text>
            4. Os Usuários Anunciantes são os únicos responsáveis pelos anúncios
            publicados, inclusive, mas sem limitação, pelos seus conteúdos (seja
            por meio de imagens ou texto) e por eventual relacionamento frente
            aos demais usuários, sejam usuários, compradores, ou não. Sem
            prejuízo ao direito do Houpa! de promover ajustes e/ou exclusões em
            anúncios, conforme as políticas do Houpa!.
          </Text>
          <Text>
            5. É vedado aos Usuários anunciarem a venda e/ou negociarem a compra
            de produtos que sob qualquer aspecto impliquem em afronta à lei, à
            moral e/ou aos bons costumes, que não possuam a devida autorização
            específica de órgãos reguladores competentes, ou que violem direitos
            de terceiros. O Houpa! não se responsabiliza por verificar a
            adequação dos anúncios, mas reserva para si o direito de tomar
            quaisquer medidas que julgue adequadas (ao critério exclusivo do
            Houpa!) ou por determinação legal.
          </Text>
          <Text>
            6. Os Usuários Anunciantes somente poderão anunciar produtos que
            possam vender e que tenham em estoque, responsabilizando-se pelos
            termos do anúncio, com todas as suas características (como título,
            preço, descrição, quantidade, imagem dos bens, prazo de entrega,
            forma de pagamento, categoria, condições de venda).
          </Text>
          <Text>
            7. Ao utilizar os serviços do Houpa!, os Usuários aceitam a Política
            de Privacidade, documento que contém informações claras e completas
            sobre coleta, uso, armazenamento, tratamento e proteção dos dados
            pessoais dos Usuários do Houpa!.
          </Text>
          <Text>
            8. Os Usuários deverão efetuar um cadastro único, por meio da
            criação de um perfil mediante o preenchimento de dados do usuário
            conforme os parâmetros estabelecidos e que venham a ser
            estabelecidos pelo Houpa!.
          </Text>
          <Text>
            9. O Houpa! não possui qualquer responsabilidade pelo uso inadequado
            (sob qualquer aspecto) de sua plataforma virtual pelos Usuários.
            Nada obstante, reserva-se o direito de, em razão de violação à
            legislação em vigor ou aos Termos e Condições (conforme o
            exclusivo critério do Houpa!), sem prejuízo de outras medidas,
            recusar qualquer solicitação de cadastro, advertir, suspender,
            temporária ou definitivamente, a conta de um Usuário e/ou seus
            anúncios. O Houpa poderá (mas não terá o dever) arquivar informações
            referentes a dados de usuários e anúncios que já tenham sido
            publicados e ainda que apagados pelo usuário.
          </Text>
          <Subtitle>Índice:</Subtitle>
          <Text>
            1. Partes e Objeto; <br />
            2. Cadastro e Conta do Usuário; <br />
            3. Anúncios; <br />
            4. Remuneração do Houpa!; <br />
            5. Privacidade de Informação; <br />
            6. Obrigações dos Usuários; <br />
            7. Informações Gerais; <br />
            8. Propriedade Intelectual; <br />
            9. Sanções e Indenizações; <br />
            10. Modificações; <br />
            11. Duração; <br />
            12. Resolução de Conflitos.
          </Text>
          <Subtitle>1. Partes e Objeto:</Subtitle>
          <Text>
            1.1 Estes Termos e Condições representam um contrato entre o Usuário
            e o 8 AS Serviços e Tecnologia S/A, inscrito no CNPJ sob o nº
            30.654.551/0001-30, com sede em Rua Professor Cesare Lombroso, 305,
            CEP 01122-021, cidade de São Paulo, Estado de São Paulo, bem como
            junto a qualquer entidade que venha a deter o Houpa!.
          </Text>
          <Text>
            1.2 Os serviços do Houpa! consistem em (i) conferir ambiente virtual
            para que os Usuários Anunciantes anunciem a venda de seus próprios
            produtos, (ii) viabilizar o contato entre Usuários Anunciantes e
            Usuários Compradores, via as plataformas web e aplicativo do Houpa!,
            para fins de realização de negócios de compra e venda por meio das
            plataformas web e aplicativo do Houpa!, e (iii) viabilizar a
            divulgação dos dados de contato de uma parte à outra, para o fim
            estabelecer negócios de compra e venda por meio das plataformas web
            e aplicativo do Houpa!. O Houpa!, portanto, possibilita aos Usuários
            se contatarem e negociarem entre si diretamente por meio das
            plataformas disponibilizadas, sem qualquer intervenção na negociação
            ou na concretização dos negócios, ficando estes a cargo dos
            Usuários.
          </Text>
          <Text>
            1.3 Os serviços do Houpa! serão mantidos à exclusiva conveniência
            deste, que poderá encerrá-los ou alterá-los, não assumindo qualquer
            dever de permanência junto aos Usuários.
          </Text>
          <Subtitle>2. Cadastro e Conta do Usuário:</Subtitle>
          <Text>
            2.1 Ao se cadastrar no Houpa!, o Usuário concorda e aceita todas as
            condições estabelecidas nos Termos e Condições e na Política de
            Privacidade, ressaltando-se que o Houpa! reserva-se o direito de
            plena administração da Plataforma e de seu ambiente virtual, podendo
            fazê-lo conforme seu exclusivo critério.
          </Text>
          <Text>
            2.2 É proibido o cadastro de Usuários que não tenham capacidade
            civil (com relação às pessoas físicas) ou não sejam representantes
            legais (com relação às pessoas jurídicas), bem como de Usuários que
            tenham sido suspensos do Houpa!, temporária ou definitivamente, sem
            prejuízo da aplicação das sanções legais previstas nos artigos 166;
            171, I e 180 do Código Civil Brasileiro. Consoante os critérios de
            cadastro estabelecidos e a serem estabelecidos pelo Houpa!, os
            Usuários observarão ainda as seguintes condições: (i) os Usuários
            Anunciantes deverão se caracterizar como confecções ou atacadistas e
            (ii) os Usuários Compradores deverão se caracterizar como lojistas
            ou varejistas.
          </Text>
          <Text>
            2.3 Apenas será confirmado o cadastro do Usuário que preencher todos
            os campos obrigatórios do cadastro exigidos, com informações exatas,
            precisas e verdadeiras. O Usuário declara que todas as informações
            fornecidas, incluindo, sem limitação, qualquer tipo de informação de
            identificação pessoal, informações bancárias e outras informações
            confidenciais de sua propriedade ou relacionadas ao Usuário são
            verdadeiras e assume o compromisso de atualizar os dados inseridos
            em seu cadastro (“Dados Pessoais”) sempre que o Houpa! entender
            necessário.
          </Text>
          <Text>
            2.4 O Usuário acessará sua conta por meio de e-mail ou apelido
            (login) e senha e compromete-se a não informar a terceiros esses
            dados, responsabilizando-se o Usuário integralmente pelo uso que
            deles seja feito. O Houpa! poderá excluir apelidos considerados
            ofensivos, bem como os que contenham Dados Pessoais do Usuário, ou
            alguma URL, ou endereço eletrônico.
          </Text>
          <Text>
            2.5 O Houpa! poderá recusar qualquer solicitação de cadastro, bem
            como suspender ou excluir um cadastro previamente aceito, a seu
            exclusivo critério, que julgue estar em desacordo com as políticas
            e regras dos presentes Termos e Condições e /ou das regras que
            venham a ser estabelecidas a qualquer momento pelo Houpa!.
          </Text>
          <Text>
            2.6 Em nenhuma hipótese será permitida a cessão, venda, aluguel ou
            outra forma de transferência da conta, sem a expressa autorização do
            Houpa! na Plataforma. Também não se permitirá a manutenção de mais
            de um cadastro por uma mesma pessoa, ou ainda a criação de novos
            cadastros por pessoas cujos cadastros originais tenham sido
            excluídos ou suspensos temporária ou definitivamente por infrações
            às políticas do Houpa!.
          </Text>
          <Text>
            2.7 O cadastro do Usuário não implicará na configuração de
            existência de ponto comercial, estabelecimento, goodwill e/ou
            qualquer outra decorrência dos negócios que venham a ser facilitados
            ao Usuário por conta da Plataforma, restando o Houpa! isento de todo
            e qualquer dever eventualmente decorrente desta circunstância.
            Ademais, caso a qualquer momento se venha a estabelecer algum
            direito ao Usuário neste sentido, seja por Lei ou decisão judicial
            ou administrativa, desde já o Usuário renuncia a tais direitos em
            favor do Houpa!, em caráter irrevogável e irretratável.
          </Text>
          <Subtitle>3. Anúncios:</Subtitle>
          <Text>
            3.1 Os anúncios deverão conter, de forma clara e completa, o título,
            a especificação, a quantidade, o preço (por unidade e/ou conjunto),
            as formas de pagamento e encargos, o modo, os custos e o prazo de
            entrega dos produtos, bem como quaisquer outras descrições exigidas
            por lei.
          </Text>
          <Text>
            3.2 Os anúncios poderão conter gráficos, textos, fotos, vídeos e
            outras informações do produto oferecido, sempre que tal prática não
            violar nenhum dispositivo previsto em lei, neste contrato, e nas
            demais políticas do Houpa! (definidas e/ou que venham a ser
            estabelecidas).
          </Text>
          <Text>
            3.3 O Usuário responsabiliza-se por não publicar anúncios que, por
            si ou em relação ao produto anunciado: (i) tenham origem e/ou
            natureza ilícitas; (ii) violem quaisquer direitos de terceiros,
            disposições dos presentes Termos e Condições, e outras políticas que
            venham a ser estabelecidas pelo Houpa!; e (iii) desrespeitem
            quaisquer disposições legais. O Houpa! não terá o dever, mas poderá
            tomar medidas para de anúncios e Usuários conforme o exclusivo
            critério do Houpa!.
          </Text>
          <Text>
            3.4 O Houpa! poderá definir e atualizar regras a serem observadas
            pelos Usuários Anunciantes para a publicação dos anúncios e
            manutenção de seu perfil. Estas regras serão definidas conforme a
            conveniência do Houpa!, que poderá, ao seu exclusivo critério,
            alterar os meios de apresentação de anúncios, disposição das telas
            da Plataforma e/ou qualquer outro ajuste que o Houpa! entenda
            adequado. O Houpa! não se compromete a manter à disposição do layout
            dos anúncios, sendo de responsabilidade do Usuário a adequação de
            seus anúncios às regras de disposição e apresentação que sejam
            definidas pelo Houpa!.
          </Text>
          <Text>
            3.5 O Houpa! responsabiliza-se apenas e tão somente pela
            disponibilização de um ambiente virtual (que poderá ser cancelado,
            alterado e ajustado consoante o critério do Houpa!) conforme estes
            Termos e Condições, e não garante tampouco se responsabiliza, em
            hipótese alguma, pelas tratativas entre Usuários e inclusive, mas
            sem limitação pela efetivação de transações, qualidade de produto
            e/ou qualquer outra circunstância.
          </Text>
          <Text>
            3.6 O Houpa! de forma alguma se compromete a divulgar e/ou promover
            todos os anúncios, se reservando ao direito de divulgar e/ou
            promover somente aqueles que, a seu exclusivo critério, sigam as
            regras temporárias ou permanentes estabelecidas pelo Houpa!.
          </Text>
          <Text>
            3.7 O Houpa! reserva o direito de oferecer, modificar ou excluir
            serviços e/ou funcionalidades a qualquer momento, a seu exclusivo
            critério, assim como se negar a prestar e/ou liberar certos serviços
            e/ou funcionalidades, mesmo que remunerados, a Usuários que não
            preencham os critérios mínimos para obter certos serviços e/ou
            funcionalidades.
          </Text>
          <Subtitle>4. Remuneração do Houpa:</Subtitle>
          <Text>
            4.1 O Houpa! poderá estabelecer a cobrança pelo uso da Plataforma
            e/ou de serviços e/ou funcionalidades (existentes ou que venham a
            existir) da Plataforma. Caso decida cobrar pelo uso da Plataforma em
            si, ou de determinados serviços e/ou funcionalidades oferecidas ou
            que venham a ser oferecidas pela Plataforma, o Houpa! informará
            previamente o Usuário, que poderá então (i) contratar os serviços ou
            funcionalidades nas condições oferecidas pelo Houpa! ou (ii) não
            contratar a funcionalidade oferecida pelo Houpa!. Caso venha a ser
            cobrado (i) pelo uso da Plataforma em si, o Usuário que não optar
            pela contratação, terá sua conta suspensa ou excluída, a critério do
            Houpa!; e/ou (ii) por determinada funcionalidade da Plataforma, o
            Usuário que não optar pela contratação, não terá acesso a tal
            funcionalidade da Plataforma.
          </Text>
          <Text>
            4.2 O Houpa! reserva o direito de modificar, aumentar ou excluir
            tarifas e planos vigentes a qualquer momento, ou durante promoções e
            outras formas transitórias de alteração dos preços praticados,
            conferindo-se ao Usuário o conhecimento prévio e a prerrogativa de
            contratação (consoante indicado no item 4.1).
          </Text>
          <Subtitle>5. Privacidade da Informação:</Subtitle>
          <Text>
            5.1 O Houpa! possui política expressa sobre privacidade dos dados
            (“Política de Privacidade”). As informações de cadastro e demais
            informações sobre os Usuários estão sujeitas ao tratamento definido
            na referida política.
          </Text>
          <Subtitle>6. Obrigações dos Usuários:</Subtitle>
          <Text>
            6.1 As obrigações inseridas nesta cláusula não excluem as demais
            previstas em lei, nos presentes Termos e Condições e demais
            políticas do Houpa! aplicáveis (definidas e/ou que venham a ser
            estabelecidas). Feita tal ressalva, o Usuário:
          </Text>
          <Text>
            (i) Deverá cumprir e observar integralmente as disposições destes
            Termos e Condições e da Política de Privacidade;
          </Text>
          <Text>
            (ii) Será o único responsável pelas operações efetuadas em sua
            conta, responsabilizando-se pelo sigilo e uso de seus dados e senha
            de acesso. Em caso de acesso não autorizado, o Usuário deverá
            informar o Houpa! (que por sua vez poderá tomar as medidas que
            julgue adequadas);
          </Text>
          <Text>
            (iii) Sem prejuízo das demais disposições destes Termos e Condições,
            responsabilizam-se integral e exclusivamente por todas as
            tratativas junto aos demais Usuários, inclusive, mas sem limitação,
            no que diz respeito aos anúncios, aos produtos oferecidos,
            entendimentos comerciais, pagamentos, qualidade, enfim, todo e
            qualquer entendimento e/ou prejuízo, danos e disputas de qualquer
            natureza;
          </Text>
          <Text>
            (iv) Reconhece que por conta do uso da Plataforma não se estabelece
            qualquer relação empregatícia, de trabalho, sociedade, joint venture
            e/ou relação jurídica não especificada nestes Termos e Condições,
            assumindo integralmente a responsabilidade pela condução de seus
            negócios; e
          </Text>
          <Text>
            (v) Indenizará e manterá o Houpa! indene a todo o tempo em virtude
            de quaisquer danos, custos ou prejuízos de qualquer natureza que o
            Houpa! venha a experimentar em virtude do descumprimento dos deveres
            do Usuário.
          </Text>
          <Subtitle>7. Informações Gerais:</Subtitle>
          <Text>
            7.1 O uso da Plataforma pelo Usuário depende de muitos fatores
            alheios ao controle do Houpa!, como, por exemplo, a qualidade da
            conexão do Usuário, configurações do dispositivo utilizado para
            acesso à Plataforma. O Houpa! não garante a disponibilidade, acesso
            e continuidade do funcionamento da Plataforma ou de suas
            funcionalidades, não sendo responsável por nenhum dano ou prejuízo
            causado ao Usuário em caso de indisponibilidade.
          </Text>
          <Text>
            7.2 O Houpa! não é o proprietário dos produtos anunciados pelos
            usuários na Plataforma, não guarda a posse desses itens e não
            realiza as ofertas de venda, tampouco intervém na entrega dos
            produtos. O Houpa! não será responsável por danos e prejuízos
            resultantes da utilização da Plataforma e contratação entre os
            Usuários, tampouco pela aquisição de produtos anunciados.
          </Text>
          <Subtitle>8. Propriedade Intelectual:</Subtitle>
          <Text>
            8.1 A utilização comercial (como marca, nome empresarial ou nome de
            domínio) das denominações “Houpa” ou “Houpa!”, os conteúdos das
            telas relativas à Plataforma, inclusive, mas sem limitação no que
            diz respeito à disposição e layout, bem como os seus programas, look
            and feel, banco de dados, redes, programas operacionais e arquivos
            que permitam aos Usuários acessarem e usarem suas contas, são
            propriedades do Houpa! e estão protegidos por leis e tratados
            nacionais e internacionais de direito autoral, marcas, patentes,
            modelos e desenhos industriais.
          </Text>
          <Text>
            8.2 O apelido que o Usuário utiliza no Houpa! não poderá guardar
            semelhança com o nome Houpa, suas marcas e nomes de domínio ou outro
            bem de propriedade intelectual, tampouco poderá ser utilizado
            qualquer apelido que insinue ou sugira que os produtos anunciados
            pertencem ou tenham qualquer relação com o Houpa. Somente será
            permitido que o Usuário utilize o nome de suas marcas, denominação
            social ou nome fantasia. O Usuário que não respeitar tal regra
            poderá ser suspenso temporariamente até que tal regra seja cumprida,
            ou permanentemente, a exclusivo critério do Houpa!.
          </Text>
          <Text>
            8.3 Se o Houpa! receber alguma reclamação ou questionamento de
            terceiros que envolva a propriedade intelectual de produto anunciado
            pelo Usuário, o Houpa! poderá, a seu critério, remover o anúncio do
            Usuário e/ou aplicar as sanções cabíveis.
          </Text>
          <Subtitle>9. Sanções e Indenizações:</Subtitle>
          <Text>
            9.1 Sem prejuízo de outras medidas cabíveis, o Houpa! poderá (mas
            não terá o dever), ao seu exclusivo critério, advertir, suspender,
            temporária ou definitivamente, a conta do Usuário e cancelar ou
            modificar os anúncios, a qualquer tempo, inclusive se o Usuário não
            cumprir qualquer dispositivo de lei, dos Termos e Condições e/ou das
            demais políticas do Houpa! (estabelecidas e que venham a ser
            estabelecidas).
          </Text>
          <Text>
            9.2 Na hipótese de aplicação de quaisquer das sanções acima
            referidas e/ou que o Houpa! entenda cabíveis, não serão devidos,
            tampouco haverá pagamento de qualquer indenização ou ressarcimento
            ao Usuário.
          </Text>
          <Subtitle>10. Modificações:</Subtitle>
          <Text>
            10.1 O Houpa! poderá modificar os presentes Termos e Condições e as
            demais políticas aplicáveis a qualquer tempo e sempre que entenda
            necessário. O Usuário deverá consultar regularmente os Termos e
            Condições e demais políticas aplicáveis. O Houpa! poderá a seu
            exclusivo critério postar avisos informando a existência de
            modificações nos presentes Termos e Condições.
          </Text>
          <Text>
            10.2 Havendo alterações, exclusões ou inclusões das utilidades,
            ferramentas, serviços e funcionalidades, tais alterações entrarão em
            vigor imediatamente, sendo responsabilidade do Usuário a verificação
            das condições aplicáveis no momento da utilização da Plataforma. Se
            o Usuário não concordar com os termos alterados, deverá descontinuar
            o uso das utilidades, ferramentas, serviços e funcionalidades ou, em
            último caso, da Plataforma, sempre em observância às suas demais
            obrigações previstas em lei, nos Termos e Condições e demais
            políticas aplicáveis, sem prejuízo ao direito do Houpa! de excluir
            ou suspender a conta do Usuário.
          </Text>
          <Text>
            10.3 O Houpa! poderá enviar determinadas informações relacionadas às
            suas utilidades e ferramentas ou outras informações adicionais que a
            lei exigir em formato eletrônico, seja por e-mail (no endereço
            eletrônico especificado pelo Usuário em sua conta) ou demais meios
            eletrônicos disponíveis, como notificações via push. Os avisos
            enviados ao Usuário via e-mail serão considerados entregues e
            recebidos na data da transmissão do e-mail. Todas as notificações ao
            Houpa devem ser enviadas por correio com aviso de recebimento para o
            endereço listado acima no preâmbulo destes Termos e Condições.
          </Text>
          <Text>
            10.4 O Usuário tem o direito de retirar o consentimento para receber
            essas informações, mas se o fizer, o Houpa! reserva-se o direito de
            cancelar sua conta, assim como mantida considerar o Usuário ciente
            de todas e quaisquer alterações, exclusões ou inclusões das
            utilidades, ferramentas, serviços e funcionalidades, bem como
            alterações, exclusões ou inclusões de cobranças. Podemos ainda
            enviar-lhe comunicações com divulgações sobre promoções, pacotes,
            novidades ou outras informações sobre o Houpa!.
          </Text>
          <Subtitle>11. Duração:</Subtitle>
          <Text>
            11.1 A disponibilização das ferramentas e funcionalidades do Houpa!
            na Plataforma tem duração indefinida. Sem prejuízo das disposições
            anteriores, o Houpa! se reserva o direito de decidir sobre o
            encerramento, suspensão ou interrupção da Plataforma e/ou das
            funcionalidades oferecidas, podendo fazê-lo, unilateralmente e a
            qualquer momento, sem a obrigatoriedade de prévio aviso aos
            Usuários, salvo se houver contraprestação financeira com relação à
            funcionalidade alterada/cancelada, sendo que neste caso a cobrança
            também cessará.
          </Text>
          <Subtitle>12. Resolução de Conflitos:</Subtitle>
          <Text>
            12.1 Os presentes Termos e Condições serão interpretados de acordo
            com as leis da República Federativa do Brasil. Todos os litígios
            oriundos dos presentes Termos e Condições ou com ele relacionados
            serão submetidos à jurisdição exclusiva do Foro da Comarca de São
            Paulo, SP.
          </Text>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default Terms;
