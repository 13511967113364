import React, {useState} from 'react';
import { 
  Container, 
  ModalContent,
  CloseModal,
  CloseModalMobile,
  ComeBack,
  Title,
  PaymentMethods,
  Method,
  RadioWrapper,
  PaymentTitle,
  PaymentMethodIcon,
} from './styles';
import Radio from '../../components/Radio';
import Main from './Main';

interface OwnProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ChangePaymentMethodModal: React.FC<OwnProps> = ({ open, setOpen }) => {
  const [paymentMethod, setPaymentMethod] = useState<'creditCard' | 'bankSlip'>('creditCard');

  const creditCardClass = paymentMethod === 'creditCard' ? 'creditCardActive' : '';
  const bankSlipClass = paymentMethod === 'bankSlip' ? 'bankSlipActive' : '';

  const handleCreditCard = () => setPaymentMethod('creditCard');
  const handlebankSlip = () => setPaymentMethod('bankSlip');

  const onClose = () => setOpen(false);

  return (
    <Container open={open} onClose={onClose}>
        <ModalContent>
          <CloseModal onClick={onClose}>
            <i className='icon icon-exit' />
          </CloseModal>
          <CloseModalMobile onClick={onClose}>
            <i className='icon icon-arrow-left' />
            <ComeBack>Voltar</ComeBack>
          </CloseModalMobile>

          <Title>Alterar a forma de pagamento</Title>

          <PaymentMethods>            
            <Method onClick={handleCreditCard}>
              <RadioWrapper>
                <Radio 
                  checked={paymentMethod === 'creditCard'} 
                  label='' 
                />
              </RadioWrapper>
              <PaymentMethodIcon className={`icon icon-credit-card ${creditCardClass}`} />
              <PaymentTitle className={`${creditCardClass}`}>Cartão de crédito</PaymentTitle>
            </Method>
            <Method onClick={handlebankSlip}>
              <RadioWrapper>
                <Radio 
                  checked={paymentMethod === 'bankSlip'} 
                  label='' 
                />
              </RadioWrapper>
              <PaymentMethodIcon className={`icon icon-bar-code ${bankSlipClass}`} />
              <PaymentTitle className={`${bankSlipClass}`}>Boleto bancário</PaymentTitle>
            </Method>
          </PaymentMethods>

          <Main paymentMethod={paymentMethod} onClose={onClose}/>
          
        </ModalContent>
    </Container>
  );
};

export default ChangePaymentMethodModal;
