import styled from 'styled-components';

export const Container = styled.form`
  width: 308px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-family: var(--poppins);
  font-weight: 700;
  color: var(--grey-dark);
  line-height: 28px;
`;

export const FormText = styled.p` 
  font-size: 14px;
  color: var(--grey);
  line-height: 20px;
  margin-top: 16px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 18px;
    margin-top: 13px;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 33px;
  @media screen and (max-width: 1279px) {
    row-gap: 41px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 41px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 69px auto 0;
    width: 296px;
  }
  @media screen and (max-width: 607px) {
    margin: 49px auto 0;
    width: 152px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 14px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 32px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 24px;
  }
`;

export const CheckboxText = styled.span`
  position: relative;
  top: 10px;
  font-size: 12px;
  line-height: 13px;
  color: var(--grey);
  > a {
    color: var(--blue);
    text-decoration: none;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    top: 0;
  }
`;

export const CheckboxErrorMessage = styled.p`
  font-size: 12px;
  color: var(--red-dark);
  margin-top: 15px;
`;