import React, {createContext, useCallback, useEffect} from 'react';
import {Container, StepsContainer} from './styles';
import Cart from './stages/Cart';
import Address from './stages/Address';
import Payment from './stages/Payment';
import Confirm from './stages/Confirm';
import Details from './stages/Details';
import CheckoutStepBar from '../../components/CheckoutStepBar';
import {Route, Routes, useLocation} from 'react-router-dom';
import EmptyCart from './stages/EmptyCart';
import {CartContextInterface} from './interfaces';
import {useAddresses} from '../../hooks/useAddresses';
import {
  useBrandWithProducts,
  useCards,
  useCartProducts,
  useOrderConfig,
  usePaymentMethods,
} from './hooks';
import {useLoading} from '../../hooks/useLoading';
import ProtectedRoute from '../../components/ProtectedRoute';
import {useShipping} from '../../hooks/useShipping';

export const CartContext = createContext<CartContextInterface>(
  {} as CartContextInterface,
);

interface CartPageProps {
  emptyCart: boolean;
}

const CartPage: React.FC<CartPageProps> = ({emptyCart = true}) => {
  return emptyCart ? <EmptyCart/> : <Cart/>;
};

const Checkout: React.FC = () => {
  const {pathname} = useLocation();

  const {showLoading, closeLoading} = useLoading();

  const {calculateAllBrands, shipping} = useShipping();

  const cartProducts = useCartProducts(shipping);

  const order = useOrderConfig();
  const brandsWithProducts = useBrandWithProducts();
  const paymentMethods = usePaymentMethods(
    order.setOrderConfig,
    cartProducts.cartResume.totalValue,
  );
  const cards = useCards(order.setOrderConfig);
  const address = useAddresses(order.setOrderConfig);

  const calcFreight = useCallback(async (brands, zipCode) => {
    try {
      const {freights} = await calculateAllBrands(brands, zipCode);

      order.setOrderConfig({freights});

    } catch (e: any) {
      console.log('Erro ao calcular os fretes: ', e);
    }
  }, [calculateAllBrands, order]);

  useEffect(() => {
    if (cartProducts.isLoadingProducts) {
      showLoading();
    } else {
      closeLoading();
    }

    // eslint-disable-next-line
  }, [cartProducts.isLoadingProducts]);

  useEffect(() => {
    const {brands} = brandsWithProducts;
    const {selectedAddress} = address;

    const formatBrands = brands.map(item => {
      return {
        companyId: item.id,
        weight: item.weight,
        packVolume: item.packVolume
      };
    });

    if (brands.length > 0 && selectedAddress?.zipCode) {
      calcFreight(formatBrands, selectedAddress?.zipCode);
    }

    // eslint-disable-next-line
  }, [brandsWithProducts.brands, address.selectedAddress]);

  return (
    <Container>
      <CartContext.Provider
        value={{
          cartProducts,
          order,
          brandsWithProducts,
          paymentMethods,
          cards,
          address,
        }}
      >
        {pathname !== '/carrinho/pedido' &&
        pathname !== '/carrinho/entrar' &&
        cartProducts.products.length > 0 && (
          <StepsContainer>
            <CheckoutStepBar
              active={
                pathname === '/carrinho'
                  ? 1
                  : pathname === '/carrinho/enderecos'
                  ? 2
                  : pathname === '/carrinho/pagamentos'
                    ? 3
                    : 4
              }
            />
          </StepsContainer>
        )}

        <Routes>
          <Route
            path={'/'}
            element={
              <CartPage emptyCart={cartProducts.products.length === 0}/>
            }
          />
          <ProtectedRoute path={'/enderecos'} element={<Address/>}/>
          <ProtectedRoute path={'/pagamentos'} element={<Payment/>}/>
          <ProtectedRoute path={'/detalhes'} element={<Details/>}/>
          <ProtectedRoute path={'/pedido'} element={<Confirm/>}/>
        </Routes>
      </CartContext.Provider>
    </Container>
  );
};

export default Checkout;
