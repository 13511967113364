import React from 'react';
import { ProductCart } from '../../../../interfaces/orderProduct';
import { Main, ProductInfo, ProductIMG, Info, Row, Col } from './styles';

interface Props {
  data: ProductCart;
}

const FreightProductsItem: React.FC<Props> = ({ data }) => {
  return (
    <Main>
      <ProductInfo>
        <ProductIMG>
          <img src={data.image ?? ''} alt={`Produto de ${data.supplier}`} />
        </ProductIMG>
        <Info>
          <div className="info-col">
            <div>
              <p>
                <strong>Loja: {data.seller}</strong>
              </p>
              <p className={'name'}>{data.name}</p>
            </div>

            <div>
              <p>Tam: {data.sizeName}</p>
              <p>Cor: {data.colorName}</p>
              <p>SKU: {data.sku}</p>
            </div>
          </div>

          <Row>
            <Col>
              <p className={'title'}>Valor</p>
              <p>{data.price}</p>
            </Col>

            <Col>
              <p className={'title'}>Quantidade</p>
              <p>{data.quantity}</p>
            </Col>
          </Row>
        </Info>
      </ProductInfo>
    </Main>
  );
};

export default FreightProductsItem;
