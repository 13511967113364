import React, { useState, useEffect, useRef, useCallback } from 'react';
import RadioButtonsGroup from '../Radio';
import {
  Container,
  SelectedCardWrapper,
  ImgWrapper,
  CardName,
  CardNumber,
  MoreCards,
} from './styles';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Select from '../Select';
import { cardTypesInterface } from '../../interfaces/creditCard';
import AMEX from '../../assets/svg/AMEX.svg';
import ELO from '../../assets/svg/ELO.svg';
import DINERS from '../../assets/svg/DINERS.svg';
import HIPER from '../../assets/svg/HIPER.svg';
import HIPERCARD from '../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../assets/svg/MASTERCARD.svg';
import VISA from '../../assets/svg/VISA.svg';
import {
  CardInterface,
  PaymentInstallmentInterface,
} from '../../pages/Checkout/interfaces';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  '@global': {
    // Você deve direcionar [class*="MuiButton-root"] em vez de aninhar temas.
    '.MuiAccordion-root': {
      borderRadius: 8,
      marginTop: 20,
      marginBottom: 20,
    },
    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      color: '#909090',
    },
    '.MuiAccordionSummary-root': {
      padding: '5px 24px',
    },
    '.MuiAccordionSummary-content .icon': {
      fontSize: 30,
      margin: '0 15px',
    },
    '.MuiAccordionSummary-content h3': {
      margin: '0 25px',
    },
    '.MuiCollapse-container .row': {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      width: '100%',
    },
    '.MuiCollapse-container .row > div': {
      margin: '0 0 0 20px',
    },
    '.MuiCollapse-container .row .select': {
      border: 0,
      borderBottom: '1px solid #000',
      borderRadius: 0,
    },
    '.MuiCollapse-container .row .code': {
      paddingRight: '40px;',
    },
    '.MuiCollapse-container .row .select-div': {
      width: 'max-content',
    },
    '.MuiCollapse-container .row .input div:nth-of-type(2)': {
      padding: '5px 0',
    },
  },
  root: {
    overflow: 'hidden',
    marginBottom: -1,
    minHeight: 56,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: 8,
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    '@global': {
      '.MuiAccordionSummary-content': {
        alignItems: 'center',
        color: 'var(--purple)',
      },
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

interface Props {
  options: CardInterface[];
  results: number;
  loadMoreCards: () => void;
  installments: PaymentInstallmentInterface[];
  cardSelected: CardInterface;
  setCardSelected: (arg: CardInterface) => void;
  onSelectInstallment: (installmentId: number) => void;
}

const SelectCard: React.FC<Props> = ({
  options,
  cardSelected,
  setCardSelected,
  installments,
  onSelectInstallment,
  results,
  loadMoreCards,
}) => {
  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    DINERS,
    HIPER,
    HIPERCARD,
    MASTERCARD,
    VISA,
  }).current;

  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('2');
  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  const selectOption = (e: React.MouseEvent<HTMLElement>) => {
    const index = Number(e.currentTarget.id);
    setCardSelected(options[index]);
    setSelectOpen(false);
  };

  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectValue = useCallback(
    (value) => {
      onSelectInstallment(Number(value));
      setSelectedValue(value);
    },
    [onSelectInstallment],
  );

  useEffect(() => {
    const handler = (event: Event): void => {
      const target = event.target as HTMLDivElement;
      if (
        selectRef.current &&
        !selectRef.current.contains(target) &&
        selectOpen
      ) {
        setSelectOpen(false);
      }
    };
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
  });

  return (
    <>
      <Container>
        <div onClick={() => setSelectOpen(true)}>
          {options.map((option, index) => (
            <Accordion
              id={`${index}`}
              onClick={selectOption}
              key={option.id}
              square
              expanded={cardSelected.id === option.id}
              // onChange={handleChange(`panel-card-${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <SelectedCardWrapper>
                  <div className={'radio'}>
                    <RadioButtonsGroup
                      checked={cardSelected.id === option.id}
                      label={''}
                    />
                  </div>
                  <ImgWrapper>
                    <img
                      src={cardTypes[option.brand.toUpperCase()]}
                      alt={`Bandeira de cartão ${option.brandName}`}
                    />
                  </ImgWrapper>
                  <div>
                    {/*<CardName>{option.name}</CardName>*/}
                    <CardNumber>
                      xxx-xxxx-xxx-
                      {option.number.slice(option.number.length - 4)}
                    </CardNumber>
                  </div>
                  <div className="cardholder">
                    <CardName>{option.holder}</CardName>
                    <p className="cardholder-desc">Nome do títular do cartão</p>
                  </div>
                </SelectedCardWrapper>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <div className="select-div">
                    <Select
                      label="Número de parcelas"
                      options={installments}
                      selectError={selectError}
                      setselectError={setSelectError}
                      selectedValue={selectedValue}
                      setSelectedValue={handleSelectValue}
                      required
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Container>
      {results > options.length && (
        <div>
          <MoreCards onClick={() => loadMoreCards()}>
            Ver mais cartões
          </MoreCards>
        </div>
      )}
    </>
  );
};

export default SelectCard;
