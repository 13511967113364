import React from 'react';
import {
  Container,
  ContentContainer,
  RowLogo,
  Content,
  Title,
  Subtitle,
  Text,
  TextBold,
} from './styles';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <ContentContainer>
        <RowLogo>
          <i className="icon icon-houpa"/>
        </RowLogo>
        <Content>
          <Title>Política de Privacidade</Title>
          <Text>
            Esta Política de Privacidade se aplica ao cadastro e uso do
            aplicativo Houpa! (doravante “Houpa” ou “Aplicativo”), que é gerido
            por 8 AS Serviços e Tecnologia S/A, aplicando-se indistintamente a
            todos os usuários cadastrados e que venham a se cadastrar no
            Aplicativo (“Usuários”) e integra os Termos e Condições de Uso
            (“Termos e Condições”).
          </Text>
          <Subtitle>Índice:</Subtitle>
          <TextBold>
            1. Objeto e consentimento; <br />
            2. Coleta e armazenamento de informações;
            <br />
            3. Uso e compartilhamento de informações; <br />
            4. Controle do Usuário; <br />
            5. Segurança; <br />
            6. Atualizações e Alterações; <br />
            
          </TextBold>

          <Subtitle>1. Objeto e consentimento:</Subtitle>
          <Text>
            1.1 Esta Política de Privacidade descreve as práticas do 8 AS
            Serviços e Tecnologia Ltda., inscrito no CNPJ/MF sob o nº
            30.654.551/0001-30 (bem como de toda e qualquer entidade que vier a
            suceder tal entidade a qualquer título na gestão do Aplicativo), em
            relação à proteção, armazenamento, uso e compartilhamento de Dados
            dos Usuários. <br />
            1.2 A Política de Privacidade se aplica aos dados fornecidos pelos
            Usuários em seu cadastro e durante o uso do Aplicativo, inclusive
            mas sem limitação, seu endereço de email, apelido, senha, endereço,
            nome ou denominação, documentos de identificação, número de
            telefone, gênero, informações bancárias, entre outros (“Dados
            Pessoais”), bem como de dados fornecidos pelos dispositivos
            eletrônicos dos Usuários e/ou coletados pelo Houpa durante a
            utilização dos Serviços, como os dados de tráfego, endereços IP,
            identificadores anônimos de publicidade, propriedades dos
            dispositivos, aplicativos instalados, dados de localização por meio
            de GPS ou rede celular, mensagens de texto, voz, entre outros
            (“Dados do Usuário”). Os Dados Pessoais, os Dados do Usuário e os
            demais dados e informações mencionados neste documento, serão
            submetidos ao tratamento aqui previsto, sendo doravante denominados
            em conjunto como “Dados”. <br />
            1.3 A utilização do Aplicativo implica no consentimento e aceitação
            do Usuário para coleta, armazenamento e uso dos Dados, consoante os
            termos desta Política de Privacidade.
          </Text>
          <Subtitle>2. Coleta e armazenamento de informações:</Subtitle>
          <Text>
            2.1 Os Dados serão coletados e armazenados pelo Houpa, ao seu
            critério, com o intuito principal de viabilizar e aprimorar o uso do
            Aplicativo.
          </Text>
          <Text>
            2.2 O Houpa também tem acesso às informações armazenadas nas listas
            de contatos dos dispositivos móveis e/ou outros aparelhos
            tecnológicos utilizados pelos Usuários. Essas informações, assim que
            forem coletadas, poderão ser aproveitadas pelo Houpa para encontrar
            e armazenar números de telefones celulares e/ou endereços de e-mails
            de potenciais usuários.
          </Text>
          <Text>
            2.3 O Houpa poderá disponibilizar serviço de gerenciamento de
            pagamentos online ou utilizar empresas terceirizadas para tanto. O
            Usuário autoriza o Houpa eventuais terceiros a coletarem e
            armazenarem os seus Dados para tais finalidades.
          </Text>
          <Text>
            2.4 As mensagens trocadas entre os Usuários por meio de serviço de
            chat do Aplicativo não são sigilosas, porquanto o Houpa possa
            acessá-las qualquer momento a seu exclusivo critério, e poderão ser
            armazenadas pelo Houpa. Entretanto, o Houpa não monitorará as
            mensagens trocadas e nem se responsabilizará por quaisquer ações ou
            mensagens de Usuários e/ou terceiros.
          </Text>
          <Text>
            2.5 O Usuário que decidir fazer login no Aplicativo por meio de
            serviços de autenticação de terceiros (por exemplo, Facebook
            Connect, Google) autoriza o Houpa a receber, coletar e armazenar
            informações adicionais de perfil e/ou outras informações autorizadas
            por esses fornecedores.
          </Text>
          <Text>
            2.6 O Houpa poderá coletar certas informações por meio de
            tecnologias denominadas como tags, cookies e pixels. Para melhor
            compreensão do Usuário, segue abaixo as funcionalidades destas
            tecnologias: <br />
            (i) Tags são ferramentas utilizadas para verificação do número de
            cliques em determinadas seções e/ou links específicos do Aplicativo;{' '}
            <br />
            (ii) Cookies são pequenos arquivos de texto que têm a função
            principal de armazenagem de informação no computador, celular ou
            outro dispositivo utilizado pelo Usuário. Com ajuda dos cookies, o
            servidor de internet pode salvar, por exemplo, preferências e
            configurações no computador, celular ou outro dispositivo do
            Usuário, as quais são automaticamente restauradas na próxima visita.
            Portanto, os cookies são usados, entre outras coisas, para tornar o
            uso do Aplicativo mais amigável (por exemplo, para que o Usuário não
            tenha que repetir processos de login quando utilizar o Aplicativo).{' '}
            <br />
            (iii) Pixels são pequenas imagens que fazem parte de códigos em
            páginas da internet que fazem coisas como permitir que outro
            servidor meça a visualização de uma página da internet e muitas
            vezes são usados em conjunto com os cookies. O código rastreia se e
            quando (e em qual página) um pixel é carregado para indicar o acesso
            do Usuário a uma página ou uma porção específica de uma página.
          </Text>
          <Text>
            2.7 O Usuário poderá contatar o Houpa mediante canal de comunicação
            eventualmente disponibilizado pelo Houpa. Nesta hipótese, o Houpa
            poderá coletar todas as informações fornecidas pelo Usuário por este
            meio.
          </Text>
          <Subtitle>3. Uso e compartilhamento de informações:</Subtitle>
          <Text>
            3.1 O Houpa poderá, ao seu critério, utilizar Dados que coletar e
            armazenar, para, inclusive mas sem limitação: <br />
            (i) Solucionar dificuldades técnicas e promover aperfeiçoamentos e
            novas versões do Aplicativo; <br />
            (ii) Pesquisar e realizar análises de Dados para uso comercial, por
            si ou mediante parcerias junto a terceiros; <br />
            (iii) Personalizar ofertas e experiências; <br />
            (iv) Acompanhar as atividades do Usuário, tais como buscas por
            palavra-chave, postagens de anúncios, negociações, e gerenciamento de
            tráfego no Aplicativo; <br />
            (v) Contatar o Usuário, inclusive assuntos relacionados ao
            Aplicativo, atendimento ou comunicações de marketing permitidas por
            meio de qualquer meio de comunicação disponível (inclusive via email
            e notificações push); e<br />
            (vi) Exercer quaisquer prerrogativas conferidas ao Houpa sob os
            Termos e Condições de uso do Aplicativo.
          </Text>
          <Text>
            3.2 O Houpa poderá compartilhar Dados com afiliadas (empresas sob
            mesmo controle e propriedade) ou grupos de entidades, localizadas
            dentro ou fora do país, bem como a qualquer entidade que venha a
            suceder o atual detentor ou operador do Houpa, a qualquer título.
          </Text>
          <Text>
            3.3 O Usuário reconhece e concorda expressamente que a base de dados
            poderá ser total ou parcialmente cedida ou transferida a terceiros,
            desde que respeitada a finalidade de dar continuidade à atividade
            prestada pelo Houpa ou se existir uma obrigatoriedade legal neste
            sentido.
          </Text>
          <Text>
            3.4 O Usuário autoriza o Houpa a informar e/ou divulgar seus dados
            fornecidos e/ ou coletados em caso de ordem legal, judicial e/ou
            administrativa ou se entender razoavelmente necessário para: <br />
            (i) fazer cumprir os Termos e Condições; <br />
            (ii) responder quaisquer alegações de suposta violação de direitos
            de terceiros e de divulgação indevida de informações para contato de
            terceiros e; <br />
            (iii) para proteger direitos, propriedade ou segurança dos Usuários,
            do Houpa e/ou de terceiros.
          </Text>
          <Subtitle>4. Controle do Usuário:</Subtitle>
          <Text>
            4.1 O Usuário poderá alterar informações de cadastro por meio do
            Aplicativo. É preciso destacar que o Houpa poderá estabelecer quais
            são os dados passíveis de alteração, conforme as políticas do Houpa.
          </Text>
          <Text>
            4.2 Algumas informações são necessárias e obrigatórias para
            viabilizar a utilização do Aplicativo pelo Usuário. Assim, caso o
            Usuário exclua ou bloqueie certas informações, o uso dos Serviços
            poderá ser comprometido ou ter uma queda da funcionalidade em alguns
            casos, consoante as políticas de uso estabelecidas e a serem
            estabelecidas pelo Houpa.
          </Text>
          <Subtitle>5. Segurança:</Subtitle>
          <Text>
            5.1 O Houpa envidará esforços para conferir tratamento seguro aos
            Dados, com o intuito de evitar acessos ou uso dos dados de forma não
            autorizada. As afiliadas, parceiros de confiança e terceiros
            prestadores de serviços do Houpa se comprometerão a gerenciar a
            informação de acordo com os requisitos de segurança e privacidade do
            Houpa.
          </Text>
          <Text>
            5.2 O Usuário deve estar ciente de que o Aplicativo poderá conter
            links para aplicativos de terceiros e que ao acessá-los, o Usuário
            poderá ser direcionado para conteúdos e serviços de outras empresas
            que se encontram fora do ambiente do Houpa. As informações
            eventualmente coletadas por estes terceiros não são de
            responsabilidade do Houpa, cabendo única e exclusivamente ao Usuário
            verificar as políticas de privacidade e/ou demais documentos
            regulatórios que sejam aplicáveis antes de utilizá-los.
          </Text>
          <Text>
            5.3 Toda informação que o Usuário disponibilizar em seu perfil e/ou
            em seus anúncios é de sua única e exclusiva responsabilidade. O
            Usuário deve considerar com atenção os riscos de fazer declarações
            ou publicar informações pessoais (como endereço, dados bancários ou
            localização precisa). Essas informações poderão ser utilizadas por
            outros Usuários fora do escopo do Aplicativo. O Houpa não assume
            qualquer responsabilidade por mensagens e/ou quaisquer atos de
            outros Usuários e/ou terceiros relacionados às informações que o
            Usuário tornar de acesso público.
          </Text>
          <Text>
            5.4 Ao publicar um anúncio no Houpa, o Usuário reconhece que o
            anúncio e o seu conteúdo poderão ser publicamente acessíveis a
            terceiros, podendo ser listados como resultados de ferramentas de
            pesquisa, feeds entre outros acordos comerciais entre o Houpa e
            outras prestadoras de serviços. O Usuário compreende que é de
            exclusiva responsabilidade de cada uma destas ferramentas de
            pesquisa, aplicativos, websites ou recursos RSS manter seus índices
            e cache atualizados e remover o conteúdo de seus índices e cache.
          </Text>
          <Text>
            5.5 O Usuário declara desde já estar ciente que o Houpa não assume
            nenhuma responsabilidade em caso de roubo, perda, alteração, uso
            indevido de informações pessoais e dos conteúdos disponibilizados no
            Aplicativo. O Houpa também não será responsável por interceptações
            ilegais ou violação de seus sistemas, ou bases de dados por parte de
            terceiros não autorizados, tampouco se responsabilizará pela
            indevida utilização da informação obtida por esses meios.
          </Text>
          <Subtitle>6. Atualizações e Alterações:</Subtitle>
          <Text>
            6.1 Esta Política de Privacidade foi atualizada em Outubro de 2018.
            O Houpa poderá, ao seu critério, atualizá-la periodicamente, cabendo
            ao Usuário verificar a versão mais atual no momento da utilização do
            Aplicativo. Ao continuar utilizando o Aplicativo, o Usuário confirma
            sua aceitação a quaisquer atualizações realizadas no presente
            documento.
          </Text>
          <Text>
            6.2 Caso o Houpa efetue alterações materiais a esta Política de
            Privacidade com as quais o Usuário não concorde, este poderá
            descontinuar o uso do Aplicativo, desde que observe de forma
            integral o quanto disposto neste documento, nos Termos e Condições,
            nas demais políticas do Houpa e nas disposições legais aplicáveis.
          </Text>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default PrivacyPolicy;
