import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto 80px;
  padding: 0 10px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 628px;
  }
  @media screen and (max-width: 607px) {
    max-width: 332px;
  }
`;


export const PagePath = styled.span`
  display: none;
  font-size: 12px;
  color: var(--grey-dark);
  > span {
    cursor: pointer;
  }
  > a {
    font-size: 12px;
    color: var(--grey-dark);
    text-decoration: none;
  }
  @media screen and (min-width: 1279px) {
    display: block;
    margin-top: 16px;
  }
`;