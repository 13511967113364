import React, {useEffect, useState} from 'react';
import { Container } from './styles';
import Login from './Login';
import Register from './Register';
import { RequiredTip } from '../../../components/Input';

interface Props {
  registerStep: 'initial' | 'createAccount';
  setRegisterStep: (page: 'initial' | 'createAccount') => void;
  navigateTo: string;
}

export interface IValidateUser {
  userId: number,
  email: string,
  password: string
}

const Main: React.FC<Props> = ({ registerStep, setRegisterStep, navigateTo }) => {
  const [validateUser, setValidateUser] = useState<IValidateUser>({} as IValidateUser);

  useEffect(() => {
    if(registerStep === 'initial'){
      setValidateUser({} as IValidateUser)
    }
  }, [registerStep]);

  return (
    <Container>
      {registerStep === 'initial' && (
        <div>
          <Login setValidateUser={setValidateUser} setRegisterStep={setRegisterStep} navigateTo={navigateTo}  />
          <RequiredTip />
        </div>
      )}
      <Register
        navigateTo={navigateTo}
        registerStep={registerStep}
        setRegisterStep={setRegisterStep}
        validateUser={validateUser}
      />
  </Container>
  );
};

export default Main;
