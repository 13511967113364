import React, {Dispatch, SetStateAction, useCallback, useRef} from 'react';
import {AccordionsContainer, Body, ButtonClear, Check, Container, Header, InputPriceContainer} from './styles';
import Accordion from '../../components/Accordion';
import Checkbox from '../Checkbox';
import {Filter, ObjectFilter} from './interface';
import useForm from '../../hooks/useForm';
import {ChangeFilterString, EditFilterString, SetFilterString} from '../../hooks/useFilterString';

interface Props {
  filter: Filter[];
  setFilter: Dispatch<SetStateAction<Filter[]>>
  setDrawer?: (state: boolean) => void
  data: ObjectFilter;
  filterString?: string|null;
  setFilterString?: SetFilterString;
  changeFilterString?: ChangeFilterString;
  editFilterString?: EditFilterString;
}

interface ObjectSwitch {
  [key: string] : () => void
}

const FilterProduct: React.FC<Props> = ({filter, filterString, setFilterString, editFilterString, changeFilterString, setFilter, setDrawer, data : { sizes, colors, prices,styles, brands}}) => {
  const { form, onChange, setValueForm } = useForm({
    minPrice: {
      type: 'currency',
    },
    maxPrice: {
      type: 'currency',
    }
  });

  const previousMinPrice = useRef('');
  const previousMaxPrice = useRef('');

  const clearFilter = () => {
    sizes.map(item => item.checked = false);
    colors.map(item => item.checked = false);
    prices.map(item => item.checked = false);
    styles.map(item => item.checked = false);
    brands.map(item => item.checked = false);
    setValueForm('minPrice', '');
    setValueForm('maxPrice', '');

    if(setFilterString !== undefined) {
      setFilterString('');
    }

    setFilter([]);
  };

  const switchChecked = useCallback((item: Filter) => {
    let index;
    const items: ObjectSwitch = {
      colors: () => {
        index = colors.findIndex((data) => data.id === item.id);
        colors[index].checked = !colors[index]?.checked;
      },
      prices: () => {
        index = prices.findIndex((data) => data.id === item.id);
        prices[index].checked = !prices[index]?.checked;
      },
      sizes: () => {
        index = sizes.findIndex((data) => data.id === item.id);
        sizes[index].checked = !sizes[index]?.checked;
      },
      styles: () => {
        index = styles.findIndex((data) => data.id === item.id);
        styles[index].checked = !styles[index]?.checked;
      },
      brands: () => {
        index = brands.findIndex((data) => data.id === item.id);
        brands[index].checked = !brands[index]?.checked;
      },
      default: () => {
        return false;
      },
    };

    return items[item.type ?? 'default']();
  }, [ colors, prices, sizes, styles, brands]);

  const handleFilterPrice = useCallback(() => {
    if(setFilterString !== undefined && filterString !== undefined && filterString !== null && editFilterString !== undefined) {
      let newFilterString = filterString;

      newFilterString = editFilterString(newFilterString, form.minPrice, 'minPrice');
      newFilterString = editFilterString(newFilterString, form.maxPrice, 'maxPrice');

      previousMinPrice.current = form.minPrice;
      previousMaxPrice.current = form.maxPrice;
      setFilterString(newFilterString);

      prices.map(item => item.checked = false);
      const newFilter = filter.filter((item) => item.type !== 'prices');
      setFilter([...newFilter]);
    }
  }, [editFilterString, filter, filterString, form.maxPrice, form.minPrice, prices, setFilter, setFilterString]);

  const selectFilter = useCallback((item: Filter, check: boolean) => {
    if (!check) {
      const data = filter.filter(i => {
        if (i.type === item.type && i.id === item.id) {
          return;
        }

        return i;
      });


      setFilter(data);
    } else {
      setFilter([...filter, item]);
    }

    changeFilterString && setFilterString && setFilterString(changeFilterString(item));
    switchChecked(item);

    if(setDrawer){
      setDrawer(false)
    }
  }, [changeFilterString, setFilterString, switchChecked, setDrawer, filter, setFilter]);

  return (
    <Container>
      <Header>
        <h3>Filtre por</h3>
        {setDrawer && <i onClick={() => setDrawer && setDrawer(false)} className="icon icon-exit" />}
        {filterString !== undefined && filterString !== null && filterString.length > 0 && <ButtonClear onClick={clearFilter}>Limpar Filtro</ButtonClear>}
      </Header>
      <AccordionsContainer>
        <Accordion title={'TAMANHOS'}>
          <Body>
            {
              sizes !== undefined && sizes.map((item, key) => (
                <Check key={key}>
                  <Checkbox checked={item.checked} onChange={(checked: boolean) => selectFilter(item, checked)}>{item.name}</Checkbox>
                </Check>
              ))
            }
          </Body>
        </Accordion>

        <Accordion title={'CORES'}>
          <Body>
            {
              colors !== undefined && colors.map((item, key) => (
                <Check key={key}>
                  <Checkbox checked={item.checked} onChange={(checked: boolean) => selectFilter(item, checked)}>{item.name}</Checkbox>
                </Check>
              ))
            }
          </Body>
        </Accordion>

        <Accordion title={'PREÇO'}>
          <Body>
            {
              prices !== undefined && prices.map((item, key) => (
                <Check key={key}>
                  <Checkbox checked={item.checked} onChange={(checked: boolean) => selectFilter(item, checked)}>{item.name}</Checkbox>
                </Check>
              ))
            }

            <InputPriceContainer>
              <p>De:</p>
              <input value={form.minPrice} onChange={(e) => onChange('minPrice', e)} onBlur={() => handleFilterPrice()} />
              <p className={'ml'}>Até:</p>
              <input value={form.maxPrice} onChange={(e) => onChange('maxPrice', e)} onBlur={() => handleFilterPrice()} />
            </InputPriceContainer>
          </Body>
        </Accordion>

        <Accordion title={'ESTILOS'}>
          <Body>
            {
              styles !== undefined && styles.map((item, key) => (
                <Check key={key}>
                  <Checkbox checked={item.checked} onChange={(checked: boolean) => selectFilter(item, checked)}>{item.name}</Checkbox>
                </Check>
              ))
            }
          </Body>
        </Accordion>

        <Accordion title={'MARCAS'}>
          <Body>
            {
              brands !== undefined && brands.map((item, key) => (
                <Check key={key}>
                  <Checkbox checked={item.checked} onChange={(checked: boolean) => selectFilter(item, checked)}>{item.name}</Checkbox>
                </Check>
              ))
            }
          </Body>
        </Accordion>

      </AccordionsContainer>

    </Container>
  );
};

export default FilterProduct;
