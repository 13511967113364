import React, {FunctionComponent, useRef} from 'react';
import {CardBody, CardInfo, Container, Content} from './styles';
import ProductImagesContainer from '../../../components/ProductImagesContainer';

const ProductImagesContainerExample: FunctionComponent = () => {

  const mainImage = useRef('https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg').current;
  const images = useRef([
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/1.jpg'
      }
    },
    {
      id: String(Math.random()),
      urlStrings: {
        big: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        medium: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg',
        mini: 'https://img.lojasrenner.com.br/item/548400958/zoom/2.jpg'
      }
    },
  ]).current;

  return (
    <Container>
      <h2>Container de imagens de produto</h2>
      <Content>
        <ProductImagesContainer
          smallColumnWidth={91}
          bigColumnWidth={400}
          images={images}
          mainImage={mainImage}
          onFavorite={() => console.log('Produto favoritado')}
        />

        <CardInfo>
          <CardBody>
            <p>import ProductImagesContainer from './src/components/ProductImagesContainer';</p>

            <div>
              {
                '<ProductImagesContainer smallColumnWidth={91} bigColumnWidth={400} images={images} mainImage={mainImage} onFavorite={() => console.log(\'Produto favoritado\')}/>'
              }
            </div>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default ProductImagesContainerExample;
