import styled from 'styled-components';

export const Main = styled.div`
  > p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  border-bottom: 2px solid var(--grey-light-2);
  margin-bottom: 10px;
  padding-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductIMG = styled.div`
  min-width: 67px;
  width: 67px;
  height: 87.1px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1279px) {
    min-width: 43px;
    width: 43px;
    height: 55.9px;
  }

  @media (max-width: 607px) {
    min-width: 57px;
    width: 57px;
    height: 74.1px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  margin-left: 30px;

  @media (max-width: 1279px) {
    margin-left: 26px;

    p {
      font-size: 12px;
    }
  }

  .info-col {
    width: max-content;

    > div:first-child {
      margin-bottom: 10px;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (min-width: 1280px) {
      width: 300px;
    }

    @media (min-width: 608px) and (max-width: 1279px) {
      width: 267px;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 1;

  @media (max-width: 607px) {
    margin-top: 28px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: max-content;

  > .title {
    font-weight: 700;
    font-family: Roboto-Bold;
    margin-bottom: 18px;
    text-align: left;

    @media (max-width: 1279px) {
      font-size: 12px;
    }
  }

  @media (max-width: 1279px) {
    font-size: 14px;
  }

  @media (max-width: 607px) {
    &.ab-mob {
      position: absolute;
      top: 10px;
      right: 10px;

      span {
        display: none;
      }
    }
  }
`;
