import React, {useState} from 'react';
import FilterProduct from '../../../components/FilterProduct';
import {
  brandsFake,
  colorsFake,
  feminineFake,
  pricesFake,
  printsFake,
  sizesFake,
  stylesFake, tissuesFake
} from '../../../dataFake/filter';
import {Filter} from '../../../components/FilterProduct/interface';

const FilterContainer: React.FC = () => {
  const [filter, setFilter] = useState<Filter[]>([]);

  const data = {
    feminine: feminineFake,
    colors: colorsFake,
    sizes: sizesFake,
    prices: pricesFake,
    styles: stylesFake,
    brands: brandsFake,
    prints: printsFake,
    tissues: tissuesFake,
  };

  return (
    <>
      <FilterProduct
        data={data}
        filter={filter}
        setFilter={setFilter}/>
    </>
  );
};

export default FilterContainer;
