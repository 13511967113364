export const ProductsItemsFake = [
  {
    id: '1',
    img: `${process.env.PUBLIC_URL}/temp/product1.png`,
    store: 'Miranda Flor',
    name: 'Vestido Paetês Prata',
    size: '38',
    color: 'Prata',
    sku: 'SAD45454DSD',
    quantity: 1,
    value: '100,00',
  },
  {
    id: '2',
    img: `${process.env.PUBLIC_URL}/temp/product2.png`,
    store: 'Miranda Flor',
    name: 'Vestido Paetês Prata',
    size: '38',
    color: 'Prata',
    sku: 'SAD45454DSD',
    quantity: 1,
    value: '100,00',
  },
  {
    id: '3',
    img: `${process.env.PUBLIC_URL}/temp/product3.png`,
    store: 'Miranda Flor',
    name: 'Vestido Paetês Prata',
    size: '38',
    color: 'Prata',
    sku: 'SAD45454DSD',
    quantity: 1,
    value: '100,00',
  },
];

export const SuggestionFake = [
  {
    img_main: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion.png`,
    img_secondary: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion.png`,
    title: 'BLUSA CAVA AMERICANA TRICÔ',
    price: 'R$ 50,00',
    price_promotion: 'R$99,90',
    parcel: '2X R$ 25,00 s/juros',
  },
  {
    img_main: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion2.png`,
    img_secondary: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion2.png`,
    title: 'BLUSA CAVA AMERICANA TRICÔ',
    price: 'R$ 50,00',
    price_promotion: 'R$99,90',
    parcel: '2X R$ 25,00 s/juros',
  },
  {
    img_main: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion3.png`,
    img_secondary: `${process.env['REACT_APP_PUBLIC_URL']}/temp/product-suggestion3.png`,
    title: 'BLUSA CAVA AMERICANA TRICÔ',
    price: 'R$ 50,00',
    price_promotion: 'R$99,90',
    parcel: '2X R$ 25,00 s/juros',
  },
];

export const AddressFake = [
  {
    id: 0,
    title: 'trabalho',
    receiver: 'Luan',
    street: 'R. Cesare lombroso',
    number: '305',
    adjunct: '3º andar, conjunto 6',
    neighborhood: 'Bom retiro',
    city: 'São Paulo',
    uf: 'SP',
    zipCode: '01122-21',
    main: '1',
  },
  {
    id: 1,
    title: 'Apartamento',
    receiver: 'Iago',
    street: 'Rua Maringá',
    number: '400',
    adjunct: 'apto 1, bloco A',
    neighborhood: 'Vila Ursulina',
    city: 'Itaquaquecetuba',
    uf: 'SP',
    zipCode: '08574-310',
    main: '0',
  },
  {
    id: 2,
    title: 'Vila',
    receiver: 'Marcelo',
    street: 'R. Professor Doutor Rubens Mercadante de Lima',
    number: '30',
    adjunct: 'casa 1',
    neighborhood: 'Vila Horizonte',
    city: 'Mogi das Cruzes',
    uf: 'SP',
    zipCode: '08820-010',
    main: '0',
  },
];

export const OrdersFake = [
  {
    sender: 'Unique Chic',
    address: 'Rua 9 de Julho, 457 - Rio de Janeiro/RJ - 20512-25 - Brasil',
    freight: [
      {
        name: 'Normal',
        deadline: 'Em até 4 dias úteis',
        icon: 'icon-truck-normal',
        value: '15,00',
      },
      {
        name: 'Expresso (Sedex 10)',
        deadline: 'Em até 2 dias úteis',
        icon: 'icon-truck-express',
        value: '25,00',
      },
    ],
    products: [
      {
        id: '1',
        img: `${process.env.PUBLIC_URL}/temp/product1.png`,
        store: 'Miranda Flor',
        name: 'Vestido Paetês Prata',
        size: '38',
        color: 'Prata',
        sku: 'SAD45454DSD',
        quantity: 1,
        value: '100,00',
      },
    ],
  },
  {
    sender: 'La Folie',
    address: 'Rua 9 de Julho, 457 - Rio de Janeiro/RJ - 20512-25 - Brasil',
    freight: [
      {
        name: 'Normal',
        deadline: 'Em até 4 dias úteis',
        icon: 'icon-truck-normal',
        value: '15,00',
      },
      {
        name: 'Expresso (Sedex 10)',
        deadline: 'Em até 2 dias úteis',
        icon: 'icon-truck-express',
        value: '25,00',
      },
    ],
    products: [
      {
        id: '1',
        img: `${process.env.PUBLIC_URL}/temp/product1.png`,
        store: 'Miranda Flor',
        name: 'Vestido Paetês Prata',
        size: '38',
        color: 'Prata',
        sku: 'SAD45454DSD',
        quantity: 1,
        value: '100,00',
      },
      {
        id: '2',
        img: `${process.env.PUBLIC_URL}/temp/product2.png`,
        store: 'Miranda Flor',
        name: 'Vestido Paetês Prata',
        size: '38',
        color: 'Prata',
        sku: 'SAD45454DSD',
        quantity: 1,
        value: '100,00',
      },
      {
        id: '3',
        img: `${process.env.PUBLIC_URL}/temp/product3.png`,
        store: 'Miranda Flor',
        name: 'Vestido Paetês Prata',
        size: '38',
        color: 'Prata',
        sku: 'SAD45454DSD',
        quantity: 1,
        value: '100,00',
      },
    ],
  },
];
