import styled from 'styled-components';

export const Container = styled.div`
  max-width: 415px;
  margin: 28px auto 0;;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 453px;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
  }
`;

export const Return = styled.div`
  display: flex;
  align-items: center;
  column-gap: 9px;
  cursor: pointer;
`;

export const ReturnIcon = styled.i`
  font-size: 18px;
  color: var(--purple);
`;

export const ReturnText = styled.span`
  font-size: 16px;
  color: var(--black);
  line-height: 19px;
`;

export const Title = styled.h2`
  font-family: var(--poppins);
  font-size: 24px;
  font-weight: 700;
  color: var(--grey-dark);
  line-height: 28px;
  margin-top: 33px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 24px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;

export const InsertCodeMessage = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: var(--grey);
  margin-top: 33px;
  @media screen and (max-width: 607px) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
  }
`;

export const EmailBold = styled.span`
  font-weight: 700;
  @media screen and (max-width: 607px) {
    font-weight: 400;
  }
`;

export const InpurCodeWrapper = styled.div`
  margin-top: 56px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 36px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 64px;
  }
`;

export const CodeNotReceivesMessage = styled.p`
  text-align: center;
  margin-top: 57px;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey-dark);
  > span {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 83px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 53px;
    font-size: 14px;
    line-height: 22px;
    > span {
      font-size: 14px;
    }
  }
`;

export const Login = styled.p`
  font-size: 16px;
  color: var(--grey-dark);
  line-height: 19px;
  text-align: center;
  margin-top: 150px;
  > span {
    font-weight: 700;
    color: var(--grey-dark);
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 607px) {
    font-size: 14px;
    > a {
      font-size: 14px;
    }
  }
`;