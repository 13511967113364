import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BankSlipContainer,
  BankSlipTotal,
  CardContainer,
  Container,
  Content,
  Form,
  PaymentContainer,
  PaymentContent,
  PaymentHeader,
  PaymentTabs,
  SelectedCardContainer,
  TabsOption,
  TitleNewCard,
} from './styles';
import OrderSummary from '../../components/OrderSummary';
import { SafetyContainer, SummaryContainer } from '../../styles';
import { ReactComponent as Safety } from '../../../../assets/svg/safety.svg';
import RadioButtonsGroup from '../../../../components/Radio';
import { cardTypesInterface } from '../../../../interfaces/creditCard';
import AMEX from '../../../../assets/svg/AMEX.svg';
import ELO from '../../../../assets/svg/ELO.svg';
import DINERS from '../../../../assets/svg/DINERS.svg';
import HIPER from '../../../../assets/svg/HIPER.svg';
import HIPERCARD from '../../../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../../../assets/svg/MASTERCARD.svg';
import VISA from '../../../../assets/svg/VISA.svg';
import useForm from '../../../../hooks/useForm';
import Input, { RequiredTip } from '../../../../components/Input';
import CreditCardImage from '../../../../assets/png/credit_card.png';
import Checkbox from '../../../../components/Checkbox';
import { MoipValidator } from 'moip-sdk-js';
import Select from '../../../Account/MyRegister/components/Select';
import SelectCard from '../../../../components/SelectCard';
import { useNavigate } from 'react-router-dom';
import { ImgWrapper } from '../../../../components/SelectCard/styles';
import { removeMask } from '../../../../helpers/removeMasks';
import { encryptCard } from '../../../../helpers/moipHelper';
import { api } from '../../../../services/api';
import { CartContext } from '../../index';
import { Accordion, AccordionDetails, AccordionSummary } from './components';
import { CardInterface } from '../../interfaces';
import { validateCreditCard } from '../../../../helpers/inputValidation';
import { useToast } from '../../../../hooks/useToast';

type EmptyObject = { [K in string]: never };

const Payment: React.FC = () => {
  const {
    paymentMethods: {
      methods,
      selectedMethod,
      handleSelectMethod,
      availableInstallments,
    },
    cards: { cards, results, getCards, cardSelected, setCardSelected },
    order: { setOrderConfig, orderConfig, newCard, setNewCard },
    cartProducts: { cartResume },
  } = useContext(CartContext);

  const selectedInstallment = useMemo(
    () =>
      orderConfig.installmentId !== undefined
        ? String(orderConfig.installmentId)
        : '',
    [orderConfig.installmentId],
  );

  const { toast } = useToast();

  const [expanded, setExpanded] = React.useState<string | false>('');
  const [cardType, setCardType] = useState<string | null>(null);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] =
    useState<string>(selectedInstallment);
  const [shouldLoadData, setShouldLoadData] = useState(false);

  const { form, onChange, onBlur, error, validateForm, setValueForm } = useForm(
    {
      cardNumber: {
        required: true,
        type: 'cardNumber',
        limit: 19,
      },
      holder: {
        required: true,
        type: 'fullname',
      },
      expirationDate: {
        required: true,
        type: 'cardDate',
        limit: 5,
      },
      securityCode: {
        required: true,
        type: 'cardSecurityCode',
        limit: 4,
      },
      cpf: {
        required: true,
        type: 'cpf',
        limit: 14,
      },
      birthDate: {
        required: true,
        type: 'date',
        limit: 10,
      },
      holderPhoneNumber: {
        required: true,
        type: 'phone',
        limit: 15,
      },
    },
  );

  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    DINERS,
    HIPER,
    HIPERCARD,
    MASTERCARD,
    VISA,
  }).current;

  const navigate = useNavigate();

  const onSelectInstallment = useCallback(
    (installmentId: number) => {
      setOrderConfig({ installmentId });
    },
    [setOrderConfig],
  );

  const handleChange =
    (panel: string) =>
    (event: React.ChangeEvent<EmptyObject>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);

      if (newExpanded) {
        setOrderConfig({ cardId: undefined });
        setCardSelected({} as CardInterface);
      }
    };

  const onSelectCard = useCallback(
    (card: CardInterface) => {
      setCardSelected(card);
      setExpanded(false);
    },
    [setCardSelected],
  );

  useEffect(() => {
    const cardNumber = form.cardNumber.replace(/\D/g, '');
    const type = MoipValidator.cardType(cardNumber);

    setCardType(type ? type.brand : null);
  }, [form.cardNumber]);

  const createCard = useCallback(async () => {
    const {
      cardNumber,
      securityCode,
      expirationDate,
      cpf,
      holderPhoneNumber,
      birthDate,
      holder,
    } = form;

    const cardWithoutMask = removeMask(cardNumber, 'card');

    const cardValidation = validateCreditCard({
      cardNumber,
      securityCode,
      expirationDate,
    });

    if (!cardValidation.valid || !validateForm()) {
      if (!cardValidation.valid) {
        toast({
          type: 'error',
          description: cardValidation.message,
        });
      }
      return false;
    }

    const { brand } = await MoipValidator.cardType(cardWithoutMask);
    const brandLowerCase = brand.toLowerCase();

    const hash = await encryptCard({
      card: cardNumber,
      validate: expirationDate,
      cvc: securityCode,
    });

    const [month, year] = expirationDate.split('/');

    setNewCard({
      cardNumber,
      securityCode,
      expirationDate,
      cpf,
      holder,
      birthDate,
      holderPhoneNumber,
    });
    return {
      brand: brandLowerCase,
      brandName:
        brandLowerCase.charAt(0).toUpperCase() + brandLowerCase.slice(1),
      hash: String(hash),
      last4Digits: cardWithoutMask.substr(-4, 4),
      holder,
      birthDate,
      cpf,
      main: 0,
      expirationMonth: month,
      expirationYear: '20' + year,
      holderPhoneNumber,
    };
  }, [form, setNewCard, toast, validateForm]);

  const nextStage = useCallback(async () => {
    if (!selectedMethod) {
      return;
    }

    if (selectedMethod.id === 2) {
      if (!orderConfig.cardId) {
        const status = validateForm();

        if (!status) {
          toast({
            type: 'error',
            description:
              'Preencha os dados do novo cartão ou selecione um cartão cadastrado',
          });
          return;
        }

        const card = await createCard();

        if (!card) {
          return;
        }

        setOrderConfig({ card });
      }
    }

    navigate('/carrinho/detalhes');
  }, [
    createCard,
    navigate,
    orderConfig.cardId,
    selectedMethod,
    setOrderConfig,
    validateForm,
    toast,
  ]);

  const handleLoadData = async (checked: boolean) => {
    setShouldLoadData(checked);

    if (checked) {
      try {
        const { data } = await api.get('/users');

        setValueForm('holder', data.customerData.fullName);
        setValueForm('cpf', data.customerData.cpf);
        setValueForm('holderPhoneNumber', data.customerData.cellphone);
        setValueForm('birthDate', data.customerData.birthDate);
      } catch (e: any) {}
    } else {
      setValueForm('holder', '');
      setValueForm('cpf', '');
      setValueForm('holderPhoneNumber', '');
      setValueForm('birthDate', '');
    }
  };

  useEffect(() => {
    if (newCard) {
      setExpanded('panel1');
      setValueForm('cardNumber', newCard.cardNumber);
      setValueForm('holder', newCard.holder);
      setValueForm('expirationDate', newCard.expirationDate);
      setValueForm('securityCode', newCard.securityCode);
      setValueForm('cpf', newCard.cpf);
      setValueForm('birthDate', newCard.birthDate);
      setValueForm('holderPhoneNumber', newCard.holderPhoneNumber);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <h3>Pagamento</h3>
      <Content>
        <div>
          <PaymentContainer>
            <h2>Formas de pagamento</h2>

            <PaymentTabs>
              <PaymentHeader>
                {methods.map((method) => (
                  <TabsOption
                    key={method.id}
                    onClick={() => handleSelectMethod(method)}
                    className={
                      selectedMethod && selectedMethod.id === method.id
                        ? 'active'
                        : ''
                    }
                  >
                    <div className={'radio'}>
                      <RadioButtonsGroup
                        checked={
                          selectedMethod && selectedMethod.id === method.id
                        }
                        label={''}
                      />
                    </div>

                    <i
                      className={`icon ${
                        method.id === 2 ? 'icon-credit-card' : 'icon-bar-code'
                      }`}
                    />

                    <p>{method.name}</p>
                  </TabsOption>
                ))}
              </PaymentHeader>
              <PaymentContent>
                {selectedMethod && selectedMethod.id === 2 && (
                  <CardContainer>
                    <Accordion
                      square
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <div className={'radio'}>
                          <RadioButtonsGroup
                            checked={expanded === 'panel1'}
                            label={''}
                          />
                        </div>
                        <ImgWrapper>
                          <i className="icon icon-credit-card" />
                        </ImgWrapper>
                        <TitleNewCard>Pagar com um novo cartão</TitleNewCard>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="newCardDiv">
                          <h1>Insira os dados do cartão</h1>

                          <Form>
                            <Checkbox
                              className="checkbox"
                              checked={shouldLoadData}
                              onChange={handleLoadData}
                            >
                              Preencher meus dados de cadastro automaticamente.
                            </Checkbox>

                            <Input
                              value={form.holder}
                              error={error.holder}
                              onChange={(e) => onChange('holder', e)}
                              onBlur={() => onBlur('holder')}
                              title={'Nome do Titular'}
                              placeholder={
                                'Digite seu nome como está no cartão'
                              }
                              className="input"
                              required
                            />

                            <Input
                              value={form.cpf}
                              error={error.cpf}
                              onChange={(e) => onChange('cpf', e)}
                              onBlur={() => onBlur('cpf')}
                              title={'CPF do Titular'}
                              placeholder={'Digite apenas números'}
                              className="input"
                              required
                            />

                            <Input
                              value={form.birthDate}
                              error={error.birthDate}
                              onChange={(e) => onChange('birthDate', e)}
                              onBlur={() => onBlur('birthDate')}
                              title={'Data de nascimento do Titular'}
                              placeholder={'Digite apenas números'}
                              className="input"
                              required
                            />

                            <Input
                              value={form.holderPhoneNumber}
                              error={error.holderPhoneNumber}
                              onChange={(e) => onChange('holderPhoneNumber', e)}
                              onBlur={() => onBlur('holderPhoneNumber')}
                              title={'Telefone do Titular'}
                              placeholder={'Digite apenas números'}
                              className="input"
                              required
                            />

                            <div className="row-icon">
                              <Input
                                value={form.cardNumber}
                                error={error.cardNumber}
                                onChange={(e) => {
                                  onChange('cardNumber', e);
                                }}
                                onBlur={() => {
                                  onBlur('cardNumber');
                                }}
                                title={'Número do Cartão'}
                                placeholder={'1234 1234 1234 1234'}
                                className="input card-number-input"
                                required
                              />

                              <div className={'icon-area'}>
                                {cardType ? (
                                  <img
                                    src={cardTypes[cardType]}
                                    alt="bandeira do cartão"
                                  />
                                ) : (
                                  <i className={'icon icon-credit-card'} />
                                )}
                              </div>
                            </div>

                            <Input
                              value={form.expirationDate}
                              error={error.expirationDate}
                              onChange={(e) => onChange('expirationDate', e)}
                              onBlur={() => onBlur('expirationDate')}
                              className={'small-input input'}
                              title={'Validade'}
                              placeholder={'Ex: 02/26'}
                              required
                            />

                            <Input
                              value={form.securityCode}
                              error={error.securityCode}
                              onChange={(e) => onChange('securityCode', e)}
                              onBlur={() => onBlur('securityCode')}
                              className={'medium-input input'}
                              title={'Código de Segurança'}
                              placeholder={'Digite apenas números'}
                              tooltipContent={
                                <div className={'card-image'}>
                                  <img
                                    src={CreditCardImage}
                                    alt={
                                      'Imagem localizando o código de segurança na parte traseira do cartão.'
                                    }
                                  />
                                </div>
                              }
                              required
                            />

                            <Select
                              className="input select"
                              label="Número de parcelas"
                              disabledOption="Selecione a opção desejada"
                              options={availableInstallments}
                              selectError={selectError}
                              setselectError={setSelectError}
                              selectedValue={selectedValue}
                              setSelectedValue={(value) => {
                                setSelectedValue(value);
                                onSelectInstallment(Number(value));
                              }}
                              required
                            />
                          </Form>
                          <RequiredTip />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <SelectedCardContainer>
                      <SelectCard
                        cardSelected={cardSelected}
                        setCardSelected={onSelectCard}
                        options={cards}
                        installments={availableInstallments}
                        onSelectInstallment={onSelectInstallment}
                        results={results}
                        loadMoreCards={getCards}
                      />
                    </SelectedCardContainer>
                  </CardContainer>
                )}

                {selectedMethod && selectedMethod.id === 1 && (
                  <BankSlipContainer>
                    <h1>Boleto bancário</h1>
                    <p>
                      Imprima o boleto bancário após a finalização do pedido.
                    </p>

                    <BankSlipTotal>
                      Total: {cartResume.totalWithDiscount}
                    </BankSlipTotal>
                  </BankSlipContainer>
                )}
              </PaymentContent>
            </PaymentTabs>
          </PaymentContainer>
        </div>
        <SummaryContainer>
          <OrderSummary text={'Avançar'} onClick={nextStage} />
          <SafetyContainer>
            <Safety />
            <p>COMPRA 100% SEGURA</p>
          </SafetyContainer>
        </SummaryContainer>
      </Content>
    </Container>
  );
};

export default Payment;
