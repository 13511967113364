import { useEffect, useState } from 'react';
import { ProductSuggestionInterface } from '../pages/Product/interfaces';
import { api } from '../services/api';

export const useRecommendedProducts = (): [
  products: ProductSuggestionInterface[],
  getProducts: () => Promise<void>,
] => {
  const [products, setProducts] = useState<ProductSuggestionInterface[]>([]);

  const getProducts = async () => {
    try {
      const { data } = await api.get(
        'search/products?page=1&limit=3&order=rand',
      );

      setProducts(data.products);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts().then();
  }, []);

  return [products, getProducts];
};
