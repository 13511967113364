import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import {Route} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';

interface OwnProps {
  element: ReactElement;
  path: string;
  noLogged?: boolean;
}

const ProtectedRoute: FunctionComponent<OwnProps> = ({element, path, noLogged = false}) => {
  const { token } = useAuth();

  useEffect(() => {
    if(noLogged && token) {
      window.location.href = process.env.REACT_APP_PUBLIC_URL || '';
    }

    if(!token && !noLogged) {
      window.location.href = process.env.REACT_APP_PUBLIC_URL + '/login';
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Route path={path} element={element}/>
  );
};

export default ProtectedRoute;
