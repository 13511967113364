import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Break,
  Color,
  Container,
  Size,
  SlickStyled,
  SlickTitle,
  BreadcrumbsContainer,
  ShowMobile,
  ShowDesktop
} from './styles';
import ProductImagesContainer from '../../components/ProductImagesContainer';
import Button from '../../components/Button';
import Accordion from '../../components/Accordion';
import Breadcrumb from '../../components/Breadcrumb';
import BackButton from '../../components/BackButton';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  ColorInterface,
  ProductInterface,
  ProductSuggestionInterface,
  VariantInterface,
} from './interfaces';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProductCard from '../../components/ProductCard';
import SideCart from '../../components/SideCart';
import ShareModal from '../../components/ShareModal';
import { api } from '../../services/api';
import { useCart } from '../../hooks/useCart';
import { useLoading } from '../../hooks/useLoading';
import { useRecommendedProducts } from '../../hooks/useRecommendedProducts';
import { SamplePrevArrow, SampleNextArrow } from './components';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { event } from 'react-ga';
import { id } from 'date-fns/locale';
import { useQuery } from '../../hooks/useQuery';

const useProduct = () => {
  const { showLoading, closeLoading } = useLoading();

  const [product, setProduct] = useState<ProductInterface>(
    {} as ProductInterface,
  );
  const [sizes, setSizes] = useState<VariantInterface[]>([]);
  const [selectedColor, setSelectedColor] = useState<ColorInterface | null>(
    null,
  );
  const [selectedSize, setSelectedSize] = useState<VariantInterface | null>(
    null,
  );
  const [errors, setErrors] = useState({
    color: false,
    size: false,
  });

  const [showDescription, setShowDescription] = useState(product.shortDescription);

  const navigate = useNavigate();

  const selectSize = useCallback(
    (sSize: VariantInterface) => {
      const newErrors = { ...errors };
      const newSizes = sizes.map((size) => {
        size.active = size.id === sSize.id;
        return size;
      });

      newErrors.size = false;

      setSizes([...newSizes]);
      setErrors(newErrors);
      setSelectedSize(sSize);
    },
    [errors, sizes],
  );

  const selectColor = useCallback(

    (sColor: ColorInterface) => {
      const newProduct = { ...product };

      newProduct.colors.forEach((color) => {
        color.active = color.id === sColor.id;
      });
      const newErrors = { ...errors };

      newErrors.color = false;

      setErrors(newErrors);
      setSizes(sColor.variants);
      setSelectedSize(null);
      setSelectedColor(sColor);
      setProduct({ ...newProduct });
    },
    [errors, product],
  );

  const { colorId } = useParams();
  const getProduct = async (profileUrl: string, productSlug: string) => {
    showLoading();
    try {
      const { data } = await api.get(`/products/${profileUrl}/${productSlug}`);

      if (colorId) {
        data.colors.forEach((color: { active: boolean; id: string; }) => {
          if (color.id == colorId) {
            color.active = true;
          }
        });
      } else {
        data.colors[0].active = true;
      }

      setSelectedColor(data.colors[0]);
      setSizes(data.colors[0].variants);
      setErrors((prevState) => ({ ...prevState, color: false }));
      setProduct(data);
    } catch (error: any) {
      navigate('/pesquisa')
    } finally {
      closeLoading();
    }
  };

  return [
    product,
    {
      setProduct,
      getProduct,
      selectColor,
      selectSize,
      setErrors,
      sizes,
      selectedColor,
      errors,
      selectedSize,
    },
  ] as const;
};

const useImagesWidth = () => {
  const [imagesWidth, setImagesWidth] = useState({
    smallImage: 91,
    bigImage: 524,
  });

  useLayoutEffect(() => {
    if (window.screen.availWidth <= 1279) {
      setImagesWidth({
        smallImage: 62,
        bigImage: 295,
      });
    }

    if (window.screen.availWidth <= 607) {
      setImagesWidth({
        smallImage: 62,
        bigImage: 241,
      });
    }
  }, []);


  return [imagesWidth] as const;
};

const useSameStoreProducts = () => {
  const [products, setProducts] = useState<ProductSuggestionInterface[]>([]);

  const getProducts = async (profileUrl: string) => {
    try {
      const { data } = await api.get(
        `seller/products/${profileUrl}?page=1&limit=3`,
      );

      setProducts(data.products);
    } catch (error: any) {}
  };

  return [products, getProducts] as const;
};

const Product: FunctionComponent = () => {
  const { profileUrl, productSlug, colorId } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const descriptionRef = useRef<any>(null);
  const { addToCart } = useCart();
  const { user } = useAuth();
  const { toast } = useToast();

  const query = useQuery()
  const origin = query.get('o')
  const searchParam = query.get('s')

  const gotoDescription = (): void => {
    window.scrollTo({
      top: descriptionRef.current.offsetTop - 110,
      behavior: 'smooth',
    });
  };

  const [
    product,
    {
      getProduct,
      selectColor,
      selectSize,
      setErrors,
      sizes,
      selectedColor,
      errors,
      selectedSize,
    },
  ] = useProduct();
  const [imagesWidth] = useImagesWidth();
  const [sameStoreProducts, getSameStoreProducts] = useSameStoreProducts();
  const [
    recommendedProducts,
    getRecommendedProducts,
  ] = useRecommendedProducts();

  const [sideCartOpen, setSideCartOpen] = useState(false);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }).current;

  const validatePurchase = useCallback(
    async (openSide = false) => {
      const newErrors = { ...errors };

      newErrors.color = selectedColor === null;
      newErrors.size = selectedSize === null;

      setErrors(newErrors);

      if (newErrors.color || newErrors.size) {
        return;
      }

      await addToCart(profileUrl, Number(selectedSize?.id));

      if (openSide) {
        setSideCartOpen(!sideCartOpen);
        return;
      }

      navigate('/carrinho');
    },
    // eslint-disable-next-line
    [
      errors,
      selectedColor,
      selectedSize,
      setErrors,
      setSideCartOpen,
      sideCartOpen,
      addToCart,
      profileUrl,
      navigate,
    ],
  );

  const addFavoriteProduct = async () => {
    try {
      if (!user) {
        navigate(`/login?ReturnUrl=produto/${profileUrl}/${productSlug}`);
        return;
      }

      await api.post(`/favorites-products/${product.id}`);
      product.isFavorite = 1;

      toast({
        type: 'success',
        description: 'Produto favoritado com sucesso!',
      });
    } catch (err: any) {
      toast({
        type: 'error',
        description: err.message,
      });
    }
  };

  useEffect(() => {
    getProduct(profileUrl, productSlug);
    getSameStoreProducts(profileUrl);
    getRecommendedProducts();

  }, [productSlug, profileUrl]);

  const [mainImageByColor, setMainImageByColor] = useState<string>('');
  const handlerColors = (event: any) => {
    let setImageMainProduct = product.mainImage;
    product.images.forEach((image: any) => {
      if (event === image.colorId) {
        setImageMainProduct = image.urlStrings.big;
      }
    });

    window.history.pushState(null, '', `/produto/${profileUrl}/${productSlug}/${event}`);

    setMainImageByColor(setImageMainProduct);
  };

  const [imageMainByColorParam, setImageMainByColorParam] = useState<string>('');
  useEffect(() => {
    if (colorId) {
      const response = api.get(`/products/${profileUrl}/${productSlug}/${colorId}`);
      response.then((response: any) => {
        const { data } = response;
        if (data.data !== false) {
          setImageMainByColorParam(data.urlStrings.big);
        } else {
          setMainImageByColor(product.mainImage);
        }
      });

      setMainImageByColor(imageMainByColorParam);
    } else {
      setMainImageByColor(product.mainImage);
    }

    addVisualization();
  }, [product.mainImage]);

  const [showDescription, setShowDescription] = useState(false);

  const breadcrumb = origin == "profile"
    ? <Breadcrumb values={[
      { title: 'Início', link: '/' },
      { title: 'Perfil', link: '/perfil', param: `/${profileUrl}` },
      { title: product.seller, link: '/perfil', param: `/${profileUrl}` },
      { title: product.name, link: '' }
    ]} />
    : <Breadcrumb values={[
      { title: 'Início', link: '/' },
      {
        title: 'Buscar',
        link: '/pesquisa',
        param: (searchParam ? `?s=${searchParam}` : '')
      },
      { title: product.name, link: '' }
    ]} />

  const addVisualization = async () => {
    try {
      if(!product.id) return;

      await api.post('/product/add-visualization', {
        id: product.id,
        platform: 'web',
        model: 'b2c',
      });

    } catch (error: any) {}
  }

  return (
    <>
      {sideCartOpen && (
        <SideCart isOpen={sideCartOpen} setIsOpen={setSideCartOpen} />
      )}
      <Container>
        <ShowDesktop>
          <BreadcrumbsContainer> {breadcrumb} </BreadcrumbsContainer>
        </ShowDesktop>
        <ShowMobile>
          <BackButton onClick={() => window.history.back()} />
        </ShowMobile>
        <div className="main-content">
          <div className="container-images">
            <div className="hide-desktop">
              <div className="info-header">
                <h2>{product.supplier}</h2>

                <div className="right-header">
                  {product.newReleaseStatus !== undefined && (
                    <div className="tag">LANÇAMENTO</div>
                  )}

                  <ShareModal />
                </div>
              </div>

              <h1 className="product-name">{product.name}</h1>

              <div className="subtitle-container">
                <p className="seller">
                  Vendido por:{' '}
                  <Link className="seller-name" to={`/perfil/${profileUrl}`}>
                    {product.seller}
                  </Link>
                </p>
                <p className="sku">Ref.: {product.reference}</p>
              </div>

              <div className="price-container">
                {product.originalPrice !== null && (
                  <p className="original-price">R$ {product.originalPrice}</p>
                )}
                <div className="price-box">
                  <p className="price">R$ {product.price}</p>
                  {product.selloffPercentage > 0 && (
                    <p className="percentage">-{product.selloffPercentage}%</p>
                  )}
                </div>
                <p className="price-description">{product.paymentCondition}</p>
              </div>
            </div>

            <ProductImagesContainer
              smallColumnWidth={imagesWidth.smallImage}
              bigColumnWidth={imagesWidth.bigImage}
              images={product.images}
              mainImage={mainImageByColor}
              isFavorite={product.isFavorite === 1}
              onFavorite={() => addFavoriteProduct()}
            />


          </div>

          <div className="container-info">
            <div className="hide-mobile">
              <div className="info-header">
                <h2>{product.supplier}</h2>

                <div className="right-header">
                  {product.newReleaseStatus !== '0' && (
                    <div className="tag">Lançamento</div>
                  )}

                  <ShareModal />
                </div>
              </div>

              <h1 className="product-name">{product.name}</h1>

              <div className="subtitle-container">
                <p className="seller">
                  Vendido por:{' '}
                  <Link className="seller-name" to={`/perfil/${profileUrl}`}>
                    {product.seller}
                  </Link>
                </p>
                <p className="sku">Ref.: {product.reference}</p>
              </div>

              <div className="price-container">
                {product.originalPrice !== null && (
                  <p className="original-price">R$ {product.originalPrice}</p>
                )}
                <div className="price-box">
                  <p className="price">{product.price}</p>
                  {product.selloffPercentage > 0 && (
                    <p className="percentage">-{product.selloffPercentage}%</p>
                  )}
                </div>
                <p className="price-description">{product.paymentCondition}</p>
              </div>

              {product.shortDescription !== undefined &&
                product.shortDescription.length > 0 && (
                  <p className="product-description">
                    {showDescription === false ? (
                      <>
                        {product.shortDescription}...{' '}
                        <p onClick={() => setShowDescription(true)} className="see-more-button">
                          Ver mais
                        </p>
                      </>
                    ) : (
                      <>
                        {product.description}...{''}
                        <p onClick={() => setShowDescription(false)} className="see-more-button">
                          Ver menos
                        </p>
                      </>
                    )}
                  </p>
                )}
            </div>

            <div className="list-container">
              <span>
                Cor selecionada:
                {selectedColor && (
                  <span className="selected-color"> {selectedColor.name}</span>
                )}
              </span>

              <div className="list">
                {product.colors !== undefined &&
                  product.colors.map((color) =>
                    color.hexadecimal ? (
                      <Color
                        key={color.id}
                        hex={color.hexadecimal}
                        active={color.active}
                        onClick={() => { selectColor(color); handlerColors(color.id); }}
                      >
                        <div className="color-content" />
                      </Color>
                    ) : (
                      <Color
                        key={color.id}
                        active={color.active}
                        onClick={() => { selectColor(color); handlerColors(color.id); }}
                      >
                        <div className="color-content">
                          <img src={color.print} alt="estampa do produto" />
                        </div>
                      </Color>
                    ),
                  )}
              </div>

              {errors.color && (
                <p className="list-error">Selecione uma cor para continuar.</p>
              )}
            </div>

            <div className="list-container">
              <span>Tamanho:</span>

              <div className="list">
                {sizes.map((size) => (
                  <Size
                    key={size.id}
                    active={size.active}
                    available={size.availableQuantity > 0}
                    onClick={() => selectSize(size)}
                  >
                    <div className="size-content">
                      {
                        size.availableQuantity > 0 ?
                          <span>{size.sizeName}</span>
                          :
                          <>

                            <div className="disabled">
                              <span>{size.sizeName}</span>
                              <div className="line"></div>
                            </div>
                          </>
                      }
                    </div>
                  </Size>
                ))}
              </div>

              {errors.size && (
                <p className="list-error">
                  Selecione um tamanho para continuar.
                </p>
              )}
            </div>

            <Button scale="scale-3" expand onClick={() => validatePurchase()}>
              Comprar
            </Button>

            <Break />

            <Button
              scale="scale-3"
              expand
              outline
              onClick={() => {
                validatePurchase(true);
              }}
            >
              Adicionar ao carrinho
            </Button>

            {product.details !== undefined && (
              <Accordion title={'DETALHES'} border={false}>
                <div className="accordion-body">
                  <div dangerouslySetInnerHTML={{ __html: product.details }} />
                </div>
              </Accordion>
            )}
          </div>
        </div>
        {/* SLIDE */}
        <SlickStyled>
          <SlickTitle>Veja mais peças da Miranda Flor</SlickTitle>
          <Slider {...settings}>
            {sameStoreProducts.map((sameStoreProduct) => (
              <ProductCard
                key={sameStoreProduct.id}
                image={sameStoreProduct.mainImage}
                hoverImage={sameStoreProduct.mainImage}
                title={sameStoreProduct.name}
                price={sameStoreProduct.price}
                pricePromotion={sameStoreProduct.originalPrice}
                parcel={sameStoreProduct.paymentCondition}
                selloffPercentage={sameStoreProduct.selloffPercentage}
                profileUrl={sameStoreProduct.sellerProfileUrl}
                isFavorite={sameStoreProduct.isFavorite}
                productId={sameStoreProduct.id}
                productSlug={sameStoreProduct.productSlug}
              />
            ))}
          </Slider>
        </SlickStyled>
        <SlickStyled>
          <SlickTitle>Veja mais peças da Miranda Flor</SlickTitle>
          <Slider {...settings}>
            {sameStoreProducts.map((sameStoreProduct) => (
              <ProductCard
                key={sameStoreProduct.id}
                image={sameStoreProduct.mainImage}
                hoverImage={sameStoreProduct.mainImage}
                title={sameStoreProduct.name}
                price={sameStoreProduct.price}
                pricePromotion={sameStoreProduct.originalPrice}
                parcel={sameStoreProduct.paymentCondition}
                selloffPercentage={sameStoreProduct.selloffPercentage}
                profileUrl={sameStoreProduct.sellerProfileUrl}
                isFavorite={sameStoreProduct.isFavorite}
                productId={sameStoreProduct.id}
                productSlug={sameStoreProduct.productSlug}
              />
            ))}
          </Slider>
        </SlickStyled>
        <div className="suggestions">
          <div className="list-products-container">
            <p className="list-products-title">
              Veja mais peças de {product.seller}
            </p>

            <div className="list-products">
              {sameStoreProducts.map((sameStoreProduct) => (
                <ProductCard
                  key={sameStoreProduct.id}
                  image={sameStoreProduct.mainImage}
                  hoverImage={sameStoreProduct.mainImage}
                  title={sameStoreProduct.name}
                  price={sameStoreProduct.price}
                  pricePromotion={sameStoreProduct.originalPrice}
                  parcel={sameStoreProduct.paymentCondition}
                  selloffPercentage={sameStoreProduct.selloffPercentage}
                  profileUrl={sameStoreProduct.sellerProfileUrl}
                  isFavorite={sameStoreProduct.isFavorite}
                  productId={sameStoreProduct.id}
                  productSlug={sameStoreProduct.productSlug}
                />
              ))}
            </div>
          </div>
          <div className="list-products-container">
            <p className="list-products-title">Você pode gostar</p>

            <div className="list-products">
              {recommendedProducts.map((recommendedProduct) => (
                <ProductCard
                  key={recommendedProduct.id}
                  image={recommendedProduct.mainImage}
                  hoverImage={recommendedProduct.mainImage}
                  title={recommendedProduct.name}
                  price={recommendedProduct.price}
                  pricePromotion={recommendedProduct.originalPrice}
                  parcel={recommendedProduct.paymentCondition}
                  selloffPercentage={recommendedProduct.selloffPercentage}
                  profileUrl={recommendedProduct.sellerProfileUrl}
                  isFavorite={recommendedProduct.isFavorite}
                  productId={recommendedProduct.id}
                  productSlug={recommendedProduct.productSlug}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Product;
