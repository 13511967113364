import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {
  SupportTitle,
  SupportTitleBold,
  SupportText,
  SelectStyled,
  SelectBottomGap,
  FormWrapper,
  TextareaWrapper,
  ButtonWrapper,
  RequiredTipContainer
} from './styles';
import Input, { RequiredTip } from '../../../../components/Input'
import useForm from '../../../../hooks/useForm';
import Select from '../../../../components/Select';
import Textarea from '../../../../components/Textarea';
import Button from '../../../../components/Button';
import {api} from '../../../../services/api';
import {useAlert} from '../../../../hooks/useAlert';

interface requestDTO {
  id: number;
  subject: string;
  status: number;
}

interface Subjects {
  id: number;
  name: string;
}

const Form: React.FC = () => {
  const [optionsSelect, setOptionsSelect] = useState<Subjects[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectError, setselectError] = useState<boolean>(false);
  const [textareaError, setTextareaError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const {form, error, onChange, validateForm, onBlur, clearForm} = useForm({
    name: {
      type: 'fullname',
      required: true,
      limit: 100,
    },
    email: {
      type: 'email',
      required: true,
      limit: 100,
    },
    phone: {
      type: 'phone',
      required: true,
      limit: 15,
    },
  });

  const {showAlert} = useAlert();

  const getOptions = useCallback(async () => {
    try {
      const {data: {data}} = await api.get('/institutional-help/subjects');

      const subjects: Subjects[] = [];

      data.map((item: requestDTO) => {
        subjects.push({
          id: item.id,
          name: item.subject
        });

        return item;
      });

      setOptionsSelect(subjects);
    } catch (e: any) {
      console.log(e);
    }
  }, []);


  const handleSubmit = useCallback(async (e: FormEvent) => {
    try {
      e.preventDefault();
      !selectedValue && setselectError(true);
      !message && setTextareaError(true);

      if (!validateForm() || textareaError || selectError) {
        return false;
      }

      await api.post('/institutional-help', {
        name: form.name,
        email: form.email,
        phone: form.phone,
        subject: selectedValue,
        message: message
      });

      showAlert({
        type: 'success',
        description: 'Sua mensagem foi enviada com sucesso! Não se preocupe, iremos responder suas dúvidas em até 48hs.'
      });

      clearForm();
      setMessage('');
      setSelectedValue('');
    } catch (e: any) {
      showAlert({
        type: 'error',
        description: 'Aconteceu um erro ao tentar enviar a mensagem. tente novamente!'
      });
    }
  }, [clearForm, form.email, form.name, form.phone, message, selectError, selectedValue, showAlert, textareaError, validateForm]);

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <SupportTitle>
        <SupportTitleBold>
          Ajuda e Suporte<br/>
        </SupportTitleBold>
        Mande a sua mensagem para a gente, vamos te responder em breve. :)
      </SupportTitle>
      <SupportText>Se preferir, fale com o nosso assistente virtual.</SupportText>
      <FormWrapper onSubmit={handleSubmit}>
        <Input
          title={'Nome'}
          value={form.name}
          onBlur={() => onBlur('name')}
          onChange={(e) => onChange('name', e)}
          error={error.name}
          required
        />
        <Input
          title={'E-mail'}
          value={form.email}
          onBlur={() => onBlur('email')}
          onChange={(e) => onChange('email', e)}
          error={error.email}
          required
        />
        <Input
          title={'Telefone'}
          value={form.phone}
          onBlur={() => onBlur('phone')}
          onChange={(e) => onChange('phone', e)}
          error={error.phone}
          required
        />
        <SelectStyled>
          <Select
            errorMessage='Escolha um assunto que deseja esclarecer'
            label='Assunto'
            disabledOption='-'
            options={optionsSelect}
            required={true}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectError={selectError}
            setselectError={setselectError}
          />
          <SelectBottomGap/>
        </SelectStyled>
        <TextareaWrapper>
          <Textarea
            message={message}
            setMessage={setMessage}
            textareaError={textareaError}
            setTextareaError={setTextareaError}
          />
        </TextareaWrapper>
        <RequiredTipContainer>
          <RequiredTip removePadding/>
        </RequiredTipContainer>
        <ButtonWrapper>
          <Button scale='scale-2' color='var(--purple)' expand>Enviar</Button>
        </ButtonWrapper>
      </FormWrapper>
    </div>
  );
};

export default Form;
