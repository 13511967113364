import {
  CardInterface,
  SetOrderConfigType,
  UseCardsInterface,
} from '../interfaces';
import { useEffect, useRef, useState } from 'react';
import { api } from '../../../services/api';

export const useCards = (
  setOrderConfig: SetOrderConfigType,
): UseCardsInterface => {
  const [cards, setCards] = useState<CardInterface[]>([]);
  const [cardSelected, setCardSelected] = useState<CardInterface>(
    {} as CardInterface,
  );
  const [results, setResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const page = useRef(1);

  const handleSelectCard = (card: CardInterface) => {
    setCardSelected(card);
    setOrderConfig({ cardId: card.id });
  };

  const getCards = () => {
    api
      .get(`/payments/cards?limit=6&page=${page.current}`)
      .then(({ data }) => {
        const mainCard = data.cards.filter(
          (card: CardInterface) => card.main === 1,
        );
        if (mainCard.length > 0) {
          setCardSelected(mainCard[0]);
          setOrderConfig({ cardId: mainCard[0].id });
        }

        setCards((prevState) => [...prevState, ...data.cards]);
        setTotalPages(data.totalPages);
        setResults(data.results);
        page.current = page.current + 1;
      })
      .catch();
  };

  useEffect(() => {
    getCards();

    //eslint-disable-next-line
  }, []);

  return {
    cards,
    results,
    totalPages,
    getCards,
    cardSelected,
    setCardSelected: handleSelectCard,
  };
};
