import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import Input from '../Input';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  position: relative;
  background-color: var(--white);
  outline: none;
  padding: 48px 106px;
  border-radius: 16px;
  max-width: 890px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    padding: 41px 54px;
    max-width: 635px;
    border-radius: 10px;
  }
  @media screen and (max-width: 607px) {
    padding: 88px 24px 56px;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    border-radius: 0px;
    overflow-y: auto;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  i {
    color: var(--grey-light-1);
    font-size: 20px;
  }
  @media screen and (max-width: 607px) {
    display: none;
  }
`;

export const CloseModalMobile = styled.div`
  display: none;
  position: absolute;
  top: 21px;
  left: 24px;
  cursor: pointer;
  > i {
    color: var(--purple);
    font-size: 18px;
  }
  @media screen and (max-width: 607px) {
    display: flex;
    align-items: center;
    column-gap: 14px;
  }
`;

export const ComeBack = styled.span`
  font-size: 16px;
  color: var(--black);
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: var(--black);
  @media screen and (max-width: 607px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const PaymentMethods = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: center;
  column-gap: 100px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    column-gap: 37px;
  }
  @media screen and (max-width: 607px) {
    column-gap: 15px;
  }
`;

export const Method = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 18px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    column-gap: 12px;
  }
  @media screen and (max-width: 607px) {
    column-gap: 8px;
  }
`;

export const RadioWrapper = styled.div`
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 16px;
    height: 20px;
    padding: 0px;
    span {
      width: inherit;
      height: 16px;
      padding: inherit;
      svg {
        width: inherit;
        height: inherit;
        padding: inherit; 
      }
    }
  }
`;

export const PaymentTitle = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
  color: var(--grey-medium-2);
  &.creditCardActive {
    color: var(--purple);
  }
  &.bankSlipActive {
    color: var(--purple);
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 607px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const PaymentMethodIcon = styled.i`
  color: var(--grey-medium-2);
  font-size: 24px;
  &.creditCardActive {
    color: var(--purple);
  }
  &.bankSlipActive {
    color: var(--purple);
  }
  @media screen and (max-width: 607px) {
    font-size: 20px;
  }
`;

export const FormTitle = styled.h2`
  font-size: 30px;
  line-height: 34px;
  margin-top: 39px;
  color: var(--black);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 24px;
  }
  @media screen and (max-width: 607px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 622px;
  margin: 0 auto;
  column-gap: 34px;
  row-gap: 18px;
  margin-top: 30px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    column-gap: 24px;
    row-gap: 20px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
    column-gap: 16px;
  }
`;

export const InputStyled = styled(Input)`
  width: 294px;
  &.validity {
    width: 100px;
  }
  &.securityCode {
    width: 176px;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 224px;
    &.cardNumber{
      width: 323px;
    }
    &.validity {
      position: relative;
      left: 15px;
      width: 110px;
    }
    &.securityCode {
      width: 162px;
    }
  }
  @media screen and (max-width: 607px) {
    width: 100%;
    &.validity {
      width: calc(40% - 16px);
    }
    &.securityCode {
      right: 0;
      width: 60%;
    }
  }
`;

// O width do CardNumber tem que ser o mesmo do .cardNumber
export const CardNumber = styled.div`
  position: relative;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 323px;
  }
  @media screen and (max-width: 607px) {
    width: 100%;
  }
`;

export const CardImg = styled.div`
  position: absolute;
  top: 20%;
  right: 0;
`;

export const CardBanner = styled.img`
  max-width: 34px;
`;

export const CardIcon = styled.i`
  color: var(--grey-light-1);
  font-size: 24px;
`;

export const SelectWrapper = styled.div`
  margin-bottom: 17px;
  width: 293px;
  select {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--grey-dark);
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    position: relative;
    left: 24px;
    width: 262px;
  }
  @media screen and (max-width: 607px) {
    width: 100%;
  }
`;

export const CheckboxWrapper = styled.div`
  label {
    font-size: 14px;
  }
  @media screen and (min-width: 1279px) {
    &.saveCard {
      margin-bottom: 17px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 169px;
  margin: 38px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 180px;
    margin: 93px auto 0;
  }
  @media screen and (max-width: 607px) {
    margin: 54px auto 0;
  }
`;

export const TooltipText = styled.p`
  min-width: 150px;
  max-width: 201px;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  color: var(--grey);
`;