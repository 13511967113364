import React, {useState, useRef} from 'react';
import { 
  CardsWrapper, 
  ButtonsWrapper, 
  LoadCardsButton,
  ChangePaymentMethodButton,
  CreditCardIcon,
  NewCardTitle,
} from './styles';
import {
  Title,
  Form, 
  FormWrapper,
  InputStyled,
  CardNumber,
  CardImg,
  CardBanner,
  CardIcon,
  SelectWrapper,
  CheckboxWrapper,
} from '../styles';
import { TooltipText } from '../styles';
import PaymentSuccess from '../components/PaymentSuccess';
import PaymentError from '../components/PaymentError';
import LoadingValidating from '../components/LoadingValidating';
import AMEX from '../../../assets/svg/AMEX.svg';
import ELO from '../../../assets/svg/ELO.svg';
import HIPERCARD from '../../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../../assets/svg/MASTERCARD.svg';
import VISA from '../../../assets/svg/VISA.svg';
import useForm from '../../../hooks/useForm';
import {cardTypesInterface} from '../../../interfaces/creditCard';
import {MoipValidator} from 'moip-sdk-js';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import RadioButtonsGroup from '../../Radio';
import Checkbox from '../../Checkbox';
import Select from '../../Select';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  '@global': {
    // Você deve direcionar [class*="MuiButton-root"] em vez de aninhar temas.
    '.MuiAccordion-root': {
      borderRadius: 8,
      marginTop: 20,
      marginBottom: 20,
    },
    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      color: '#909090'
    },
    '.MuiAccordionSummary-root': {
      padding: '5px 24px',
    },
    '.MuiAccordionSummary-content .icon':{
      fontSize: 30,
      margin: '0 15px'
    },
    '.MuiAccordionSummary-content h3':{
      margin: '0 25px'
    }
  },
  root: {
    overflow: 'hidden',
    marginBottom: -1,
    minHeight: 56,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: 8,
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    '@global': {
      '.MuiAccordionSummary-content': {
        alignItems: 'center',
        color: 'var(--purple)'
      },
    }
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


interface Card{
  id?: number | null | undefined;
  banner: { img: string; alt: string };
  cardHolder: string;
  cardName: string;
  cardNumbers: string;
}

interface OwnProps {
  onClose: () => void;
}

const CreditCards: React.FC<OwnProps> = ({onClose}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<'' | 'error' | 'success'>('');
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [cardSelected, setCardSelected] = useState<Card>({
    id: undefined,
    banner: {
      img:
        'https://logodownload.org/wp-content/uploads/2014/07/mastercard-logo-1-1.png',
      alt: 'MasterCard',
    },
    cardHolder: 'Renata M Silva',
    cardName: '1 MasterCard',
    cardNumbers: '111-1111-111-1006',
  });

  const [cardType, setCardType] = useState<string | null>(null);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [autoFill, setAutoFill] = useState(false);

  const {form, onChange, onBlur, validateForm, error} = useForm({
    holder: {
      required: true,
      type: 'fullname'
    },
    cpf: {
      required: true,
      type: 'cpf',
      limit: 14
    },
    birthDate: {
      required: true,
      type: 'date',
      limit: 10,
    },
    phone: {
      required: true,
      type: 'phone',
      limit: 15
    },
    cardNumber: {
      required: true,
      type: 'cardNumber',
      limit: 19
    },
    expirationDate: {
      required: true,
      type: 'cardDate'
    },
    securityCode: {
      required: true,
      type: 'cardSecurityCode',
      limit: 4
    }
  });

  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    HIPERCARD,
    MASTERCARD,
    VISA
  }).current;

  type EmptyObject = { [K in string] : never};
  const handleChange = (panel: string) => (event: React.ChangeEvent<EmptyObject>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const newCard = () => {
    setCardSelected({
      id: null,
      banner: {
        img:
          '',
        alt: '',
      },
      cardHolder: '',
      cardName: '',
      cardNumbers: '',
    });
  };

  const getCardType = (cardNumber: string) => {
    cardNumber = cardNumber.replace(/\D/g, '');
    const type = MoipValidator.cardType(cardNumber);

    setCardType(type ? type.brand : null);
  };


  const changePaymentMethod = () => {
    if(!cardSelected) return;
    
    if(cardSelected.id === null) {
      const isFormValid = validateForm();
      if(!isFormValid) return;
    }

    if(cardSelected.id === undefined) {
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setPaymentStatus('success');
      setLoading(false);
    }, 2200);
  }

  if(paymentStatus === 'error' && !loading) return (
    <PaymentError setPaymentStatus={setPaymentStatus} />
  )
  if(paymentStatus === 'success' && !loading) return (
    <PaymentSuccess onClose={onClose} />
  )
  if(loading) return <LoadingValidating />

  return (
    <>
      <CardsWrapper>
        <Accordion square expanded={expanded === 'panel1'} onClick={newCard} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className={'radio'}>
              <RadioButtonsGroup checked={expanded === 'panel1'} label={''}/>
            </div>
            <CreditCardIcon className="icon icon-credit-card"/>
            <NewCardTitle>Pagar com um novo cartão</NewCardTitle>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Title>Insira os dados do cartão</Title>
              <Form>
                <CheckboxWrapper>
                  <Checkbox 
                    checked={autoFill} 
                    onChange={(checked: boolean) => setAutoFill(checked)}
                  >
                    Preencher meus dados de cadastro automaticamente.
                  </Checkbox>
                </CheckboxWrapper>
                <FormWrapper>
                  <InputStyled 
                    value={form.holder} 
                    error={error.holder} 
                    onChange={(e) => 
                    onChange('holder', e)} 
                    onBlur={() => onBlur('holder')} 
                    className={''} 
                    title={'Nome do Titular'} 
                    placeholder={'Nome impresso no cartão'} 
                    required
                  />
                  <InputStyled
                    title={"CPF do Titular"}
                    value={form.cpf}
                    onBlur={() => onBlur("cpf")}
                    onChange={(e) => onChange("cpf", e)}
                    error={error.cpf}
                    required
                    placeholder={"Digite apenas números"}
                    tooltipContent={
                      <TooltipText>
                        Insira aqui o CPF do titular do cartão. Este 
                        dado é solicitado para segurança da transação.
                      </TooltipText>
                    }
                  />
                  <InputStyled
                    title={"Data de Nascimento"}
                    value={form.birthDate}
                    onBlur={() => onBlur("birthDate")}
                    onChange={(e) => onChange("birthDate", e)}
                    error={error.birthDate}
                    required
                    placeholder={"DD/MM/AAA"}
                    tooltipContent={
                      <TooltipText>
                        Insira aqui a data de nascimento do titular do cartão. 
                        Este dado é solicitado para segurança da transação.
                      </TooltipText>
                    }
                  />
                  <InputStyled
                    title={"Telefone"}
                    value={form.phone}
                    onBlur={() => onBlur("phone")}
                    onChange={(e) => onChange("phone", e)}
                    error={error.phone}
                    type={"phone"}
                    required
                    placeholder={"Digite o número com DDD"}  
                  />
                  <CardNumber>
                    <InputStyled 
                      value={form.cardNumber} 
                      error={error.cardNumber} 
                      className={'cardNumber'} 
                      title={'Número do Cartão'} 
                      placeholder={'1234 1234 1234 1234'} 
                      onChange={(e) => {
                        getCardType(e.target.value);
                        onChange('cardNumber', e);
                      }} 
                      onBlur={() => {
                        onBlur('cardNumber');
                      }} 
                      required
                    />
                    <CardImg className='icon-area'>
                      {cardType ? (
                        <CardBanner src={cardTypes[cardType]} alt="bandeira do cartão"/>
                      ) : (
                        <CardIcon className={'icon icon-credit-card'}/>
                      )}
                    </CardImg>
                  </CardNumber>

                  <InputStyled 
                    value={form.expirationDate} 
                    error={error.expirationDate} 
                    onChange={(e) => onChange('expirationDate', e)} 
                    onBlur={() => onBlur('expirationDate')} 
                    className={'validity'} 
                    title={'Validade'} 
                    placeholder={'MMAA'} required
                  />

                  <InputStyled
                    value={form.securityCode}
                    error={error.securityCode}
                    onChange={(e) => onChange('securityCode', e)}
                    onBlur={() => onBlur('securityCode')}
                    className={'securityCode'}
                    title={'Código de Segurança'}
                    placeholder='Digite apenas números'
                    required
                  />
                  <SelectWrapper>
                    <Select 
                      label={'Número de parcelas'}
                      required={true}
                      options={['1x de R$ 100,00  Sem juros', 'sdsd']}
                      disabledOption='selecione uma opção'
                      selectError={selectError}
                      setselectError={setSelectError}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                    />
                  </SelectWrapper>
                </FormWrapper>
              </Form>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* AQUI VAI O COMPONENTE selectCard */}


        {/* AQUI VAI O COMPONENTE selectCard */}

      </CardsWrapper>
      <ButtonsWrapper>
        <LoadCardsButton link='b2c' scale='scale-3'>Ver mais cartões</LoadCardsButton>
        <ChangePaymentMethodButton 
          onClick={changePaymentMethod}
          color='var(--purple)' 
          scale='scale-3'
        >
          Alterar pagamento
        </ChangePaymentMethodButton>
      </ButtonsWrapper>
    </>
    );
}

export default CreditCards;