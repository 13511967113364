import styled from 'styled-components';

export const Container = styled.section`

`;

export const Content = styled.div`
  display: flex;

  > div {
    flex: 1
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const Items = styled.div`
  margin-bottom: 27px;

  &:last-child {
    margin-bottom: 0;
  }
`;


export const TipsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 844px;
  margin: 72px auto 100px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;

    &:nth-child(1) {
      > svg {
        margin-bottom: 35.53px;
      }
    }

    &:nth-child(2) {
      > svg {
        margin-bottom: 17.05px;
      }
    }

    &:nth-child(3) {
      > svg {
        margin-bottom: 30.6px;
      }
    }

    @media (max-width: 1279px) {
      max-width: 169px;
      justify-content: flex-start;

      &:nth-child(1) {
        > svg {
          width: 28.82px;
          height: 36px;
          margin-bottom: 22px;
        }
      }

      &:nth-child(2) {
        > svg {
          width: 54px;
          height: 49px;
          margin-bottom: 15.99px;
        }
      }

      &:nth-child(3) {
        > svg {
          width: 28.8px;
          height: 36px;
          margin-bottom: 32px;
        }
      }

      > p {
        text-align: center;
        font-size: 14px;
      }
    }

    @media (max-width: 607px) {
      max-width: 97px;
      width: 100%;

      &:nth-child(1) {
        > svg {
          width: 37px;
          height: 33.58px;
          margin-bottom: 6.42px;
        }
      }

      &:nth-child(2) {
        > svg {
          width: 37px;
          height: 33.58px;
          margin-bottom: 6.42px;
        }
      }

      &:nth-child(3) {
        > svg {
          width: 18px;
          height: 22.5px;
          margin-bottom: 17.5px;
        }

      }

      > p {
        font-size: 10px;
      }
    }

`;


export const SuggestionsContainer = styled.div`
  max-width: 1054px;
  margin: 0 auto;

  > h4 {
    font-size: 18px;
  }

  > a {
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
    font-weight: 500;
    color: var(--grey-dark);
  }

  @media (max-width: 607px) {
    display: none;
  }
`;

export const GridProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 65px;

  margin: 24px 0 48px;

  @media (max-width: 1279px) {
    column-gap: 18.02px;
  }
`;
