import styled from 'styled-components';

export const Container = styled.div` 
  max-width: 740px;
  margin: 59px auto 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 131px;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
    row-gap: 80px;
    margin: 56px auto 0;
  }
  @media screen and (max-width: 607px) {
    row-gap: 64px;
    margin: 32px auto 0;
  }
`;