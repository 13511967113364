import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--grey-light-1);
  border-radius: 8px;
  background-color: var(--white);
  padding: 15px 0;
  display: none;
  z-index: 5;
  &.open {
    display: block;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 5px 24px;
  cursor: pointer;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    gap: 40px;
  }
  @media screen and (max-width: 607px) {
    gap: 30px;
  }
`;

export const OptionImgWrapper = styled.div`
  max-width: 22px;
`;

export const LeftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const OptionCardName = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: var(--grey-dark);
  @media screen and (max-width: 607px) {
    font-size: 12px;
  }
`;

export const OptionCardNumber = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: var(--grey-dark);
  @media screen and (max-width: 607px) {
    font-size: 10px;
  }
`;

export const SelectedCardWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--white);
  width: 100%;

  .cardholder{
    display: none;
    flex: 1;
    text-align: right;

    @media(min-width: 37.9375em){
      display: block;
    }
  }

  .cardholder-desc{
    margin-top: 5px;
    font-size: 14px;
    color: #909090;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 47px;
`;

export const CardName = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: var(--grey-dark);
  line-height: 20px;
  @media screen and (max-width: 607px) {
    font-size: 14px
  }
`;

export const CardNumber = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: var(--grey-dark);
  line-height: 20px;
  @media screen and (max-width: 607px) {
    font-size: 12px;
  }
`;

export const ArrowIcon = styled.i`
  color: var(--purple);
  position: absolute;
  top: 40%;
  right: 30px;
  font-size: 14px;
  cursor: pointer;
`;

export const MoreCards = styled.span`
  font-size: 20px;
  display: block;
  text-align: center;
  color: var(--purple);
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
`;

export const SelectWrapper = styled.div`
  max-width: 250px;
  @media screen and (max-width: 607px) {
    max-width: 100%;
  }
`;