import styled from 'styled-components';
import Button from '../../Button';

export const LineBreakMobile = styled.br`
  display: none;
  @media screen and (max-width: 607px) {
    display: block;
  }
`;

export const Container = styled.div`
  margin-top: 68px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 103px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 77px;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 34px;
  color: var(--black);
  font-weight: 700;
  @media screen and (max-width: 607px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const ChangePaymentMethodText = styled.p`
  margin: 12px 0;
  font-size: 16px;
  line-height: 18.75px;
  color: var(--grey-medium-2);
  @media screen and (max-width: 607px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Total = styled.p`
  font-size: 30px;
  line-height: 34px;
  color: var(--black);
  font-weight: 700;
  @media screen and (max-width: 607px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const BarCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 33.58px;
  margin-top: 59px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 48px;
    column-gap: 22px;
  }
  @media screen and (max-width: 607px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 36px;
  }
`;

export const BarCode = styled.p`
  font-size: 18px;
  text-decoration: underline;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 14px;
  }
  @media screen and (max-width: 607px) {
    text-decoration: none;
    font-weight: bold;
    word-break: break-all;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CopyBarCode = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  cursor: pointer;
  > i {
    display: none;
    color: var(--purple);
  }
  @media screen and (max-width: 607px) {
    > i {
      display: block;
    }
  }
`;

export const Copy = styled.p`
  font-size: 16px;
  text-decoration: underline;
  color: var(--purple);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 12px;
  }
  @media screen and (max-width: 607px) {
    font-size: 14px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 180px;
  margin: 77px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 82px auto 0;
  }
  @media screen and (max-width: 607px) {
    margin: 68px auto 0;
    max-width: 123px;
  }
`;

export const PrintOutButton = styled(Button)`
  border: 1px solid var(--purple);
  max-width: 186px;
  margin: 32px auto 0;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 50px auto 0;
  }
  @media screen and (max-width: 607px) {
    margin: 56px auto 0;
  }
`;

export const ImpotantMessageWrapper = styled.div`
  background-color: var(--grey-light-3);
  margin-top: 24px;
  padding: 27px 39px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 50px;
    padding: 27px 23px;
  }
`;

export const ImportantMessage = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-medium-4);
  span {
    color: var(--red-light);
  }
`;

export const Loading = styled.div`
  margin-top: 32px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 49px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 68px;
  }
`;

export const LoadingMessage = styled.p`
  font-family: var(--poppins);
  color: var(--purple);
  text-align: center;
  margin-top: 22px;
  @media screen and (max-width: 607px) {
    margin-top: 16px;
  }
`;