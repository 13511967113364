import styled from 'styled-components';

export const Container = styled.section`
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 72px 0 219px;

  @media(max-width: 1279px){
    padding: 39px 0 150px;
  }

  @media(max-width: 607px){
    padding: 16px 0 55px;
  }
`;

export const SummaryContainer = styled.div`
  max-width: 306px;
  margin-left: 17px;

  @media(max-width: 1279px){
    max-width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
`;



export const SafetyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  > p {
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
    color: var(--grey-dark);
    margin-left: 13px;
  }

  @media(max-width: 1279px){
    flex-direction: column;

    > p {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

export const StepsContainer = styled.div`
  max-width: 829px;
  margin: 0 auto 105px;

  @media(max-width: 1279px){
    max-width: 447px;
    margin: 0 auto 62px;
  }

  @media(max-width: 607px){
    max-width: 212px;
    margin: 0 auto 39px;
  }
`;
