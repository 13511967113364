import React from 'react';
import { Container, Wrapper, Line, Circle, Icon, StepText } from './styles';

interface Props {
  active: number;
}

const CheckoutStepBar: React.FC<Props> = ({active}) => {

  return (
    <Container>
      <Line />
      <Wrapper>
        <Circle className={active > 0 ? 'active' : ''}>
          <Icon className={`icon icon-cart ${active > 0 ? 'active' : ''}`} />
          <StepText>Carrinho</StepText>
        </Circle>
        <Circle className={active > 1 ? 'active' : ''}>
          <Icon className={`icon icon-truck ${active > 1 ? 'active' : ''}`} />
          <StepText>Endereço e frete</StepText>
        </Circle>
        <Circle className={active > 2 ? 'active' : ''}>
          <Icon className={`icon icon-cipher ${active > 2 ? 'active' : ''}`} />
          <StepText>Pagamento</StepText>
        </Circle>
        <Circle className={`${active > 3 ? 'active' : ''} lastStep`}>
          <Icon className={`icon icon-success ${active > 3 ? 'active' : ''} lastStep`} />
          <StepText>Confirmação</StepText>
        </Circle>
      </Wrapper>
    </Container>
  );
};

export default CheckoutStepBar;
