import React, { useCallback, useContext } from 'react';
import {
  Container,
  Content,
  Divider,
  AbstractRow,
  SmallAbstractText,
  TotalText,
  ButtonContainer,
  ButtonContent,
  Abstract,
  TotalContent,
} from './styles';
import Button from '../../../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../..';
import { useAuth } from '../../../../hooks/useAuth';

import { removeString } from './../../../../helpers/formatNumber';

interface Props {
  onClick: () => void;
  cart?: boolean;
  text?: string;
  className?: string;
  nextPage?: string;
}

const OrderSummary: React.FC<Props> = ({
  onClick,
  cart,
  text,
  className,
  nextPage,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    cartProducts: { cartResume },
  } = useContext(CartContext);

  const handleClick = useCallback(() => {
    if (!user) {
      const page = nextPage ? nextPage : '/carrinho/enderecos';
      navigate(`/login?ReturnUrl=${page}`);
      return;
    }

    onClick();
  }, [navigate, nextPage, onClick, user]);

  function isNotZero(value: string) {
    return removeString(value) !== 0
  }

  return (
    <Container className={className}>
      <h3>Resumo do pedido</h3>

      <Content>
        <Divider />

        <Abstract>
          
          {isNotZero(cartResume.discount.toString()) &&
            <AbstractRow>
              <SmallAbstractText className={'ref'}>
                VOCÊ ECONOMIZOU
              </SmallAbstractText>
              <SmallAbstractText className={'neutral'}>
                {cartResume.discount}
              </SmallAbstractText>
            </AbstractRow>
          }

          <AbstractRow>
            <SmallAbstractText className={'ref'}>FRETE</SmallAbstractText>
            <SmallAbstractText>{cartResume.freight}</SmallAbstractText>
          </AbstractRow>

          <AbstractRow className={'total'}>
            <TotalContent>
              <TotalText>Total</TotalText>
            </TotalContent>
            <TotalText className={'value'}>
              {cartResume.totalWithDiscount}
            </TotalText>
          </AbstractRow>
        </Abstract>
      </Content>
      <ButtonContainer>
        <ButtonContent>
          <Button onClick={handleClick} expand scale={'scale-3'}>
            {text ?? 'Finalizar compra'}
          </Button>
          {cart && (
            <Button
              onClick={() => navigate('/pesquisa')}
              expand
              outline
              scale={'scale-3'}
            >
              Continuar comprando
            </Button>
          )}
        </ButtonContent>
        <Link to="/politicas-privacidade" target="_blank">
          Política de Privacidade
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default OrderSummary;
