import styled from 'styled-components';

export const NeedHelp = styled.div` 
  max-width: 648px;
  padding: 56px 24px;
  box-shadow: 0 12px 24px 0 #E5E5E5;
  @media screen and (max-width: 1279px) {
    padding: 24px;
    max-width: 100%;
  }

`;

export const NeedHelpTitle = styled.h3` 
  font-family: var(--poppins);
  color: var(--purple);
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  max-width: 489px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 18px;
    line-height: 20px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 270px;
  }
`;

export const NeedHelpText = styled.p` 
  color: var(--grey-medium-1);
  font-size: 18px;
  line-height: 30px;
  margin-top: 24px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ContrastPurple = styled.span` 
  font-size: 18px;
  color: var(--purple);
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const EmailLink = styled.a`
  text-decoration: none;
  color: var(--purple);
  font-size: 18px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Email = styled.p` 
  color: var(--purple);
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 23px;
  @media screen and (max-width: 1279px) {
    gap: 12px;
    font-size: 20px;
  }
  @media screen and (max-width: 607px) {
    align-self: center;
  }
`;

export const EmailImgWrapper = styled.span`
  position: relative;
  top: 1px;
  width: 32px;
  height: 23px;
  @media screen and (max-width: 1279px) {
    width: 22px;
    height: 16px;
  }
`;

export const CustomerService = styled.div`
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  row-gap: 57px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    row-gap: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media screen and (max-width: 607px) {
    row-gap: 32px;
  }
`;

export const OpeningHoursTitle = styled.p` 
  font-size: 18px;
  color: var(--purple);
  font-weight: 700;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    gap: 5px;
    line-height: 20px;
  }
`;

export const OpeningHoursText = styled(OpeningHoursTitle)` 
  font-weight: 400;
  color: var(--grey-medium-1);
`;

export const TelephoneImgWrapper = styled.span` 
  width: 25px;
  height: 28px;
  @media screen and (max-width: 1279px) {
    width: 8px;
    height: 9px;
  }
`;