import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: var(--grey-dark);
  text-align: center;
  margin-top: 36px;
  @media screen and (max-width: 607px) {
    margin-top: 28px;
    max-width: 290px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 38px;
  margin: 44px 0;
  @media screen and (max-width: 607px) {
    row-gap: 32px;
    margin: 32px 0;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: var(--grey-medium-2);
  text-align: center;
  max-width: 424px;
  @media screen and (max-width: 607px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 278px;
  }
`;