import { BrandCartInterface } from '../../../interfaces/orderProduct';
import { useEffect, useState } from 'react';
import { api } from '../../../services/api';
import { UseBrandsWithProductsInterface } from '../interfaces';

export const useBrandWithProducts = (): UseBrandsWithProductsInterface => {
  const [brands, setBrands] = useState<BrandCartInterface[]>([]);

  const getBrands = async () => {
    try {
      const { data } = await api.get('/carts/brands/products');

      setBrands(data);
    } catch (e: any) {}
  };

  useEffect(() => {
    getBrands().then();
  }, []);

  return { brands, getBrands };
};
