import {api_node} from '../services/api';
import {moneyToNumber, numberToMoney} from '../helpers/formatNumber';
import {useState} from 'react';

interface IUseShipping {
  shipping: string;
  calculateShipping: ({companyId, zipCode, weight}: ParamsCalculateFreight) => Promise<void>;
  calculateAllBrands: (data: Omit<ParamsCalculateFreight, 'zipCode'>[], zipCode: string) => Promise<ReturnCalcBrands>;
}

interface ParamsCalculateFreight {
  companyId: number;
  zipCode: string | undefined;
  weight: number | undefined;
  packVolume: number | undefined;
}

interface ReturnCalcBrands {
  status: boolean;
  freights: Array<IFreights>;
  total: string;
}

interface Freights {
  id: number;
  name: string;
  price: string;
  deadline: number;
  freightId: number;
}

export interface IFreights {
  supplier: number,
  amount: number
}

export interface ITotalPrice {
  price: string,
}


export const useShipping = (): IUseShipping => {
  const [shipping, setShipping] = useState('');

  const accumulateFreightAllBrands = (freights: Array<IFreights>) => {
    return freights.reduce((acc, current) => {
      if (Object.keys(acc).length === 0) {
        acc.price = numberToMoney(current.amount);
        return acc;
      }

      acc.price = numberToMoney(
        moneyToNumber(acc.price) + current.amount
      );

      return acc;
    }, {} as ITotalPrice);
  };

  const formatAllShipping = (freights: Freights[], id: number) => {
    return freights.reduce((acc, item) => {
      if (Object.keys(acc).length === 0) {
        acc.supplier = id;
        if (item.id == 5) {
          acc.amount = moneyToNumber(item.price);
        }else {
          acc.amount = 0.00;
        }

        return acc;
      }

      if (item.id == 5) {
        acc.amount = acc.amount + moneyToNumber(item.price);
      }

      return acc;
    }, {} as IFreights);

  };

  const calculateAllBrands = async (data: Omit<ParamsCalculateFreight, 'zipCode'>[], zipCode: string): Promise<ReturnCalcBrands> => {
    try {
      const freightsAllBrands: Array<IFreights> = [];

      for (const company of data) {
        const {companyId, weight, packVolume} = company;

        const {status, freights} = await calculateShipping({
          companyId,
          weight,
          zipCode,
          packVolume
        });

        if (status) {
          const formatFreight = formatAllShipping(freights, companyId);
          freightsAllBrands.push(formatFreight);
        }

      }

      const freightsAccumulated = accumulateFreightAllBrands(freightsAllBrands);

      setShipping(freightsAccumulated.price);

      return {
        status: true,
        freights: freightsAllBrands,
        total: freightsAccumulated.price
      };

    } catch (e: any) {
      return {
        status: false,
        freights: [],
        total: ''
      };
    }
  };

  const calculateShipping = async ({companyId, zipCode, weight, packVolume}: ParamsCalculateFreight) => {
    try {
      const {data} = await api_node.post('api-portal-postal/freight/calculate-shipping', {
        companyId,
        zipcodeDestination: zipCode,
        orderWeight: weight,
        packVolume
      });

      return data;
    } catch (e: any) {
      console.log(e);
    }
  };

  return {calculateShipping, calculateAllBrands, shipping};
};
