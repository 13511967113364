import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Container,
  ProductsWrapper,
  Title,
  SeparateItems,
  ProductsQuantity,
  ProductsQuantityNumber,
  SelectWrapper,
  ProductCardWrapper,
  IconCircle,
  CloseIcon,
  Unavailable,
  UnavailableMessageWrapper,
  UnavailableMessage,
  UnavailableRedMessage,
  NoProducts,
  Hello,
  Message,
  CallToAction,
  Location,
} from './styles';
import ProductCard from '../../components/ProductCard';
import Select from '../../components/Select';
import {Link} from 'react-router-dom';
import AlertModal from '../../components/AlertModal';
import ScrollToTop from '../../components/ScrollToTop';
import Pagination from '../../components/Pagination';
import {api} from '../../services/api';
import {useQuery} from '../../hooks/useQuery';
import {useToast} from '../../hooks/useToast';
import {useLoading} from '../../hooks/useLoading';

const Favorites: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const [products, setProducts] = useState<Array<{
    id: number;
    favoriteId: number;
    brand: string;
    name: string;
    price: string;
    promotionPrice?: string;
    originalPrice?: string;
    productSlug: string;
    selloffPercentage?: number;
    sellerProfileUrl: string;
    img: string;
    available: number;
  }>>([]);

  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState();

  const order = useRef('');
  const page = useRef<number>(1);
  const {toast} = useToast();
  const {showLoading, closeLoading} = useLoading();

  const executeScroll = useCallback(
    () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    [],
  );

  useEffect(() => {
    setTotalPages(totalPages);
  }, [selectedValue, products, totalPages]);

  const nextPage = useCallback(async () => {
    if (page.current === totalPages) {
      return false;
    }
    page.current = page.current + 1;

    navigate(`?page=${page.current}&order=${order.current}`);
  }, [navigate, totalPages]);

  const previousPage = useCallback(async () => {
    if (page.current === 1) {
      return false;
    }
    page.current = page.current - 1;

    navigate(`?page=${page.current}&order=${order.current}`);
  }, [navigate]);

  const removeProductStart = (event: React.MouseEvent<HTMLElement>) => {
    setId(Number(event.currentTarget.id));
    setModal(true);
  };

  const removeProductEnd = () => {
    removeFavorite(id);
    const removeItem = products.filter((product) => product.favoriteId !== id);
    setProducts(removeItem);
    setModal(false);
  };

  const getFavoriteProducts = useCallback(async () => {
    try {
      showLoading();
      const {data} = await api.get(`/favorites-products?page=${page.current}&order=${order.current}`);

      setProducts(data.products);
      setTotalProducts(data.results);
      setTotalPages(data.pages);
    } catch (err: any) {
      console.log(err);
    } finally {
      closeLoading();
      executeScroll();
    }
  }, [closeLoading, executeScroll, showLoading]);

  const removeFavorite = useCallback(async (favoriteId) => {
    try {
      await api.delete(`/favorites-products/${favoriteId}`);
      toast({
        type: 'success',
        description: 'Produto removido com sucesso!'
      });
      await getFavoriteProducts();
    } catch (err: any) {
      toast({
        type: 'error',
        description: err.message
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const orderParam = query.get('order');
    const pageParam = query.get('page');
    if (orderParam !== null) {
      order.current = orderParam;
    }

    if (pageParam !== null) {
      page.current = Number(pageParam);
    }

    getFavoriteProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('page'), query.get('order')]);

  useEffect(() => {
    if (selectedValue.length === 0) {
      return;
    }

    order.current = selectedValue;

    getFavoriteProducts().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <>
      <AlertModal
        open={modal}
        type="error"
        description="Deseja mesmo excluir esse item dos seus favoritos?"
        submitText="Excluir"
        cancelText="Cancelar"
        onSubmit={removeProductEnd}
        close={() => setModal(false)}
      />
      <Container>
        <Location>
          <Link to="/">Início</Link>
          {'/ '}
          <Link to="/favoritos">Meus favoritos</Link>
        </Location>
        <div>
          <Title>Meus Favoritos</Title>
          <SeparateItems>
            <ProductsQuantity>
              <ProductsQuantityNumber>
                {totalProducts}
              </ProductsQuantityNumber>
              {products.length === 1
                ? 'item na sua lista de favoritos'
                : 'itens na sua lista de favoritos'}
            </ProductsQuantity>
            <SelectWrapper>
              <Select
                disabledOption="Ordenar por"
                options={[
                  {
                    id: 'release',
                    name: 'Lançamento'
                  },
                  {
                    id: 'popularity',
                    name: 'Mais populares'
                  },
                  {
                    id: 'biggerDiscount',
                    name: 'Maior desconto'
                  },
                  {
                    id: 'smallerDiscount',
                    name: 'Menor desconto'
                  },
                  {
                    id: 'biggerPrice',
                    name: 'Maior preço'
                  },
                  {
                    id: 'smallerPrice',
                    name: 'Menor preço'
                  },
                  {
                    id: 'AZ',
                    name: 'A-Z'
                  },
                ]}
                selectError={selectError}
                setselectError={setSelectError}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                required={false}
              />
            </SelectWrapper>
          </SeparateItems>
          {products.length > 0 ? (
            <>
              <ProductsWrapper>
                {products.map((product, index) => (
                  <ProductCardWrapper key={index}>
                    <IconCircle
                      onClick={removeProductStart}
                      id={`${product.favoriteId}`}
                    >
                      <CloseIcon className="icon icon-exit"/>
                    </IconCircle>
                    <Unavailable
                      className={product.available === 0 ? 'unavailable' : ''}
                    >
                      <UnavailableMessageWrapper
                        className={product.available === 0 ? 'unavailable' : ''}
                      >
                        <UnavailableMessage>Indisponívell</UnavailableMessage>
                      </UnavailableMessageWrapper>
                      <ProductCard
                        key={product.id}
                        productId={product.id}
                        image={product.img}
                        hoverImage={product.img}
                        title={product.name}
                        price={product.price}
                        pricePromotion={product.originalPrice}
                        selloffPercentage={product.selloffPercentage}
                        profileUrl={product.sellerProfileUrl}
                        productSlug={product.productSlug}
                        brandName={product.brand}
                      />
                    </Unavailable>
                    <UnavailableRedMessage
                      className={product.available === 0 ? 'unavailable' : ''}
                    >
                      Produto esgotado
                    </UnavailableRedMessage>
                  </ProductCardWrapper>
                ))}
              </ProductsWrapper>
              {totalPages > 1 &&
              <Pagination
                index={
                  page.current <= 9
                    ? `0${page.current.toString()}`
                    : page.current.toString()
                }
                max={totalPages.toString()}
                onClick={(direction: string) =>
                  direction === 'next' ? nextPage() : previousPage()
                }
              />
              }
            </>
          ) : (
            <NoProducts>
              <Hello>Olá!</Hello>
              <Message>
                Você ainda não possui itens na lista de favoritos!
              </Message>
              <CallToAction>
                Que tal navegar e adicionar peças para sua próxima compra?
              </CallToAction>
            </NoProducts>
          )}
        </div>
      </Container>
      <ScrollToTop/>
    </>
  );
};

export default Favorites;
