import React from 'react';
import { Container, Code, CodeItem } from './styles';
import CheckoutStepBar from '../../../components/CheckoutStepBar';

const CheckoutStepBarExample: React.FC = () => {
  return (
    <>
      <Container>
        <CheckoutStepBar active={0} />
        <CheckoutStepBar active={4} />
      </Container>
      <Code>
        <CodeItem>{'<CheckoutStepBar active={0} />'}</CodeItem>
        <CodeItem>{'<CheckoutStepBar active={4} />'}</CodeItem>
      </Code>
    </>
  );
};

export default CheckoutStepBarExample;
