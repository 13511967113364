import React from 'react';
import GlobalStyles from './styles/GlobalStyles';
import RoutesWrapper from './Routes';
import {BrowserRouter} from 'react-router-dom';
import AppProvider from './AppProvider';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { AnalyticsProvider } from './hooks/useAnalytics';
import useTheme from './hooks/useTheme';
import './styles/icons.css';
import './styles/fonts-config.css';

import light from './styles/themes/light';


function App(): JSX.Element {
  const [theme] = useTheme<DefaultTheme>('@houpa:theme', light);
  // const toggleTheme = () => {
  //     setTheme(theme.title === "light" ? dark : light);
  // };

  return (
    <ThemeProvider theme={theme}>
      <AnalyticsProvider>
        <AppProvider>
          <BrowserRouter>
            <GlobalStyles />
            <RoutesWrapper />
          </BrowserRouter>
        </AppProvider>
      </AnalyticsProvider>
    </ThemeProvider>
  );
}

export default App;
