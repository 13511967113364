import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 25px 24px 36px;
  background-color: var(--grey-light-3);
  border-radius: 4px;

  > h3 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 29px;
  }

  @media(max-width: 1279px){
    padding: 23px 17px;

    > h3 {
      text-align: left;
      font-size: 16px;
    }
  }

  @media(max-width: 607px){
    padding: 8px 13px;

    > h3 {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1279px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 607px) {
    flex-direction: column;
  }
`;


export const Divider = styled.div`
  margin: 19px 0 8px;
  height: 1px;
  border: 1px solid var(--grey-light-2);
  background-color: var(--grey-light-2);


  @media (max-width: 1279px) {
    display: none;
  }


  @media (max-width: 607px) {
    display: block;
    width: auto;
    height: 1px;

    margin: 12px 0;
  }
`;

export const Abstract = styled.div`
  @media (max-width: 1279px) {
    flex: 1;
  }
`;

export const AbstractRow = styled.div`
  display: flex;
  justify-content: space-between;

  &.total {
    margin-top: 36px;
  }
`;

export const SmallAbstractText = styled.p`
  font-size: 12px;
  margin-bottom: 8px;
  color: var(--grey-dark);

  &.ref {
    font-family: Roboto-Light, Poppins-Light, Arial, sans-serif;
  }

  &.neutral {
    color: var(--grey-medium-4);
  }
`;


export const TotalContent = styled.div`
  display: flex;

  > div {
    margin-left: 5px;
  }
`;

export const TooltipContainer = styled.div`
  width: 121px;
  margin: 17px 13px;
  font-size: 14px;
  color: var(--grey-medium-1);
`;

export const TotalText = styled.p`
  color: var(--grey-dark);
  font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;

  &.value {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;

  > a {
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--grey-dark);
    font-size: 12px;
    margin-top: 26px;

    @media (max-width: 1279px) {
      margin-top: 10px;
    }
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-bottom: 8.5px;
  }

  @media (max-width: 1279px) {
    flex-direction: row;
    justify-content: center;

    > button {
      max-width: 198px;
      margin-bottom: 0;
      font-size: 12px;
      padding: 0;
      height: 32px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  @media (max-width: 607px) {

    > button {
      max-width: 120px;

      &:first-child {
        margin-right: 14px;
      }
    }
  }
`;
