import React, { useState, useEffect } from 'react';
import {
  Container,
  Return,
  ReturnIcon,
  ReturnText,
  Title,
  ContactUsText,
  ButtonWrapper,
  Login
} from './styles';
import Button from '../../../components/Button';
import { BlipChat } from 'blip-chat-widget';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  navigateTo: string;
}
const blipClient = new BlipChat();

const ForgotEmail: React.FC<Props> = ({ navigateTo }) => {
  const [chat, setChat] = useState(false);
  const {sendEventAnalytics} = useAnalytics();
  const navigate = useNavigate();

  const queryValidation = navigateTo !== '/' ? `?ReturnUrl=${navigateTo}` : '';

  function openBlip(){
    if(chat) blipClient.destroy();

    blipClient.withAppKey('Y2hhdGJvdGNmOjI5NjA5Y2RhLTJmNmMtNGI3OS1hNWM3LTU0MDZmZTA5MTdmMg');
    blipClient.withButton({ color: '#7F1CEF' });
    blipClient.build();
    blipClient.toogleChat();
    setChat(true);

    sendEventAnalytics({category: 'usuário', action: 'Recuperar E-mail (BlipChat)', label: 'BlipChat'});
  }

  function closeBlip(){
    if(chat) blipClient.destroy();
  }

  const goToInitialPage = () => {
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      closeBlip();
    };
  });
  return (
    <Container>
      <Return onClick={goToInitialPage}>
        <ReturnIcon className="icon icon-arrow-left" />
        <ReturnText>Voltar</ReturnText>
      </Return>
      <Title>Esqueceu o seu e-mail?</Title>
      <ContactUsText>Entre em contato com a nossa equipe para recuperar o seu acesso.</ContactUsText>
      <ButtonWrapper onClick={openBlip}>
        <Button color='var(--purple)' scale='scale-4' expand>Entrar em contato</Button>
      </ButtonWrapper>
      <Login>Já possui conta? <Link to={`/login${queryValidation}`} >Faça login</Link></Login>
    </Container>
  );
};

export default ForgotEmail;
