import styled, {keyframes} from 'styled-components';
import {Modal} from '@material-ui/core';

const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000 !important;
`;

export const Content = styled.div`
  width: 849px;
  outline: 0;
  border-radius: 16px;
  background-color: var(--white);
  padding: 64px 28px 80px;
  position: relative;

  @media (max-width: 1279px) {
    width: 592px;
    padding: 40px 56px 53px;
  }

  @media (max-width: 607px) {
    width: 100%;
    height: 100%;
    padding: 21px 24px;
    border-radius: 0;

    transform: translateX(0);
    animation: ${animeRight} 0.3s ease-out;
    overflow-y: auto;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;

  > i {
    color: var(--grey-light-1)
  }

  @media (max-width: 607px) {
    display: none;
  }
`;

export const GoBack = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 32px;

  > i {
    color: var(--purple);
    margin-right: 16px;
    font-size: 18px;
  }

  @media (max-width: 607px) {
    display: flex;
  }
`;

export const Title = styled.h1`
  max-width: 626px;
  width: 100%;
  margin: 0 auto 38px;
  font-size: 30px;

  @media (max-width: 1279px) {
    font-size: 20px;
  }

  @media (max-width: 607px) {
    margin-bottom: 44px;
  }
`;

export const Body = styled.div`
  width: 100%;
  max-height: 308px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 11.66px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--grey-light-3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--grey-light-1);
  }

  @media (max-width: 1279px) {
    max-height: 376px;

    &::-webkit-scrollbar {
      width: 6px;
    }
  }

`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 19px 30px 14px;
  cursor: pointer;

  &.active {
    background-color: #F3F2F2;
  }

  @media (max-width: 1279px) {
    padding: 8px 15px 6px;
  }

  @media (max-width: 607px) {
    height: 76px;
    margin-bottom: 24px;
    padding: 8px 15px 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CardContent = styled.div`
  margin-left: 24px;

  > h4 {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: var(--grey-dark);
    margin-bottom: 5px;
  }

  > p {
    letter-spacing: 0.2px;
  }

  @media (max-width: 1279px) {
    max-width: 321px;

    > p {
      line-height: 20px;
      font-size: 14px;
    }
  }

  @media (max-width: 607px) {
    max-width: 196px;
    margin-left: 8px;

    > h4 {
      font-size: 12px;
    }

    > p {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const Radio = styled.div`
  span:first-child {
    color: #C5C5C5;
  }


  &.active {
    span:first-child {
      color: var(--purple);
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;

  > button {
    max-width: 200px;

    &:first-child {
      margin-right: 28px;
    }

    &:disabled {
      border: 1px solid #C5C5C5;
      color: #C5C5C5;
      cursor: initial;
    }
  }

  @media (max-width: 1279px) {
    margin-top: 40px;

    > button {
      max-width: 159px;
      height: 32px;
      font-size: 14px;
      padding: 0;

      &:first-child {
        margin-right: 36px;
      }
    }
  }

  @media (max-width: 607px) {
    flex-direction: column-reverse;
    margin-top: 28px;

    > button {
      &:first-child {
        margin-right: 0;
        margin-top: 12px;
      }
    }
  }
`;
