import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  position: relative;

  background-color: var(--grey-light-3);

  @media (max-width: 1279px) {
    padding: 24px;
  }

  @media (max-width: 607px) {
    padding: 14px;
    flex-direction: column;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
  max-width: 274.96px;

  margin-right: 130px;

  @media (max-width: 1279px) {
    max-width: 195.92px;
    margin-right: 54.03px;
  }

  @media (max-width: 607px) {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @media (max-width: 607px) {
    flex: 0;
    width: 100%;
    margin-top: 28px;
  }
`;

export const ProductIMG = styled.div`
  min-width: 67px;
  width: 67px;
  height: 87.1px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1279px) {
    min-width: 43px;
    width: 43px;
    height: 55.9px;
  }

  @media (max-width: 607px) {
    min-width: 57px;
    width: 57px;
    height: 74.1px;
  }
`;

export const Info = styled.div`
  margin-left: 30px;
  max-width: 178px;

  > div:first-child {
    margin-bottom: 10px;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1279px) {
    margin-left: 26px;

    p {
      font-size: 12px;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    font-weight: 700;
    margin-bottom: 18px;

    @media (max-width: 1279px) {
      font-size: 12px;
    }
  }

  @media (max-width: 1279px) {
    font-size: 14px;
  }

  @media (max-width: 607px) {
    &.ab-mob {
      position: absolute;
      top: 10px;
      right: 10px;

      span {
        display: none;
      }
    }
  }
`;

export const Remove = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: none;
  background-color: transparent;
  color: var(--grey);

  cursor: pointer;

  > span {
    margin-top: 6px;
    text-decoration: underline;
    font-size: 10px;
  }
`;
