import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  float: left;
  padding: 20px 0;

  @media (min-width: 37.9375em) {
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--container);
`;

export const Clear = styled.div`
  clear: both;
`;

export const ContainerDownloadApp = styled.div`
  width: 100%;
  float: left;

  img {
    width: 100%;
  }

  @media (min-width: 37.9375em) {
    margin-bottom: 30px;
  }
`;

export const ContainerSlide = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: 15px;

  .scaffoldImage {
    outline: none;
  }

  img {
    width: 100%;
  }
`;

export const Category = styled.nav`
  max-width: 100%;
  overflow: auto;
  padding: 20px 0;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li {
    font-size: 12px;
    font-weight: bold;
    margin: 0 20px;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  font-size: 11px;
  font-weight: 400;

  &.center {
    text-align: center;
  }

  &#destaques {
    font-size: 14px;
  }

  @media (min-width: 37.9375em) {
    &#destaques {
      font-size: 16px;
    }
  }

  @media (min-width: 79.9375em) {
    font-size: 20px;

    &#destaques {
      font-size: 20px;
    }
  }
`;

export const HighlightsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: var(--container);

  @media (min-width: 37.9375em) {
    flex-direction: row;
    justify-content: center;

    margin-bottom: 40px;
  }
`;

export const TitleHighlight = styled(Link)`
  display: block;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  font-weight: 300;
  text-decoration: none;
  color: #000;

  @media (min-width: 37.9375em) {
    font-size: 16px;
  }

  @media (min-width: 79.9375em) {
    font-size: 20px;
  }
`;

export const BoxHighlights = styled.article`
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    border-radius: 3.68932px;
    width: 100%;
  }

  @media (min-width: 37.9375em) {
    flex: 1;
    width: 100%;
    margin-right: 20px;
    margin-top: 60px;
    margin-bottom: 60px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: 79.9375em) {
    max-width: 495.29px;
    margin-right: 40px;
  }
`;

export const ContentSlideNews = styled.div`
  max-width: 100%;
  overflow: auto;
  display: flex;
  margin-top: 20px;
  padding-bottom: 10px;

  @media (min-width: 37.9375em) {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &.five {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
`;

export const SlideNewsItem = styled.div`
  margin-right: 20px;
  min-width: 60%;

  @media (min-width: 37.9375em) {
    display: none;
    &:nth-of-type(1) {
      display: block;
    }

    &:nth-of-type(2) {
      display: block;
    }

    &:nth-of-type(3) {
      display: block;
    }

    min-width: 30%;
  }
`;

export const SlidePromotions = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 170px);
  column-gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-top: 20px;

  @media (min-width: 79.9375em) {
    grid-template-columns: repeat(5, 236px);
  }
`;

export const SlidePromotionsItem = styled.div`
  @media (min-width: 37.9375em) {
    display: none;
    &:nth-of-type(1) {
      display: block;
    }

    &:nth-of-type(2) {
      display: block;
    }

    &:nth-of-type(3) {
      display: block;
    }

    &:nth-of-type(4) {
      display: block;
    }

    &:nth-of-type(5) {
      display: block;
    }

    min-width: 19%;
    margin-right: 0;
  }
`;

export const ViewMore = styled(Link)`
  display: block;
  font-size: 11px;
  margin: 20px 0;
  text-align: center;
  color: var(--black);
  font-weight: bold;
  text-decoration: underline;

  @media (min-width: 37.9375em) {
    font-size: 10px;
  }

  @media (min-width: 79.9375em) {
    font-size: 18px;
  }
`;

export const ImageLink = styled.div`
  cursor: pointer;
`;
