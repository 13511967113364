import styled from 'styled-components';
import Button from '../../Button'

export const Container = styled.div``;

export const CardsWrapper = styled.div`
  max-height: 406px;
  overflow-y: auto;
  margin-top: 56px;
  padding-right: 10px;
  // scroll-bar style
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--grey-light-1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--grey-light-1);
  }
  // scroll-bar style
  .loadCards {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 36px;
  margin-top: 22px;
  @media screen and (max-width: 607px) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const LoadCardsButton = styled(Button)`
  border: 1px solid var(--purple);
  color: var(--purple);
  border-radius: 4px;
  width: 180px;
`;

export const ChangePaymentMethodButton = styled(Button)`
  width: 170px;
`;

export const NewCardTitle = styled.h2`
  font-size: 16px;
  @media screen and (max-width: 607px) {
    font-size: 14px;
  }
`;

export const CreditCardIcon = styled.i`
  font-size: 24px;
`;