import styled from 'styled-components';
import { SuggestionsContainer } from '../Cart/styles';

export const Container = styled.section`
  .container-title {
    font-size: 20px;
    color: ${(props) => props.theme.colors.greyDark};

    margin-bottom: 36px;

    @media (max-width: 607px) {
      margin-top: 24px;
    }
  }
`;

export const Content = styled.section`
  display: flex;

  > div {
    flex: 1;

    &:last-child {
      max-width: 308px;
      margin-left: 16px;

      @media (max-width: 1279px) {
        display: none;
      }
    }
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 438px;
  background-color: var(--white);
`;

export const Panel = styled.div`
  padding: 52px 32px;
  background-color: var(--grey-light-3);
  border-radius: 4px;

  @media (max-width: 1279px) {
    padding: 32px 24px;
  }

  @media (max-width: 607px) {
    padding: 16px;
    width: 320px;
  }
`;

export const Title = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  display: flex;
  color: var(--green);
  margin-bottom: 53px;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 36px;
    height: 36px;
    margin-right: 20px;

    border-radius: 50%;

    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
      0px 2px 4px rgba(96, 97, 112, 0.16);

    font-size: 13px;
  }

  @media (max-width: 1279px) {
    margin: 0 auto 35px;

    font-size: 20px;
    margin-bottom: 32px;

    .icon-container {
      margin-right: 15px;
    }
  }

  @media (max-width: 607px) {
    font-size: 16px;

    .icon-container {
      margin-right: 8px;

      width: 32px;
      height: 32px;
    }

    > p {
      max-width: 199px;
    }
  }
`;

export const OrderContainer = styled.div`
  color: var(--grey-dark);
  margin-bottom: 25px;

  font-size: 16px;

  > h2 {
    font-size: 20px;
    color: var(--black);
    margin: 10px 0 16px;
  }

  @media (max-width: 607px) {
    > h2 {
      font-size: 12px;
      margin: 3px 0 25px;
    }

    > p {
      font-size: 12px;
    }
  }
`;

export const PaymentsContainer = styled.div`
  padding: 24px 0;
`;

export const BankSlipContainer = styled.div`
  > p {
    color: var(--grey-dark);
    padding-right: 150px;
  }

  > h2 {
    margin: 24px 0;
    font-size: 32px;
  }

  > h4 {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 607px) {
    > p {
      font-size: 12px;
      padding: 0;
    }

    > h2 {
      font-size: 16px;
    }

    > h4 {
      font-size: 10px;
    }
  }
`;

export const CreditContainer = styled.div`
  > p {
    color: var(--grey-dark);
    font-size: 20px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 1279px) {
      font-size: 16px;
    }

    > strong {
      font-family: Roboto-Medium;
    }
  }

  .alert {
    margin-top: 40px;

    .alert-title {
      font-size: 16px;
      color: ${(props) => props.theme.colors.redDark};
      margin-bottom: 10px;
    }

    .alert-description {
      font-size: 14px;
      color: ${(props) => props.theme.colors.greyMedium2};
    }

    @media (max-width: 1279px) {
      margin-top: 30px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  > button {
    max-width: 219px;

    &:first-child {
      margin-right: 16px;
    }
  }

  @media (max-width: 607px) {
    > button {
      max-width: 148px;
      padding: 0;
      height: 32px;
      font-size: 12px;
    }
  }
`;

export const ButtonFinishOrder = styled(ButtonContainer)`
  margin-top: 52px;
  justify-content: center;

  > button {
    max-width: 306px;

    &:first-child {
      margin-right: 24px;
    }

    @media (max-width: 1279px) {
      max-width: 234px;
    }
  }
`;

export const NewsletterContainer = styled.div`
  > div {
    float: initial;
  }
`;

export const Suggestion = styled(SuggestionsContainer)`
  margin-top: 72px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 150px 32px 0;

  @media (max-width: 1279px) {
    padding: 0px 0px 32px;
  }

  @media (max-width: 607px) {
    flex-direction: column;
  }
`;

export const ContainerInfo = styled.div`
  color: var(--grey-dark);

  .subtitle {
    font-family: Roboto-Bold;
  }

  > h4 {
    font-size: 16px;
    margin-bottom: 12px;
    font-family: Roboto-Bold;
  }

  > p {
    font-size: 14px;
  }

  @media (max-width: 1279px) {
    text-align: left;
    padding-top: 24px;
  }

  @media (max-width: 607px) {
    > h2 {
      font-size: 14px;
    }

    > p {
      font-size: 12px;
    }
  }
`;

export const Wrapper = styled.div`
  padding-left: 30px;
  border-bottom: 1px solid #dddddd;

  @media (max-width: 607px) {
    padding-left: 0;
  }
`;

export const FreightArea = styled.div`
  margin-top: 22px;
  border-bottom: 1px solid #dddddd;

  .freight-title {
    font-family: Roboto-Bold;
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 1279px) {
    .freight-title {
      font-size: 16px;
    }
  }
`;

export const FreightContent = styled.div`
  color: var(--grey-dark);

  > h2 {
    font-size: 20px;
    margin-bottom: 11px;
  }

  > p {
    font-weight: 700;
    margin-bottom: 8px;

    &.small {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &.address {
      color: var(--grey-medium-4);
    }
  }

  @media (max-width: 1279px) {
    > h2 {
      font-size: 16px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const FreightProducts = styled.div`
  padding: 30px;

  @media (max-width: 1279px) {
    padding: 24px;
  }

  @media (max-width: 607px) {
    padding: 14px;
  }
`;

export const ButtonBankSlip = styled.button`
  position: relative;

  width: 480px;
  height: 60px;
  background-color: transparent;
  border: 1px solid var(--purple);
  color: var(--purple);
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 32px;
`;

export const AreaLoading = styled.div`
  position: absolute;
  left: 61px;
  top: 0;

  display: flex;
  align-items: center;

  width: 50px;
  height: 100%;
`;

export const BankSlipRow = styled.div`
  display: flex;
  justify-content: center;

  &.snack {
    > div {
      max-width: 753px;
    }
  }
`;
