import React from 'react';
import {
  Container,
  MoreAboutTextWrapper,
  MoreAboutTitle,
  Bold,
  MoreAboutText,
  SolutionsText,
  UserImgWrapper,
} from './styles';

import UserImg from '../../../../assets/png/image-help-2.png';


const MoreAbout: React.FC = () => {

  return (
    <Container>
      <UserImgWrapper>
        <img src={UserImg} alt='Smiling woman holding a tablet'/>
      </UserImgWrapper>
      <MoreAboutTextWrapper>
        <MoreAboutTitle>Conheça mais sobre a <Bold>nossa solução</Bold> </MoreAboutTitle>
        <MoreAboutText>Somos a primeira startup brasileira a trazer inovação na forma de comprar e vender roupas e a primeira rede social fashion do mundo que busca conectar toda a cadeia produtora de moda, visando trazer mais eficiência para quem produz e comercializa roupas, e maior conveniência e experiência para o consumidor.</MoreAboutText>
        <SolutionsText>Soluções para você!<br />
        Soluções para atacadistas!</SolutionsText>
      </MoreAboutTextWrapper>
    </Container>

  );
};

export default MoreAbout;
