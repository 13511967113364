import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 52px;
  width: 100%;

  display: flex;

  > h2 {
    font-size: 20px;
    margin-bottom: 32px;
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    padding-top: 17px;

    > h2 {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  .summary {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .header {
    border-bottom: 1px solid #dddddd;

    > h2 {
      margin-bottom: 32px;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #dddddd;

  @media (min-width: 1280px) {
    margin-top: 20px;
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const ButtonAlterMethods = styled.div`
  background-color: transparent;
  color: var(--purple);
  margin: 24px 0px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`;

export const ContainerInfo = styled.div`
  color: var(--grey-dark);

  :last-child {
    text-align: right;
  }

  .subtitle {
    font-family: Roboto-Bold;
  }

  > h4 {
    margin-bottom: 8px;
  }

  > p {
    font-size: 14px;
  }

  @media (max-width: 1279px) {
    text-align: left;
    padding-top: 24px;

    :last-child {
      text-align: left;
    }
  }

  @media (max-width: 607px) {
    > h2 {
      font-size: 14px;
    }

    > p {
      font-size: 12px;
    }
  }
`;

export const FreightArea = styled.div`
  margin-top: 16px;
`;

export const FreightContent = styled.div`
  margin-bottom: 29px;
  color: var(--grey-dark);

  > h2 {
    font-size: 20px;
    margin-bottom: 11px;
  }

  > p {
    font-weight: 700;
    margin-bottom: 8px;

    &.small {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &.address {
      color: var(--grey-medium-4);
    }
  }

  @media (max-width: 1279px) {
    > h2 {
      font-size: 16px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const FreightProducts = styled.div`
  padding: 30px 30px 0px;
  margin-top: 32px;
  background-color: var(--grey-light-3);

  @media (max-width: 1279px) {
    padding: 24px;
  }

  @media (max-width: 607px) {
    padding: 14px;
  }
`;
