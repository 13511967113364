import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F3F2F2;
  opacity: 0.95;
  padding: 20px 0;
  z-index: 9999;
`;

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--container);

  @media(min-width: 37.9375em){
    display: flex;
    align-items: center;
    justify-content: space-betweeen;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button{
    background-color: var(--grey-dark);
    color: var(--white);
    font-size: 12px;
    width: 135.24px;
    height: 31px;
  }
`;

export const Text = styled.p`
  font-size: 10px;

  a{
    color: var(--black);
    font-weight: bold;
  }

  @media(min-width: 37.9375em){
    font-size: 12px;
    margin-right: 20px;
  }
`;
