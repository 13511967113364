import React, {useRef} from 'react';
import { 
  Container,
  Title,
  TextWrapper,
  Text
} from './styles';
import Button from '../../../Button';
import Lottie from 'react-lottie';
import ErrorAnimation from '../../../../assets/animations/error.json';

interface Props {
  setPaymentStatus: (status: '' | 'success' | 'error') => void;
}

const PaymentError: React.FC<Props> = ({setPaymentStatus}) => {

  const defaultOptions = useRef({
    loop: false,
    autoplay: true,
    animationData: ErrorAnimation
  }).current;

  const choseAnotherCard = () => {
    setPaymentStatus('');
  }
  
  return (
    <Container>
      <Lottie width={76} height={76} options={defaultOptions} />
      <Title>O pagamento não foi autorizado.</Title>
      <TextWrapper>
        <Text>
          Não foi possível finalizar a transação. Sugerimos que entre 
          em contato com a operadora do seu cartão para saber mais.
        </Text>
        <Text>
          Você também pode optar por outra forma 
          de pagamento para não perder sua compra.
        </Text>
      </TextWrapper>

      <Button 
        onClick={choseAnotherCard}
        color='var(--purple)'
        scale='scale-3'
      >
        Escolher outro cartão
      </Button>
    </Container>
  );
}

export default PaymentError;