import {
  PaymentInstallmentInterface,
  PaymentMethodInterface,
  SetOrderConfigType,
  UsePaymentMethods,
} from '../interfaces';
import { useCallback, useEffect, useState } from 'react';
import { formatCurrency } from '../../../helpers/formatting';
import { api } from '../../../services/api';

export const usePaymentMethods = (
  setOrderConfig: SetOrderConfigType,
  cartPrice: number,
): UsePaymentMethods => {
  const [methods, setMethods] = useState<PaymentMethodInterface[]>([]);
  const [
    selectedMethod,
    setSelectedMethod,
  ] = useState<PaymentMethodInterface>();
  const [availableInstallments, setAvailableInstallments] = useState<
    PaymentInstallmentInterface[]
  >([]);

  const calculateInstallmentsValues = useCallback(
    (installments: PaymentInstallmentInterface[]) => {
      const newInstallments = installments.map((installment) => {
        const price = formatCurrency(
          (cartPrice / installment.value).toFixed(2),
        );

        installment.name = `${installment.value}x de R$ ${price} sem juros`;

        return installment;
      });

      setAvailableInstallments(newInstallments);
    },
    [cartPrice],
  );

  const handleSelectMethod = useCallback(
    (method: PaymentMethodInterface) => {
      setSelectedMethod(method);

      setOrderConfig({ methodId: method.id });
    },
    [setOrderConfig],
  );

  useEffect(() => {
    const hasCreditCard = methods.findIndex((method) => method.id === 2);

    if (hasCreditCard >= 0) {
      calculateInstallmentsValues(methods[hasCreditCard].installments);
    }
  }, [calculateInstallmentsValues, methods]);

  useEffect(() => {
    api
      .get('/payments/methods')
      .then(({ data }) => {
        data.reverse();

        setOrderConfig({ methodId: data[0].id });
        setSelectedMethod(data[0]);
        setMethods(data);
      })
      .catch();

    // eslint-disable-next-line
  }, []);

  return {
    methods,
    selectedMethod,
    handleSelectMethod,
    availableInstallments,
  };
};
