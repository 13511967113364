import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { Container, Content, ButtonContainer, Text  } from './styles';

const CookiesAccept: React.FC = () => {
  const [cookie, setCookie] = React.useState(false);

  React.useEffect(() => {
    const cookie = localStorage.getItem('accept');
    if(cookie){
      setCookie(true);
    }else{
      setCookie(false);
    }
  }, []);

  function AcceptCookie(){
    const date = new Date();
    localStorage.setItem('accept', date.toDateString());
    setCookie(true);
  }

  if(cookie) return null;

  return (
    <Container>
      <Content>
        <Text>Este site coleta cookies próprios e de terceiros para proporcionar uma melhor experiência  ao usuário, apresentar publicidade relevante de acordo com o seu perfil e melhorar a qualidade do nosso site. Aceite a coleta de todos os cookies ou clique em configurar cookies para gerenciar suas preferências. <Link to="#">Saiba mais sobre esse assunto.</Link></Text>
        <ButtonContainer>
          <Button onClick={AcceptCookie} scale='scale-1' link='b2c'>Aceitar Cookies</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default CookiesAccept;
