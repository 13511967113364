import styled from 'styled-components';

export const Container = styled.section`
  display: flex;

  > div {
    flex: 1
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const AddressContainer = styled.div`
  max-width: 898px;

  > h1 {
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

export const AddressTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;

  > p {
    color: var(--grey-dark);
  }

  @media(max-width: 607px){
    justify-content: flex-end;
    margin-top: 32px;

    > p {
      display: none;
    }
  }
`;

export const ButtonAddAddress = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    border-radius: 50%;
    background-color: var(--purple);
    color: var(--white);

    margin-right: 16px;
    font-size: 22px;
  }


  @media(max-width: 607px){
    font-size: 12px;

    > div {
      width: 19.38px;
      height: 19.38px;
      font-size: 17.7692px;
      margin-right: 8.62px;
    }
  }
`;

export const FreightArea = styled.div`
  margin-top: 64px;
`;

export const FreightContent = styled.div`
  margin-bottom: 29px;
  color: var(--grey-dark);

  > h2 {
    font-size: 20px;
    margin-bottom: 11px;
  }

  > p {
    font-weight: 700;
    margin-bottom: 8px;

    &.small {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &.address {
      color: var(--grey-medium-4);
    }
  }

  @media (max-width: 1279px) {
    > h2 {
      font-size: 16px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const FreightProducts = styled.div`
  padding: 30px;
  margin-top: 32px;

  @media (max-width: 1279px) {
    padding: 24px;
  }

  @media (max-width: 607px) {
    padding: 14px;
  }
`;

export const FreightOptions = styled.div`
  margin-top: 24px;

  > p {
    font-weight: 700;
    text-align: center;
  }
`;

export const FreightOptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 607px) {
    flex-direction: column;
  }
`;

export const Options = styled.div`
  display: flex;
  padding: 15px 22px;
  cursor: pointer;

  > i {
    margin: 0 12px;
    padding-top: 3px;
  }

  .radio {
    max-width: 40px;

  }

  span {
    padding: 0 !important;
  }

  .optionsInfo {
    p {
      font-size: 14px;
      font-weight: 700;

      &.small {
        font-weight: 400;
        font-size: 11.11px;
      }

      &.value {
        font-family: Roboto-Light, Poppins-Light, Arial, sans-serif;
        font-weight: 300;
        margin-top: 6px;
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 10px 8px;

    > i {
      margin: 0 6px;
    }
  }


  @media (max-width: 607px) {
    align-items: center;
    padding: 10px 0;
    width: 100%;

    > i {
      font-size: 12px;
    }

    .optionsInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .row-mobile {
        display: flex;
      }

      p {
        font-size: 10px;

        &.small {
          margin-left: 4px;
          font-size: 9px;
        }

        &.value {
          margin-top: 0;
        }
      }
    }
  }
`;


export const ButtonContainer = styled.div`
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
    font-weight: 500;
    width: 284px;
    height: 52px;
    border: 1.625px solid var(--purple);
    color: var(--purple);
    border-radius: 6.5px;
    cursor: pointer;
    font-size: 19.5px;
  }

  @media(max-width: 1279px){
    margin-top: 23px;

    > button {
      width: 235px;
      font-size: 10px;
    }
  }

  @media(max-width: 607px){
    margin-top: 31px;

    > button {
      width: 100%;
      font-size: 10px;
    }
  }
`;


export const EndYearContainer = styled.div`
  p {
    font-size: 20px;
    line-height: 28px;
    color: var(--grey);
    margin-top: 22px;

    @media(max-width: 1279px){
      line-height: 24px;
      font-size: 18px;
    }

    @media(max-width: 607px){
      font-size: 16px;
    }
  }
`;