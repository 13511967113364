import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  @media screen and (max-width: 607px) {
    margin-top: 39px;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: var(--grey-dark);
  text-align: center;
  width: 437px;
  margin-top: 38px;
  @media screen and (max-width: 607px) {
    font-size: 20px;
    line-height: 24px;
    max-width: 290px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  margin-top: 24px;
  @media screen and (max-width: 607px) {
    row-gap: 32px;
  }
`;

export const Text = styled.p`
  max-width: 496px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--grey-medium-2);
  @media screen and (max-width: 607px) {
    font-size: 16px;
    max-width: 273px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 160px;
  margin: 44px auto 0;
`;