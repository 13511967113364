import styled from 'styled-components';

export const Container = styled.div`
  @keyframes fadeIn{
    from{
      opacity: 0;
    }

    to{
      opacity: initial;
    }
  }

  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  animation: 0.5s fadeIn;
`;

export const Content = styled.div`
  @keyframes fadeInContent{
    from{
      right: -100%;
    }

    to{
      right: 0;
    }
  }

  width: 100%;
  max-width: 291px;
  height: 100vh;
  position: relative;
  background-color: var(--white);
  animation: 0.5s fadeInContent;
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #DDDDDD;

  a{
    color: var(--purple);
    text-decoration: none;
    font-size: 12px;
  }

  i{
    font-size: 16px;
    cursor: pointer;
  }

  button{
    font-size: 12px;
  }
`;

export const DrawerStyled = styled.div`
  button {
    font-family: var(--poppins);
  }
  i{
    margin-left: 10px;
  }
`;
