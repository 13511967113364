import React, { useState, useRef, useCallback, FormEvent } from 'react';

import {
  FormTitle,
  Form, 
  FormWrapper,
  InputStyled,
  CardNumber,
  CardImg,
  CardBanner,
  CardIcon,
  SelectWrapper,
  CheckboxWrapper,
  ButtonWrapper,
  TooltipText
} from '../styles';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import useForm from '../../../hooks/useForm';
import {validateCreditCard} from '../../../helpers/inputValidation';
import {MoipValidator} from 'moip-sdk-js';
import AMEX from '../../../assets/svg/AMEX.svg';
import ELO from '../../../assets/svg/ELO.svg';
import HIPERCARD from '../../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../../assets/svg/MASTERCARD.svg';
import VISA from '../../../assets/svg/VISA.svg';
import {cardTypesInterface} from '../../../interfaces/creditCard';
import Select from '../../../components/Select';
import PaymentSuccess from '../components/PaymentSuccess';
import PaymentError from '../components/PaymentError'
import LoadingValidating from '../components/LoadingValidating';

interface OwnProps {
  onClose: () => void;
}


const NoCard: React.FC<OwnProps> = ({ onClose }) => {
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [autoFill, setAutoFill] = useState(false);
  const [cardType, setCardType] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<'' | 'success' | 'error'>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {form, onChange, onBlur, validateForm, error, clearForm} = useForm({
    holder: {
      required: true,
      type: 'fullname'
    },
    cpf: {
      required: true,
      type: 'cpf',
      limit: 14
    },
    birthDate: {
      required: true,
      type: 'date',
      limit: 10,
    },
    phone: {
      required: true,
      type: 'phone',
      limit: 15
    },
    cardNumber: {
      required: true,
      type: 'cardNumber',
      limit: 19
    },
    expirationDate: {
      required: true,
      type: 'cardDate'
    },
    securityCode: {
      required: true,
      type: 'cardSecurityCode',
      limit: 4
    }
  });



  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    HIPERCARD,
    MASTERCARD,
    VISA
  }).current;

  const getCardType = (cardNumber: string) => {
    cardNumber = cardNumber.replace(/\D/g, '');
    const type = MoipValidator.cardType(cardNumber);

    setCardType(type ? type.brand : null);
  };

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    const { cardNumber, securityCode, expirationDate } = form;
    const cardValidation = validateCreditCard({cardNumber, securityCode, expirationDate});

    if(!selectedValue) {
      setSelectError(true);
    }

    if(!cardValidation.valid && !validateForm()){
      console.log('error', cardValidation.message);
      return;
    }

    if(validateForm() && selectedValue){
      setLoading(true);
      setTimeout(() => {
        setPaymentStatus('error');
        setCardType(null);
        clearForm();
        setLoading(false);
      }, 2200);
    }
  }, [clearForm, form, validateForm, selectedValue]);


  if(paymentStatus === 'success' && !loading) return (
    <PaymentSuccess onClose={onClose} />
  )
  if(paymentStatus === 'error' && !loading) return (
    <PaymentError setPaymentStatus={setPaymentStatus} />
  )
  if(loading) return <LoadingValidating />

  return (
    <>
      <FormTitle>Insira os dados do cartão</FormTitle>
      <Form onSubmit={handleSubmit}>
        <CheckboxWrapper>
          <Checkbox 
            checked={autoFill} 
            onChange={(checked: boolean) => setAutoFill(checked)}
          >
            Preencher meus dados de cadastro automaticamente.
          </Checkbox>
        </CheckboxWrapper>
        <FormWrapper>
          <InputStyled 
            value={form.holder} 
            error={error.holder} 
            onChange={(e) => 
            onChange('holder', e)} 
            onBlur={() => onBlur('holder')} 
            className={''} 
            title={'Nome do Titular'} 
            placeholder={'Nome impresso no cartão'} 
            required
          />
          <InputStyled
            title={"CPF do Titular"}
            value={form.cpf}
            onBlur={() => onBlur("cpf")}
            onChange={(e) => onChange("cpf", e)}
            error={error.cpf}
            required
            placeholder={"Digite apenas números"}
            tooltipContent={
              <TooltipText>
                Insira aqui o CPF do titular do cartão. Este 
                dado é solicitado para segurança da transação.
              </TooltipText>
            }
          />
          <InputStyled
            title={"Data de Nascimento"}
            value={form.birthDate}
            onBlur={() => onBlur("birthDate")}
            onChange={(e) => onChange("birthDate", e)}
            error={error.birthDate}
            required
            placeholder={"DD/MM/AAA"}
            tooltipContent={
              <TooltipText>
                Insira aqui a data de nascimento do titular do cartão. 
                Este dado é solicitado para segurança da transação.
              </TooltipText>
            }
          />
          <InputStyled
            title={"Telefone"}
            value={form.phone}
            onBlur={() => onBlur("phone")}
            onChange={(e) => onChange("phone", e)}
            error={error.phone}
            type={"phone"}
            required
            placeholder={"Digite o número com DDD"}  
          />
          <CardNumber>
            <InputStyled 
              value={form.cardNumber} 
              error={error.cardNumber} 
              className={'cardNumber'} 
              title={'Número do Cartão'} 
              placeholder={'1234 1234 1234 1234'} 
              onChange={(e) => {
                getCardType(e.target.value);
                onChange('cardNumber', e);
              }} 
              onBlur={() => {
                onBlur('cardNumber');
              }} 
              required
            />
            <CardImg className='icon-area'>
              {cardType ? (
                <CardBanner src={cardTypes[cardType]} alt="bandeira do cartão"/>
              ) : (
                <CardIcon className={'icon icon-credit-card'}/>
              )}
            </CardImg>
          </CardNumber>

          <InputStyled 
            value={form.expirationDate} 
            error={error.expirationDate} 
            onChange={(e) => onChange('expirationDate', e)} 
            onBlur={() => onBlur('expirationDate')} 
            className={'validity'} 
            title={'Validade'} 
            placeholder={'MMAA'} required
          />

          <InputStyled
            value={form.securityCode}
            error={error.securityCode}
            onChange={(e) => onChange('securityCode', e)}
            onBlur={() => onBlur('securityCode')}
            className={'securityCode'}
            title={'Código de Segurança'}
            placeholder='Digite apenas números'
            required
          />
          <SelectWrapper>
            <Select 
              label={'Número de parcelas'}
              required={true}
              options={['1x de R$ 100,00  Sem juros', 'sdsd']}
              disabledOption='selecione uma opção'
              selectError={selectError}
              setselectError={setSelectError}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </SelectWrapper>
        </FormWrapper>

        <ButtonWrapper>
          <Button 
            color='var(--purple)'
            scale='scale-3'
            expand={true}
          >
            Alterar pagamento
          </Button>
        </ButtonWrapper>
      </Form>
    </>
  );
}

export default NoCard;