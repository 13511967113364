import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Content,
  Title,
  Card,
  CardContent,
  Radio,
  ButtonContainer,
  Body,
  CloseButton,
  GoBack,
} from './styles';
import RadioButtonsGroup from '../Radio';
import Button from '../Button';
import {
  AddressInterface,
  SelectedAddressType,
} from '../../interfaces/address';
import { useToast } from '../../hooks/useToast';

interface Props {
  addresses: AddressInterface[];
  selectedAddress: SelectedAddressType;
  modal: boolean;
  setModal: (opened: boolean) => void;
  onSelectAddress: (address: AddressInterface) => void;
  loadMoreAddresses: () => void;
  totalAddresses: number;
}

const ModalAddressList: React.FC<Props> = ({
  addresses,
  modal,
  setModal,
  onSelectAddress,
  selectedAddress,
  loadMoreAddresses,
  totalAddresses,
}) => {
  const { toast } = useToast();

  const [selectAddress, setSelectAddress] = useState<SelectedAddressType>(
    {} as SelectedAddressType,
  );

  useEffect(() => {
    setSelectAddress(selectedAddress);
  }, [selectedAddress]);

  const handleSelectAddress = useCallback(() => {
    if (!selectAddress) {
      toast({
        type: 'error',
        description: 'Selecione um endereço de entrega',
      });

      return;
    }

    onSelectAddress(selectAddress);
    setModal(false);
  }, [onSelectAddress, selectAddress, setModal, toast]);

  return (
    <Container open={modal} onClose={() => setModal(false)}>
      <Content>
        <CloseButton onClick={() => setModal(false)}>
          <i className="icon icon-exit" />
        </CloseButton>

        <GoBack onClick={() => setModal(false)}>
          <i className="icon icon-arrow-left" />
          Voltar
        </GoBack>

        <Title>Escolher endereço</Title>

        <Body>
          {addresses.map((item) => (
            <Card
              onClick={() => setSelectAddress(item)}
              className={
                selectAddress && selectAddress.id === item.id ? 'active' : ''
              }
              key={item.id}
            >
              <Radio
                className={
                  selectAddress && selectAddress.id === item.id ? 'active' : ''
                }
              >
                <RadioButtonsGroup
                  checked={!!(selectAddress && selectAddress.id === item.id)}
                />
              </Radio>
              <CardContent>
                <h4>{item.title}</h4>
                <p>
                  {item.street}, {item.number},{' '}
                  {item.adjunct && item.adjunct + ' - '} {item.city} - {item.uf}{' '}
                  CEP {item.zipCode}
                </p>
              </CardContent>
            </Card>
          ))}
        </Body>

        <ButtonContainer>
          <>
            <Button
              onClick={() => loadMoreAddresses()}
              color={'var(--purple)'}
              outline
              expand
              disabled={totalAddresses <= addresses.length}
              scale={'scale-4'}
            >
              Ver mais endereços
            </Button>
            <Button
              onClick={() => handleSelectAddress()}
              color={'var(--purple)'}
              expand
              scale={'scale-4'}
            >
              Selecionar endereço
            </Button>
          </>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default ModalAddressList;
