import React, { useEffect, useState } from 'react';
import { Blip, ButtonBlip, CloseIcon} from './styled';
import Close from '@material-ui/icons/Close';
import ChatIcon from '../../assets/svg/brand-logo.svg';
import { BlipChat } from 'blip-chat-widget';
import { useAnalytics } from '../../hooks/useAnalytics';

interface PropsTakeBlip{
  needsLogin?: boolean;
  open?: boolean;
}

const blipClient = new BlipChat();
const KEY_LOGIN = process.env.REACT_APP_BLIP_LOGIN;
const KEY_LOGOUT = process.env.REACT_APP_BLIP_LOGOUT;

const TakeBlip: React.FC<PropsTakeBlip> = ({
  needsLogin = false,
  open = true,
}) => {
  const [visible, setVisible] = useState(open);

  const {sendEventAnalytics} = useAnalytics();

  useEffect(() => {
    loadBlip();
  });

  function loadBlip(){
    const KEY = needsLogin ? KEY_LOGIN : KEY_LOGOUT;

    blipClient.withAppKey(KEY);
    blipClient.withButton({ color: '#7F1CEF' });
    blipClient.withTarget('blip-chat');
    blipClient.build();

    sendEventAnalytics({category: 'Chat', action: 'Chat Take Blip', label: 'BlipChat'});

    const body = document.body;

    if(visible){
      body.classList.add('visible-takeBlip');
    }else{
      const classExist = body.classList.contains('visible-takeBlip');
      if (classExist) body.classList.remove('visible-takeBlip');
    }
  }

  return (
    <>
      <Blip className={`visible-${visible}`} id="blip-chat"></Blip>
        <ButtonBlip onClick={() => setVisible(!visible)}>
          {visible ? <Close/> : <img src={ChatIcon}/>}
        </ButtonBlip>
        {visible &&
          <CloseIcon onClick={() => setVisible(!visible)}>
            <Close/>
          </CloseIcon>
        }
    </>
  );
};

export default TakeBlip;
