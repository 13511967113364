import React from 'react';
import {
  Container,
  IconWrapper,
  ImgWrapper,
  IconTitle,
  IconText,
  IconContainer,
  LinkFalse,
} from './styles';

import Chat from '../../../../assets/svg/chat.svg';
import Doubts from '../../../../assets/svg/doubts.svg';
import ShoppingCart from '../../../../assets/svg/shopping_cart.svg';
import {Tooltip} from '@material-ui/core';

const IconsSection: React.FC = () => {
  return (
    <Container>
      <IconContainer>
        <IconWrapper>
          <ImgWrapper>
            <img src={Chat} alt="Message icon"/>
          </ImgWrapper>
        </IconWrapper>
        <IconTitle>Chat</IconTitle>
        <IconText className="first">Precisa de ajuda?<br/> Fale diretamente com<br/> nossos atendentes.</IconText>
      </IconContainer>

      <Tooltip title={<p style={{textAlign: "center"}}>Estamos trabalhando nisso,<br/> liberaremos em breve</p>} placement="bottom">
        <IconContainer>
          <IconWrapper>
            <ImgWrapper>
              <img src={Doubts} alt="Message icon"/>
            </ImgWrapper>
          </IconWrapper>
          <IconTitle>Dúvidas?</IconTitle>
          <IconText className="second">Visite nossa seção de<br/> <LinkFalse>Perguntas
            frequentes</LinkFalse>.</IconText>
        </IconContainer>
      </Tooltip>

      <IconContainer>
        <IconWrapper>
          <ImgWrapper>
            <img src={ShoppingCart} alt="Message icon"/>
          </ImgWrapper>
        </IconWrapper>
        <IconTitle>Quer vender?</IconTitle>
        <IconText className="third">Veja mais sobre nosso<br/> App para Empresas.</IconText>
      </IconContainer>
    </Container>
  );
};

export default IconsSection;
