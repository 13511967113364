import React from 'react';
import {
  NeedHelp,
  NeedHelpTitle,
  NeedHelpText,
  EmailLink,
  ContrastPurple,
  Email,
  EmailImgWrapper,
  CustomerService,
  OpeningHoursTitle,
  OpeningHoursText,
  TelephoneImgWrapper,
} from './styles';

import EmailImg from '../../../../assets/svg/email.svg';
import TelephoneImg from '../../../../assets/svg/telephone.svg';


const Help: React.FC = () => {

  return (
    <div>
      <NeedHelp>
        <NeedHelpTitle>
          Precisando de ajuda?<br /> Entre em contato com a nossa equipe de atendimento.
        </NeedHelpTitle>
        <NeedHelpText>
          O {<ContrastPurple>"To Aqui"</ContrastPurple>} é um e-mail exclusivo do houpa! para você entrar em contato com a gente! Através dele, você poderá compartilhar suas dúvidas, elogios, reclamações e o que mais precisar. Fique à vontade e mande a sua mensagem para a gente: <EmailLink href='mailto:toaqui@houpa.app?Subject='>toaqui@houpa.app</EmailLink>
        </NeedHelpText>
      </NeedHelp>
      <CustomerService>
        <Email>
          <EmailImgWrapper>
            <img src={EmailImg} alt='Message purple icon'/>
          </EmailImgWrapper>
          toaqui@houpa.app
        </Email>
        <div>
          <OpeningHoursTitle>
            <TelephoneImgWrapper>
              <img src={TelephoneImg} alt='Telephone purple icon'/>
            </TelephoneImgWrapper>
            Horário de atendimento:
          </OpeningHoursTitle>
          <OpeningHoursText>
            Segunda a sexta, das 8h às 18h<br /> Finais de semana e feriados, das 9h às 20h.
          </OpeningHoursText>
        </div>
      </CustomerService>
    </div>
  );
};

export default Help;
