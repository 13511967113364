import React, {useState, useRef} from 'react';
import { 
  LineBreakMobile,
  Container,
  Title,
  ChangePaymentMethodText,
  Total,
  BarCodeWrapper,
  CopyBarCode,
  BarCode,
  Copy,
  ButtonWrapper,
  PrintOutButton,
  ImpotantMessageWrapper,
  ImportantMessage,
  Loading,
  LoadingMessage,
} from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '../../Button';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../assets/animations/loading.json';

const BankSlip: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [bankSlipGenerated, setBankSlipGenerated] = useState<boolean>(false);
  const [bankSlipCode] = useState<string>('75582258522582885282258258522582');
  const [loading, setLoading] = useState<boolean>(false);

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation
  }).current;

  const CopyCode = async () => {
    await navigator.clipboard.writeText(bankSlipCode);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = () => setSnackbarOpen(false);

  const generateBankSlip = () => {
    setLoading(true);
    setTimeout(() => {
      setBankSlipGenerated(true);
      setLoading(false);
    }, 2100);
  }
  
  return (
    <Container>
      <Title>Boleto bancário</Title>
      <ChangePaymentMethodText>
        Altere a forma de pagamento para boleto à vista.
      </ChangePaymentMethodText>
      <Total>Total: R$ 1.399,90</Total>



      {bankSlipGenerated ? (
        <>
          <BarCodeWrapper>
            <BarCode><b>Código de Barras:</b> <LineBreakMobile />{bankSlipCode}</BarCode>
            <CopyBarCode  onClick={CopyCode}>
              <i className="icon icon-copy" />
              <Copy>Copiar código</Copy>
            </CopyBarCode>
          </BarCodeWrapper>
          <PrintOutButton 
            link='b2c'
            scale='scale-3' 
            expand
          >
            Imprimir Boleto
          </PrintOutButton>
        </>
      ) : (
        <>
          {loading ? (
            <Loading>
              <Lottie width={64} height={64} options={defaultOptions}/>
              <LoadingMessage>Aguarde! Gerando boleto.</LoadingMessage>
            </Loading>
          ) : (
            <ButtonWrapper>
              <Button 
                onClick={generateBankSlip}
                color='var(--purple)' 
                scale='scale-3' 
                expand
              >
                Gerar boleto
              </Button>
            </ButtonWrapper>
          )}
        </>
      )}

      <ImpotantMessageWrapper>
        <ImportantMessage>
          <span>Importante:</span> Para não perder seus itens, pague  o boleto antes ou até 
          a data de vencimento para evitar cancelamento automático do pedido
        </ImportantMessage>
      </ImpotantMessageWrapper>

      <Snackbar open={snackbarOpen} autoHideDuration={1500} onClose={handleSnackBarClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackBarClose}
          severity="info"
        >
          Código copiado!
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}

export default BankSlip;