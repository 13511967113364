import React, {useState, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  Container,
  PagePath,
} from './styles';
import Main from './Main';
import RecoverPassword from './RecoverPassword';
import ForgotEmail from './ForgotEmail';
import {Route, Routes} from 'react-router-dom';
import {useQuery} from '../../hooks/useQuery';

const LoginOrRegister: React.FC = () => {
  const [registerStep, setRegisterStep] = useState<'initial' | 'createAccount'>('initial');

  const query = useQuery();
  const searchParam = query.get('ReturnUrl');


  const navigateTo = useMemo(() => {
    return searchParam ? searchParam : '/';
  }, [searchParam]);

  return (
    <>
      <Container>
        <PagePath><Link to="/">Início</Link>{' > '}<span>Entrar</span></PagePath>

        <Routes>
          <Route path='/' element={
            <Main
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              navigateTo={navigateTo}
            />
          }/>
          <Route path='/recuperar-senha' element={
            <RecoverPassword navigateTo={navigateTo}/>
          }/>
          <Route path='/recuperar-email' element={<ForgotEmail navigateTo={navigateTo}/>}/>
        </Routes>
      </Container>
    </>

  );
};

export default LoginOrRegister;
