import React, { ChangeEvent } from 'react';
import {
  Container,
  Label,
  TextareaStyled,
  TextWrapper,
  Authorization,
  CharactersLimitText,
  ErrorMessage,
} from './styles';

interface Props {
  textareaError: boolean;
  setTextareaError: (arg: boolean) => void;
  message: string;
  setMessage: (arg: string) => void;
  charLimit?: number;
}

const Textarea: React.FC<Props> = ({
  textareaError, 
  setTextareaError, 
  message, 
  setMessage,
  charLimit = 1000,
}) => {

  const handleChange = (e: ChangeEvent<{value: string}>) => {
    message.length >= 0 && setTextareaError(false);
    setMessage(e.target.value.substring(0, charLimit));
  };

  const handleBlur = () => {
    if(!message) {
      setTextareaError(true);
    }
  };

  return (
    <Container>
      <Label>Mensagem<span>*</span></Label>
      <TextareaStyled value={message} onChange={handleChange} onBlur={handleBlur} maxLength={charLimit}/>
      <TextWrapper>
        <Authorization>Ao fornecer seus dados de telefone, você autoriza o houpa a enviar mensagens em aplicativos terceiros.</Authorization>
        <CharactersLimitText>
          Limite de {charLimit - message.length} {charLimit - message.length > 1 ? 'caracteres' : 'caractere'}
        </CharactersLimitText>
      </TextWrapper>
      {textareaError && <ErrorMessage>Campo obrigatório. Conte para nós em que podemos ajudar.</ErrorMessage>}
    </Container>
  );
};

export default Textarea;
