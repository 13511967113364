import React, {useRef} from 'react';
import { Container, Validating } from './styles';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../../assets/animations/loading.json';

const LoadingValidating: React.FC = () => {
  const defaultOptions = useRef({
    loop: false,
    autoplay: true,
    animationData: LoadingAnimation,
  }).current;
  return (
    <Container>
      <Lottie width={64} height={64} options={defaultOptions} />
      <Validating>Validando</Validating>
    </Container>
  );
}

export default LoadingValidating;