import React, { useEffect } from 'react';
import { Container, Content } from './styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Account from '../pages/Account';
import Icons from '../pages/Icons';
import RecoverPassword from '../pages/RecoverPassword';
import Register from '../pages/Register';
import StyleGuide from '../pages/StyleGuide';
import ProfileStore from '../pages/ProfileStore';
import Search from '../pages/Search';
import Product from '../pages/Product';
import Checkout from '../pages/Checkout';
import Favorites from '../pages/Favorites';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Terms from '../pages/Terms';
import Home from '../pages/Home';
import CookiesAccept from '../components/CookiesAccept';
import ProtectedRoute from '../components/ProtectedRoute';
import NotFound from '../pages/NotFound';
import AboutHoupa from '../pages/Institutional/AboutHoupa';
import Help from '../pages/Institutional/Help';
import ReplacementReturnPolicy from '../pages/Institutional/ReplacementReturnPolicy';
import ForBusiness from '../pages/Institutional/ForBusiness';
import { LoadingProvider } from '../hooks/useLoading';
import ForgotEmail from '../pages/ForgotEmail';
import { useAnalytics } from '../hooks/useAnalytics';
import TakeBlip from '../components/TakeBlip';
import { useAuth } from '../hooks/useAuth';
import LoginOrRegister from '../pages/LoginOrRegister';

const RoutesWrapper: React.FC = () => {
  const { startAnalytics } = useAnalytics();
  startAnalytics();

  const { token } = useAuth();
  const { pathname } = useLocation();

  const notShowHeaderAndFooterRoutes = [
    '/cadastro',
    '/esqueci-meu-email',
    '/recuperar-senha',
    '/icons',
    '/style-guide',
  ];
  const isInArray = notShowHeaderAndFooterRoutes.findIndex((el) =>
    pathname.startsWith(el),
  );
  const showHeaderAndFooter = () => {
    return isInArray < 0;
  };
  const willHeaderAndFooterAppear = showHeaderAndFooter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <LoadingProvider>
        {willHeaderAndFooterAppear && <Header />}
        <Content>
          <Routes>
            <Route path={'*'} element={<NotFound />} />
            <Route path={'/'} element={<Home />} />
            <Route path={'/home'} element={<Home />} />
            <ProtectedRoute
              path={'/cadastro'}
              element={<Register />}
              noLogged
            />
            <Route path={'/recuperar-senha'} element={<RecoverPassword />} />
            <Route path={'/esqueci-meu-email'} element={<ForgotEmail />} />
            <Route path={'/icons'} element={<Icons />} />
            <Route path={'/style-guide/*'} element={<StyleGuide />} />
            <ProtectedRoute path={'/minha-conta/*'} element={<Account />} />
            <Route path={'/perfil/:profileUrl'} element={<ProfileStore />} />
            <Route path={'/pesquisa'} element={<Search />} />
            <Route
              path={'/produto/:profileUrl/:productSlug'}
              element={<Product />}
            />
            <Route
              path={'/produto/:profileUrl/:productSlug/:colorId'}
              element={<Product />}
            />
            <Route path={'/favoritos'} element={<Favorites />} />
            <Route
              path={'/politicas-privacidade'}
              element={<PrivacyPolicy />}
            />
            <Route path={'/termos'} element={<Terms />} />
            <Route path={'/carrinho/*'} element={<Checkout />} />
            <Route path={'/sobre'} element={<AboutHoupa />} />
            <Route path={'/ajuda'} element={<Help />} />
            <Route
              path={'/politicas-troca-devolucao'}
              element={<ReplacementReturnPolicy />}
            />
            <Route path={'/para-negocios'} element={<ForBusiness />} />
            <ProtectedRoute
              path={'/login/*'}
              element={<LoginOrRegister />}
              noLogged
            />
          </Routes>
        </Content>
        <CookiesAccept />
        {token && <TakeBlip needsLogin open={false} />}
        {!token &&
          pathname !== '/esqueci-meu-email' &&
          pathname !== '/login/recuperar-email' && <TakeBlip open={false} />}
        {willHeaderAndFooterAppear && <Footer />}
      </LoadingProvider>
    </Container>
  );
};

export default RoutesWrapper;
