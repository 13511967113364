import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  margin: 112px 188px 214px;

  @media (max-width: 1279px) {
    max-width: var(--container);
    margin: 50px 0 214px;
    padding: 0 10px;
  }

  @media (max-width: 607px) {
    max-width: var(--container);
    margin: 24px 0 214px;
    padding: 0 10px;
  }
`;

export const RowLogo = styled.div`
  display: flex;
  justify-content: flex-end;

  > i {
    font-size: 24px;
    color: var(--purple);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 44px;

  @media (max-width: 1279px) {
    margin-top: 36px;
  }

  @media (max-width: 607px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.h6`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const TextBold = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 30px;
`;

