import styled from 'styled-components';

export const Container = styled.section`
  > h3 {
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
    margin-bottom: 40px;
  }

  @media (max-width: 1279px) {
    > h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }
  }
`;

export const Content = styled.section`
  display: flex;

  > div {
    flex: 1;
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const PaymentContainer = styled.div`
  background-color: var(--grey-light-3);
  padding: 48px 80px;
  border-radius: 10px;

  > h2 {
    font-size: 24px;
    margin-bottom: 54px;
  }

  @media (max-width: 1279px) {
    padding: 32px;
  }

  @media (max-width: 607px) {
    padding: 16px;
  }
`;

export const PaymentTabs = styled.div`
  max-width: 678px;
  margin: 0 auto;

  @media (max-width: 1279px) {
    max-width: 544px;
  }
`;

export const PaymentHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 48px;
`;

export const TabsOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 25px;
  color: var(--grey-medium-2);
  cursor: pointer;

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  span:first-child {
    color: var(--grey-medium-2) !important;
  }

  .radio {
    max-width: 40px;

    span {
      padding: 0;
    }
  }

  > i {
    margin: 0 32px 0 24px;
  }

  > p {
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
  }

  &.active {
    color: var(--purple);

    span:first-child {
      color: var(--purple) !important;
    }
  }

  @media (max-width: 1279px) {
    > i {
      font-size: 24px;
      margin: 0 16px;
    }

    > p {
      font-size: 24px;
    }
  }

  @media (max-width: 607px) {
    > i {
      font-size: 18px;
      margin: 0 8px;
    }

    > p {
      font-size: 11px;
    }
  }
`;

export const PaymentContent = styled.div`
  .big-input {
    max-width: 100%;
    width: 100%;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 13px;
  }

  @media (max-width: 1279px) {
    .row {
      max-width: 100%;

      .small-input {
        max-width: 200px;
      }
    }
  }

  @media (max-width: 607px) {
    h1 {
      font-size: 16px;
    }
  }
`;

export const CardContainer = styled.div`
  h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  @media (max-width: 607px) {
    h4 {
      font-size: 16px;
    }
  }

  .newCardDiv {
    width: 100%;

    @media (min-width: 608px) {
      padding: 0 20px;
    }
  }
`;

export const SelectedCardContainer = styled.div`
  margin-bottom: 32px;
`;

export const BankSlipContainer = styled.div`
  .text {
    color: var(--grey-medium-2);
  }
`;

export const BankSlipTotal = styled.p`
  font-weight: 700;
  margin-top: 34px;
`;

export const TitleNewCard = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-left: 25px;

  @media (min-width: 37.9375em) {
    font-size: 16px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 16px;

  --input-width: 100%;
  --medium-input-width: 55%;
  --small-input-width: 40%;
  --card-number-input-width: 100%;

  @media (min-width: 608px) {
    --input-width: 212px;
    --medium-input-width: 35%;
    --small-input-width: 20%;
  }

  @media (min-width: 1279px) {
    --input-width: 295px;
    --card-number-input-width: 295px;
    --medium-input-width: 30%;
    --small-input-width: 15%;
  }

  .input {
    margin-bottom: 16px;
    max-width: var(--input-width);
  }

  .medium-input {
    max-width: var(--medium-input-width);
  }

  .small-input {
    max-width: var(--small-input-width);
  }

  .card-number-input {
    max-width: var(--card-number-input-width);
  }

  .checkbox {
    margin-bottom: 35px;
    width: 100%;

    &.wrap {
      @media (min-width: 1280px) {
        max-width: var(--input-width);
        padding-left: 5px;
      }
    }
  }

  .select {
    @media (min-width: 608px) {
      max-width: 60%;
    }

    @media (min-width: 1280px) {
      max-width: var(--input-width);
    }
  }

  .card-image {
    width: 188.13px;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1279px) {
      width: 126.55574817445977px;
    }

    @media (max-width: 607px) {
      width: 73.79px;
    }
  }

  .row-icon {
    display: flex;
    width: 100%;
    position: relative;

    .icon-area {
      position: absolute;
      right: 0;
      top: 23%;

      > i {
        font-size: 24px;
        color: ${(props) => props.theme.colors.greyLight1};
      }

      > img {
        height: 24px;
      }
    }

    @media (min-width: 608px) {
      width: 75%;
    }

    @media (min-width: 1280px) {
      width: var(--input-width);
    }
  }
`;
