import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Container,
  ProfileContainer,
  ProfileContent,
  ProfileIMG,
  Cover,
  CategoryRow,
  CategoryCard,
  Content,
  FilterContainer,
  TabsHeader,
  TabsContainer,
  Body,
  FilterRow,
  InputContainer,
  Row,
  ResultText,
  SelectContainer,
  TagsRow,
  GridLayout,
  BlockContainer,
  ShowMobile,
  ShowDesktop
} from './styles';
import FilterProduct from '../../components/FilterProduct';
import { Box, Tab } from '@material-ui/core';
import Search from './components/Search';
import GridBlocks from '../../components/GridBlocks';
import Select from '../../components/Select';
import ButtonFilters from '../../components/ButtonFilters';
import Breadcrumb from '../../components/Breadcrumb';
import BackButton from '../../components/BackButton';

import ProductCard from '../../components/ProductCard';
import Pagination from '../../components/Pagination';
import { Filter, ObjectFilter } from '../../components/FilterProduct/interface';
import { api } from '../../services/api';
import NotFound from '../NotFound';
import { ProductOrders } from '../../interfaces/orderProduct';
import { useQuery } from '../../hooks/useQuery';
import { useFilterString } from '../../hooks/useFilterString';
import { useLoading } from '../../hooks/useLoading';

interface Category {
  id: string;
  name: string;
}

interface Profile {
  profileImage?: string;
  coverImage?: string;
  name: string;
  description?: string;
  categories: Category[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const ProfileStore: React.FC = () => {
  const { profileUrl } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const { showLoading, closeLoading } = useLoading()

  const searchParam = query.get('s');
  const pageParam = query.get('p');

  const [filter, setFilter] = useState<Filter[]>([]);
  const [filters, setFilters] = useState<ObjectFilter>({} as ObjectFilter);
  const [value, setValue] = React.useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [resultsCount, setResultsCount] = useState(0);
  const [grid, setGrid] = React.useState('four-grid');
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<string>('');
  const [products, setProducts] = useState<ProductOrders[]>([]);
  const [profile, setProfile] = useState<Profile | null>({
    profileImage: '',
    coverImage: '',
    name: '',
    description: '',
    categories: []
  } as Profile);

  const [filterString, { setFilterString, changeFilterString, editFilterString }] = useFilterString();

  const page = useRef(1);
  const tab = useRef('');
  const order = useRef('');
  const search = useRef('');
  const options = useRef([
    {
      key: 'two-grid',
      columns: 2,
    },
    {
      key: 'three-grid',
      columns: 3,
    },
    {
      key: 'four-grid',
      columns: 4,
    }
  ]).current;

  const executeScroll = useCallback(
    () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [],
  );

  const getFilters = useCallback(async () => {
    try {
      const { data } = await api.get(`filters/${profileUrl}`);
      setFilters(data);
    } catch (e: any) {
      console.log(e);
    }
  }, [profileUrl]);

  const getProducts = useCallback(async () => {
    try {
      showLoading()
      const { data } = await api.get(`seller/products/${profileUrl}?search=${search.current}&page=${page.current}&order=${order.current}&tab=${tab.current}${filterString.current}`);
      setProducts(data.products);
      setTotalPages(data.pages);
      setResultsCount(data.results);
    } catch (e: any) {
      console.log(e);
    } finally {
      executeScroll();
      closeLoading()
    }
    // eslint-disable-next-line
  }, [executeScroll, filterString, profileUrl]);

  const callSetFilterString = useCallback((value: string) => {

    setFilterString(value, getProducts);

  }, [getProducts, setFilterString]);

  const getProfile = useCallback(async () => {
    if (!profileUrl) {
      setProfile(null);
    }

    try {
      showLoading()
      const { data } = await api.get(`/seller/${profileUrl}`);
      setProfile(data);
      await Promise.all([getProducts(), getFilters()]);
    } catch (e: any) {
      closeLoading()
      if (e.status === 404) {
        setProfile(null);
      }
    }
    // eslint-disable-next-line
  }, [getFilters, getProducts, profileUrl]);

  useEffect(() => {
    if (searchParam !== null) {
      search.current = searchParam;
    }

    if (pageParam !== null) {
      page.current = Number(pageParam);
    }
    getProfile().then();
  }, [getProfile, pageParam, profileUrl, searchParam]);

  useEffect(() => {
    if (selectedOrder.length === 0) {
      return;
    }

    order.current = selectedOrder;
    getProducts().then();
  }, [getProducts, selectedOrder]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const removeFilter = (item: Filter) => {
    callSetFilterString(changeFilterString(item));
    const data = filter.filter(i => i !== item);

    filters[item.type].map(i => {
      if (i.id === item.id) {
        i.checked = false;
      }
    });

    setFilter(data);
  };

  const nextPage = useCallback(() => {
    if (page.current === totalPages || totalPages === 0) {
      return false;
    }

    page.current = page.current + 1;
    navigate(`?s=${search.current}&p=${page.current}`);
  }, [navigate, totalPages]);

  const previousPage = useCallback(() => {
    if (page.current === 1 || totalPages === 0) {
      return false;
    }
    page.current = page.current - 1;
    navigate(`?s=${search.current}&p=${page.current}`);
  }, [navigate, totalPages]);

  const handleChangeTab = useCallback(async (tabId: string) => {
    tab.current = tabId;
    await getProducts();
  }, [getProducts]);

  if (profile === null) {
    return <NotFound />;
  }

  return (
    <Container>
      <ShowDesktop>
        <Breadcrumb values={[
          { title: 'Início', link: '/' },
          { title: 'Perfil', link: '' },
          { title: profile.name, link: '' }
        ]} />
      </ShowDesktop>
      <ShowMobile>
        <BackButton onClick={() => window.history.back() }/>
      </ShowMobile>
      <ProfileContainer>
        <ProfileContent>
          <ProfileIMG>
            <img src={profile.profileImage} alt={`foto de ${profile.name}`} />
          </ProfileIMG>

          <h3>{profile.name}</h3>
          <p>{profile.description}</p>

          <CategoryRow>
            {profile.categories.map((category) => (
              <CategoryCard key={category.id}>{category.name}</CategoryCard>
            ))}
          </CategoryRow>
        </ProfileContent>
        <Cover>
          <img src={profile.coverImage} alt="" />
        </Cover>
      </ProfileContainer>

      <Content>
        <FilterContainer>
          <FilterProduct
            data={filters}
            filter={filter}
            setFilter={setFilter}
            filterString={filterString.current}
            setFilterString={callSetFilterString}
            changeFilterString={changeFilterString}
            editFilterString={editFilterString}
          />
        </FilterContainer>
        <Body>
          <FilterRow>
            <InputContainer>
              <Search placeholder={'Buscar na ' + profile.name} />
            </InputContainer>

            <Row>
              <ResultText>
                <span>{resultsCount}</span> Resultados
              </ResultText>

              <BlockContainer>
                <GridBlocks
                  changeGrid={setGrid}
                  value={grid}
                  options={options}
                />
              </BlockContainer>

              <SelectContainer>
                <Select
                  disabledOption="Ordenar por"
                  options={[
                    {
                      id: 'release',
                      name: 'Lançamento'
                    },
                    {
                      id: 'popularity',
                      name: 'Mais populares'
                    },
                    {
                      id: 'biggerDiscount',
                      name: 'Maior desconto'
                    },
                    {
                      id: 'smallerDiscount',
                      name: 'Menor desconto'
                    },
                    {
                      id: 'biggerPrice',
                      name: 'Maior preço'
                    },
                    {
                      id: 'smallerPrice',
                      name: 'Menor preço'
                    },
                    {
                      id: 'AZ',
                      name: 'A-Z'
                    },
                  ]}
                  selectError={selectError}
                  setselectError={setSelectError}
                  selectedValue={selectedOrder}
                  setSelectedValue={setSelectedOrder}
                  required={false}
                />
              </SelectContainer>
            </Row>
          </FilterRow>
          <TabsContainer>
            <TabsHeader
              centered
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="TUDO" {...a11yProps(0)} onClick={() => handleChangeTab('all')} />
              <Tab label="VITRINE" {...a11yProps(1)} onClick={() => handleChangeTab('showcase')} />
              <Tab label="LANÇAMENTOS" {...a11yProps(2)} onClick={() => handleChangeTab('release')} />
              <Tab label="PROMOÇÕES"  {...a11yProps(3)} onClick={() => handleChangeTab('selloff')} />
            </TabsHeader>

            {filter.length > 0 && (
              <TagsRow>
                {filter.map((item, key) => (
                  <ButtonFilters onClick={() => removeFilter(item)} key={key}>
                    {item.name}
                  </ButtonFilters>
                ))}
              </TagsRow>
            )}

            <TabPanel value={value} index={0}>
              <GridLayout className={grid}>
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    productId={Number(product.id)}
                    productSlug={product.productSlug}
                    profileUrl={product.sellerProfileUrl}
                    image={product.mainImage}
                    hoverImage={product.mainImage}
                    title={product.name}
                    price={product.price}
                    pricePromotion={product.originalPrice}
                    parcel={product.paymentCondition}
                    selloffPercentage={product.selloffPercentage}
                    isFavorite={product.isFavorite}
                    inProfile={true}
                  />
                ))}
              </GridLayout>
              {totalPages > 1 && <Pagination
                index={
                  page.current <= 9
                    ? `0${page.current.toString()}`
                    : page.current.toString()
                }
                max={totalPages.toString()}
                onClick={(direction: string) =>
                  direction === 'next' ? nextPage() : previousPage()
                }
              />}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <GridLayout className={grid}>
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    productId={Number(product.id)}
                    productSlug={product.productSlug}
                    profileUrl={product.sellerProfileUrl}
                    image={product.mainImage}
                    hoverImage={product.mainImage}
                    title={product.name}
                    price={product.price}
                    pricePromotion={product.originalPrice}
                    parcel={product.paymentCondition}
                    selloffPercentage={product.selloffPercentage}
                    isFavorite={product.isFavorite}
                  />
                ))}
              </GridLayout>
              {totalPages > 1 && <Pagination
                index={
                  page.current <= 9
                    ? `0${page.current.toString()}`
                    : page.current.toString()
                }
                max={totalPages.toString()}
                onClick={(direction: string) =>
                  direction === 'next' ? nextPage() : previousPage()
                }
              />}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <GridLayout className={grid}>
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    productId={Number(product.id)}
                    productSlug={product.productSlug}
                    profileUrl={product.sellerProfileUrl}
                    image={product.mainImage}
                    hoverImage={product.mainImage}
                    title={product.name}
                    price={product.price}
                    pricePromotion={product.originalPrice}
                    parcel={product.paymentCondition}
                    selloffPercentage={product.selloffPercentage}
                    isFavorite={product.isFavorite}
                  />
                ))}
              </GridLayout>
              {totalPages > 1 && <Pagination
                index={
                  page.current <= 9
                    ? `0${page.current.toString()}`
                    : page.current.toString()
                }
                max={totalPages.toString()}
                onClick={(direction: string) =>
                  direction === 'next' ? nextPage() : previousPage()
                }
              />}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <GridLayout className={grid}>
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    productId={Number(product.id)}
                    productSlug={product.productSlug}
                    profileUrl={product.sellerProfileUrl}
                    image={product.mainImage}
                    hoverImage={product.mainImage}
                    title={product.name}
                    price={product.price}
                    pricePromotion={product.originalPrice}
                    parcel={product.paymentCondition}
                    selloffPercentage={product.selloffPercentage}
                    isFavorite={product.isFavorite}
                  />
                ))}
              </GridLayout>
              {totalPages > 1 && <Pagination
                index={
                  page.current <= 9
                    ? `0${page.current.toString()}`
                    : page.current.toString()
                }
                max={totalPages.toString()}
                onClick={(direction: string) =>
                  direction === 'next' ? nextPage() : previousPage()
                }
              />}
            </TabPanel>
          </TabsContainer>
        </Body>
      </Content>
    </Container>
  );
};

export default ProfileStore;
