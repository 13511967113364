import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Safety } from '../../../../assets/svg/safety.svg';
import { SummaryContainer, SafetyContainer } from '../../styles';
import {
  AddressContainer,
  AddressTitleContainer,
  ButtonAddAddress,
  ButtonContainer,
  Container,
  FreightArea,
  FreightContent,
  FreightProducts,
  EndYearContainer
} from './styles';
import OrderSummary from '../../components/OrderSummary';
import AddressCard from '../../components/AddressCard';
import ModalAddress from '../../../../components/ModalAddress';
import { AddressInterface } from '../../../../interfaces/address';
import FreightProductsItem from '../../components/FreightProductsItem';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../services/api';
import ModalAddressList from '../../../../components/ModalAddressList';
import { useToast } from '../../../../hooks/useToast';
import { useContext } from 'react';
import { CartContext } from '../..';
import ModalMoipAccount from './components/ModalMoipAccount';
import { useAlert } from '../../../../hooks/useAlert';
import useMedia from '../../../../hooks/useMedia';
import { isBefore } from 'date-fns/esm';

const useModalClientData = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const toggleModal = () => setIsModalOpened((prevState) => !prevState);

  const closeModal = () => setIsModalOpened(false);

  const renderModal = useCallback(
    (onSave: () => void) => {
      if (!isModalOpened) {
        return null;
      }

      return (
        <ModalMoipAccount
          open={isModalOpened}
          onClose={closeModal}
          onSave={onSave}
        />
      );
    },
    [isModalOpened],
  );

  return { toggleModal, renderModal } as const;
};

const Address: React.FC = () => {
  const mobile = useMedia('(max-width: 607px)');
  const navigate = useNavigate();
  const { toast } = useToast();
  const { showAlert } = useAlert();

  const {
    brandsWithProducts: { brands },
    order: { orderConfig },
    address: {
      selectedAddress,
      setSelectedAddress,
      addresses,
      getAddresses,
      results,
    },
  } = useContext(CartContext);

  const { renderModal, toggleModal } = useModalClientData();

  const [errorAddress, setErrorAddress] = useState(false);
  const [edit, setEdit] = useState<AddressInterface>({} as AddressInterface);
  const [modal, setModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const updateAddress = (addressObj: AddressInterface) => {
    setEdit(addressObj);
    setModal(true);
  };

  const navigatePayments = useCallback(() => navigate('/carrinho/pagamentos'), [
    navigate,
  ]);


  const onClickAdvance = () => {
    const showEndYearModal = isBefore(new Date(), new Date(2022, 0, 11));

    if (showEndYearModal) {
      return showAlertEndYear(); 
    }

    nextStage();
  }

  const showAlertEndYear = () => {
    const description = <EndYearContainer>
      <p>Atenção!</p>
      <p>Durante o período de festividades, o prazo de envio para os pedidos realizados será estendido por conta do alto volume de entregas. Por isso, contamos com a sua compreensão.</p>
      <p className='end'>Boas compras e boas festas</p>
    </EndYearContainer>

    showAlert({
      type: 'warning',
      description: description,
      submitText: 'Ok',
      onSubmit: () => nextStage(),
      isBottom: mobile || false,
    })
  }

  const nextStage = useCallback(async () => {
    if (addresses.length === 0) {
      setErrorAddress(true);

      toast({
        type: 'error',
        description:
          'Cadastre e defina um endereço para entrega do seu pedido.',
      });

      return false;
    }

    const response = await api.get('moip-customers');

    if (!response.data && !orderConfig.clientData) {
      toggleModal();
      return;
    }

    navigatePayments();
  }, [
    addresses.length,
    navigatePayments,
    orderConfig.clientData,
    toast,
    toggleModal,
  ]);

  const handleUpdateAddress = useCallback(
    (updatedAddress: AddressInterface) => {
      setSelectedAddress(updatedAddress);
      getAddresses(true);
    },
    [getAddresses, setSelectedAddress],
  );

  const handleAddAddress = useCallback(
    async (form: Omit<AddressInterface, 'id'>) => {
      try {
        const { data } = await api.post('/addresses', form);

        setSelectedAddress(data);

        toast({
          type: 'success',
          description: 'Endereço cadastrado com sucesso!',
        });
        getAddresses(true);
      } catch (e: any) {
        toast({
          type: 'error',
          description: 'Erro ao tentar cadastrar',
        });
      }
    },
    [getAddresses, setSelectedAddress, toast],
  );

  useEffect(() => {
    if (!modal) setEdit({} as AddressInterface);
  }, [modal]);

  useEffect(() => {
    if (addresses.length > 0) {
      setErrorAddress(false);
    }
  }, [addresses]);

  return (
    <Container>
      <div>
        <AddressContainer>
          <h1>Meus Endereços</h1>
          <AddressTitleContainer>
            <ButtonAddAddress onClick={() => setModal(true)}>
              <div>+</div>
              Adicionar novo endereço
            </ButtonAddAddress>
          </AddressTitleContainer>
          <AddressCard
            error={errorAddress}
            updateAddress={updateAddress}
            data={selectedAddress}
          />
          {results > 1 && (
            <ButtonContainer>
              <button onClick={() => setListModal(true)}>
                Escolher outro endereço
              </button>
            </ButtonContainer>
          )}
        </AddressContainer>

        <FreightArea>
          {brands.map((brand, key) => (
            <FreightContent key={brand.id}>
              <h2>Encomenda {key + 1}</h2>
              <p>
                Envio {key + 1} de {brands.length} (Enviado por {brand.name})
              </p>
              {brand.address !== undefined && (
                <p className={'small address'}>
                  {`${brand.address.street}, ${brand.address.streetNumber} - ${brand.address.city}/${brand.address.state} - ${brand.address.zipCode}`}
                </p>
              )}

              <FreightProducts>
                {brand.products.map((product) => (
                  <FreightProductsItem
                    data={product}
                    key={product.cartProductId}
                  />
                ))}
              </FreightProducts>
            </FreightContent>
          ))}
        </FreightArea>
      </div>
      <SummaryContainer>
        <OrderSummary text={'Avançar'} onClick={onClickAdvance}/>
        <SafetyContainer>
          <Safety />
          <p>COMPRA 100% SEGURA</p>
        </SafetyContainer>
      </SummaryContainer>

      {listModal && (
        <ModalAddressList
          modal={listModal}
          setModal={setListModal}
          onSelectAddress={setSelectedAddress}
          addresses={addresses}
          selectedAddress={selectedAddress}
          loadMoreAddresses={getAddresses}
          totalAddresses={results}
        />
      )}

      {modal && (
        <ModalAddress
          modal={modal}
          edit={edit}
          setModal={setModal}
          onUpdateAddress={handleUpdateAddress}
          onAddAddress={handleAddAddress}
        />
      )}

      {renderModal(navigatePayments)}
    </Container>
  );
};

export default Address;
