import styled from 'styled-components';
import {ReactComponent as NotFound} from '../../assets/svg/notfound.svg';

export const Container = styled.section`
  max-width: var(--container);
  margin: 0 auto;
  padding: 27px 0 72px;

  > a {
    font-size: 12.11px;
    color: var(--purple-business-dark);
  }

  @media (min-width: 607px) {
    padding: 54px 0 44px;
  }

  @media (min-width: 1279px) {
    padding: 64px 0 81px;
    > a {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 83px;
  display: flex;
  flex-direction: column;

  > div:last-child {
    display: none;
  }

  @media (min-width: 607px) {
    max-width: 452px;
    margin: 54px auto 0;
  }

  @media (min-width: 1279px) {
    max-width: 1089.01px;
    flex-direction: row;
    margin-top: 64px;

    > div:last-child {
      display: initial;
    }
  }
`;

export const Image = styled(NotFound)`
  width: 231px;
  height: 161px;
  margin: 32px 0;

  @media (min-width: 607px) {
    width: 295.01px;
    height: 205.01px;
    margin: 32px 0 54px;
  }

  @media (min-width: 1279px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: 1279px) {
    flex: 1;
    max-width: 518px;
    text-align: left;
    align-items: initial;

    > svg {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  color: var(--purple);
  font-size: 24px;
  font-family: Poppins-Light, Poppins-Regular, Roboto-Regular, sans-serif, Arial;
  font-weight: 300;

  @media (min-width: 1279px) {
    font-size: 60px;
    margin-bottom: 16px;
    font-weight: 300;
  }
`;

export const Subtitle = styled.h3`
  line-height: 24px;
  font-size: 16px;
  font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 32px;

  strong {
    font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
  }

  @media (min-width: 607px) {
    font-size: 18px;
    max-width: 401px;
    line-height: 27px;
  }

  @media (min-width: 1279px) {
    font-size: 20px;
    line-height: 30px;
  }
`;


export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: var(--grey-medium-1);

  @media (min-width: 1279px) {
    font-size: 16px;
    line-height: 19px;
  }
`;


export const AreaImg = styled.div`
  flex: 1;
  margin-left: 93px;
`;
