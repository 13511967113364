import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 112px;
  display: grid;
  grid-template-columns: 518px minmax(700px, 778px) ;
  align-items: start;
  width: 100%;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 60px;
    grid-template-columns: 220px minmax(300px, 390px);
  }
  @media screen and (max-width: 607px) {
    margin-top: 70px;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
  }
`;

export const MoreAboutTextWrapper = styled.div`
  padding: 24px;
  box-shadow: 0 12px 24px 0 #E5E5E5;
  background-color: var(--white);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-bottom: 70px;
    grid-column: 2/-1;
  }
  @media screen and (max-width: 607px) {
    margin-bottom: 63px;
    grid-column: 1/-1;
    grid-row: 1;
  }
`;

export const MoreAboutTitle = styled.div` 
  font-family: var(--poppins);
  font-size: 24px;
  color: var(--purple);
  line-height: 42px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }
  @media screen and (max-width: 607px) {
    font-size: 16px;
    line-height: 19px;
    max-width: 100%;
  }
`;

export const Bold = styled.span` 
  font-weight: 700;
  font-family: var(--poppins);
`;

export const MoreAboutText = styled.p`
  margin-top: 10px; 
  font-size: 18px;
  line-height: 35px;
  color: var(--grey-medium-1);
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 30px;
  }
`;

export const SolutionsText = styled(MoreAboutText)`
  line-height: 2.5;
`;

export const UserImgWrapper = styled.div`
  align-self: end;
  @media screen and (max-width: 607px) {
    grid-column: 1/-1;
    max-width: 220px;
    margin: 0 auto;
  }
`;